import InventoryMainMenu from '@/views/inventory/menus/InventoryMainMenu'
import itemRoutes from './models/item.routes'
import categoryRoutes from './models/category.routes'
import contractorRoutes from './models/contractor.routes'
import supplierRoutes from './models/supplier.routes'

export default [
  {
    path: '/inventory',
    name: 'InventoryMainMenu',
    component: InventoryMainMenu,
    meta: {
      type: 'menu',
      requiresAuth: true,
    },
    children: [
      // Keep the same order as in the menu of the related application.
      ...itemRoutes,
      ...categoryRoutes,
      ...supplierRoutes,
      ...contractorRoutes,
    ],
  }
]
