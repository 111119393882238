<template>
  <contact-contact-tag-form
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import ContactContactTagForm from './shared/ContactContactTagForm'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'ContactContactTagFormCreate',
  mixins: [ DialogUnsavedChanges ],
  components: { ContactContactTagForm },
  data () {
    return {
      formIsEditing: true
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
