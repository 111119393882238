import store from '../../store/index'

function hasAccess (appName) {
  if (store.state.login.me) {
    return store.state.login.me.accesses?.includes(appName)
  }
  return false
}

export { hasAccess }
export default {
  methods: {
    hasAccess
  }
}
