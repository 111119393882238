<script>
import WarningModal from '../components/WarningModal.vue'
export default {
  beforeRouteLeave (to, from, next) {
    if (this.formIsEditing) {
      this.$dialog.show(WarningModal, {
        icon: 'content-save-alert',
        title: 'Discard Changes ?',
        message: 'There are potential unsaved changes.',
        advice: 'Do you want to discard them ?',
        okText: 'DISCARD',
        okColor: 'red',
        noButton: true,
        noText: 'CANCEL',
        persistent: true,
        waitForResult: true,
      }).then(discard => {
        if (discard === true) next()
        return false
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
        return false
      })
    } else {
      next()
    }
  }
}
</script>
