<template>
  <div>
    <v-toolbar
      v-if="hasPermission('add_apikey')"
      flat
      height=36
      color="grey lighten-4"
      dense
      class="ma-4">
      <v-toolbar-items>
        <v-btn v-if="hasPermission('add_apikey')" text @click="createKey">
          <v-icon small left>mdi-plus</v-icon>
          Create Another
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider/>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="apiKeys"
      :hide-default-footer="apiKeys.length < 10">
      <template v-slot:loading>
        <div class="my-12">Loading...</div>
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
        <v-icon v-if="item.is_active" color="green">mdi-check-bold</v-icon>
        <v-icon v-else color="red">mdi-close-thick</v-icon>
      </template>
      <template v-slot:[`item.create_date`]="{ item }">
        {{ item.create_date | moment("calendar") }}
      </template>
      <template v-slot:[`item.last_activation_date`]="{ item }">
        <template v-if="item.last_activation_date">
          {{ item.last_activation_date | moment("calendar") }}
        </template>
      </template>
      <template v-slot:[`item.last_deactivation_date`]="{ item }">
        <template v-if="item.last_deactivation_date">
          {{ item.last_deactivation_date | moment("calendar") }}
        </template>
      </template>
      <template v-slot:[`item.last_used_date`]="{ item }">
        <template v-if="item.last_used_date">
          {{ item.last_used_date | moment("calendar") }}
        </template>
      </template>
      <template v-slot:[`item.token`]="{ item }">
        {{ item.token | truncate(9, '****-****-****-************') }}
        <v-tooltip bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="copyToken(item)" icon>
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          Copy to Clipboard
        </v-tooltip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          v-if="hasPermission('change_apikey')"
          bottom
          open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="rotateKey(item)" icon>
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </template>
          Rotate
        </v-tooltip>
        <v-tooltip
          v-if="hasPermission('change_apikey')"
          bottom
          open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              @click="item.is_active ? deactivateKey(item) : activateKey(item)">
              <v-icon v-if="item.is_active">mdi-archive-arrow-down</v-icon>
              <v-icon v-else>mdi-archive-arrow-up</v-icon>
            </v-btn>
          </template>
          {{ item.is_active ? 'Deactivate' : 'Activate' }}
        </v-tooltip>
        <!-- <v-tooltip v-if="hasPermission('')" bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="editKey(item)" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          Edit
        </v-tooltip> -->
        <v-tooltip
          v-if="hasPermission('delete_apikey')"
          bottom
          open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="deleteKey(item)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          Delete
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import APIKeyFormDialog from './APIKeyFormDialog'
import BaseSettingsService from '@/services/base/base.settings.service'
import WarningModal from '@/common/components/WarningModal'
import DeleteModal from '@/common/components/DeleteModal'
export default {
  name: 'APIKeyList',
  data () {
    return {
      loading: false,
      apiKeys: [],
      headers: [
        { value: 'name', text: 'Name' },
        { value: 'token', text: 'Token' },
        { value: 'create_date', text: 'Creation Date'},
        { value: 'is_active', text: 'Active' },
        { value: 'last_activation_date', text: 'Last Activation Date' },
        { value: 'last_deactivation_date', text: 'Last Deactivation Date' },
        { value: 'last_used_date', text: 'Last Used Date' },
        { value: 'actions', text: 'Actions', sortable: false },
      ],
    }
  },
  async mounted () {
    await this.getAPIKeys()
  },
  methods: {
    copyToken (apiKey) {
      navigator.clipboard.writeText(apiKey.token)
      this.$dialog.notify.success(
        'API Key Token copied to clipboard', {
          position: 'top-right',
          border: 'left',
          dense: true,
          timeout: 5000,
        }
      )
    },
    async getAPIKeys () {
      this.loading = true
      this.apiKeys = await BaseSettingsService.getAPIKeys()
      this.loading = false
    },
    createKey () {
      this.$dialog.show(APIKeyFormDialog, {
        persistent: true,
        waitForResult: true
      }).then(async result => {
        if (result) {
          const createdAPIKey = await BaseSettingsService.createAPIKey({
            name: result.name,
            is_active: result.is_active,
          })
          if (createdAPIKey) {
            this.apiKeys.push(createdAPIKey)
            this.$dialog.notify.success(
              'API Key created', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    rotateKey (key) {
      this.$dialog.show(WarningModal, {
        icon: 'sync',
        title: 'Rotate API Key ?',
        message: `Are you sure you want to rotate '${key.name}' API Key ?`,
        okText: 'YES',
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const rotatedKey = await BaseSettingsService.rotateAPIKey(key.id)
          if (rotatedKey) {
            this.apiKeys.splice(this.apiKeys.indexOf(key), 1, rotatedKey)
            this.$dialog.notify.success(
              `API Key '${key.name}' rotated`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    activateKey (key) {
      this.$dialog.show(WarningModal, {
        icon: 'archive-arrow-down',
        title: 'Activate API Key ?',
        message: `Are you sure you want to activate '${key.name}' API Key ?`,
        okText: 'YES',
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const activatedKey = await BaseSettingsService.activateAPIKey(key.id)
          if (activatedKey) {
            this.apiKeys.splice(this.apiKeys.indexOf(key), 1, activatedKey)
            this.$dialog.notify.success(
              `API Key '${key.name}' activated`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deactivateKey (key) {
      this.$dialog.show(WarningModal, {
        icon: 'archive-arrow-down',
        title: 'Deactivate API Key ?',
        message: `Are you sure you want to deactivate '${key.name}' API Key ?`,
        okText: 'YES',
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const deactivatedKey = await BaseSettingsService.deactivateAPIKey(key.id)
          if (deactivatedKey) {
            this.apiKeys.splice(this.apiKeys.indexOf(key), 1, deactivatedKey)
            this.$dialog.notify.success(
              `API Key '${key.name}' deactivated`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    // editKey () {
    //   console.log('...not yet implemented...')
    // },
    deleteKey (key) {
      this.$dialog.show(DeleteModal, {
        recordName: 'API Key',
        recordNamePlural: 'API Keys',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await BaseSettingsService.deleteAPIKey(key.id)
          if (response) {
            this.apiKeys.splice(this.apiKeys.indexOf(key), 1)
            this.$dialog.notify.success(
              `API Key '${key.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    }
  },
}
</script>
