<template>
  <v-card tile elevation="3">
    <v-toolbar
      flat
      dense
      v-if="
        company
        && !editing
        && (
          hasPermission('add_contactcompany')
          || hasPermission('change_contactcompany')
          || hasPermission('delete_contactcompany')
        )
      ">
      <v-toolbar-items>
        <v-btn
          v-if="hasPermission('change_contactcompany')"
          text
          @click="edit()">
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          {{ $t('common.buttons.edit') }}
        </v-btn>
        <v-btn
          v-if="hasPermission('delete_contactcompany')"
          text
          @click="deleteCompany(company)">
          <v-icon small class="mr-1">mdi-delete</v-icon>
          {{ $t('common.buttons.delete')}}
        </v-btn>
        <v-btn
          v-if="hasPermission('add_contactcompany')"
          text
          :to="{ name: 'ContactCompanyFormCreate' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          {{ $t('common.buttons.create') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider v-if="company"/>
    <v-tabs vertical background-color="grey lighten-4">
      <v-tab>
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-form-select</v-icon>
          </template>
          {{ $t('common.labels.company') }}
        </v-tooltip>
      </v-tab>
      <v-tab :disabled="!company">
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-office-building-marker</v-icon>
          </template>
          {{ $t('common.labels.addresses') }}
        </v-tooltip>
      </v-tab>
      <v-tab-item>
        <template v-if="!loadingCompany">
          <v-form
            class="pa-4"
            v-model="isFormValid"
            :disabled="!editing">
            <v-text-field
              v-model="form.name"
              required
              clearable
              autofocus
              type="text"
              maxlength="255"
              :label="$t('common.labels.name')"
              :counter="editing"
              :rules="[formRules.required]"/>
            <v-combobox
              v-model="form.domains"
              multiple
              small-chips
              deletable-chips
              :label="$t('common.labels.domains')"
              :delimiters="[',', ' ']"
              :hint="$t('common.hints.pressToValidate')"
              :persistent-hint="editing"
              :rules="[
                formRules.validDomains,
              ]"/>
            <v-text-field
              v-model="form.phone"
              clearable
              type="tel"
              maxlength="100"
              :label="$t('common.labels.phone')"
              :counter="editing"/>
            <v-text-field
              v-model="form.email"
              clearable
              type="email"
              maxlength="254"
              :label="$t('common.labels.email')"
              :counter="editing"
              :rules="[formRules.validEmail]"/>
            <v-autocomplete
              v-if="isHelpdeskAppActivated"
              v-model="form.sla_level"
              clearable
              item-text="name"
              return-object
              :label="`${$t('common.labels.slaLevel')} (HELPDESK)`"
              :loading="loadingSLALevels"
              :items="slaLevels"
              @click="getSLALevels()"/>
          </v-form>
          <template v-if="editing">
            <v-divider/>
            <v-card-actions>
              <v-btn
                text
                @click="cancel()">
                {{ $t('common.buttons.cancel') }}
              </v-btn>
              <v-btn
                text
                color="green"
                :disabled="!isFormValid"
                v-if="hasPermission('change_contactcompany') || hasPermission('add_contactcompany')"
                @click="!company ? createCompany() : updateCompany(company)">
                {{ $t('common.buttons.save') }}
              </v-btn>
            </v-card-actions>
          </template>
        </template>
        <template v-else>
          <v-col cols=12 align="center" justify="center">
            <h-request-loading/>
          </v-col>
        </template>
      </v-tab-item>
      <v-tab-item v-if="company" class="pa-4">
        <v-toolbar
          flat
          height=36
          color="grey lighten-4"
          class="mb-4">
          <v-toolbar-items>
            <v-btn
              text
              small
              v-if="hasPermission('add_contactcompanyaddress')"
              @click="createAddress(company)">
              <v-icon small class="mr-1">mdi-plus</v-icon>
              {{ $t('common.buttons.addAddress') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :loading="loadingCompanyAddresses"
                :headers="companyAddressesHeaders"
                :items="companyAddresses"
                :hide-default-footer="true">
                <template v-slot:[`item.actions`]="{ item }">
                  <!-- <v-tooltip bottom open-delay="250">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon>
                        <v-icon>mdi-map-search</v-icon>
                      </v-btn>
                    </template>
                    Show on map
                  </v-tooltip> -->
                  <v-tooltip v-if="hasPermission('change_contactcompanyaddress')" bottom open-delay="250">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click="editAddress(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    {{ $t('common.buttons.edit') }}
                  </v-tooltip>
                  <v-tooltip v-if="hasPermission('delete_contactcompanyaddress')" bottom open-delay="250">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" @click="deleteAddress(item)" icon>
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    {{ $t('common.buttons.delete') }}
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col>
            <!-- TODO: Integrate Google Maps or similar -->
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DeleteModal from '@/common/components/DeleteModal'
import ContactCompanyAddressFormDialog from '../../address/shared/ContactCompanyAddressFormDialog'
import ContactCompanyService from '@/services/contact/contact.company.service'
import HelpdeskSLALevelService from '@/services/helpdesk/helpdesk.sla.level.service'
export default {
  name: 'ContactCompanyForm',
  components: {
    HRequestLoading,
  },
  props: {
    editing: { type: Boolean },
    companyId: { type: [String, Number], default: () => { return null } },
  },
  data () {
    return {
      loadingCompany: false,
      company: null,
      loadingCompanyAddresses: false,
      companyAddresses: [],
      loadingSLALevels: false,
      slaLevels: [],
      form: {
        name: '',
        domains: [],
        phone: '',
        email: '',
        sla_level: null,
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || `${this.$t('common.labels.required')}.`,
        validDomains: value => {
          const domainPattern = /^((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/
          return value.every(domain => domainPattern.test(domain)) || 'One of the domains is invalid.'
        },
        validEmail: value => {
          const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || emailPattern.test(value) || `${this.$t('common.errors.invalidEmail')}.`
        },
      },
    }
  },
  async mounted () {
    if (this.companyId) {
      await this.getCompany(this.companyId)
      await this.getCompanyAddresses(this.companyId)
      if (this.company) this.initFormFields()
    }
  },
  computed: {
    isHelpdeskAppActivated () {
      return this.$store.state.base.activatedApplications.find(app => app.name === 'helpdesk') !== undefined
    },
    companyAddressesHeaders () {
      return [
        { value: 'name', text: this.$t('common.labels.name') },
        { value: 'string_repr', text: this.$t('common.labels.address') },
        { value: 'actions', text: this.$t('common.labels.actions'), sortable: false },
      ]
    },
  },
  methods: {
    async getCompany (companyId) {
      this.loadingCompany = true
      this.company = await ContactCompanyService.getCompany(companyId)
      this.loadingCompany = false
    },
    async getCompanyAddresses (companyId) {
      this.loadingCompanyAddresses = true
      this.companyAddresses = await ContactCompanyService.getCompanyAddresses(companyId)
      this.loadingCompanyAddresses = false
    },
    async getSLALevels () {
      this.loadingSLALevels = true
      this.slaLevels = await HelpdeskSLALevelService.getSLALevelsInput()
      this.loadingSLALevels = false
    },
    initFormFields () {
      for (let field in this.form) {
        if (field === 'sla_level') this.slaLevels = this.company[field]
        this.form[field] = this.company[field]
      }
    },
    edit () {
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.company) {
        this.initFormFields()
      } else {
        history.back()
      }
    },
    async createCompany () {
      const { name, domains, phone, email, sla_level } = this.$data.form
      const createdCompany = await ContactCompanyService.createCompany({
        name: name,
        domains: domains,
        phone: phone || '',
        email: email || '',
        sla_level_id: sla_level ? sla_level.id : null,
      })
      if (createdCompany) {
        this.cancelEdit()
        this.$router.push({ name: 'ContactCompanyFormRead', params: { companyId: createdCompany.id } })
        this.$dialog.notify.success(
          this.$t('applications.contact.company.messages.companyCreated', { name: createdCompany.name }), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async updateCompany (company) {
      const { name, domains, phone, email, sla_level } = this.$data.form
      const updatedCompany = await ContactCompanyService.updateCompany(company.id, {
        name: name,
        domains: domains,
        phone: phone || '',
        email: email || '',
        sla_level_id: sla_level ? sla_level.id : null,
      })
      if (updatedCompany) {
        this.cancelEdit()
        this.company = updatedCompany
        this.$dialog.notify.success(
          this.$t('applications.contact.company.messages.companyUpdated'), {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    deleteCompany (company) {
      this.$dialog.show(DeleteModal, {
        recordName: this.$t('common.labels.company'),
        recordNamePlural: this.$t('common.labels.companies'),
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await ContactCompanyService.deleteCompanies({ ids: [company.id] })
          if (response) {
            this.$dialog.notify.success(
              this.$t('applications.contact.company.messages.companyDeleted', { name: company.name }), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
            this.$router.push({ name: 'ContactCompanyListing' })
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    createAddress (company) {
      this.$dialog.show(ContactCompanyAddressFormDialog, {
        persistent: true,
        waitForResult: true,
      }).then(async result => {
        if (result) {
          const createdAddress = await ContactCompanyService.createCompanyAddress(company.id, {
            name: result.name,
            street: result.street,
            zip_code: result.zip_code,
            city: result.city,
            country: result.country,
            company_id: company.id,
          })
          if (createdAddress) {
            this.companyAddresses.push(createdAddress)
            this.$dialog.notify.success(
              this.$t('applications.contact.address.messages.addressCreated'), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    editAddress (address) {
      this.$dialog.show(ContactCompanyAddressFormDialog, {
        persistent: true,
        waitForResult: true,
        address: address,
      }).then(async result => {
        if (result) {
          const updatedAddress = await ContactCompanyService.updateCompanyAddress(this.company.id, address.id, {
            name: result.name,
            street: result.street,
            zip_code: result.zip_code,
            city: result.city,
            country: result.country,
          })
          if (updatedAddress) {
            this.companyAddresses.splice(this.companyAddresses.indexOf(address), 1, updatedAddress)
            this.$dialog.notify.success(
              this.$t('applications.contact.address.messages.addressUpdated'), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteAddress (address) {
      this.$dialog.show(DeleteModal, {
        recordName: this.$t('common.labels.address'),
        recordNamePlural: this.$t('common.labels.addresses'),
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await ContactCompanyService.deleteCompanyAddress(this.company.id, address.id)
          if (response) {
            this.companyAddresses.splice(this.companyAddresses.indexOf(address), 1)
            this.$dialog.notify.success(
              this.$t('applications.contact.address.messages.addressDeleted'), {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
