import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class HelpdeskTicketPortalService {
  static getTickets = async (urlSearchParams) => {
    try {
      const response = await http.get('helpdesk/portal/tickets', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTicket = async (id) => {
    try {
      const response = await http.get(`helpdesk/portal/tickets/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTicket = async (data) => {
    try {
      const response = await http.post(
        'helpdesk/portal/tickets/create',
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateTicket = async (id, data) => {
    try {
      const response = await http.patch(`helpdesk/portal/tickets/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
