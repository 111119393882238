<template>
  <statistic-wrapper
    :height="height"
    statTitle="SLA Statuses">
    <div v-if="zeroInEveryStatus" :style="{'height': 'calc(100% - 72px)'}">
      <v-row class="my-0" :style="{'height': '100%'}">
        <v-col class="d-flex justify-center align-center font-weight-bold" cols="12">
          <v-chip
            color="#4CAF50"
            class="font-weight-bold"
            :dark="shouldTextBeWhite('#4CAF50')">
            There are currently no tickets with SLA status
          </v-chip>
        </v-col>
      </v-row>
    </div>
    <echart v-else :option="chartOptions"/>
  </statistic-wrapper>
</template>

<script>
import StatisticWrapper from '@/common/components/StatisticWrapper'
export default {
  name: 'TeamTicketSLAStatuses',
  components: {
    StatisticWrapper
  },
  props: {
    height: { type: Number },
    team: { type: Object }
  },
  computed: {
    zeroInEveryStatus: function () {
      return [
        this.team.open_tickets_sla_ok_count,
        this.team.open_tickets_sla_warning_count,
        this.team.open_tickets_sla_alert_count,
        this.team.open_tickets_sla_failed_count,
      ].every(count => count === 0)
    },
    series: function () {
      let slaStatuses = [
        { name: 'OK', count: this.team.open_tickets_sla_ok_count },
        { name: 'WARNING', count: this.team.open_tickets_sla_warning_count },
        { name: 'ALERT', count: this.team.open_tickets_sla_alert_count },
        { name: 'FAILED', count: this.team.open_tickets_sla_failed_count },
      ]
      let stackedSeries = []
      for (let slaStatus of slaStatuses) {
        stackedSeries.push({
          name: slaStatus.name,
          type: 'bar',
          stack: 'total',
          label: {
            show: slaStatus.count > 0,
            fontWeight: 'bolder',
            fontSize: 24,
          },
          data: [slaStatus.count],
        })
      }
      return stackedSeries
    },
    chartOptions: function () {
      return {
        grid: {
          top: '0%',
          left: '5%',
          right: '5%',
          bottom: '40%',
        },
        legend: {
          show: true,
          top: '58%',
          textStyle: {
            fontWeight: 'bolder',
          },
        },
        xAxis: {
          type: 'value',
          show: false,
          max: 'dataMax',
        },
        yAxis: {
          type: 'category',
          show: false,
        },
        series: this.series,
        color: ['#4CAF50', '#FFCA28', '#FB8C00', '#FF4560'],
      }
    },
  },
}
</script>
