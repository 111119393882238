<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title v-if="!inboundMailServer">
        <v-icon left>mdi-plus</v-icon>
        Create Inbound Mailbox Configuration
      </v-toolbar-title>
      <v-toolbar-title v-else>
        <v-icon left>mdi-pencil</v-icon>
        Update Inbound Mailbox Configuration
      </v-toolbar-title>
    </v-toolbar>
    <v-alert outlined text dense class="mt-4 mx-4 mb-0" :type="testAlert.type">
      {{ testAlert.text }}
    </v-alert>
    <v-form
      v-model="isFormValid"
      class="pa-4">
      <v-text-field
        v-model="form.name"
        label="Name"
        required
        clearable
        autofocus
        type="text"
        maxlength="255"
        counter
        :rules="[formRules.required]"/>
      <v-row>
        <v-col cols=8>
          <v-select
            v-model="form.server_type"
            label="Server Type"
            :items="[
              { text: 'IMAP', value: 'imap' },
              { text: 'POP', value: 'pop' },
            ]"
            item-value="value"
            :rules="[formRules.required]"/>
        </v-col>
        <v-col cols=4>
          <v-switch
            v-model="form.use_ssl"
            label="Use SSL"
            inset
            dense
            color="green"/>
        </v-col>
      </v-row>
      <v-text-field
        v-model="form.host"
        label="Host"
        required
        clearable
        type="text"
        maxlength="255"
        counter
        :rules="[
          formRules.required,
          formRules.domain
        ]"/>
      <v-text-field
        v-model="form.port"
        label="Port"
        required
        clearable
        type="number"
        :rules="[
          formRules.aboveMinPort,
          formRules.belowMaxPort,
          formRules.required,
        ]"/>
      <v-text-field
        v-model="form.username"
        label="Username"
        required
        clearable
        type="text"
        maxlength="255"
        counter
        :rules="[formRules.required]"/>
      <v-text-field
        v-model="form.password"
        label="Password"
        required
        clearable
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        maxlength="255"
        counter
        :rules="[formRules.required]"/>
    </v-form>
    <v-btn
      color="primary"
      class="mx-auto mb-4"
      style="display: block; width: 80%"
      :disabled="!isFormValid"
      :loading="loadingTest"
      @click="testSettings">
      Test Connection
    </v-btn>
    <v-divider/>
  </DialogCard>
</template>

<script>
import BaseSettingsService from '@/services/base/base.settings.service'
export default {
  name: 'EmailSettingsClassicInboundFormDialog',
  props: {
    inboundMailServer: {
      type: Object,
      default: () => { return null },
    },
  },
  data () {
    return {
      loadingTest: false,
      form: {
        name: '',
        host: '',
        port: 0,
        server_type: '',
        use_ssl: false,
        username: '',
        password: '',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || 'Required.',
        belowMaxPort: value => value <= 65535 || 'The highest allowed port is 65535.',
        aboveMinPort: value => value >= 0 || 'The lowest allowed port is 0.',
        domain: value => {
          const pattern = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/
          return !value || pattern.test(value) || 'Invalid hostname.'
        },
      },
      showPassword: false,
      formTested: false,
      formSuccessfullyTested: false,
    }
  },
  watch: {
    'form': {
      handler (form) {
        this.formTested = false
        this.formSuccessfullyTested = false
      },
      deep: true,
    },
  },
  computed: {
    actions () {
      return {
        false: {
          text: 'Cancel',
        },
        true: {
          text: 'Save',
          color: 'green',
          disabled: !this.isFormValid || !this.formSuccessfullyTested,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
    testAlert () {
      if (!this.formTested) {
        return {
          type: 'info',
          text: 'Please test whether the connection is working or not before saving.',
        }
      } else if (this.formTested && !this.formSuccessfullyTested) {
        return {
          type: 'error',
          text: 'The provided connection information did not work.',
        }
      } else {
        return {
          type: 'success',
          text: `The provided connection information successfully connected to ${this.form.server_type.toUpperCase()} server.`,
        }
      }
    },
  },
  mounted () {
    if (this.inboundMailServer) {
      this.initFormFields(this.inboundMailServer)
    }
  },
  methods: {
    initFormFields (inboundMailServer) {
      for (let field in this.form) {
        if (field === 'password') continue
        this.form[field] = inboundMailServer[field]
      }
    },
    async testSettings () {
      this.loadingTest = true
      const { name, server_type, host, port, username, password, use_ssl } = this.$data.form
      const testResult = await BaseSettingsService.checkClassicInboundEmailSettings({
        name: name,
        server_type: server_type,
        host: host,
        port: port,
        username: username,
        password: password,
        use_ssl: use_ssl,
      })
      if (testResult) {
        this.formTested = true
        if (testResult.success === true) {
          this.formSuccessfullyTested = true
        } else {
          this.formSuccessfullyTested = false
        }
      }
      this.loadingTest = false
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep
    .v-card__text {
      padding: 0;
    }
</style>
