<template>
  <v-navigation-drawer
    app
    dark
    permanent
    floating
    miniVariant
    mini-variant-width=55
    width=255>
    <v-list>
      <v-list-item class="h-logo">
        <v-img
          src="@/assets/images/hubbiz_color.png"
          max-height="40"
          max-width="40"/>
      </v-list-item>
      <v-divider class="mb-2"/>
      <v-list-item
        link
        :to="{ name: 'HomePage' }"
        exact>
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        v-for="application in authorizedApplications"
        :key="application.id"
        @click="routeToApplication(application.name)"
        :class="{'v-list-item--active': isActiveSubRoute(application.name)}">
        <v-list-item-icon>
          <v-tooltip right open-delay="250">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-{{ application.icon }}</v-icon>
            </template>
            <span>{{ $t(`applicationNames.${application.name}`) }}</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t(`applicationNames.${application.name}`) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="hasAccess('base') || hasAccess('auth')"
        link
        @click="routeToApplication('settings')"
        :class="{'v-list-item--active': isActiveSubRoute('settings')}">
        <v-list-item-icon>
          <v-tooltip right open-delay="150">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-cogs</v-icon>
            </template>
            <span>{{ $t('applicationNames.base') }}</span>
          </v-tooltip>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('applicationNames.base') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getFirstAuthorizedViewNameFromRoutePath } from '@/router/tools'
export default {
  name: 'HAppSystemSidebar',
  mounted () {
    if (!this.$store.state.base.activatedApplications.length) {
      this.getActivatedApplications()
    }
    if (Object.keys(this.$store.state.base.allSettingsByApplication).length === 0) {
      this.listAllSettingsByApplication()
    }
  },
  computed: {
    authorizedApplications () {
      if (this.$store.state.login.me) {
        return this.$store.state.base.activatedApplications.filter(app => this.hasAccess(app.name))
      }
      return []
    },
  },
  methods: {
    async getActivatedApplications () {
      await this.$store.dispatch('FETCH_ACTIVATED_APPLICATIONS')
    },
    async listAllSettingsByApplication () {
      await this.$store.dispatch('FETCH_ALL_SETTINGS_BY_APPLICATION')
    },
    routeToApplication (appName) {
      this.$router.push({ name: getFirstAuthorizedViewNameFromRoutePath(`/${appName}`) })
    },
    isActiveSubRoute (appName) {
      const routePath = this.$route.path
      const pathRegexp = new RegExp(`^\/${appName}`)
      return routePath.includes(appName) && pathRegexp.test(routePath)
    },
  },
}
</script>

<style scoped>
  div.v-list {
    padding-top: 0px;
  }
  div.v-list .v-list-item--active {
    border-right: 4px solid #00ff88DD;
  }
  div.v-list-item.h-logo {
    padding-top: 8px;
    padding-bottom: 8px;
  }
</style>
