<template>
  <v-card :color="cardColor">
    <v-card-title class="px-2">
      <span class="subtitle-2">
        <template v-if="log.author">
          {{ log.author.full_identification }}
        </template>
        <template v-else>
          {{ log.author_full_name }} ({{ log.author_username }})
        </template>
      </span>
      &nbsp;
      <span class="caption font-italic" style="opacity: 0.5">- {{ log.create_date | moment('calendar') }}</span>
    </v-card-title>
    <v-card-text>
      <div
        v-if="log.text"
        :class="{'mb-2': !!log.changes}">
        {{ log.text }}
      </div>
      <div
        v-for="change in log.changes"
        :key="change.field_name">
        <span class="font-weight-bold">{{ change.verbose_field_name }}:</span>
        &nbsp;&nbsp;
        {{ change.before }} &rarr; {{ change.after }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'HelpdeskTicketLogCard',
  props: {
    log: {
      type: Object,
      default: () => { return null },
    },
    cardColor: {
      type: String,
      default: '#e6e6e6',
    },
  }
}
</script>

<style scoped>
.v-card__title {
  padding: 5px;
}
</style>
