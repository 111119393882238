<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-eye</v-icon>
        Preview Content
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs grow>
      <v-tab>Raw</v-tab>
      <v-tab v-if="language === 'html'">
        Processed
      </v-tab>
      <v-tab-item>
        <v-divider/>
        <vue-code-highlight :language="language">
          {{ content }}
        </vue-code-highlight>
      </v-tab-item>
      <v-tab-item>
        <v-divider/>
        <span v-html="content"></span>
      </v-tab-item>
    </v-tabs>
    <v-divider/>
  </DialogCard>
</template>

<script>
import { component as VueCodeHighlight } from 'vue-code-highlight'
import 'vue-code-highlight/themes/duotone-sea.css'
export default {
  name: 'HCodeViewerModal',
  components: {
    VueCodeHighlight
  },
  props: {
    content: { type: String, required: true },
    language: { type: String, required: true },
  },
  computed: {
    actions () {
      return {
        true: {
          text: 'Close',
          color: 'primary',
        },
      }
    }
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
</style>
