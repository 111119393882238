import ContactMainMenu from '@/views/contact/menus/ContactMainMenu'
import companyRoutes from './models/company.routes'
import contactRoutes from './models/contact.routes'
import contactTagRoutes from './models/contact-tag.routes'

export default [
  {
    path: '/contact',
    name: 'ContactMainMenu',
    component: ContactMainMenu,
    meta: {
      type: 'menu',
      requiresAuth: true,
    },
    children: [
      // Keep the same order as in the menu of the related application.
      ...companyRoutes,
      ...contactRoutes,
      ...contactTagRoutes,
    ],
  }
]
