<template>
  <inventory-item-supplier-form
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import InventoryItemSupplierForm from './shared/InventoryItemSupplierForm'
import DialogUnsavedChanges from '../../../common/mixins/DialogUnsavedChanges'
export default {
  name: 'InventoryItemSupplierFormCreate',
  mixins: [ DialogUnsavedChanges ],
  components: { InventoryItemSupplierForm },
  data () {
    return {
      formIsEditing: true
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
