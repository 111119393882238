import HelpdeskPortalTicketList from '@/views/helpdesk/portal/HelpdeskPortalTicketList'
import HelpdeskPortalInventoryItemList from '@/views/helpdesk/portal/HelpdeskPortalInventoryItemList'

export default [
	{
		path: '/portal/tickets',
		name: 'HelpdeskPortalTicketList',
		component: HelpdeskPortalTicketList,
		meta: {
			type: 'listing',
			requiresAuth: true,
		},
	},
	{
		path: '/portal/inventory',
		name: 'HelpdeskPortalInventoryItemList',
		component: HelpdeskPortalInventoryItemList,
		meta: {
			type: 'listing',
			requiresAuth: true,
		},
	}
]
