export default class FileService {
  static downloadFile (httpResponse) {
    const filename = this.getFilename(httpResponse)
    const file = httpResponse.data
    const blob = new Blob([file], { type: file.type })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
    URL.revokeObjectURL(link.href)
  }

  static getFilename (httpResponse) {
    const contentHeader = httpResponse.headers['content-disposition']
    const filenameRegex = /filename=(\S*\s*|".*")(?:;|$)/i
    let filename = contentHeader.match(filenameRegex)[1]
    return filename
  }
}
