<template>
  <h-app-main-menu :menuItems="menuItems"/>
</template>

<script>
import HAppMainMenu from '@/common/components/HAppMainMenu'
export default {
  name: 'ContactMainMenu',
  components: { HAppMainMenu },
  data () {
    return {
      menuItems: [
        {
          menuLabel: this.$t('common.labels.companies'),
          menuPermissions: [
            'view_contactcompany'
          ],
          routeName: 'ContactCompanyListing',
          matchingPaths: [
            '/contact/companies',
          ],
        },
        {
          menuLabel: this.$t('common.labels.contacts'),
          menuPermissions: [
            'view_contactcontact',
          ],
          routeName: 'ContactContactListing',
          matchingPaths: [
            '/contact/contacts',
          ],
        },
        {
          menuLabel: 'Tags',
          menuPermissions: [
            'view_contactcontacttag',
          ],
          routeName: 'ContactContactTagListing',
          matchingPaths: [
            '/contact/tags',
          ],
        },
      ],
    }
  },
}
</script>
