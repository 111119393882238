import InventoryItemCategoryFormCreate from '@/views/inventory/category/InventoryItemCategoryFormCreate'
import InventoryItemCategoryFormRead from '@/views/inventory/category/InventoryItemCategoryFormRead'
import InventoryItemCategoryListing from '@/views/inventory/category/InventoryItemCategoryListing'

export default [
  {
    path: 'categories',
    name: 'InventoryItemCategoryListing',
    component: InventoryItemCategoryListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_inventoryitemcategory',
    },
  },
  {
    path: 'categories/create',
    name: 'InventoryItemCategoryFormCreate',
    component: InventoryItemCategoryFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_inventoryitemcategory',
    },
  },
  {
    path: 'categories/:categoryId',
    name: 'InventoryItemCategoryFormRead',
    component: InventoryItemCategoryFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_inventoryitemcategory',
    },
  },
]
