function getPictureURL (picturePath) {
  const location = window.location
  if (process.env.NODE_ENV === 'production') {
    return picturePath
  } else {
    return `http://${location.hostname}:8001${picturePath}`
  }
}

export { getPictureURL }
export default {
  methods: {
    getPictureURL
  }
}
