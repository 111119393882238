<template>
  <v-row class="my-0">
    <v-col cols=12>
      <v-toolbar flat tile color="transparent">
        <v-spacer/>
        <span
          v-if="items.length"
          class="caption my-auto">
          Showing <span style="color:#1E88E5">{{ items.length }}</span> of {{ pagination?.total_records }}
        </span>
        <v-divider
          v-if="pagination?.has_next_page"
          class="mx-4"
          vertical/>
        <v-btn
          v-if="pagination?.has_next_page"
          color="blue darken-1"
          outlined
          :loading="loadingMore"
          @click="$emit('loadNextItemPage')">
          <v-icon left>mdi-download</v-icon>
          Load more
        </v-btn>
      </v-toolbar>
    </v-col>
    <template v-if="items.length && !loading">
      <v-col
        v-for="item in items"
        :key="item.id"
        cols=12 sm=6 md=3 lg=2
        class="pt-0">
        <v-card
          tile
          elevation="3"
          class="kanban-card"
          :ripple="false"
          @click="onItemClick(item)">
          <v-card-title>
            <span class="subtitle-2">{{ item.name | truncate(20, '...')}}</span>
            <v-spacer/>
            <v-menu
              v-if="hasPermission('delete_inventoryitem')"
              close-on-click
              close-on-content-click
              offsetY
              left>
              <!-- TODO: Add close-delay when it work -->
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" tile icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="hasPermission('delete_inventoryitem')"
                  @click="$emit('deleteItem', item)">
                  <v-icon small class="mr-4">mdi-delete</v-icon>
                  Delete
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-subtitle class="pb-0 caption">
            <v-icon small left>mdi-format-list-bulleted-type</v-icon>
            <template v-if="item.category">{{ item.category.name }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon small left>mdi-barcode</v-icon>
            <template v-if="item.serial_number">{{ item.serial_number }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon small left>mdi-fingerprint</v-icon>
            <template v-if="item.part_number">{{ item.part_number }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon small left>mdi-network</v-icon>
            <template v-if="item.mac_address">{{ item.mac_address }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <!-- <v-card-subtitle class="py-0 caption">
            <v-icon small left>mdi-file-document-check</v-icon>
            <template v-if="item.contract_reference">{{ item.contract_reference }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle> -->
          <v-card-subtitle class="py-0 caption">
            <v-icon small left>mdi-office-building</v-icon>
            <template v-if="item.company">{{ item.company.name }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon small left>mdi-account</v-icon>
            <template v-if="item.contact">{{ item.contact.full_name }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="pt-0 caption">
            <v-icon small left>mdi-face-agent</v-icon>
            <template v-if="item.contractor">{{ item.contractor.name }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col
        v-if="pagination?.has_next_page"
        cols=12
        class="py-0">
        <v-btn
          block
          text
          small
          color="blue darken-1"
          :loading="loadingMore"
          @click="$emit('loadNextItemPage')">
          <v-icon small left>mdi-download</v-icon>
          Load more...
        </v-btn>
      </v-col>
    </template>
    <template v-else-if="!items.length && !loading">
      <v-col cols=12 align="center" justify="center">
        <h-no-records-found createFormViewName="InventoryItemFormCreate"/>
      </v-col>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: 'InventoryItemKanban',
  components: {
    HNoRecordsFound,
    HRequestLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => { return [] },
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onItemClick (item) {
      this.$router.push({
        name: 'InventoryItemFormRead',
        params: { itemId: item.id },
      })
    },
  },
}
</script>

<style scoped>
  .kanban-card {
    transition: all 0.15s;
    cursor: pointer;
  }
  .kanban-card:hover {
    border: thin solid rgba(0, 0, 0, 0.25);
  }
</style>
