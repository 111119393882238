<template>
  <statistic-wrapper
    :height="height"
    statTitle="Ticket Statuses">
    <v-row class="my-0">
      <v-col cols="6">
        <echart
          :option="openTicketsChartOptions"
          class="chart"/>
      </v-col>
      <v-col cols="6">
        <echart
          :option="unassignedTicketsChartOptions"
          class="chart"/>
      </v-col>
    </v-row>
  </statistic-wrapper>
</template>

<script>
import StatisticWrapper from '@/common/components/StatisticWrapper'
export default {
  name: 'OverallTicketStatuses',
  components: {
    StatisticWrapper
  },
  props: {
    height: { type: Number },
    dashboard: { type: Object }
  },
  computed: {
    openTicketsChartOptions: function () {
      return {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: Math.ceil(this.dashboard.total_open_tickets_alert_threshold * 1.3),
            splitNumber: Math.ceil(this.dashboard.total_open_tickets_alert_threshold * 1.3),
            axisLine: {
              lineStyle: {
                width: 12,
                color: !this.dashboard.total_open_tickets_warning_threshold && !this.dashboard.total_open_tickets_alert_threshold ? [[1, '#4CAF50']] : [
                  [(this.dashboard.total_open_tickets_warning_threshold - 1) / Math.ceil(this.dashboard.total_open_tickets_alert_threshold * 1.3), '#4CAF50'],
                  [(this.dashboard.total_open_tickets_alert_threshold - 1) / Math.ceil(this.dashboard.total_open_tickets_alert_threshold * 1.3), '#FFCA28'],
                  [1, '#FF4560'],
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '12%',
              width: 20,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              length: 0,
            },
            splitLine: {
              length: 0,
            },
            axisLabel: {
              color: 'inherit',
              fontSize: '16px',
              fontWeight: 'bold',
              distance: -30,
              formatter: (value) => {
                if (
                  value === this.dashboard.total_open_tickets_warning_threshold - 1
                  || value === this.dashboard.total_open_tickets_alert_threshold - 1
                ) {
                  return value
                }
                return '';
              }
            },
            title: {
              offsetCenter: [0, '20%'],
              fontSize: '16px',
              fontWeight: 'bold',
              color: 'inherit',
            },
            detail: {
              fontSize: '24px',
              offsetCenter: [0, '-15%'],
              valueAnimation: true,
              color: 'inherit'
            },
            data: [{
              value: this.dashboard.total_open_tickets_count,
              name: 'Open',
            }]
          }
        ]
      }
    },
    unassignedTicketsChartOptions: function() {
      return {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: Math.ceil(this.dashboard.total_unassigned_tickets_alert_threshold * 1.3),
            splitNumber: Math.ceil(this.dashboard.total_unassigned_tickets_alert_threshold * 1.3),
            axisLine: {
              lineStyle: {
                width: 12,
                color: !this.dashboard.total_unassigned_tickets_warning_threshold && !this.dashboard.total_unassigned_tickets_alert_threshold ? [[1, '#4CAF50']] : [
                  [(this.dashboard.total_unassigned_tickets_warning_threshold - 1) / Math.ceil(this.dashboard.total_unassigned_tickets_alert_threshold * 1.3), '#4CAF50'],
                  [(this.dashboard.total_unassigned_tickets_alert_threshold - 1) / Math.ceil(this.dashboard.total_unassigned_tickets_alert_threshold * 1.3), '#FFCA28'],
                  [1, '#FF4560'],
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '12%',
              width: 20,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              length: 0,
            },
            splitLine: {
              length: 0,
            },
            axisLabel: {
              color: 'inherit',
              fontSize: '16px',
              fontWeight: 'bold',
              distance: -30,
              formatter: (value) => {
                if (
                  value === this.dashboard.total_unassigned_tickets_warning_threshold - 1
                  || value === this.dashboard.total_unassigned_tickets_alert_threshold - 1
                ) {
                  return value
                }
                return '';
              }
            },
            title: {
              offsetCenter: [0, '20%'],
              fontSize: '16px',
              fontWeight: 'bold',
              color: 'inherit',
            },
            detail: {
              fontSize: '24px',
              offsetCenter: [0, '-15%'],
              valueAnimation: true,
              color: 'inherit'
            },
            data: [{
              value: this.dashboard.total_unassigned_tickets_count,
              name: 'Unassigned',
            }]
          }
        ]
      }
    },
  }
}
</script>

<style scoped>
  .col {
    padding: 0;
  }
  .chart {
    height: 200px;
  }
</style>
