<template>
  <v-toolbar tile dense elevation="1">
    <v-menu
      v-if="
        selectedSubtasks.length
        && (
          hasPermission('delete_helpdeskticketsubtask')
          || hasPermission('change_helpdeskticketsubtask')
        )
      "
      close-on-click
      close-on-content-click
      offsetY>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          {{ $t('common.labels.actions') }}
          <v-icon small right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-if="hasPermission('change_helpdeskticketsubtask')"
          @click="$emit('updateSubtasksAsToDo', selectedSubtasks)">
          <v-icon small class="mr-4">mdi-circle-outline</v-icon>
          Set as 'To Do'
        </v-list-item>
        <v-list-item
          v-if="hasPermission('change_helpdeskticketsubtask')"
          @click="$emit('updateSubtasksAsInProgress', selectedSubtasks)">
          <v-icon small class="mr-4">mdi-progress-wrench</v-icon>
          Set as 'In Progress'
        </v-list-item>
        <v-list-item
          v-if="hasPermission('change_helpdeskticketsubtask')"
          @click="$emit('updateSubtasksAsDone', selectedSubtasks)">
          <v-icon small class="mr-4">mdi-check-circle</v-icon>
          Set as 'Done'
        </v-list-item>
        <v-list-item
          v-if="hasPermission('delete_helpdeskticketsubtask')"
          @click="$emit('deleteSubtasks', selectedSubtasks)">
          <v-icon small class="mr-4">mdi-delete</v-icon>
          {{ $t('common.buttons.delete') }}
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer/>
    <h-filtering-bar
      :availableFilters="availableFilters"
      :defaultFilters="[
        { fieldName: 'status', label: 'Status', type: 'predefinedValue', value: 'to_do', valueDisplay: 'To Do' },
        { fieldName: 'status', label: 'Status', type: 'predefinedValue', value: 'in_progress', valueDisplay: 'In Progress' },
      ]"/>
    <v-spacer/>
    <v-btn
      tile
      icon
      link
      :class="{'v-btn--active': viewType === 'list'}"
      @click="$emit('setListView')">
      <v-icon>mdi-format-list-bulleted</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import HFilteringBar from '@/common/components/HFilteringBar'
export default {
  name: 'HelpdeskTicketSubtaskListingToolbar',
  components: { HFilteringBar },
  props: {
    viewType: { type: String },
    selectedSubtasks: {
      type: Array,
      default: () => { return [] },
    },
  },
  data () {
    return {
      availableFilters: [
        { fieldName: 'author', label: 'Author', type: 'string' },
        { fieldName: 'assignee', label: 'Assignee', type: 'string' },
        { fieldName: 'unassigned', label: 'Unassigned', optionText: 'Unassigned', type: 'boolean', value: true },
        { fieldName: 'description', label: 'Description', type: 'string' },
        { fieldName: 'status', label: 'Status', type: 'predefinedValue', value: 'to_do', valueDisplay: 'To Do' },
        { fieldName: 'status', label: 'Status', type: 'predefinedValue', value: 'in_progress', valueDisplay: 'In Progress' },
        { fieldName: 'status', label: 'Status', type: 'predefinedValue', value: 'done', valueDisplay: 'Done' },
      ],
    }
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .25s ease;
  }
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child {
    /* Override the default -12px right margin in order to be symmetrical */
    margin-right: 0px;
  }
  .v-toolbar {
    height: unset!important;
  }
  .v-toolbar::v-deep .v-toolbar__content {
    height: fit-content!important;
  }
</style>
