function shouldTextBeWhite (bgColorHEX) {
  /*
    Tells if 'yes' or 'no' the text color will be white depending on the given
    background color. It is used for instance on tags to dynamically pick
    white or black for the text to insure readability.
  */
  const bgColor = (bgColorHEX.charAt(0) === '#') ? bgColorHEX.substring(1, 7) : bgColorHEX.substring(0, 6)
  const r = parseInt(bgColor.substring(0, 2), 16)
  const g = parseInt(bgColor.substring(2, 4), 16)
  const b = parseInt(bgColor.substring(4, 6), 16)
  if (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) {
    return false
  } else {
    return true
  }
}

export { shouldTextBeWhite }
export default {
  methods: {
    shouldTextBeWhite
  }
}
