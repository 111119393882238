<template>
  <v-row class="my-0">
    <template v-if="teams.length && !loading">
      <v-col
        v-for="team in teams"
        :key="team.id"
        sm=6 md=4 lg=3>
        <v-card
          tile
          elevation="3"
          class="h-team-card"
          :ripple="false"
          @click="onTeamClick(team)">
          <v-card-title>
            {{ team.name }}
            <v-chip
              x-small
              outlined
              class="ml-3 font-weight-bold"
              :color="team.members_count > 0 ? 'primary' : 'grey lighten-2'">
              <v-icon left x-small>
                mdi-account-group
              </v-icon>
              {{ team.members_count }}
            </v-chip>
            <v-spacer/>
            <v-menu
              v-if="hasPermission('delete_helpdeskteam')"
              close-on-click
              close-on-content-click
              offsetY
              left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" tile icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list class="h-menu-list">
                <v-list-item
                  v-if="hasPermission('delete_helpdeskteam')"
                  @click="$emit('deleteTeam', team)">
                  <v-icon small class="mr-4">mdi-delete</v-icon>
                  Delete
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-subtitle class="pb-0 caption">
            <v-icon small left>mdi-email-arrow-left</v-icon>
            <template v-if="team.inbound_mail">{{ team.inbound_mail }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle>
            {{ team.description || '&nbsp;' }}
          </v-card-subtitle>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              text
              color="primary"
              :class="{ 'disable-events': !hasPermission('view_helpdeskticket') }"
              @click.stop="routeToTicketsKanban(team)">
              Tickets ({{ team.open_tickets_count }})
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
    <template v-else-if="!teams.length && !loading">
      <v-col cols=12 align="center" justify="center">
        <h-no-records-found createFormViewName="HelpdeskTeamFormCreate"/>
      </v-col>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: 'HelpdeskTeamKanban',
  components: {
    HNoRecordsFound,
    HRequestLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    teams: {
      type: Array,
      default: () => { return [] },
    },
  },
  methods: {
    onTeamClick (team) {
      this.$router.push({
        name: 'HelpdeskTeamFormRead',
        params: { helpdeskTeamId: team.id },
      })
    },
    routeToTicketsKanban (team) {
      this.$router.push({
        name: 'HelpdeskTicketKanban',
        params: { helpdeskTeamId: team.id },
      })
    },
  },
}
</script>

<style scoped>
  .h-team-card {
    transition: all 0.15s;
    cursor: pointer;
    height: 100%;
  }
  .h-team-card:hover {
    border: thin solid rgba(0, 0, 0, 0.25);
  }
  .v-list.h-menu-list {
    padding: 0;
  }
  .disable-events {
    pointer-events: none;
  }
</style>
