<template>
  <div>
    <v-toolbar v-if="hasPermission('add_helpdeskslalevel')" flat dense>
      <v-toolbar-items>
        <v-btn v-if="hasPermission('add_helpdeskslalevel')"
        text
        @click="createSLALevel()">
        <v-icon small left>mdi-plus</v-icon>
        {{ slaLevels.length ? 'Create Another' : 'Create SLA Level' }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider/>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="slaLevels"
      :hide-default-footer="slaLevels.length < 10">
      <template v-slot:loading>
        <div class="my-12">Loading...</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip v-if="hasPermission('change_helpdeskslalevel')" bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="editSLALevel(item)" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          Edit
        </v-tooltip>
        <v-tooltip v-if="hasPermission('delete_helpdeskslalevel')" bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="deleteSLALevel(item)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          Delete
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DeleteModal from '@/common/components/DeleteModal'
import HelpdeskSLALevelFormDialog from '@/views/helpdesk/settings/settings/HelpdeskSLALevelFormDialog'
import HelpdeskSLALevelService from '@/services/helpdesk/helpdesk.sla.level.service'
export default {
  name: 'HelpdeskSLALevelList',
  data () {
    return {
      loading: false,
      slaLevels: [],
      headers: [
        { value: 'name', text: 'Name' },
        { value: 'hours_to_first_message', text: 'Hours To First Message' },
        { value: 'hours_to_first_answer', text: 'Hours To First Answer' },
        { value: 'hours_to_close', text: 'Hours To Close' },
        { value: 'contact_companies_count', text: '# Companies'},
        { value: 'actions', text: 'Actions', sortable: false },
      ]
    }
  },
  async mounted () {
    await this.getSLALevels()
  },
  methods: {
    async getSLALevels () {
      this.loading = true
      this.slaLevels = await HelpdeskSLALevelService.getSLALevels()
      this.loading = false
    },
    createSLALevel () {
      this.$dialog.show(HelpdeskSLALevelFormDialog, {
        persistent: true,
        waitForResult: true,
      }).then(async result => {
        if (result) {
          const createdSLALevel = await HelpdeskSLALevelService.createSLALevel({
            name: result.name,
            hours_to_first_message: result.hours_to_first_message,
            hours_to_first_answer: result.hours_to_first_answer,
            hours_to_close: result.hours_to_close,
          })
          if (createdSLALevel) {
            this.slaLevels.push(createdSLALevel)
            this.$dialog.notify.success(
              `SLA level '${createdSLALevel.name}' created`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    editSLALevel (slaLevel) {
      this.$dialog.show(HelpdeskSLALevelFormDialog, {
        slaLevel: slaLevel,
        persistent: true,
        waitForResult: true,
      }).then(async result => {
        if (result) {
          const updatedSLALevel = await HelpdeskSLALevelService.updateSLALevel(slaLevel.id, {
            name: result.name,
            hours_to_first_message: result.hours_to_first_message,
            hours_to_first_answer: result.hours_to_first_answer,
            hours_to_close: result.hours_to_close,
          })
          if (updatedSLALevel) {
            this.slaLevels.splice(this.slaLevels.indexOf(slaLevel), 1, updatedSLALevel)
            this.$dialog.notify.success(
              `SLA level '${updatedSLALevel.name}' updated`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          }
        }
      })
    },
    deleteSLALevel (slaLevel) {
      this.$dialog.show(DeleteModal, {
        recordName: 'SLA Level',
        recordNamePural: 'SLA Levels',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskSLALevelService.deleteSLALevel(slaLevel.id)
          if (response) {
            this.slaLevels.splice(this.slaLevels.indexOf(slaLevel), 1)
            this.$dialog.notify.success(
              `SLA Level '${slaLevel.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    }
  }
}
</script>
