import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class BaseGroupService {
  static getGroups = async (urlSearchParams) => {
    try {
      const response = await http.get('base/groups', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getGroup = async (id) => {
    try {
      const response = await http.get(`base/groups/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getGroupUsers = async (id) => {
    try {
      const response = await http.get(`base/groups/${id}/users`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static addUsersToGroup = async (id, data) => {
    try {
      const response = await http.patch(`base/groups/${id}/add-users`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static removeUserFromGroup = async (id, data) => {
    try {
      const response = await http.patch(`base/groups/${id}/remove-user`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getGroupPermissions = async (id) => {
    try {
      const response = await http.get(`base/groups/${id}/permissions`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateGroupPermissions = async (id, data) => {
    try {
      const response = await http.patch(`base/groups/${id}/permissions/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createGroup = async (data) => {
    try {
      const response = await http.post('base/groups/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateGroup = async (id, data) => {
    try {
      const response = await http.patch(`base/groups/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getGroupsInput = async () => {
    try {
      const response = await http.get('base/groups/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteGroups = async (data) => {
    try {
      const response = await http.post('base/groups/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
