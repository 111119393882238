<template>
  <v-app-bar app flat dark :clipped-left="!isUserSystem">
    <v-app-bar-title v-if="!isUserSystem">
      <v-img
        src="@/assets/images/hubbiz_full_mix_black_h.png"
        contain
        max-height="40"
        max-width="200"/>
    </v-app-bar-title>
    <div class="flex-grow-1"></div>
    <v-divider vertical/>
    <v-menu
      v-if="me"
      v-model="menuOpen"
      tile
      offsetY
      close-on-click
      close-on-content-click>
      <template v-slot:activator="{ on }">
        <v-card
          flat
          tile
          color="transparent">
          <v-list-item
            v-on="on"
            :ripple="false"
            :two-line="me.id !== 1">
            <v-list-item-avatar>
              <v-img :src="me.picture ? getPictureURL(me.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ me.full_name }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="me.id !== 1">
                {{ me.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon v-if="menuOpen">mdi-menu-up</v-icon>
              <v-icon v-else>mdi-menu-down</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-card>
      </template>
      <v-list>
        <v-list-item
          v-if="me.id !== 1"
          :to="{ name: 'UserProfileForm' }">
          <v-list-item-icon>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('applications.base.menus.myProfile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('applications.base.menus.logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-divider vertical/>
  </v-app-bar>
</template>

<script>
import { handleUnloggedStatus } from '@/router/tools'
import AuthService from '@/services/auth.service'
export default {
  name: 'HAppHeader',
  data () {
    return {
      menuOpen: false,
    }
  },
  computed: {
    me () {
      return this.$store.state.login.me
    },
    isUserSystem () {
      return this.me?.user_type === 'system'
    },
  },
  methods: {
    async logout () {
      const response = await AuthService.logout()
      if (response) {
        handleUnloggedStatus()
        this.$router.push({ name: 'LoginForm' })
      }
    },
  },
}
</script>

<style scoped>
  .v-toolbar {
    background: linear-gradient(to top, #29B6F6 0%, #01579B 100%);
    z-index: 20!important;
  }
  .v-card {
    height: inherit;
  }
  .v-card::v-deep .v-list-item__content {
    max-width: fit-content;
  }
  .v-list {
    padding: 0;
  }
  .v-list-item.v-list-item--two-line {
    height: inherit;
  }
</style>
