import ContactContactFormCreate from '@/views/contact/contact/ContactContactFormCreate'
import ContactContactFormRead from '@/views/contact/contact/ContactContactFormRead'
import ContactContactListing from '@/views/contact/contact/ContactContactListing'

export default [
  {
    path: 'contacts',
    name: 'ContactContactListing',
    component: ContactContactListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_contactcontact',
    },
  },
  {
    path: 'contacts/create',
    name: 'ContactContactFormCreate',
    component: ContactContactFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_contactcontact',
    },
  },
  {
    path: 'contacts/:contactId',
    name: 'ContactContactFormRead',
    component: ContactContactFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_contactcontact',
    },
  },
]
