<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="blue darken-1">
      <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
    </v-toolbar>
    <v-form
      v-model="isFormValid"
      :disabled="shouldDisableForm"
      class="pa-4">
      <v-text-field
        v-model="form.name"
        label="Name"
        required
        clearable
        autofocus
        type="text"
        maxlength="255"
        :counter="!shouldDisableForm"
        :rules="[formRules.required]"/>
      <v-checkbox
        v-model="form.is_closing"
        label="Closing Stage"
        hint="Moving a Ticket to a closing Stage will close it automatically."
        persistent-hint/>
    </v-form>
  </DialogCard>
</template>

<script>
export default {
  name: 'HelpdeskTicketStageFormDialog',
  props: {
    mode: { type: String, required: true },  // Should be 'view', 'change' or 'add'
    stage: { type: Object, default: () => { return null } }
  },
  computed: {
    modalTitle () {
      if (this.mode === 'add') return 'Create Stage'
      if (this.mode === 'change') return `Editing Stage: ${this.stage.name}`
      return `Viewing Stage: ${this.stage.name}`
    },
    shouldDisableForm () {
      if (this.mode === 'add' && this.hasPermission('add_helpdeskticketstage')) return false
      if (this.mode === 'change' && this.hasPermission('change_helpdeskticketstage')) return false
      return true
    },
    actions () {
      if (
        (this.mode === 'add' && this.hasPermission('add_helpdeskticketstage')) ||
        (this.mode === 'change' && this.hasPermission('change_helpdeskticketstage'))
      ) {
        return {
          false: {
            text: 'Cancel'
          },
          true: {
            text: 'Save',
            color: 'green',
            disabled: !this.isFormValid,
            handler: () => {
              return new Promise(resolve => {
                resolve(this.form)
              })
            }
          }
        }
      }
      return {
        false: {
          text: 'Close',
          color: 'primary'
        }
      }
    }
  },
  data () {
    return {
      form: {
        name: this.stage ? this.stage.name : '',
        is_closing: this.stage ? this.stage.is_closing : false
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || 'Required.'
      }
    }
  }
}
</script>

<style scoped>
  .v-card::v-deep
    .v-card__text {
      padding: 0;
    }
</style>
