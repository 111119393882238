import APIKeyList from '@/views/base/settings/settings/integrations/APIKeyList'
import BaseEmailSettingsMenu from '@/views/base/settings/settings/emails/BaseEmailSettingsMenu.vue'
import BaseIntegrationsSettingsMenu from '@/views/base/settings/settings/integrations/BaseIntegrationsSettingsMenu'
import BaseSettingsMenu from '@/views/base/settings/BaseSettingsMenu'
import EmailSettingsForm from '@/views/base/settings/settings/emails/EmailSettingsForm.vue'
import GlobalScheduleForm from '@/views/base/settings/settings/GlobalScheduleForm'
import OutboundFailedMailList from '@/views/base/settings/settings/emails/OutboundFailedMailList'
import WebhookList from '@/views/base/settings/settings/integrations/WebhookList'

export default [
  {
    path: '/',
    name: 'BaseSettingsMenu',
    component: BaseSettingsMenu,
    meta: {
      type: 'submenu',
      requiresAuth: true,
    },
    children: [
      {
        path: 'global-schedule',
        name: 'GlobalScheduleForm',
        component: GlobalScheduleForm,
        meta: {
          type: 'form',
          requiresAuth: true,
          permission: 'view_globalschedule',
        },
      },
      {
        path: 'emails',
        name: 'BaseEmailSettingsMenu',
        component: BaseEmailSettingsMenu,
        meta: {
          type: 'submenu',
          requiresAuth: true,
        },
        children: [
          {
            path: 'config',
            name: 'EmailSettingsForm',
            component: EmailSettingsForm,
            meta: {
              type: 'form',
              requiresAuth: true,
              permission: 'view_emailsettings',
            },
          },
          {
            path: 'failed-emails',
            name: 'OutboundFailedMailList',
            component: OutboundFailedMailList,
            meta: {
              type: 'list',
              requiresAuth: true,
              permission: 'view_outboundfailedmail',
            },
          },
        ],
      },
      {
        path: 'integrations',
        name: 'BaseIntegrationsSettingsMenu',
        component: BaseIntegrationsSettingsMenu,
        meta: {
          type: 'submenu',
          requiresAuth: true,
        },
        children: [
          {
            path: 'api-keys',
            name: 'APIKeyList',
            component: APIKeyList,
            meta: {
              type: 'list',
              requiresAuth: true,
              permission: 'view_apikey',
            },
          },
          {
            path: 'webhooks',
            name: 'WebhookList',
            component: WebhookList,
            meta: {
              type: 'list',
              requiresAuth: true,
              permission: 'view_webhook',
            },
          },
        ],
      }
    ]
  }
]
