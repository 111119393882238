<template>
  <div class="d-flex align-center mr-2">
    <v-divider vertical class="ml-3 mr-6"/>
    <v-item-group class="v-btn-toggle">
      <v-btn :disabled="disabled" @click="startOrPauseTimer">
        <v-icon>{{ iconPlayPause }}</v-icon>
      </v-btn>
      <v-btn :disabled="disabled" class="font-weight-bold disable-events">
        <span class="px-3">{{ hours }}:{{ minutes }}:{{ seconds }}</span>
      </v-btn>
    </v-item-group>
  </div>
</template>

<script>
import HelpdeskTicketService from '@/services/helpdesk/helpdesk.ticket.service'
export default {
  name: 'HelpdeskTicketFormTimer',
  props: {
    ticketId: {
      type: [Number, String],
      default: () => { return null },
    },
    ticketHasBeenDeleted: { type: Boolean, default: false },
    shouldStartOnMounted: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data () {
    return {
      elapsedTime: 0,
      timerInterval: null,
      autoSaveInterval: null,
    }
  },
  computed: {
    initialTime: {
      get () {
        return this.$store.state.helpdesk.initialTicketFormTimer
      },
      set (value) {
        this.$store.dispatch('UPDATE_INITIAL_TICKET_FORM_TIMER', value)
      },
    },
    hours () {
      return Math.floor((this.initialTime + this.elapsedTime) / 3600).toString().padStart(2, '0')
    },
    minutes () {
      return Math.floor(((this.initialTime + this.elapsedTime) % 3600) / 60).toString().padStart(2, '0')
    },
    seconds () {
      return ((this.initialTime + this.elapsedTime) % 60).toString().padStart(2, '0')
    },
    iconPlayPause () {
      if (this.timerInterval) return 'mdi-pause'
      return 'mdi-play'
    },
  },
  mounted () {
    if (this.shouldStartOnMounted) {
      this.startTimer()
    }
  },
  async beforeDestroy () {
    clearInterval(this.autoSaveInterval)
    clearInterval(this.timerInterval)
    if (this.ticketId && !this.ticketHasBeenDeleted) {
      await this.updateTicketTimer()
    }
  },
  methods: {
    startOrPauseTimer () {
      if (!this.timerInterval) {
        this.startTimer()
      } else {
        this.pauseTimer()
      }
    },
    startTimer () {
      if (!this.autoSaveInterval) {
        this.startAutoSave()
      }
      if (!this.timerInterval) {
        this.timerInterval = setInterval(() => {
          this.elapsedTime++
        }, 1_000)
      }
    },
    startAutoSave () {
      this.autoSaveInterval = setInterval(async () => {
        await this.updateTicketTimer()
      }, 300_000)
    },
    async pauseTimer () {
      clearInterval(this.timerInterval)
      clearInterval(this.autoSaveInterval)
      this.timerInterval = null
      this.autoSaveInterval = null
      await this.updateTicketTimer()
    },
    async updateTicketTimer () {
      if (this.elapsedTime > 0) {
        this.initialTime += this.elapsedTime
        await HelpdeskTicketService.updateTicketTimer(this.ticketId, { time: this.elapsedTime })
        this.elapsedTime = 0
      }
    },
  },
}
</script>

<style scoped>
.disable-events {
  pointer-events: none;
}
</style>
