<template>
  <contact-contact-tag-form
    :tagId=tagId
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import ContactContactTagForm from './shared/ContactContactTagForm'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'ContactContactTagFormRead',
  mixins: [ DialogUnsavedChanges ],
  components: { ContactContactTagForm },
  props: {
    tagId: {
      type: [Number, String],
      default: () => { return null }
    }
  },
  data () {
    return {
      formIsEditing: false
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
