<template>
  <v-toolbar tile dense elevation="1">
    <v-btn
      v-if="hasPermission('add_inventoryitem')"
      text
      :to="{ name: 'InventoryItemFormCreate', params: { editing: true } }">
      <v-icon small left>mdi-plus</v-icon>
      Create
    </v-btn>
    <v-menu
      v-if="selectedItems.length && hasPermission('delete_inventoryitem')"
      close-on-click
      close-on-content-click
      offsetY>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          Actions
          <v-icon small right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="h-menu-list">
        <v-list-item
          v-if="hasPermission('delete_inventoryitem')"
          @click="$emit('deleteItems', selectedItems)">
          <v-icon small class="mr-4">mdi-delete</v-icon>
          Delete
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer/>
    <h-filtering-bar :availableFilters="availableFilters"/>
    <v-spacer/>
    <v-btn
      tile
      icon
      link
      :class="{'v-btn--active': viewType === 'kanban'}"
      @click="$emit('setKanbanView')">
      <v-icon>mdi-view-grid</v-icon>
    </v-btn>
    <v-btn
      tile
      icon
      link
      :class="{'v-btn--active': viewType === 'list'}"
      @click="$emit('setListView')">
      <v-icon>mdi-format-list-bulleted</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import HFilteringBar from '@/common/components/HFilteringBar'
export default {
  name: 'InventoryItemListingToolbar',
  components: {
    HFilteringBar,
  },
  props: {
    viewType: { type: String },
    selectedItems: {
      type: Array,
      default: () => { return [] },
    },
  },
  data () {
    return {
      availableFilters: [
        { fieldName: 'name', label: 'Name', type: 'string' },
        { fieldName: 'serial_number', label: 'Serial Number', type: 'string' },
        { fieldName: 'part_number', label: 'Part Number', type: 'string' },
        { fieldName: 'mac_address', label: 'MAC Address', type: 'string' },
        // { fieldName: 'contract_reference', label: 'Contract Reference', type: 'string' },
        { fieldName: 'category', label: 'Category', type: 'string' },
        { fieldName: 'company', label: 'Company', type: 'string' },
        { fieldName: 'contact', label: 'Contact', type: 'string' },
        { fieldName: 'supplier', label: 'Supplier', type: 'string' },
      ],
    }
  },
}
</script>

<style scoped>
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child {
    /* Override the default -12px right margin in order to be symmetrical */
    margin-right: 0px;
  }
  .v-toolbar {
    height: unset!important;
  }
  .v-toolbar::v-deep .v-toolbar__content {
    height: fit-content!important;
  }
  .v-list.h-menu-list {
    padding: 0;
  }
</style>
