import UserFormCreate from '@/views/base/user/UserFormCreate'
import UserFormRead from '@/views/base/user/UserFormRead'
import UserListing from '@/views/base/user/UserListing'

export default [
  {
    path: 'users',
    name: 'UserListing',
    component: UserListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_user',
    },
  },
  {
    path: 'users/create',
    name: 'UserFormCreate',
    component: UserFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_user',
    },
  },
  {
    path: 'users/:userId',
    name: 'UserFormRead',
    component: UserFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_user',
    },
  },
]
