<template>
  <inventory-item-supplier-form
    :supplierId=supplierId
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import InventoryItemSupplierForm from './shared/InventoryItemSupplierForm'
import DialogUnsavedChanges from '../../../common/mixins/DialogUnsavedChanges'
export default {
  name: 'InventoryItemSupplierFormRead',
  mixins: [ DialogUnsavedChanges ],
  components: { InventoryItemSupplierForm },
  props: {
    supplierId: {
      type: [String, Number],
      default: () => { return null }
    }
  },
  data () {
    return {
      formIsEditing: false
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
