import TimesheetsMainMenu from '@/views/timesheets/menus/TimesheetsMainMenu'
import timesheetsRoutes from './models/timesheet-item.routes'

export default [
  {
    path: '/timesheets',
    name: 'TimesheetsMainMenu',
    component: TimesheetsMainMenu,
    meta: {
      type: 'menu',
      requiresAuth: true,
    },
    children: [
      // Keep the same order as in the menu of the related application.
      ...timesheetsRoutes,
    ],
  },
]
