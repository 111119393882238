<template>
  <multiselect
    v-if="availableFilters.length"
    placeholder="Select filters..."
    :value="selectedFilters"
    :options="availableFilters"
    :multiple="true"
    :internal-search="false"
    @select="handleSelection"
    @search-change="searchChangeTracker">
    <template slot="option" slot-scope="props">
      {{ optionText(props) }}
    </template>
    <template v-slot:clear>
      <div class="multiselect__clear" v-if="selectedFilters.length" @click="removeAllFilters">
        <v-icon color="#999">mdi-close-thick</v-icon>
      </div>
    </template>
    <template slot="tag" slot-scope="{ option }">
      <v-chip
        small
        close
        class="mr-1"
        @click:close="handleDeletion(option)">
        {{ filterText(option) }}
      </v-chip>
    </template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import HDatePickerDialog from '@/common/components/HDatePickerDialog'
import { eventBus } from '../../main.js'
export default {
  name: 'HFilteringBar',
  components: {
    Multiselect,
  },
  props: {
    availableFilters: {
      type: Array,
      required: true,
    },
    defaultFilters: {
      type: Array,
      default: () => { return [] },
    },
  },
  data () {
    return {
      searchInput: '',
      selectedFilters: [],
    }
  },
  mounted () {
    if (Object.keys(this.$route.query).length) {
      this.readSearchFromURL(this.$route.query)
    } else {
      this.useDefaultFilters()
    }
    eventBus.$on('remove-selected-filters', () => {
      this.removeAllFilters()
    })
  },
  destroyed () {
    this.commitURLSearchParamsToStore(new URLSearchParams())
    eventBus.$off('remove-selected-filters')
  },
  watch: {
    $route (to, from) {
      const currentURLSearch = new URL(window.location).search.slice(1)
      const currentSearchParams = this.$store.state.url.urlSearchParams
      if (currentURLSearch !== currentSearchParams?.toString()) {
        this.updateURL(currentSearchParams)
      }
    },
  },
  methods: {
    useDefaultFilters () {
      for (const defaultFilter of this.defaultFilters) {
        this.selectedFilters.push({
          fieldName: defaultFilter.fieldName,
          label: defaultFilter.label,
          value: defaultFilter.value,
        })
      }
      if (this.selectedFilters.length) {
        const urlSearchParams = this.buildURLSearchParams()
        this.updateURL(urlSearchParams)
        this.commitURLSearchParamsToStore(urlSearchParams)
      }
    },
    readSearchFromURL (currentRouteQuery) {
      for (const queryParam in currentRouteQuery) {
        if (currentRouteQuery[queryParam] instanceof Array) {
          for (const value of currentRouteQuery[queryParam]) {
            this.selectedFilters.push({
              fieldName: queryParam,
              label: this.getFieldLabel(queryParam),
              value: value,
            })
          }
        } else {
          this.selectedFilters.push({
            fieldName: queryParam,
            label: this.getFieldLabel(queryParam),
            value: currentRouteQuery[queryParam],
          })
        }
      }
      const urlSearchParams = this.buildURLSearchParams()
      this.commitURLSearchParamsToStore(urlSearchParams)
    },
    commitURLSearchParamsToStore (urlSearchParams) {
      this.$store.commit('SET_URL_SEARCH_PARAMS', urlSearchParams)
    },
    optionText (props) {
      if (props.option.type === 'string') return `${props.option.label}: ${this.searchInput}`
      if (props.option.type === 'boolean') return props.option.optionText
      if (props.option.type === 'date') return props.option.label
      if (props.option.type === 'predefinedValue') return `${props.option.label}: ${props.option.valueDisplay}`
      return `ERROR: unknown type '${props.option.type}' for field '${props.option.fieldName}'`
    },
    filterText (filter) {
      return `${filter.label}: ${filter.value}`
    },
    searchChangeTracker (searchQuery) {
      this.searchInput = searchQuery
    },
    getFieldLabel (fieldName) {
      return this.availableFilters.find(field => field.fieldName === fieldName).label
    },
    buildURLSearchParams () {
      const urlSearchParams = new URLSearchParams()
      for (const filter of this.selectedFilters) {
        urlSearchParams.append(filter.fieldName, filter.value)
      }
      return urlSearchParams
    },
    updateURL (urlSearchParams) {
      const url = new URL(window.location)
      url.search = `?${urlSearchParams?.toString()}`
      history.replaceState({}, '', url)
    },
    async handleSelection (selection) {
      var newFilter
      if (selection.type === 'boolean') {
        if (this.selectedFilters.map(f => f.fieldName).includes(selection.fieldName)) {
          this.selectedFilters.splice(this.selectedFilters.findIndex(f => f.fieldName === selection.fieldName), 1)
        }
        newFilter = {
          fieldName: selection.fieldName,
          label: selection.label,
          value: selection.value,
        }
      }
      if (selection.type === 'string' && this.searchInput) {
        newFilter = {
          fieldName: selection.fieldName,
          label: selection.label,
          value: this.searchInput,
        }
      }
      if (selection.type === 'date') {
        const dialogInstance = await this.$dialog.show(HDatePickerDialog)
        const date = await dialogInstance.wait()
        if (date) {
          newFilter = {
            fieldName: selection.fieldName,
            label: selection.label,
            value: date,
          }
        }
      }
      if (selection.type === 'predefinedValue') {
        newFilter = {
          fieldName: selection.fieldName,
          label: selection.label,
          value: selection.value,
        }
      }
      if (newFilter) {
        if (selection.type === 'date') {
          // We check here if there is already a filter for this date.
          // If so, we replace the date (value) in existing filter.
          const index = this.selectedFilters.map(f => f.fieldName).indexOf(selection.fieldName)
          if (index >= 0) {
            this.selectedFilters[index].value = newFilter.value
          } else {
            this.selectedFilters.push(newFilter)
          }
        } else {
          this.selectedFilters.push(newFilter)
        }
        const urlSearchParams = this.buildURLSearchParams()
        this.commitURLSearchParamsToStore(urlSearchParams)
        this.updateURL(urlSearchParams)
      }
    },
    handleDeletion (filter) {
      this.selectedFilters.splice(this.selectedFilters.indexOf(filter), 1)
      const urlSearchParams = this.buildURLSearchParams()
      this.commitURLSearchParamsToStore(urlSearchParams)
      this.updateURL(urlSearchParams)
    },
    removeAllFilters () {
      if (this.selectedFilters) {
        this.selectedFilters = []
        const urlSearchParams = new URLSearchParams()
        this.commitURLSearchParamsToStore(urlSearchParams)
        this.updateURL(urlSearchParams)
      }
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .v-toolbar {
    z-index: 5;
  }
  .v-application ul.multiselect__content {
    padding-left: 0px;
  }
</style>
<style scoped>
  .multiselect {
    width: 50%;
    margin: 4px;
  }
  .multiselect::v-deep .multiselect__tags {
    padding: 8px 70px 8px 8px;
  }
  .multiselect::v-deep .multiselect__clear {
    position: absolute;
    right: 41px;
    cursor: pointer;
    top: 3px;
    padding: 4px 0px 4px 8px;
    height: 38px;
  }
  .v-chip {
    color: #01579B;
    margin-top: 1px;
    margin-bottom: 1px;
  }
</style>
