import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class BaseApplicationService {
  static getApplications = async (urlSearchParams) => {
    try {
      const response = await http.get('base/applications', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getActivatedApplications = async () => {
    try {
      const response = await http.get('base/applications/activated')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static activateApplication = async (id) => {
    try {
      const response = await http.patch(`base/applications/${id}/activate`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deactivateApplication = async (id) => {
    try {
      const response = await http.patch(`base/applications/${id}/deactivate`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static listAllSettingsByApplication = async () => {
    try {
      const response = await http.get('base/applications/settings')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
