import InventoryItemSupplierFormCreate from '@/views/inventory/supplier/InventoryItemSupplierFormCreate'
import InventoryItemSupplierFormRead from '@/views/inventory/supplier/InventoryItemSupplierFormRead'
import InventoryItemSupplierListing from '@/views/inventory/supplier/InventoryItemSupplierListing'

export default [
  {
    path: 'suppliers',
    name: 'InventoryItemSupplierListing',
    component: InventoryItemSupplierListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_inventoryitemsupplier',
    },
  },
  {
    path: 'suppliers/create',
    name: 'InventoryItemSupplierFormCreate',
    component: InventoryItemSupplierFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_inventoryitemsupplier',
    },
  },
  {
    path: 'suppliers/:supplierId',
    name: 'InventoryItemSupplierFormRead',
    component: InventoryItemSupplierFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_inventoryitemsupplier',
    },
  },
]
