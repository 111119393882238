<template>
  <div>
    <user-listing-toolbar
      :viewType="viewType"
      :selectedUsers="selectedUsers"
      @setListView="setListView"
      @setKanbanView="setKanbanView"
      @activateUsers="activateUsers"
      @deactivateUsers="deactivateUsers"
      @deleteUsers="deleteUsers"/>
    <transition name="router-anim" mode="out-in">
      <user-kanban
        v-if="viewType === 'kanban'"
        :users="users"
        :pagination="pagination"
        :loading="loading"
        :loadingMore="loadingMore"
        @loadNextUserPage="loadNextUserPage"
        @activateUser="activateUser"
        @deactivateUser="deactivateUser"
        @deleteUser="deleteUser"/>
      <user-list
        v-else
        v-model="selectedUsers"
        :users="users"
        :pagination="pagination"
        :loading="loading"
        :loadingMore="loadingMore"
        @loadNextUserPage="loadNextUserPage"
        @activateUser="activateUser"
        @deactivateUser="deactivateUser"
        @deleteUser="deleteUser"/>
    </transition>
  </div>
</template>

<script>
import UserListingToolbar from '@/views/base/user/shared/UserListingToolbar'
import UserKanban from '@/views/base/user/UserKanban'
import UserList from '@/views/base/user/UserList'
import WarningModal from '@/common/components/WarningModal'
import DeleteModal from '@/common/components/DeleteModal'
import BaseUserService from '@/services/base/base.user.service'
export default {
  name: 'UserListing',
  components: {
    UserListingToolbar,
    UserKanban,
    UserList,
  },
  data () {
    return {
      loading: false,
      loadingMore: false,
      viewType: 'kanban',
      users: [],
      pagination: null,
      selectedUsers: [],
    }
  },
  mounted () {
    this.getUsers(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getUsers(this.$store.state.url.urlSearchParams)
    },
  },
  computed: {
    userMe () {
      return this.$store.state.login.me
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    setKanbanView () {
      this.viewType = 'kanban'
    },
    async getUsers (urlSearchParams) {
      this.loading = true
      const responseData = await BaseUserService.getUsers(urlSearchParams)
      this.users = responseData.users
      this.pagination = responseData.pagination
      for (let user of this.users) {
        this.addIsSelectableOnUser(user)
      }
      this.loading = false
    },
    addIsSelectableOnUser (user) {
      this.$set(user, 'isSelectable', this.isSelectable(user))
    },
    isSelectable (user) {
      return ![1, 2, this.userMe.id].includes(user.id)
    },
    async loadNextUserPage () {
      if (this.pagination.has_next_page) {
        this.loadingMore = true
        const nextPageNumber = this.pagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await BaseUserService.getUsers(urlSearchParams)
        this.pagination = responseData.pagination
        const newUserPage = responseData.users
        for (let user of newUserPage) {
          this.addIsSelectableOnUser(user)
        }
        const currentUsers = this.users
        const updatedUserList = currentUsers.concat(newUserPage)
        this.users = updatedUserList
        this.loadingMore = false
      }
    },
    async activateUser (user) {
      const activatedUsers = await BaseUserService.activateUsers({ ids: [user.id] })
      if (activatedUsers) {
        let activatedUser = activatedUsers[0]
        this.addIsSelectableOnUser(activatedUser)
        this.users.splice(this.users.indexOf(user), 1, activatedUser)
        if (
          this.viewType === 'list'
          && this.selectedUsers.some(selectedUser => selectedUser.id === user.id)
        ) {
          this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1, activatedUser)
        }
        this.$dialog.notify.success(
          `User '${user.username}' activated`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async activateUsers (users) {
      const deactivatedUsers = users.filter(user => !user.is_active)
      const userIds = deactivatedUsers.map(user => user.id)
      const activatedUsers = await BaseUserService.activateUsers({ ids: userIds })
      if (activatedUsers) {
        for (let activatedUser of activatedUsers) {
          this.addIsSelectableOnUser(activatedUser)
          let userToUpdate = this.users.find(record => record.id === activatedUser.id)
          this.users.splice(this.users.indexOf(userToUpdate), 1, activatedUser)
          if (
            this.viewType === 'list'
            && this.selectedUsers.some(selectedUser => selectedUser.id === activatedUser.id)
          ) {
            this.selectedUsers.splice(this.selectedUsers.indexOf(userToUpdate), 1, activatedUser)
          }
        }
        this.$dialog.notify.success(
          `${deactivatedUsers.length} of ${users.length} selected ${userIds.length > 1 ? 'users' : 'user'} activated`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    deactivateUser (user) {
      this.$dialog.show(WarningModal, {
        icon: 'archive-arrow-down',
        title: 'Deactivate User ?',
        message: `Are you sure you want to deactivate '${user.full_name}' ?`,
        okText: 'YES',
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const deactivatedUsers = await BaseUserService.deactivateUsers({ ids: [user.id] })
          if (deactivatedUsers) {
            let deactivatedUser = deactivatedUsers[0]
            this.addIsSelectableOnUser(deactivatedUser)
            this.users.splice(this.users.indexOf(user), 1, deactivatedUser)
            if (
              this.viewType === 'list'
              && this.selectedUsers.some(selectedUser => selectedUser.id === deactivatedUser.id)
            ) {
              this.selectedUsers.splice(this.selectedUsers.indexOf(user), 1, deactivatedUser)
            }
            this.$dialog.notify.success(
              `User '${user.username}' deactivated`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deactivateUsers (users) {
      this.$dialog.show(WarningModal, {
        icon: 'archive-arrow-down',
        title: 'Deactivate Selected User(s) ?',
        message: 'Are you sure you want to deactivate selected user(s) ?',
        okText: 'YES',
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const activatedUsers = users.filter(user => user.is_active)
          const userIds = activatedUsers.map(user => user.id)
          const deactivatedUsers = await BaseUserService.deactivateUsers({ ids: userIds })
          if (deactivatedUsers) {
            for (let deactivatedUser of deactivatedUsers) {
              this.addIsSelectableOnUser(deactivatedUser)
              let userToUpdate = this.users.find(record => record.id === deactivatedUser.id)
              this.users.splice(this.users.indexOf(userToUpdate), 1, deactivatedUser)
              if (
                this.viewType === 'list'
                && this.selectedUsers.some(selectedUser => selectedUser.id === deactivatedUser.id)
              ) {
                this.selectedUsers.splice(this.selectedUsers.indexOf(userToUpdate), 1, deactivatedUser)
              }
            }
            this.$dialog.notify.success(
              `${activatedUsers.length} of ${users.length} selected ${userIds.length > 1 ? 'users' : 'user'} deactivated`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteUser (user) {
      this.$dialog.show(DeleteModal, {
        alertMessage: 'By deleting an user, you may loose links and history records. Try deactivating instead.',
        checkRequired: true,
        checkMessage: 'I prefer deleting this user',
        recordName: 'User',
        recordNamePlural: 'Users',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await BaseUserService.deleteUsers({ ids: [user.id] })
          if (response) {
            this.users.splice(this.users.indexOf(user), 1)
            this.pagination.total_records -= 1
            this.selectedUsers = []
            this.$dialog.notify.success(
              `User ${user.username} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteUsers (usersToDelete) {
      this.$dialog.show(DeleteModal, {
        alertMessage: `By deleting ${usersToDelete.length > 1 ? 'users' : 'an user'}, you may loose links and history records. Try deactivating instead.`,
        checkRequired: true,
        checkMessage: `I prefer deleting ${usersToDelete.length > 1 ? 'these users' : 'this user'}`,
        recordName: 'User',
        recordNamePlural: 'Users',
        summaryHeaders: [
          { value: 'username', text: 'Username' },
          { value: 'email', text: 'Email' },
          { value: 'is_active', text: 'Active' },
          { value: 'user_type', text: 'Type' },
        ],
        summaryRecords: usersToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const userIdsToDelete = usersToDelete.map(user => user.id)
          const response = await BaseUserService.deleteUsers({ ids: userIdsToDelete })
          if (response) {
            this.users = this.users.filter(undeletedUser => !userIdsToDelete.includes(undeletedUser.id))
            this.pagination.total_records -= usersToDelete.length
            this.selectedUsers = []
            this.$dialog.notify.success(
              `${usersToDelete.length} ${usersToDelete.length > 1 ? 'users' : 'user'} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
