<template>
  <div>
    <inventory-item-listing-toolbar
      :viewType="viewType"
      :selectedItems="selectedItems"
      @setListView="setListView"
      @setKanbanView="setKanbanView"
      @deleteItems="deleteItems"/>
    <transition name="router-anim" mode="out-in">
      <inventory-item-kanban
        v-if="viewType === 'kanban'"
        :items="items"
        :loading="loading"
        :loadingMore="loadingMore"
        :pagination="pagination"
        @loadNextItemPage="loadNextItemPage"
        @deleteItem="deleteItem"/>
      <inventory-item-list
        v-else
        v-model="selectedItems"
        :items="items"
        :loading="loading"
        :loadingMore="loadingMore"
        :pagination="pagination"
        @loadNextItemPage="loadNextItemPage"
        @deleteItem="deleteItem"/>
    </transition>
  </div>
</template>

<script>
import DeleteModal from '@/common/components/DeleteModal'
import InventoryItemKanban from '@/views/inventory/item/InventoryItemKanban'
import InventoryItemList from '@/views/inventory/item/InventoryItemList'
import InventoryItemListingToolbar from '@/views/inventory/item/shared/InventoryItemListingToolbar'
import InventoryItemService from '@/services/inventory/inventory.item.service'
export default {
  name: 'InventoryItemListing',
  components: {
    InventoryItemKanban,
    InventoryItemList,
    InventoryItemListingToolbar,
  },
  data () {
    return {
      loading: false,
      loadingMore: false,
      viewType: 'kanban',
      items: [],
      selectedItems: [],
      pagination: null,
    }
  },
  mounted () {
    this.getItems(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getItems(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    setKanbanView () {
      this.viewType = 'kanban'
    },
    async getItems (urlSearchParams) {
      this.loading = true
      const responseData = await InventoryItemService.getItems(urlSearchParams)
      this.items = responseData.items
      this.pagination = responseData.pagination
      this.loading = false
    },
    async loadNextItemPage () {
      if (this.pagination.has_next_page) {
        this.loadingMore = true
        const nextPageNumber = this.pagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await InventoryItemService.getItems(urlSearchParams)
        this.pagination = responseData.pagination
        const newItemPage = responseData.items
        const currentItems = this.items
        const updatedItemList = currentItems.concat(newItemPage)
        this.items = updatedItemList
        this.loadingMore = false
      }
    },
    deleteItem (item) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Item',
        recordNamePlural: 'Items',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await InventoryItemService.deleteItems({ ids: [item.id] })
          if (response) {
            this.items.splice(this.items.indexOf(item), 1)
            this.pagination.total_records -= 1
            this.selectedItems = []
            this.$dialog.notify.success(
              `Item '${item.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteItems (itemsToDelete) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Item',
        recordNamePlural: 'Items',
        summaryHeaders: [
          { value: 'name', text: 'Name' },
          { value: 'category.name', text: 'Category' },
          { value: 'serial_number', text: 'S/N' },
          { value: 'contract_reference', text: 'Contract Ref.' },
          { value: 'company.name', text: 'Company' },
          { value: 'contact.full_name', text: 'Contact' },
        ],
        summaryRecords: itemsToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const itemIdsToDelete = itemsToDelete.map(item => item.id)
          const response = await InventoryItemService.deleteItems({ ids: itemIdsToDelete })
          if (response) {
            this.items = this.items.filter(undeletedItem => !itemIdsToDelete.includes(undeletedItem.id))
            this.pagination.total_records -= itemIdsToDelete.length
            this.selectedItems = []
            this.$dialog.notify.success(
              `${itemsToDelete.length} ${itemsToDelete.length > 1 ? 'items' : 'item'} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
