<template>
  <contact-contact-form
    :contactId=contactId
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import ContactContactForm from './shared/ContactContactForm.vue'
import DialogUnsavedChanges from '../../../common/mixins/DialogUnsavedChanges'
export default {
  name: 'ContactContactFormRead',
  mixins: [ DialogUnsavedChanges ],
  components: { ContactContactForm },
  props: {
    contactId: {
      type: [Number, String],
      default: () => { return null }
    }
  },
  data () {
    return {
      formIsEditing: false
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
