<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    tile
    nudge-right="28"
    nudge-bottom="15"
    min-width="auto"
    transition="scroll-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        v-bind="attrs"
        v-on="on"
        readonly
        clearable
        prepend-inner-icon="mdi-calendar"
        :label="label"
        :rules="rules"
        @click:clear="handleClear"/>
    </template>
    <v-date-picker
      v-model="date"
      show-adjacent-months
      color="blue darken-1"
      first-day-of-week="1"
      :min="minDate"
      :max="maxDate"
      @input="handleInput"/>
  </v-menu>
</template>

<script>
export default {
  name: 'HDatePicker',
  props: {
    label: { type: String },
    minDate: { type: String, default: "" }, // Must be format YYYY-MM-DD
    maxDate: { type: String, default: "" }, // Must be format YYYY-MM-DD
    rules: { type: Array, default: () => { return [] } },
  },
  data () {
    return {
      menu: false,
      date: null
    }
  },
  methods: {
    handleInput () {
      this.menu = false
      this.$emit('input', this.date)
    },
    handleClear () {
      this.$emit('input', null)
    }
  }
}
</script>
