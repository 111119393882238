<template>
  <inventory-item-contractor-form
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import InventoryItemContractorForm from './shared/InventoryItemContractorForm'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'InventoryItemContractorFormCreate',
  mixins: [ DialogUnsavedChanges ],
  components: { InventoryItemContractorForm },
  data () {
    return {
      formIsEditing: true,
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    },
  },
}
</script>
