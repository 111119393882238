<template>
  <v-row class="my-0">
    <v-col>
      <v-card tile elevation="3">
        <v-data-table
          v-model="selectedTags"
          multi-sort
          checkbox-color="blue darken-2"
          :loading="loading"
          :show-select="hasPermission('delete_contactcontacttag')"
          :headers="headers"
          :items="tags"
          :hide-default-footer="tags.length < 10">
          <!-- TODO: Find a way not to hardcode the 10 in hide-default-footer ? -->
          <template v-slot:loading>
            <div class="my-12">Loading...</div>
          </template>
          <template v-slot:no-data>
            <h-no-records-found createFormViewName="ContactContactTagFormCreate"/>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <v-chip
              small
              class="font-weight-bold"
              :class="{ 'white--text': shouldTextBeWhite(item.color) }"
              :color="item.color">
              {{ item.name }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="hasPermission('view_contactcontacttag')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn :to="{ name: 'ContactContactTagFormRead', params: { tagId: item.id } }" v-on="on" icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.open') }}
            </v-tooltip>
            <v-tooltip v-if="hasPermission('delete_contactcontacttag')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="$emit('deleteTag', item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.delete') }}
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
export default {
  name: 'ContactContactTagList',
  components: { HNoRecordsFound },
  props: {
    value: {
      type: Array,
      default: () => { return [] },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => { return [] },
    },
  },
  computed: {
    headers () {
      return [
        { value: 'name', text: this.$t('common.labels.name') },
        { value: 'contacts_count', text: `# ${this.$t('common.labels.contacts')}` },
        { value: 'actions', text: this.$t('common.labels.actions'), sortable: false },
      ]
    },
    selectedTags: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
  beforeDestroy () {
    this.selectedTags = []
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.selectedTags = []
    },
  },
}
</script>
