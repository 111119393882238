import { render, staticRenderFns } from "./HelpdeskTicketSubtaskFormDialog.vue?vue&type=template&id=b31c46dc&scoped=true"
import script from "./HelpdeskTicketSubtaskFormDialog.vue?vue&type=script&lang=js"
export * from "./HelpdeskTicketSubtaskFormDialog.vue?vue&type=script&lang=js"
import style0 from "./HelpdeskTicketSubtaskFormDialog.vue?vue&type=style&index=0&id=b31c46dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b31c46dc",
  null
  
)

export default component.exports