import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class TimesheetItemService {
  static getTimesheets = async (urlSearchParams) => {
    try {
      const response = await http.get('timesheets/items', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteTimesheets = async (data) => {
    try {
      const response = await http.post('timesheets/items/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static exportTimesheets = async (format, data) => {
    try {
      const responseType = format === 'csv' ? 'json' : 'blob'
      const response = await http.post(`timesheets/items/export`, data, { responseType: responseType })
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static exportSelectedTimesheets = async (format, data) => {
    try {
      const responseType = format === 'csv' ? 'json' : 'blob'
      const response = await http.post(`timesheets/items/export-selected`, data, { responseType: responseType })
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
