<template>
  <div>
    <!-- TODO: find something else than a naked div -->
    <v-toolbar
      v-if="
        hasPermission('view_helpdeskticket')
        || hasPermission('add_helpdeskticket')
        || hasPermission('delete_helpdeskticket')
      "
      tile
      dense
      elevation="1">
      <v-btn
        v-if="hasPermission('add_helpdeskticket')"
        text
        :to="{ name: 'HelpdeskTicketFormCreate', params: { editing: true } }">
        <v-icon small left>mdi-plus</v-icon>
        Create
      </v-btn>
      <v-menu
        v-if="
          selectedTickets.length
          && hasPermission('delete_helpdeskticket')
        "
        close-on-click
        close-on-content-click
        offsetY>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            Actions
            <v-icon small right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-if="hasPermission('delete_helpdeskticket')"
            @click="deleteTickets()">
            <v-icon small class="mr-4">mdi-delete</v-icon>
            Delete
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer/>
      <h-filtering-bar :availableFilters="availableFilters"/>
      <v-spacer/>
      <v-btn
        tile
        icon
        v-if="hasPermission('view_helpdeskticket')"
        :to="{ name: 'HelpdeskTicketList' }">
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
    </v-toolbar>
    <transition name="router-anim" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import { eventBus } from '@/main.js'
import HFilteringBar from '@/common/components/HFilteringBar'
export default {
  name: 'HelpdeskTicketListingToolbar',
  components: { HFilteringBar },
  data () {
    return {
      availableFilters: [
        { fieldName: 'name', label: 'Name', type: 'string' },
        { fieldName: 'author', label: 'Author', type: 'string' },
        { fieldName: 'assignee', label: 'Assignee', type: 'string' },
        { fieldName: 'unassigned', label: 'Unassigned', optionText: 'Unassigned', type: 'boolean', value: true },
        { fieldName: 'contact', label: 'Contact', type: 'string' },
        { fieldName: 'company', label: 'Company', type: 'string' },
        { fieldName: 'team', label: 'Team', type: 'string' },
        { fieldName: 'stage', label: 'Stage', type: 'string' },
        { fieldName: 'tag', label: 'Tag', type: 'string' },
        { fieldName: 'priority', label: 'Priority', type: 'predefinedValue', value: 'low', valueDisplay: 'Low' },
        { fieldName: 'priority', label: 'Priority', type: 'predefinedValue', value: 'normal', valueDisplay: 'Normal' },
        { fieldName: 'priority', label: 'Priority', type: 'predefinedValue', value: 'high', valueDisplay: 'High' },
        { fieldName: 'sla_status', label: 'SLA Status', type: 'predefinedValue', value: 'ok', valueDisplay: 'Ok' },
        { fieldName: 'sla_status', label: 'SLA Status', type: 'predefinedValue', value: 'warning', valueDisplay: 'Warning' },
        { fieldName: 'sla_status', label: 'SLA Status', type: 'predefinedValue', value: 'alert', valueDisplay: 'Alert' },
        { fieldName: 'sla_status', label: 'SLA Status', type: 'predefinedValue', value: 'failed', valueDisplay: 'Failed' },
        { fieldName: 'answered', label: 'Answered', optionText: 'Answered', type: 'boolean', value: true },
        { fieldName: 'answered', label: 'Answered', optionText: 'Unanswered', type: 'boolean', value: false },
        { fieldName: 'is_closed', label: 'Closed', optionText: 'Closed', type: 'boolean', value: true },
        { fieldName: 'is_closed', label: 'Closed', optionText: 'Open', type: 'boolean', value: false },
      ],
    }
  },
  computed: {
    selectedTickets () {
      return this.$store.state.helpdesk.selectedHelpdeskTickets
    },
  },
  methods: {
    deleteTickets () {
      eventBus.$emit('delete-selected-helpdesk-tickets')
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .25s ease;
  }
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child {
    /* Override the default -12px right margin in order to be symmetrical */
    margin-right: 0px;
  }
  .v-toolbar {
    height: unset!important;
  }
  .v-toolbar::v-deep .v-toolbar__content {
    height: fit-content!important;
  }
</style>
