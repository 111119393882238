<template>
  <v-row class="my-0">
    <v-col>
      <v-card tile elevation="3">
        <v-row no-gutters class="pa-4">
          <v-col class="d-flex justify-end">
            <span class="caption my-auto">Showing <span style="color:#1E88E5">{{ items.length }}</span> of {{ pagination?.total_records }}</span>
            <v-divider
              v-if="pagination?.has_next_page"
              class="mx-4"
              vertical/>
            <v-btn
              v-if="pagination?.has_next_page"
              color="blue darken-1"
              outlined
              :loading="loadingMore"
              @click="$emit('loadNextItemPage')">
              <v-icon left>mdi-download</v-icon>
              Load more
            </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
        <v-data-table
          v-model="selectedItems"
          multi-sort
          checkbox-color="blue darken-2"
          :loading="loading"
          :show-select="hasPermission('delete_inventoryitem')"
          :headers="headers"
          :items="items"
          :items-per-page=15
          :hide-default-footer="items.length < 16"
          :footer-props="{
            'items-per-page-options': [10, 15, 20, 30],
            'show-first-last-page': true,
          }">
          <!-- TODO: Find a way not to hardcode the 10 in hide-default-footer ? -->
          <template v-slot:loading>
            <div class="my-12">Loading...</div>
          </template>
          <template v-slot:no-data>
            <h-no-records-found createFormViewName="InventoryItemFormCreate"/>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="hasPermission('view_inventoryitem')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn :to="{ name: 'InventoryItemFormRead', params: { itemId: item.id } }" v-on="on" icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              Open
            </v-tooltip>
            <v-tooltip v-if="hasPermission('delete_inventoryitem')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="$emit('deleteItem', item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              Delete
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
export default {
  name: 'InventoryItemList',
  components: {
    HNoRecordsFound,
  },
  props: {
    value: {
      type: Array,
      default: () => { return [] },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => { return [] },
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      headers: [
        { value: 'name', text: 'Name' },
        { value: 'category.name', text: 'Category' },
        { value: 'serial_number', text: 'S/N' },
        { value: 'part_number', text: 'P/N' },
        { value: 'mac_address', text: 'MAC Address' },
        // { value: 'contract_reference', text: 'Contract Ref.' },
        { value: 'company.name', text: 'Company' },
        { value: 'contact.full_name', text: 'Contact' },
        { value: 'contractor.name', text: 'Contractor' },
        { value: 'actions', text: 'Actions', sortable: false },
      ],
    }
  },
  computed: {
    selectedItems: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
  beforeDestroy () {
    this.selectedItems = []
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.selectedItems = []
    },
  },
}
</script>
