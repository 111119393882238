import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class HelpdeskSLALevelService {
  static getSLALevels = async () => {
    try {
      const response = await http.get('helpdesk/sla-levels')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createSLALevel = async (data) => {
    try {
      const response = await http.post('helpdesk/sla-levels/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateSLALevel = async (id, data) => {
    try {
      const response = await http.patch(`helpdesk/sla-levels/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteSLALevel = async (id) => {
    try {
      const response = await http.delete(`helpdesk/sla-levels/${id}/delete`)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getSLALevelsInput = async () => {
    try {
      const response = await http.get(`helpdesk/sla-levels/input`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
