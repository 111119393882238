<template>
  <h-app-main-menu :menuItems="menuItems"/>
</template>

<script>
import HAppMainMenu from '@/common/components/HAppMainMenu'
import { getFirstAuthorizedChildViewNameFromRouteName } from '@/router/tools'
export default {
  name: 'BaseMainMenu',
  components: { HAppMainMenu },
  data () {
    return {
      menuItems: [
        {
          menuLabel: 'Users',
          menuPermissions: [
            'view_user',
          ],
          routeName: 'UserListing',
          matchingPaths: [
            '/settings/users',
          ],
        },
        {
          menuLabel: 'Groups',
          menuPermissions: [
            'view_group',
          ],
          routeName: 'GroupListing',
          matchingPaths: [
            '/settings/groups',
          ],
        },
        {
          menuLabel: 'Applications',
          menuPermissions: [
            'view_application'
          ],
          routeName: 'ApplicationListing',
          matchingPaths: [
            '/settings/applications',
          ],
        },
        {
          menuLabel: 'Settings',
          menuPermissions: [
            'view_globalschedule',
            'view_emailsettings',
            'view_outboundfailedmail',
            'view_apikey',
            'view_webhook',
          ],
          matchingPaths: [
            '/settings/global-schedule',
            '/settings/emails',
            '/settings/integrations',
          ],
          routeName: getFirstAuthorizedChildViewNameFromRouteName('BaseSettingsMenu'),
        },
      ],
    }
  },
}
</script>
