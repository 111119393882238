var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-toolbar',{attrs:{"tile":"","dense":"","elevation":"1"}},[(
      _vm.selectedSubtasks.length
      && (
        _vm.hasPermission('delete_helpdeskticketsubtask')
        || _vm.hasPermission('change_helpdeskticketsubtask')
      )
    )?_c('v-menu',{attrs:{"close-on-click":"","close-on-content-click":"","offsetY":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.$t('common.labels.actions'))+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,603960717)},[_c('v-list',[(_vm.hasPermission('change_helpdeskticketsubtask'))?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('updateSubtasksAsToDo', _vm.selectedSubtasks)}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""}},[_vm._v("mdi-circle-outline")]),_vm._v(" Set as 'To Do' ")],1):_vm._e(),(_vm.hasPermission('change_helpdeskticketsubtask'))?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('updateSubtasksAsInProgress', _vm.selectedSubtasks)}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""}},[_vm._v("mdi-progress-wrench")]),_vm._v(" Set as 'In Progress' ")],1):_vm._e(),(_vm.hasPermission('change_helpdeskticketsubtask'))?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('updateSubtasksAsDone', _vm.selectedSubtasks)}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""}},[_vm._v("mdi-check-circle")]),_vm._v(" Set as 'Done' ")],1):_vm._e(),(_vm.hasPermission('delete_helpdeskticketsubtask'))?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('deleteSubtasks', _vm.selectedSubtasks)}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t('common.buttons.delete'))+" ")],1):_vm._e()],1)],1):_vm._e(),_c('v-spacer'),_c('h-filtering-bar',{attrs:{"availableFilters":_vm.availableFilters,"defaultFilters":[
      { fieldName: 'status', label: 'Status', type: 'predefinedValue', value: 'to_do', valueDisplay: 'To Do' },
      { fieldName: 'status', label: 'Status', type: 'predefinedValue', value: 'in_progress', valueDisplay: 'In Progress' },
    ]}}),_c('v-spacer'),_c('v-btn',{class:{'v-btn--active': _vm.viewType === 'list'},attrs:{"tile":"","icon":"","link":""},on:{"click":function($event){return _vm.$emit('setListView')}}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }