import HelpdeskTicketTagFormCreate from '@/views/helpdesk/tag/HelpdeskTicketTagFormCreate'
import HelpdeskTicketTagFormRead from '@/views/helpdesk/tag/HelpdeskTicketTagFormRead'
import HelpdeskTicketTagListing from '@/views/helpdesk/tag/HelpdeskTicketTagListing'

export default [
  {
    path: 'tags',
    name: 'HelpdeskTicketTagListing',
    component: HelpdeskTicketTagListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_helpdesktickettag',
    },
  },
  {
    path: 'tags/create',
    name: 'HelpdeskTicketTagFormCreate',
    component: HelpdeskTicketTagFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_helpdesktickettag',
    },
  },
  {
    path: 'tags/:helpdeskTicketTagId',
    name: 'HelpdeskTicketTagFormRead',
    component: HelpdeskTicketTagFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_helpdesktickettag',
    },
  },
]
