<template>
  <v-tabs grow>
    <v-tab
      v-if="hasPermission('view_emailsettings')"
      :to="{ name: 'EmailSettingsForm' }">
      Configuration
    </v-tab>
    <v-tab
      v-if="hasPermission('view_outboundfailedmail')"
      :to="{ name: 'OutboundFailedMailList' }">
      Outbound Failed Emails
    </v-tab>
    <v-tabs-items>
      <v-divider/>
      <router-view/>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
export default {
  name: 'BaseEmailSettingsMenu',
}
</script>
