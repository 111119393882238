var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"color":_vm.cardColor}},[_c('v-card-title',[_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.message.message_type_display))]),_c('span',{staticClass:"caption"},[_vm._v(" by ")]),_c('span',{staticClass:"subtitle-2"},[(_vm.message.author)?[_vm._v(" "+_vm._s(_vm.message.author.full_name)+" ")]:[_vm._v(" "+_vm._s(_vm.message.author_full_name)+" ")]],2),_vm._v("   "),_c('span',{staticClass:"caption font-italic",staticStyle:{"opacity":"0.5"}},[_vm._v("- "+_vm._s(_vm._f("moment")(_vm.message.create_date,'calendar')))]),_c('v-spacer'),(
        _vm.hasPermission('delete_helpdeskticketmessage')
        && _vm.message.message_type === 'comment'
      )?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteMessage(_vm.message)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e(),(
        _vm.message.message_type !== 'comment'
        && _vm.message.is_sending_failed || _vm.message.sending_date
      )?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"150"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({},on),[_c('v-btn',{staticStyle:{"pointer-events":"none"},attrs:{"icon":"","small":""}},[_c('v-icon',{staticStyle:{"opacity":"0.7"},attrs:{"small":"","color":_vm.iconMailStatusAttributes(_vm.message).color}},[_vm._v(" "+_vm._s(_vm.iconMailStatusAttributes(_vm.message).icon)+" ")])],1)],1)]}}],null,false,2445989023)},[_vm._v(" "+_vm._s(_vm.iconMailStatusAttributes(_vm.message).tooltipText)+" ")]):_vm._e()],1),_c('v-card-text',{staticClass:"pb-2"},[_c('h-rich-text-editor',{attrs:{"value":_vm.message.message}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }