<template>
  <v-btn
    class="h-rich-editor-menu-button"
    icon
    :disabled="disabled">
    <v-icon>mdi-{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'HRichTextEditorMenuButton',
  props: {
    disabled: { type: Boolean },
    icon: { type: String },
  }
}
</script>
