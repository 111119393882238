import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en'
import fr from './locales/fr'

Vue.use(VueI18n)

const messages = {
  en: en,
  fr: fr,
}

function getPreferedLocale () {
  const lastLoggedInUser = JSON.parse(localStorage.getItem('hubbiz-last-logged'))
  if (lastLoggedInUser) return lastLoggedInUser.language
  const browserLanguage = navigator.language
  if (browserLanguage) return browserLanguage
  return 'en'
}

const i18n = new VueI18n({
  locale: getPreferedLocale(),
  fallBackLocale: 'en',
  messages,
})

export default i18n
