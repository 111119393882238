import HelpdeskGeneralSettingsForm from '@/views/helpdesk/settings/settings/HelpdeskGeneralSettingsForm'
import HelpdeskSLALevelList from '@/views/helpdesk/settings/settings/HelpdeskSLALevelList'
import HelpdeskRecurringTicketTaskList from '@/views/helpdesk/settings/settings/HelpdeskRecurringTicketTaskList'
import HelpdeskSettingsMenu from '@/views/helpdesk/settings/HelpdeskSettingsMenu'

export default [
  {
    path: 'settings',
    name: 'HelpdeskSettingsMenu',
    component: HelpdeskSettingsMenu,
    meta: {
      type: 'submenu',
      requiresAuth: true,
    },
    children: [
      {
        path: 'general',
        name: 'HelpdeskGeneralSettingsForm',
        component: HelpdeskGeneralSettingsForm,
        meta: {
          type: 'form',
          requiresAuth: true,
          permission: 'view_helpdesksettings',
        },
      },
      {
        path: 'sla-levels',
        name: 'HelpdeskSLALevelList',
        component: HelpdeskSLALevelList,
        meta: {
          type: 'list',
          requiresAuth: true,
          permission: 'view_helpdeskslalevel',
        },
      },
      {
        path: 'recurring-tickets',
        name: 'HelpdeskRecurringTicketTaskList',
        component: HelpdeskRecurringTicketTaskList,
        meta: {
          type: 'list',
          requiresAuth: true,
          permission: 'view_helpdeskrecurringtickettask',
        },
      },
    ],
  }
]
