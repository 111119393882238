<template>
  <v-row class="my-0">
    <v-col cols=12>
      <v-toolbar flat tile color="transparent">
        <v-spacer/>
        <span
          v-if="contacts.length"
          class="caption my-auto">
          {{ $t('showing') }} <span style="color:#1E88E5">{{ contacts.length }}</span> {{ $t('of') }} {{ pagination?.total_records }}
        </span>
        <v-divider
          v-if="pagination?.has_next_page"
          class="mx-4"
          vertical/>
        <v-btn
          v-if="pagination?.has_next_page"
          color="blue darken-1"
          outlined
          :loading="loadingMore"
          @click="$emit('loadNextContactPage')">
          <v-icon left>mdi-download</v-icon>
          {{ $t('common.buttons.loadMore') }}
        </v-btn>
      </v-toolbar>
    </v-col>
    <template v-if="contacts.length && !loading">
      <v-col
        cols=12 sm=6 md=3 lg=2
        v-for="contact in contacts"
        :key="contact.id">
        <v-card
          tile
          elevation="3"
          class="contact-card"
          :ripple="false"
          @click="onContactClick(contact)">
          <v-card-title>
            <span class="subtitle-1">
              {{ contact.full_name | truncate(25, '...') }}
            </span>
            <v-spacer/>
            <v-menu
              v-if="
                (
                  hasPermission('add_user')
                  || hasPermission('change_contactcontact')
                  || hasPermission('delete_contactcontact')
                )
                && !contact.user
              "
              close-on-click
              close-on-content-click
              offsetY
              left>
              <!-- TODO: Add close-delay when it work -->
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" tile icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="hasPermission('add_user') && !contact.user && !contact.is_archived"
                  @click="$emit('createUserForContact', contact)">
                  <v-icon small left>mdi-account-plus</v-icon>
                  {{ $t('common.buttons.createUser') }}
                </v-list-item>
                <v-list-item
                  v-if="hasPermission('change_contactcontact') && !contact.user"
                  @click="contactArchiving(contact)">
                  <v-icon v-if="contact.is_archived" small left>mdi-archive-arrow-up</v-icon>
                  <v-icon v-else small left>mdi-archive-arrow-down</v-icon>
                  {{ contact.is_archived ? $t('applications.contact.buttons.unarchiveContact') : $t('applications.contact.buttons.archiveContact') }}
                </v-list-item>
                <v-list-item
                  v-if="hasPermission('delete_contactcontact') && !contact.user"
                  @click="$emit('deleteContact', contact)">
                  <v-icon small left>mdi-delete</v-icon>
                  {{ $t('common.buttons.delete') }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-subtitle class="pb-1">
            <v-chip
              x-small
              outlined
              label
              class="font-weight-bold"
              :color="contact.is_archived ? 'red' : 'green'">
              {{ contact.is_archived ? $t('common.labels.archived') : $t('common.labels.unarchived') }}
            </v-chip>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon x-small left>mdi-account</v-icon>
            <v-chip
              v-if="contact.user"
              x-small
              :color="contact.user.user_type === 'system' ? 'green' : 'primary'"
              class="font-weight-bold white--text">
              {{ contact.user.username }} ({{ contact.user.user_type_display }})
            </v-chip>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon x-small left>mdi-office-building</v-icon>
            <template v-if="contact.company">{{ contact.company.name | truncate(35, '...') }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon x-small left>mdi-phone</v-icon>
            <template v-if="contact.phone_pro">{{ contact.phone_pro }}</template>
            <template v-else-if="contact.phone_pro_mobile">{{ contact.phone_pro_mobile }}</template>
            <template v-else-if="contact.phone_perso">{{ contact.phone_perso }}</template>
            <template v-else-if="contact.phone_perso_mobile">{{ contact.phone_perso_mobile }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon x-small left>mdi-email</v-icon>
            <template>{{ contact.email }}</template>
          </v-card-subtitle>
          <v-card-subtitle class="pt-1">
            <v-chip-group column>
              <v-chip
                v-for="tag in contact.tags"
                :key="tag.id"
                x-small
                class="font-weight-bold"
                :color="tag.color"
                :class="{ 'white--text': shouldTextBeWhite(tag.color) }">
                {{ tag.name }}
              </v-chip>
            </v-chip-group>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </template>
    <template v-else-if="!contacts.length && !loading">
      <v-col cols=12 align="center" justify="center">
        <h-no-records-found createFormViewName="ContactContactFormCreate"/>
      </v-col>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: 'ContactContactKanban',
  components: {
    HNoRecordsFound,
    HRequestLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    contacts: {
      type: Array,
      default: () => { return [] },
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onContactClick (contact) {
      this.$router.push({
        name: 'ContactContactFormRead',
        params: { contactId: contact.id },
      })
    },
    contactArchiving (contact) {
      contact.is_archived
      ? this.$emit('unarchiveContact', contact)
      : this.$emit('archiveContact', contact)
    },
  },
}
</script>

<style scoped>
  .contact-card {
    height: 100%;
    transition: all 0.15s;
    cursor: pointer;
    border: thin solid transparent;
  }
  .contact-card:hover {
    border: thin solid rgba(0, 0, 0, 0.25);
  }
  .contact-card .v-card__title {
    height: 68px;
  }
</style>
