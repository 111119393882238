import HelpdeskMainMenu from '@/views/helpdesk/menus/HelpdeskMainMenu'
import dashboardRoutes from './models/dashboard.routes'
import settingsRoutes from './models/settings.routes'
import teamRoutes from './models/team.routes'
import ticketRoutes from './models/ticket.routes'
import ticketTagRoutes from './models/ticket-tag.routes'

export default [
  {
    path: '/helpdesk',
    name: 'HelpdeskMainMenu',
    component: HelpdeskMainMenu,
    meta: {
      type: 'menu',
      requiresAuth: true,
    },
    children: [
      // Keep the same order as in the menu of the related application.
      ...teamRoutes,
      ...ticketRoutes,
      ...dashboardRoutes,
      ...ticketTagRoutes,
      ...settingsRoutes,
    ],
  }
]
