<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-plus</v-icon>
        Create Webhook
      </v-toolbar-title>
    </v-toolbar>
    <v-form v-model="isFormValid" class="pa-4">
      <v-text-field
        v-model="form.name"
        label="Name"
        placeholder="e.g.: Create ticket from Zabbix"
        required
        autofocus
        type="text"
        maxlength="100"
        counter
        :rules="[formRules.required]"/>
      <v-select
        v-model="form.api_user"
        label="API User"
        item-value="id"
        return-object
        :loading="loadingAPIUsers"
        :items="apiUsers"
        :menu-props="{ top: false, offsetY: true }"
        :rules="[formRules.required]"
        @click.once="getAPIUsersInput">
        <template v-slot:prepend-item>
          <v-list-item @click="createAPIUser">
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Create one
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"/>
        </template>
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item v-on="on" v-bind="attrs">
            <v-list-item-avatar>
              <v-img :src="item.picture ? getPictureURL(item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.full_name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="data">
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ data.item.full_name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
      <v-switch
        v-model="form.is_active"
        inset
        dense
        color="green">
        <template v-slot:label>
          <span class="caption">{{ form.is_active ? 'Active' : 'Inactive' }}</span>
        </template>
      </v-switch>
      <v-select
        v-model="form.action"
        item-value="value"
        label="Action"
        :items="webhookActions"
        :rules="[formRules.required]"/>
      <v-select
        v-if="form.action === 'create_or_update_helpdesk_ticket'"
        v-model="form.helpdesk_team"
        label="Helpdesk Team"
        item-text="name"
        return-object
        :items="helpdeskTeams"
        :loading="loadingTeamsInput"
        @click.once="getHelpdeskTeamsInput"
        :rules="[formRules.required]"/>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
import BaseUserService from '@/services/base/base.user.service'
import HelpdeskTeamService from '@/services/helpdesk/helpdesk.team.service'
import WebhookAPIUserFormDialog from './WebhookAPIUserFormDialog'
export default {
  name: 'WebhookFormDialog',
  data () {
    return {
      loadingAPIUsers: false,
      loadingTeamsInput: false,
      apiUsers: [],
      helpdeskTeams: [],
      webhookActions: [
        { value: 'create_or_update_helpdesk_ticket', text: 'Create or Update Helpdesk Ticket'},
      ],
      isFormValid: false,
      form: {
        name: '',
        api_user: null,
        is_active: true,
        action: '',
        helpdesk_team: null,
      },
      formRules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  methods: {
    async getAPIUsersInput () {
      this.loadingAPIUsers = true
      this.apiUsers = await BaseUserService.getAPIUsersInput()
      this.loadingAPIUsers = false
    },
    async getHelpdeskTeamsInput () {
      this.loadingTeamsInput = true
      this.helpdeskTeams = await HelpdeskTeamService.getTeamsInput()
      this.loadingTeamsInput = false
    },
    createAPIUser () {
      this.$dialog.show(WebhookAPIUserFormDialog, {
        persistent: true,
        waitForResult: true,
      }).then(async result => {
        if (result) {
          const createdAPIUser = await BaseUserService.createAPIUser({
            name: result.name
          })
          if (createdAPIUser) {
            this.apiUsers.push(createdAPIUser)
            this.form.api_user = createdAPIUser
            this.$dialog.notify.success(
              `API User ${createdAPIUser.full_name} created`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
  computed: {
    actions () {
      return {
        false: {
          text: 'Cancel',
        },
        true: {
          text: 'Save',
          color: 'green',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
</style>
