import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class HelpdeskTeamService {
  static getTeams = async (urlSearchParams) => {
    try {
      const response = await http.get('helpdesk/teams', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTeam = async (id) => {
    try {
      const response = await http.get(`helpdesk/teams/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTeam = async (data) => {
    try {
      const response = await http.post('helpdesk/teams/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTeamMembers = async (id) => {
    try {
      const response = await http.get(`helpdesk/teams/${id}/members`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static addMembersToTeam = async (id, data) => {
    try {
      const response = await http.patch(`helpdesk/teams/${id}/add-members`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static removeMemberFromTeam = async (id, data) => {
    try {
      const response = await http.patch(`helpdesk/teams/${id}/remove-member`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateTeam = async (id, data) => {
    try {
      const response = await http.patch(`helpdesk/teams/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteTeams = async (data) => {
    try {
      const response = http.post('helpdesk/teams/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTeamStage = async (id, data) => {
    try {
      const response = await http.post(`helpdesk/teams/${id}/stages/create`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateTeamStage = async (teamId, stageId, data) => {
    try {
      const response = await http.patch(`helpdesk/teams/${teamId}/stages/${stageId}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTeamStages = async (id) => {
    try {
      const response = await http.get(`helpdesk/teams/${id}/stages`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteTeamStage = async (teamId, stageId) => {
    try {
      const response = await http.delete(`helpdesk/teams/${teamId}/stages/${stageId}/delete`)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getAllTeamStageTickets = async (teamId, stageIds, urlSearchParams) => {
    const endpoints = []
    for (const stageId of stageIds) {
      endpoints.push({
        stageId: stageId,
        url: `helpdesk/teams/${teamId}/stages/${stageId}/tickets`
      })
    }
    const allResponsesDatas = await Promise.all(endpoints.map(endpoint =>
      http.get(endpoint.url, { params: urlSearchParams })
    )).then(responses => {
      const responsesDatas = {}
      for (const response of responses) {
        const stageId = response.config.url.match(/stages\/(.*)\//)[1]
        responsesDatas[stageId] = response.data
      }
      return responsesDatas
    }).catch(error => {
      handleAxiosError(error)
    })
    return allResponsesDatas
  }

  static getTeamStageTickets = async (teamId, stageId, urlSearchParams) => {
    try {
      const response = await http.get(`helpdesk/teams/${teamId}/stages/${stageId}/tickets`, { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTeamStageTicket = async (teamId, stageId, data) => {
    try {
      const response = await http.post(
        `helpdesk/teams/${teamId}/stages/${stageId}/tickets/create`,
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateTeamStageTicket = async (teamId, stageId, ticketId, data) => {
    try {
      const response = await http.patch(`helpdesk/teams/${teamId}/stages/${stageId}/tickets/${ticketId}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTeamsInput = async () => {
    try {
      const response = await http.get('helpdesk/teams/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTeamStagesInput = async (id) => {
    try {
      const response = await http.get(`helpdesk/teams/${id}/stages/input`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
