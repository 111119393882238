import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class BaseUserService {
  static getMe = async () => {
    try {
      const response = await http.get('base/users/me')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getUserProfile = async () => {
    try {
      const response = await http.get('base/users/profile')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateUserProfile = async (data) => {
    try {
      const response = await http.post(
        'base/users/profile/update',
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static selfUpdatePassword = async (data) => {
    try {
      const response = await http.patch('base/users/profile/update-password', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getUsers = async (urlSearchParams) => {
    try {
      const response = await http.get('base/users', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getUser = async (id) => {
    try {
      const response = await http.get(`base/users/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createUser = async (data) => {
    try {
      const response = await http.post('base/users/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createAPIUser = async (data) => {
    try {
      const response = await http.post('base/api-users/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getUserPermissions = async (id) => {
    try {
      const response = await http.get(`base/users/${id}/permissions`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getUserGroups = async (id) => {
    try {
      const response = await http.get(`base/users/${id}/groups`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static addGroupsToUser = async (id, data) => {
    try {
      const response = await http.patch(`base/users/${id}/add-groups`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static removeGroupFromUser = async (id, data) => {
    try {
      const response = await http.patch(`base/users/${id}/remove-group`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateUser = async (id, data) => {
    try {
      const response = await http.patch(`base/users/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateUserPassword = async (id, data) => {
    try {
      const response = await http.patch(`base/users/${id}/change-password`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static activateUser = async (id) => {
    try {
      const response = await http.patch(`base/users/${id}/activate`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static activateUsers = async (data) => {
    try {
      const response = await http.patch(`base/users/activate`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deactivateUser = async (id) => {
    try {
      const response = await http.patch(`base/users/${id}/deactivate`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deactivateUsers = async (data) => {
    try {
      const response = await http.patch(`base/users/deactivate`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static uploadUserPicture = async (id, data) => {
    try {
      const response = await http.post(
        `base/users/${id}/upload-picture`,
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteUsers = async (data) => {
    try {
      const response = await http.post('base/users/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getUsersInput = async () => {
    try {
      const response = await http.get('base/users/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getAPIUsersInput = async () => {
    try {
      const response = await http.get('base/api-users/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
