import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class HelpdeskSettingsService {
  static getSettings = async () => {
    try {
      const response = await http.get('helpdesk/settings')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateSettings = async (data) => {
    try {
      const response = await http.patch('helpdesk/settings/update', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
