<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-calendar-edit</v-icon>
        Recurring Period Configuration
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="activeTab" grow>
      <v-tab>Weekly</v-tab>
      <v-tab>Monthly</v-tab>
      <v-tab-item>
        <v-divider/>
        <v-row class="pa-4">
          <v-col class="px-12">
            Every
            <v-divider/>
            <v-checkbox
              v-model="weeklyForm.monday"
              label="Monday"/>
            <v-checkbox
              v-model="weeklyForm.tuesday"
              label="Tuesday"/>
            <v-checkbox
              v-model="weeklyForm.wednesday"
              label="Wednesday"/>
            <v-checkbox
              v-model="weeklyForm.thursday"
              label="Thursday"/>
            <v-checkbox
              v-model="weeklyForm.friday"
              label="Friday"/>
            <v-checkbox
              v-model="weeklyForm.saturday"
              label="Saturday"/>
            <v-checkbox
              v-model="weeklyForm.sunday"
              label="Sunday"/>
          </v-col>
          <v-divider vertical/>
          <v-col class="px-12">
            At
            <v-divider/>
            <v-time-picker
              v-model="weeklyForm.time"
              format="24hr"
              scrollable
              class="pt-4"/>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-divider/>
        <v-row class="pa-4">
          <v-col class="px-12">
            <v-select
              v-model="monthlyForm.days"
              :items="daysEnum"
              item-value="value"
              label="On day(s)"
              multiple
              clearable
              chips
              small-chips
              deletable-chips
              outlined/>
            <v-select
              v-model="monthlyForm.months"
              :items="monthsEnum"
              item-value="value"
              label="Of month(s)"
              multiple
              clearable
              chips
              small-chips
              deletable-chips
              outlined/>
          </v-col>
          <v-divider vertical/>
          <v-col class="px-12">
            At
            <v-divider/>
            <v-time-picker
              v-model="monthlyForm.time"
              format="24hr"
              scrollable
              class="pt-4"/>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>
    <v-divider/>
  </DialogCard>
</template>

<script>
export default {
  name: 'HCronEditorModal',
  data () {
    return {
      activeTab: 0,
      weeklyForm: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        time: null,
      },
      monthlyForm: {
        days: [],
        months: [],
        time: null
      },
      daysEnum: [
        { text: '1', value: 1 },
        { text: '2', value: 2 },
        { text: '3', value: 3 },
        { text: '4', value: 4 },
        { text: '5', value: 5 },
        { text: '6', value: 6 },
        { text: '7', value: 7 },
        { text: '8', value: 8 },
        { text: '9', value: 9 },
        { text: '10', value: 10 },
        { text: '11', value: 11 },
        { text: '12', value: 12 },
        { text: '13', value: 13 },
        { text: '14', value: 14 },
        { text: '15', value: 15 },
        { text: '16', value: 16 },
        { text: '17', value: 17 },
        { text: '18', value: 18 },
        { text: '19', value: 19 },
        { text: '20', value: 20 },
        { text: '21', value: 21 },
        { text: '22', value: 22 },
        { text: '23', value: 23 },
        { text: '24', value: 24 },
        { text: '25', value: 25 },
        { text: '26', value: 26 },
        { text: '27', value: 27 },
        { text: '28', value: 28 },
        { text: '29', value: 29 },
        { text: '30', value: 30 },
        { text: '31', value: 31 },
      ],
      monthsEnum: [
        { text: 'January', value: 1 },
        { text: 'February', value: 2 },
        { text: 'March', value: 3 },
        { text: 'April', value: 4 },
        { text: 'May', value: 5 },
        { text: 'June', value: 6 },
        { text: 'July', value: 7 },
        { text: 'August', value: 8 },
        { text: 'September', value: 9 },
        { text: 'October', value: 10 },
        { text: 'November', value: 11 },
        { text: 'December', value: 12 },
      ]
    }
  },
  computed: {
    isWeeklyFormValid () {
      return this.weeklyForm.time !== null && this.atLeastOneWeeklyDayIsChosen()
    },
    isMonthlyFormValid () {
      return !!this.monthlyForm.days.length && !!this.monthlyForm.months.length && this.monthlyForm.time !== null
    },
    okButtonShouldBeDisabled () {
      if (this.activeTab === 0) { // WEEKLY TAB
        return !this.isWeeklyFormValid
      }
      if (this.activeTab === 1) { // MONTHLY TAB
        return !this.isMonthlyFormValid
      }
    },
    actions () {
      return {
        false: {
          text: 'Cancel'
        },
        true: {
          text: 'Ok',
          color: 'primary',
          disabled: this.okButtonShouldBeDisabled,
          handler: () => {
            const cron = this.generateCRONExpression()
            return new Promise(resolve => {
              resolve(cron)
            })
          }
        }
      }
    }
  },
  methods: {
    atLeastOneWeeklyDayIsChosen () {
      return [
        this.weeklyForm.monday,
        this.weeklyForm.tuesday,
        this.weeklyForm.wednesday,
        this.weeklyForm.thursday,
        this.weeklyForm.friday,
        this.weeklyForm.saturday,
        this.weeklyForm.sunday,
      ].some(value => value)
    },
    generateCRONExpression () {
      if (this.activeTab === 0) { // WEEKLY TAB
        let days = ''
        if (this.weeklyForm.sunday) days = days.concat('0,')
        if (this.weeklyForm.monday) days = days.concat('1,')
        if (this.weeklyForm.tuesday) days = days.concat('2,')
        if (this.weeklyForm.wednesday) days = days.concat('3,')
        if (this.weeklyForm.thursday) days = days.concat('4,')
        if (this.weeklyForm.friday) days = days.concat('5,')
        if (this.weeklyForm.saturday) days = days.concat('6,')
        days = days.replace(/(^,)|(,$)/g, "")
        const hours = this.weeklyForm.time ? this.weeklyForm.time.split(':')[0].replace(/^0+/, '') : '*'
        const minutes = this.weeklyForm.time ? this.weeklyForm.time.split(':')[1].replace(/^0+/, '') : '*'
        return `${minutes} ${hours} * * ${days}`
      } else if (this.activeTab === 1) { // MONTHLY TAB
        const days = this.monthlyForm.days.join(',')
        const months = this.monthlyForm.months.join(',')
        const hours = this.monthlyForm.time ? this.monthlyForm.time.split(':')[0].replace(/^0+/, '') : '*'
        const minutes = this.monthlyForm.time ? this.monthlyForm.time.split(':')[1].replace(/^0+/, '') : '*'
        return `${minutes} ${hours} ${days} ${months} *`
      }
    }
  }
}
</script>

<style scoped>
.v-card::v-deep .v-card__text {
  padding: 0;
}
.v-input.v-select {
  width: 260px;
  max-width: 260px;
}
</style>
<style>
/* TODO Move this to a global location CSS file or whatever */
.v-dialog__content .v-dialog.vuedl-layout {
  min-width: 400px;
  width: fit-content;
  max-width: 70%!important;
  max-height: 80%!important;
  /* overflow: hidden; */
}
</style>
