import { render, staticRenderFns } from "./InventoryItemCategoryList.vue?vue&type=template&id=9f5d69c4"
import script from "./InventoryItemCategoryList.vue?vue&type=script&lang=js"
export * from "./InventoryItemCategoryList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports