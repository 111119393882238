<template>
  <div>
    <helpdesk-team-listing-toolbar
      :viewType="viewType"
      :selectedTeams="selectedTeams"
      @setListView="setListView"
      @setKanbanView="setKanbanView"
      @deleteTeams="deleteTeams"/>
    <transition name="router-anim" mode="out-in">
      <helpdesk-team-kanban
        v-if="viewType === 'kanban'"
        :teams="teams"
        :loading="loading"
        @deleteTeam="deleteTeam"/>
      <helpdesk-team-list
        v-else
        v-model="selectedTeams"
        :teams="teams"
        :loading="loading"
        @deleteTeam="deleteTeam"/>
    </transition>
  </div>
</template>

<script>
import DeleteModal from '@/common/components/DeleteModal'
import HelpdeskTeamListingToolbar from '@/views/helpdesk/team/shared/HelpdeskTeamListingToolbar'
import HelpdeskTeamKanban from '@/views/helpdesk/team/HelpdeskTeamKanban'
import HelpdeskTeamList from '@/views/helpdesk/team/HelpdeskTeamList'
import HelpdeskTeamService from '@/services/helpdesk/helpdesk.team.service'
export default {
  name: 'HelpdeskTeamListing',
  components: {
    HelpdeskTeamListingToolbar,
    HelpdeskTeamKanban,
    HelpdeskTeamList,
  },
  data () {
    return {
      loading: false,
      viewType: 'kanban',
      teams: [],
      selectedTeams: [],
    }
  },
  mounted () {
    this.getTeams(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getTeams(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    setKanbanView () {
      this.viewType = 'kanban'
    },
    async getTeams (urlSearchParams) {
      this.loading = true
      this.teams = await HelpdeskTeamService.getTeams(urlSearchParams)
      this.loading = false
    },
    deleteTeam (team) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Team',
        recordNamePlural: 'Teams',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTeamService.deleteTeams({ ids: [team.id] })
          if (response) {
            this.teams.splice(this.teams.indexOf(team), 1)
            this.selectedTeams = []
            this.$dialog.notify.success(
              `Team '${team.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteTeams (teamsToDelete) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Team',
        recordNamePlural: 'Teams',
        summaryHeaders: [
          { value: 'name', text: 'Name' },
          { value: 'members_count', text: '# Members' },
          { value: 'open_tickets_count', text: '# Open Tickets' },
        ],
        summaryRecords: teamsToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          let teamIdsToDelete = teamsToDelete.map(team => team.id)
          const response = await HelpdeskTeamService.deleteTeams({ ids: teamIdsToDelete })
          if (response) {
            this.teams = this.teams.filter(undeletedTeam => !teamIdsToDelete.includes(undeletedTeam.id))
            this.selectedTeams = []
            this.$dialog.notify.success(
              `${teamsToDelete.length} ${teamsToDelete.length > 1 ? 'teams' : 'team'} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
