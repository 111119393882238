import BaseApplicationService from '@/services/base/base.application.service'

export default {
  state: () => ({
    activatedApplications: [],
    allSettingsByApplication: {}
  }),
  mutations: {
    SET_ACTIVATED_APPLICATIONS: (state, apps) => {
      state.activatedApplications = apps
    },
    ADD_ACTIVATED_APPLICATION: (state, app) => {
      state.activatedApplications.push(app)
      // TODO: Determine where to insert to keep a certain order.
    },
    REMOVE_DEACTIVATED_APPLICATION: (state, app) => {
      state.activatedApplications.splice(state.activatedApplications.indexOf(app), 1)
    },
    SET_ALL_SETTINGS_BY_APPLICATION: (state, settings) => {
      state.allSettingsByApplication = settings
    }
  },
  actions: {
    FETCH_ACTIVATED_APPLICATIONS ({ commit }) {
      return new Promise(async resolve => {
        const activatedApplications = await BaseApplicationService.getActivatedApplications()
        if (activatedApplications) {
          commit('SET_ACTIVATED_APPLICATIONS', activatedApplications)
          resolve()
        }
      })
    },
    FETCH_ALL_SETTINGS_BY_APPLICATION ({ commit }) {
      return new Promise(async resolve => {
        const settings = await BaseApplicationService.listAllSettingsByApplication()
        if (settings) {
          commit('SET_ALL_SETTINGS_BY_APPLICATION', settings)
          resolve()
        }
      })
    },
  },
}
