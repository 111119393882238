import BaseUserService from '@/services/base/base.user.service'

export default {
  state: () => ({
    isLoggedIn: false,
    me: null
  }),
  mutations: {
    SET_IS_LOGGED_IN: (state, status) => {
      state.isLoggedIn = status
    },
    SET_ME: (state, user) => {
      state.me = user
    },
    UNSET_ME: (state) => {
      const lastLoggedInUser = {
        username: state.me.username,
        full_name: state.me.full_name,
        language: state.me.language,
        // picture: state.me.picture,
      }
      localStorage.setItem('hubbiz-last-logged', JSON.stringify(lastLoggedInUser))
      state.me = null
    }
  },
  actions: {
    FETCH_ME ({ commit }) {
      return new Promise(async resolve => {
        const me = await BaseUserService.getMe()
        if (me) {
          commit('SET_ME', me)
          resolve()
        }
      })
    }
  }
}
