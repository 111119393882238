<template>
  <div>
    <helpdesk-ticket-subtask-listing-toolbar
      :viewType="viewType"
      :selectedSubtasks="selectedSubtasks"
      @setListView="setListView"
      @updateSubtasksAsToDo="updateSubtasksAsToDo"
      @updateSubtasksAsInProgress="updateSubtasksAsInProgress"
      @updateSubtasksAsDone="updateSubtasksAsDone"
      @deleteSubtasks="deleteSubtasks"/>
    <transition name="router-anim" mode="out-in">
      <helpdesk-ticket-subtask-list
        v-if="viewType === 'list'"
        v-model="selectedSubtasks"
        :subtasks="subtasks"
        :pagination="pagination"
        :loading="loading"
        :loadingMore="loadingMore"
        @loadNextSubtaskPage="loadNextSubtaskPage"
        @updateSubtaskAsToDo="updateSubtaskAsToDo"
        @updateSubtaskAsInProgress="updateSubtaskAsInProgress"
        @updateSubtaskAsDone="updateSubtaskAsDone"
        @deleteSubtask="deleteSubtask"/>
    </transition>
  </div>
</template>

<script>
import DeleteModal from '@/common/components/DeleteModal'
import HelpdeskTicketSubtaskListingToolbar from '@/views/helpdesk/subtask/HelpdeskTicketSubtaskListingToolbar'
import HelpdeskTicketSubtaskList from '@/views/helpdesk/subtask/HelpdeskTicketSubtaskList'
import HelpdeskTicketSubtaskService from '@/services/helpdesk/helpdesk.ticket.subtask.service'
export default {
  name: 'HelpdeskTicketSubtaskListing',
  components: {
    HelpdeskTicketSubtaskList,
    HelpdeskTicketSubtaskListingToolbar,
  },
  data () {
    return {
      loading: false,
      loadingMore: false,
      viewType: 'list',
      subtasks: [],
      pagination: null,
      selectedSubtasks: [],
    }
  },
  mounted () {
    this.getSubtasks(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getSubtasks(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    async getSubtasks (urlSearchParams) {
      this.loading = true
      const responseData = await HelpdeskTicketSubtaskService.getSubtasks(urlSearchParams)
      this.subtasks = responseData.subtasks
      this.pagination = responseData.pagination
      this.loading = false
    },
    async loadNextSubtaskPage () {
      if (this.pagination?.has_next_page) {
        this.loadingMore = true
        const nextPageNumber = this.pagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await HelpdeskTicketSubtaskService.getSubtasks(urlSearchParams)
        this.pagination = responseData.pagination
        const newSubtasksPage = responseData.subtasks
        const currentSubtasks = this.subtasks
        const updatedSubtasksList = currentSubtasks.concat(newSubtasksPage)
        this.subtasks = updatedSubtasksList
        this.loading = false
      }
    },
    updateSubtaskAsToDo (subtask) {
      this.updateSubtaskStatus(subtask, 'to_do')
    },
    updateSubtaskAsInProgress (subtask) {
      this.updateSubtaskStatus(subtask, 'in_progress')
    },
    updateSubtaskAsDone (subtask) {
      this.updateSubtaskStatus(subtask, 'done')
    },
    async updateSubtaskStatus (subtask, status) {
      if (status !== subtask.status) {
        const updatedSubtask = await HelpdeskTicketSubtaskService.updateSubtaskStatus(subtask.id, { status: status })
        if (updatedSubtask) {
          this.subtasks.splice(this.subtasks.indexOf(subtask), 1, updatedSubtask)
          if (
            this.viewType === 'list'
            && this.selectedSubtasks.some(selectedSubtask => selectedSubtask.id === subtask.id)
          ) {
            this.selectedSubtasks.splice(this.selectedSubtasks.indexOf(subtask), 1, updatedSubtask)
          }
          this.$dialog.notify.success(
           'Subtask status updated', {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    updateSubtasksAsToDo (subtasks) {
      this.bulkUpdateSubtasksStatus(subtasks, 'to_do')
    },
    updateSubtasksAsInProgress (subtasks) {
      this.bulkUpdateSubtasksStatus(subtasks, 'in_progress')
    },
    updateSubtasksAsDone (subtasks) {
      this.bulkUpdateSubtasksStatus(subtasks, 'done')
    },
    async bulkUpdateSubtasksStatus (subtasks, status) {
      const subtasksWithDifferentStatus = subtasks.filter(subtask => subtask.status !== status)
      const subtasksIds = subtasksWithDifferentStatus.map(subtask => subtask.id)
      const updatedSubtasks = await HelpdeskTicketSubtaskService.bulkUpdateSubtasksStatus({
        ids: subtasksIds,
        status: status,
      })
      if (updatedSubtasks) {
        for (const updatedSubtask of updatedSubtasks) {
          const subtaskToUpdate = this.subtasks.find(subtask => subtask.id === updatedSubtask.id)
          this.subtasks.splice(this.subtasks.indexOf(subtaskToUpdate), 1, updatedSubtask)
          if (
            this.viewType === 'list'
            && this.selectedSubtasks.some(selectedSubtask => selectedSubtask.id === updatedSubtask.id)
          ) {
            this.selectedSubtasks.splice(this.selectedSubtasks.indexOf(subtaskToUpdate), 1, updatedSubtask)
          }
        }
        this.$dialog.notify.success(
          `${subtasksWithDifferentStatus.length} of ${subtasks.length} selected ${subtasksIds.length > 1 ? 'subtasks' : 'subtask'} status updated`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    deleteSubtask (subtask) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Subtask',
        recordNamePlural: 'Subtasks',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTicketSubtaskService.deleteSubtasks({ ids: [subtask.id] })
          if (response) {
            this.subtasks.splice(this.subtasks.indexOf(subtask), 1)
            this.selectedSubtasks = []
            this.$dialog.notify.success(
              'Subtask deleted', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteSubtasks (subtasksToDelete) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Subtask',
        recordNamePlural: 'Subtasks',
        summaryHeaders: [
          { value: 'description', text: this.$t('common.labels.description') },
          { value: 'ticket_id', text: 'Ticket ID' },
        ],
        summaryRecords: subtasksToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const subtaskIdsToDelete = subtasksToDelete.map(subtask => subtask.id)
          const response = await HelpdeskTicketSubtaskService.deleteSubtasks({ ids: subtaskIdsToDelete })
          if (response) {
            this.subtasks = this.subtasks.filter(undeletedSubtask => !subtaskIdsToDelete.includes(undeletedSubtask.id))
            this.selectedSubtasks = []
            this.$dialog.notify.success(
              `${subtasksToDelete.length} ${subtasksToDelete.length > 1 ? 'subtasks' : 'subtask'} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
