<template>
  <contact-contact-form
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import ContactContactForm from './shared/ContactContactForm.vue'
import DialogUnsavedChanges from '../../../common/mixins/DialogUnsavedChanges'
export default {
  name: 'ContactContactFormCreate',
  mixins: [ DialogUnsavedChanges ],
  components: { ContactContactForm },
  data () {
    return {
      formIsEditing: true
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
