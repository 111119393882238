var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-rich-editor-wrapper"},[(_vm.shouldShowMenuBar)?_c('h-rich-text-editor-menu-bar',{attrs:{"disabled":!_vm.editable,"editor":_vm.editor,"formatParagraph":_vm.formatParagraph,"formatBold":_vm.formatBold,"formatItalic":_vm.formatItalic,"formatStrike":_vm.formatStrike,"formatMarker":_vm.formatMarker,"formatH1":_vm.formatH1,"formatH2":_vm.formatH2,"formatH3":_vm.formatH3,"formatListB":_vm.formatListB,"formatListN":_vm.formatListN,"formatCode":_vm.formatCode,"formatCodeBlock":_vm.formatCodeBlock,"formatSeparator":_vm.formatSeparator,"historyUndo":_vm.historyUndo,"historyRedo":_vm.historyRedo}}):_vm._e(),_c('editor-content',{staticClass:"h-rich-editor",class:{
      'disabled': !_vm.editable,
      'editor-padding': _vm.editorPadding,
      'opacify-disabled': _vm.opacifyDisabled,
      'editor-borders': _vm.editorBorders,
      'has-error': this.hasError(),
    },attrs:{"editor":_vm.editor}}),(_vm.hasError())?_c('div',{staticClass:"h-rich-editor-errors mt-1"},[_vm._v(" "+_vm._s(_vm.getErrorMessage())+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }