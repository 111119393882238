import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class HelpdeskTicketService {
  static getTickets = async (urlSearchParams) => {
    try {
      const response = await http.get('helpdesk/tickets', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTicket = async (data) => {
    try {
      const response = await http.post(
        'helpdesk/tickets/create',
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTicket = async (id) => {
    try {
      const response = await http.get(`helpdesk/tickets/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateTicket = async (id, data) => {
    try {
      const response = await http.patch(`helpdesk/tickets/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTicketMessages = async (id) => {
    try {
      const response = await http.get(`helpdesk/tickets/${id}/messages`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTicketComment = async (id, data) => {
    try {
      const response = await http.post(`helpdesk/tickets/${id}/messages/comment/create`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTicketMessage = async (id, data) => {
    try {
      const response = await http.post(`helpdesk/tickets/${id}/messages/message/create`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTicketAnswer = async (id, data) => {
    try {
      const response = await http.post(`helpdesk/tickets/${id}/messages/answer/create`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteTicketMessage = async (ticketId, messageId) => {
    try {
      const response = await http.post(`helpdesk/tickets/${ticketId}/messages/${messageId}/delete`)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTicketLogs = async (id) => {
    try {
      const response = await http.get(`helpdesk/tickets/${id}/logs`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTicketInventoryItems = async (id) => {
    try {
      const response = await http.get(`helpdesk/tickets/${id}/inventory-items`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getInventoryItemsTicketHasNot = async (id, urlSearchParams) => {
    try {
      const response = await http.get(`helpdesk/tickets/${id}/linkable-inventory-items`, { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static linkInventoryItemsToTicket = async (id, data) => {
    try {
      const response = await http.post(`helpdesk/tickets/${id}/inventory-items/link`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static unlinkInventoryItemFromTicket = async (ticketId, inventoryItemId) => {
    try {
      const response = await http.post(`helpdesk/tickets/${ticketId}/inventory-items/${inventoryItemId}/unlink`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getInventoryItemsContractorNotificationPreviews = async (ticketId, data) => {
    try {
      const response = await http.post(`helpdesk/tickets/${ticketId}/inventory-items/contractor-notification-previews`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static notifyInventoryItemsContractorForTicket = async (ticketId, data) => {
    try {
      const response = await http.post(`helpdesk/tickets/${ticketId}/inventory-items/contractor-notification`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTicketAttachments = async (id) => {
    try {
      const response = await http.get(`helpdesk/tickets/${id}/attachments`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static downloadTicketAttachment = async (ticketId, attachmentId) => {
    try {
      const response = await http.get(`helpdesk/tickets/${ticketId}/attachments/${attachmentId}/download`, { responseType: 'blob' })
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static uploadTicketAttachments = async (ticketId, data) => {
    try {
      const response = await http.post(
        `helpdesk/tickets/${ticketId}/attachments/upload`,
        data,
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      )
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteTicketAttachment = async (ticketId, attachmentId) => {
    try {
      const response = await http.delete(`helpdesk/tickets/${ticketId}/attachments/${attachmentId}/delete`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteTickets = async (data) => {
    try {
      const response = await http.post('helpdesk/tickets/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getRecurringTicketTasks = async () => {
    try {
      const response = await http.get('helpdesk/tickets/recurring-tasks')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static disableRecurringTicketTask = async (id) => {
    try {
      const response = await http.patch(`helpdesk/tickets/recurring-tasks/${id}/disable`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static enableRecurringTicketTask = async (id) => {
    try {
      const response = await http.patch(`helpdesk/tickets/recurring-tasks/${id}/enable`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteRecurringTicketTasks = async (data) => {
    try {
      const response = await http.post(`helpdesk/tickets/recurring-tasks/delete`, data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTicketSubtasks = async (id) => {
    try {
      const response = await http.get(`helpdesk/tickets/${id}/subtasks`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTicketSubtask = async (id, data) => {
    try {
      const response = await http.post(`helpdesk/tickets/${id}/subtasks/create`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateTicketSubtask = async (id, subtaskId, data) => {
    try {
      const response = await http.put(`helpdesk/tickets/${id}/subtasks/${subtaskId}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static changeTicketSubtaskStatus = async (id, subtaskId, data) => {
    try {
      const response = await http.patch(`helpdesk/tickets/${id}/subtasks/${subtaskId}/change-status`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static changeTicketSubtaskAssignee = async (id, subtaskId, data) => {
    try {
      const response = await http.patch(`helpdesk/tickets/${id}/subtasks/${subtaskId}/change-assignee`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static changeTicketSubtaskDueDate = async (id, subtaskId, data) => {
    try {
      const response = await http.patch(`helpdesk/tickets/${id}/subtasks/${subtaskId}/change-due-date`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static changeTicketSubtaskDoneDate = async (id, subtaskId, data) => {
    try {
      const response = await http.patch(`helpdesk/tickets/${id}/subtasks/${subtaskId}/change-done-date`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteTicketSubtask = async (id, subtaskId) => {
    try {
      const response = await http.delete(`helpdesk/tickets/${id}/subtasks/${subtaskId}/delete`)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTicketTimesheet = async (id, data) => {
    try {
      const response = await http.post(`helpdesk/tickets/${id}/timesheets/create`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTicketTimesheets = async (id) => {
    try {
      const response = await http.get(`helpdesk/tickets/${id}/timesheets`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteTicketTimesheet = async (id, timesheetId) => {
    try {
      const response = await http.delete(`helpdesk/tickets/${id}/timesheets/${timesheetId}/delete`)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateTicketTimesheet = async (id, timesheetId, data) => {
    try {
      const response = await http.put(`helpdesk/tickets/${id}/timesheets/${timesheetId}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static changeTicketTimesheetAssignee = async (id, timesheetId, data) => {
    try {
      const response = await http.patch(`helpdesk/tickets/${id}/timesheets/${timesheetId}/change-assignee`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateTicketTimer = async (id, data) => {
    try {
      const response = await http.patch(`helpdesk/tickets/${id}/update-timer`, data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
