<template>
  <div v-if="dashboard" class="h-helpdesk-dashboard">
    <v-toolbar tile dense elevation="1">
      <v-toolbar-title>Helpdesk Dashboard</v-toolbar-title>
      <v-progress-linear
        v-if="dashboardRefreshInterval"
        absolute
        bottom
        height="6"
        color="light-blue darken-4"
        :indeterminate="loadingDashboard"
        :value="dashboardTime"/>
      <v-spacer/>
      <v-btn
        icon
        v-if="hasPermission('change_helpdeskdashboard')"
        @click="editDashboard(dashboard)">
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <v-btn
        icon
        v-else
        @click="viewDashboard(dashboard)">
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row class="mt-3 mb-0">
      <v-col cols="12" lg="3" md="6">
        <overall-ticket-statuses
          :dashboard="dashboard"
          :height="160"/>
      </v-col>
      <v-col cols="12" lg="3" md="6">
        <overall-ticket-s-l-a-statuses
          :dashboard="dashboard"
          :height="160"/>
      </v-col>
      <v-col cols="12" lg="3" md="6">
        <overall-time-averages
          :dashboard="dashboard"
          :height="160"/>
      </v-col>
      <v-col cols="12" lg="3" md="6">
        <overall-tickets-by-month
          :ticketsByMonth="dashboard.tickets_by_month"
          :height="160"/>
      </v-col>
    </v-row>
    <v-card tile>
      <v-window v-model="windowPage" continuous>
        <v-window-item
          v-for="team in dashboardTeams"
          :key="team.id">
          <v-card flat>
            <v-card-title class="window-title">
              {{ team.name }}
              <v-spacer/>
              <v-btn
                icon
                v-if="hasPermission('change_helpdeskteam')"
                @click="editTeam(team)">
                <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-btn
              icon
              v-else
              @click="viewTeam(team)">
              <v-icon>mdi-information</v-icon>
            </v-btn>
            </v-card-title>
            <v-row class="my-0">
              <v-col cols="12" lg="3" md="6" sm="6">
                <team-ticket-statuses
                  :team="team"
                  :height="200"/>
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6">
                <team-ticket-s-l-a-statuses
                  :team="team"
                  :height="200"/>
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6">
                <team-time-averages
                  :team="team"
                  :height="200"/>
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6">
                <team-top-performers
                  :topPerformers="team.top_performers"
                  :height="200"/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols="12" lg="3" md="6" sm="6">
                <team-tickets-by-stage
                  :openTicketsCountByStage="team.open_tickets_count_by_stage"
                  :height="220"/>
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6">
                <team-tickets-open-closed-today
                  :team="team"
                  :height="220"/>
              </v-col>
              <v-col cols="12" lg="6">
                <team-tickets-by-month
                  :ticketsByMonth="team.tickets_by_month"
                  :height="220"/>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>
      <v-divider/>
      <v-card-actions class="justify-space-between">
        <v-btn
          text
          @click="showPreviousWindow()">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-item-group
          v-model="windowPage"
          class="text-center"
          mandatory>
          <v-item
            v-for="team in dashboardTeams"
            :key="team.id"
            v-slot:default="{ active, toggle }">
            <v-tooltip top open-delay="200">
              <template v-slot:activator="{ on }">
                <v-btn
                  :input-value="active"
                  icon
                  v-on="on"
                  @click="toggle">
                  <v-icon>mdi-record</v-icon>
                </v-btn>
              </template>
              <span>{{ team.name }}</span>
            </v-tooltip>
          </v-item>
        </v-item-group>
        <v-btn
          text
          @click="showNextWindow()">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-progress-linear
          v-if="windowSwitchInterval"
          absolute
          bottom
          height="4"
          color="light-blue darken-4"
          :value="windowTime"/>
      </v-card-actions>
    </v-card>
  </div>
  <div v-else>
    <v-col cols=12 align="center" justify="center">
      <h-request-loading/>
    </v-col>
  </div>
</template>

<script>
import HelpdeskDashboardFormDialog from './shared/HelpdeskDashboardFormDialog'
import HelpdeskTeamFormDialog from '../team/shared/HelpdeskTeamFormDialog'
import OverallTicketStatuses from './statistics/overall/OverallTicketStatuses'
import OverallTicketSLAStatuses from './statistics/overall/OverallTicketSLAStatuses'
import OverallTimeAverages from './statistics/overall/OverallTimeAverages'
import OverallTicketsByMonth from './statistics/overall/OverallTicketsByMonth'
import TeamTopPerformers from './statistics/team/TeamTopPerformers'
import TeamTimeAverages from './statistics/team/TeamTimeAverages'
import TeamTicketStatuses from './statistics/team/TeamTicketStatuses'
import TeamTicketSLAStatuses from './statistics/team/TeamTicketSLAStatuses'
import TeamTicketsByStage from './statistics/team/TeamTicketsByStage'
import TeamTicketsOpenClosedToday from './statistics/team/TeamTicketsOpenClosedToday'
import TeamTicketsByMonth from './statistics/team/TeamTicketsByMonth'
import HelpdeskDashboardService from '@/services/helpdesk/helpdesk.dashboard.service'
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: 'HelpdeskDashboard',
  components: {
    HRequestLoading,
    OverallTicketStatuses,
    OverallTicketSLAStatuses,
    OverallTimeAverages,
    OverallTicketsByMonth,
    TeamTopPerformers,
    TeamTimeAverages,
    TeamTicketStatuses,
    TeamTicketSLAStatuses,
    TeamTicketsByStage,
    TeamTicketsOpenClosedToday,
    TeamTicketsByMonth,
  },
  data () {
    return {
      loadingDashboard: false,
      dashboard: null,
      dashboardRefreshInterval: 0,
      dashboardInterval: null,
      dashboardTime: 0,
      dashboardTeams: [],
      windowSwitchInterval: 0,
      windowInterval: null,
      windowTime: 0,
      windowPage: 0,
    }
  },
  async mounted () {
    await this.getDashboard()
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.dashboardInterval)
    clearInterval(this.windowInterval)
    next()
  },
  methods: {
    async getDashboard () {
      this.loadingDashboard = true
      this.dashboard = await HelpdeskDashboardService.getDashboard()
      this.dashboardTeams = await HelpdeskDashboardService.getDashboardTeams()
      if (this.dashboard) {
        if (this.dashboardRefreshInterval !== this.dashboard.dashboard_refresh_interval) {
          this.dashboardRefreshInterval = this.dashboard.dashboard_refresh_interval
          this.initiateDashboardCountdown()
        }
        if (this.windowSwitchInterval !== this.dashboard.window_switch_interval) {
          this.windowSwitchInterval = this.dashboard.window_switch_interval
          this.initiateWindowCountdown()
        }
      }
      this.loadingDashboard = false
    },
    async incrDashboardTime () {
      if (this.dashboardTime === 100) {
        await this.getDashboard()
        this.dashboardTime = 0
      } else {
        this.dashboardTime += 1
      }
    },
    reinitDashboardCountdown () {
      this.dashboardTime = 0
      if (this.dashboardInterval) clearInterval(this.dashboardInterval)
    },
    initiateDashboardCountdown () {
      this.reinitDashboardCountdown()
      if (this.dashboardRefreshInterval > 0) {
        this.dashboardInterval = setInterval(() => {
          this.incrDashboardTime()
        }, this.dashboardRefreshInterval / 100)
      }
    },
    incrWindowTime () {
      if (this.windowTime === 100) {
        this.windowTime = 0
        if (++this.windowPage >= this.dashboardTeams.length) this.windowPage = 0
      } else {
        this.windowTime += 1
      }
    },
    reinitWindowCountdown () {
      this.windowTime = 0
      if (this.windowInterval) clearInterval(this.windowInterval)
    },
    initiateWindowCountdown () {
      this.reinitWindowCountdown()
      if (this.windowSwitchInterval > 0) {
        this.windowInterval = setInterval(() => {
          this.incrWindowTime()
        }, this.windowSwitchInterval / 100)
      }
    },
    viewDashboard (dashboard) {
      this.$dialog.show(HelpdeskDashboardFormDialog, {
        persistent: true,
        mode: 'view',
        dashboard: dashboard,
      }).catch(error => {
        console.log(error)
        // TODO: Do something with the error, rather than printing to console
      })
    },
    editDashboard (dashboard) {
      this.$dialog.show(HelpdeskDashboardFormDialog, {
        persistent: true,
        waitForResult: true,
        mode: 'change',
        dashboard: dashboard,
      }).then(async result => {
        if (result) {
          const updatedDashboard = await HelpdeskDashboardService.updateDashboard({
            dashboard_refresh_interval: this.convertTimeInMilliseconds(0, 0, result.dashboardRefreshInterval),
            window_switch_interval: this.convertTimeInMilliseconds(0, 0, result.windowSwitchInterval),
            total_open_tickets_warning_threshold: result.totalOpenTicketsWarningThreshold || 0,
            total_open_tickets_alert_threshold: result.totalOpenTicketsAlertThreshold || 0,
            total_unassigned_tickets_warning_threshold: result.totalUnassignedTicketsWarningThreshold || 0,
            total_unassigned_tickets_alert_threshold: result.totalUnassignedTicketsAlertThreshold || 0,
            average_ttfa_warning_threshold: this.convertTimeInMilliseconds(
              result.averageTtfaWarningThresholdDays,
              result.averageTtfaWarningThresholdHours,
              result.averageTtfaWarningThresholdMinutes,
            ),
            average_ttfa_alert_threshold: this.convertTimeInMilliseconds(
              result.averageTtfaAlertThresholdDays,
              result.averageTtfaAlertThresholdHours,
              result.averageTtfaAlertThresholdMinutes,
            ),
            average_ttc_warning_threshold: this.convertTimeInMilliseconds(
              result.averageTtcWarningThresholdDays,
              result.averageTtcWarningThresholdHours,
              result.averageTtcWarningThresholdMinutes,
            ),
            average_ttc_alert_threshold: this.convertTimeInMilliseconds(
              result.averageTtcAlertThresholdDays,
              result.averageTtcAlertThresholdHours,
              result.averageTtcAlertThresholdMinutes,
            ),
          })
          if (updatedDashboard) {
            this.dashboard = updatedDashboard
            this.dashboardRefreshInterval = this.dashboard.dashboard_refresh_interval
            this.initiateDashboardCountdown()
            this.windowSwitchInterval = this.dashboard.window_switch_interval
            this.initiateWindowCountdown()
            this.$dialog.notify.success(
              `Dashboard updated`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    viewTeam (team) {
      this.$dialog.show(HelpdeskTeamFormDialog, {
        persistent: true,
        mode: 'view',
        team: team,
      }).catch(error => {
        console.log(error)
        // TODO: Do something with the error, rather than printing to console
      })
    },
    editTeam (team) {
      this.$dialog.show(HelpdeskTeamFormDialog, {
        persistent: true,
        waitForResult: true,
        mode: 'change',
        team: team,
      }).then(async result => {
        if (result) {
          const updatedTeam = await HelpdeskDashboardService.updateTeamConfiguration(team.id, {
            open_tickets_warning_threshold: result.open_tickets_warning_threshold,
            open_tickets_alert_threshold: result.open_tickets_alert_threshold,
            unassigned_tickets_warning_threshold: result.unassigned_tickets_warning_threshold,
            unassigned_tickets_alert_threshold: result.unassigned_tickets_alert_threshold,
            average_ttfa_warning_threshold: this.convertTimeInMilliseconds(
              result.averageTtfaWarningThresholdDays,
              result.averageTtfaWarningThresholdHours,
              result.averageTtfaWarningThresholdMinutes,
            ),
            average_ttfa_alert_threshold: this.convertTimeInMilliseconds(
              result.averageTtfaAlertThresholdDays,
              result.averageTtfaAlertThresholdHours,
              result.averageTtfaAlertThresholdMinutes,
            ),
            average_ttc_warning_threshold: this.convertTimeInMilliseconds(
              result.averageTtcWarningThresholdDays,
              result.averageTtcWarningThresholdHours,
              result.averageTtcWarningThresholdMinutes,
            ),
            average_ttc_alert_threshold: this.convertTimeInMilliseconds(
              result.averageTtcAlertThresholdDays,
              result.averageTtcAlertThresholdHours,
              result.averageTtcAlertThresholdMinutes,
            ),
          })
          if (updatedTeam) {
            this.dashboardTeams.splice(this.dashboardTeams.indexOf(team), 1, updatedTeam)
            this.$dialog.notify.success(
              `Team configuration updated`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    showPreviousWindow () {
      this.windowPage = this.windowPage - 1 < 0
        ? this.dashboardTeams.length - 1
        : this.windowPage - 1
    },
    showNextWindow () {
      this.windowPage = this.windowPage + 1 === this.dashboardTeams.length
        ? 0
        : this.windowPage + 1
    },
    convertTimeInMilliseconds (daysNumber, hoursNumber, minutesNumber) {
      const daysInMilliseconds = daysNumber * 24 * 60 * 60 * 1000
      const hoursInMilliseconds = hoursNumber * 60 * 60 * 1000
      const minutesInMilliseconds = minutesNumber * 60 * 1000
      return daysInMilliseconds + hoursInMilliseconds + minutesInMilliseconds
    }
  }
}
</script>

<style scoped>
  .h-helpdesk-dashboard {
    height: 100%;
  }
  .v-window-item >
    .v-card >
     .row {
       padding-left: 15px;
       padding-right: 15px;
     }
  .v-card__title.window-title {
    padding-bottom: 8px;
  }
</style>
