import router from './index'
import store from '../store/index'
import HelpdeskPortalRoutes from "@/router/modules/helpdesk/portal.routes"
import { hasPermission } from '@/common/helpers/hasPermission'

function getIsLoggedIn () {
  /*
    Check if the current user is logged in. Fot that it checks 2 things :
      auth-token-exp must be present in localStorage
      auth-token-exp must be > than now
    Note that this is purely theorical since it only checks if the expiration
    has been exceeded. If the authentication cookie is expired, it won't be
    passed to the backend and the request will fail.
  */
  const tokenExp = localStorage.getItem('auth-token-exp')
  const now = Math.floor(new Date().getTime() / 1000)
  const isLoggedIn = !!(tokenExp && tokenExp > now)
  return isLoggedIn
}

function getFirstAuthorizedViewNameFromRoutePath (sourceRoutePath) {
  /*
    Returns the name of the first route that matches all these conditions :
      - Its parent must have : path = sourceRoutePath
      - meta.type = 'listing' || 'dashboard'
        (also toolbar for the moment but soon only listing || dashboard)
      - hasPermission(meta.permission) = true
  */
  const sourceRoute = router.options.routes.filter(route => route.path === sourceRoutePath)[0]
  const subRoutes = sourceRoute.children.filter(route =>
    (['listing', 'toolbar', 'dashboard'].includes(route.meta.type) && hasPermission(route.meta.permission)) ||
    ['submenu'].includes(route.meta.type)
  )
  if (subRoutes) {
    const firstRoute = subRoutes[0]
    switch (firstRoute.meta.type) {
      case 'listing':
        return firstRoute.name
      case 'toolbar':
        // to be deleted when recombining all listing views
        return firstRoute.children[0].name
      case 'dashboard':
        return firstRoute.name
      case 'submenu':
        const authorizedSubmenuRoutes = firstRoute.children.filter(route => hasPermission(route.meta.permission))
        if (authorizedSubmenuRoutes) {
          return authorizedSubmenuRoutes[0].name
        } else {
          // TODO: Make a warning no authorized route found ?
          return 'HomePage'
        }
      default:
        // TODO: Make a warning no authorized route found ?
        return 'HomePage'
    }
  } else {
    // TODO: Make a warning no authorized route found ?
    return 'HomePage'
  }
}

function recursiveRouteSearch (routes, routeName) {
  for (let route of routes) {
    if (route.name === routeName) {
      return route
    } else if (route.children && route.children.length > 0) {
      const found = recursiveRouteSearch(route.children, routeName)
      if (found) {
        return found
      }
    }
  }
}

function findDeepestAuthorizedSubRouteFromRoute (route) {
  if (route.children?.length) {
    for (let subRoute of route.children) {
      if (
        !subRoute.children
        && (
          !subRoute.meta.permission
          || (
            subRoute.meta.permission
            && hasPermission(subRoute.meta.permission)
          )
        )
      ) {
        return subRoute
      } else if (subRoute.children?.length) {
        const found = findDeepestAuthorizedSubRouteFromRoute(subRoute)
        if (found) {
          return found
        }
      }
    }
  }
}

function getFirstAuthorizedChildViewNameFromRouteName (sourceRouteName) {
  /*
    Returns the name of the first child route that matches all these conditions :
      - Its parent route must have : name = sourteRouteName
      - hasPermission(meta.permission) = true
  */
  // TODO: Update this function once updating vue-router (remove resursiveRouteSearch)
  // When we'll use vue-router 4.x, getRoutes() will return all routes in a
  // flat way with their children. While currently vue-router 3.x does not.
  // We have to do dirty things to find a route by its name along with its
  // children.
  const sourceRoute = recursiveRouteSearch(router.options.routes, sourceRouteName)
  const targetRoute = findDeepestAuthorizedSubRouteFromRoute(sourceRoute)
  const targetRouteName = targetRoute ? targetRoute.name : 'HomePage'
  return targetRouteName
}

function handleUnloggedStatus () {
  /*
    Handle the login status when it becomes false.
  */
  store.commit('SET_IS_LOGGED_IN', false)
  localStorage.removeItem('auth-token-exp')
  if (store.state.login.me) store.commit('UNSET_ME')
}

function addPortalRoutes () {
  /*
    Adds routes related to the Helpdesk Portal to the main Router.
  */
 for (const route of HelpdeskPortalRoutes) {
   if (!router.getRoutes().map(route => route.name).includes(route.name)) {
      // Replace this later by .hasRoute()
      router.addRoute(route)
    }
  }
}

export {
  getIsLoggedIn,
  getFirstAuthorizedViewNameFromRoutePath,
  getFirstAuthorizedChildViewNameFromRouteName,
  handleUnloggedStatus,
  addPortalRoutes,
}
