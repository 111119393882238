<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-plus</v-icon>
        Create API User
      </v-toolbar-title>
    </v-toolbar>
    <v-form v-model="isFormValid" class="pa-4">
      <v-text-field
        v-model="form.name"
        label="Name"
        placeholder="e.g.: Zabbix"
        required
        autofocus
        type="text"
        suffix=" BOT"
        maxlength="30"
        counter
        hint="Use a meaningful name that represents the usage that will be made."
        persistent-hint
        :rules="[formRules.required]"/>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
export default {
  name:  'WebhookAPIUserFormDialog',
  data () {
    return {
      isFormValid: false,
      form: {
        name: '',
      },
      formRules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  computed: {
    actions () {
      return {
        false: {
          text: 'Cancel',
        },
        true: {
          text: 'Save',
          color: 'green',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
</style>
