<template>
  <statistic-wrapper
    :height="height"
    statTitle="Tickets By Month">
    <div v-if="bothAllZero" :style="{'height': 'calc(100% - 72px)'}">
      <v-row class="my-0" :style="{'height': '100%'}">
        <v-col class="d-flex justify-center align-center font-weight-bold" cols="12">
          <v-chip
            color="#4CAF50"
            class="font-weight-bold"
            :dark="shouldTextBeWhite('#4CAF50')">
            There are no tickets this year yet
          </v-chip>
        </v-col>
      </v-row>
    </div>
    <echart v-else :option="chartOptions"/>
  </statistic-wrapper>
</template>

<script>
import StatisticWrapper from '@/common/components/StatisticWrapper'
export default {
  name: 'TeamTicketsByMonth',
  components: {
    StatisticWrapper,
  },
  props: {
    height: { type: Number },
    ticketsByMonth: { type: Array },
  },
  computed: {
    bothAllZero: function () {
      return this.ticketsByMonth.every(month => month.opened === 0 && month.closed === 0)
    },
    openedTicketsSerie: function () {
      return this.ticketsByMonth.map(month => month.opened)
    },
    closedTicketsSerie: function () {
      return this.ticketsByMonth.map(month => month.closed)
    },
    series: function () {
      return [
        {
          name: 'Opened Tickets',
          type: 'bar',
          data: this.openedTicketsSerie,
          label: {
            show: true,
            fontWeight: 'bolder',
            fontSize: 16,
            rotate: this.openedTicketsSerie.some(number => number > 999) ? 90 : 0,
            formatter: (value) => {
              if (value.value === 0) {
                return ''
              }
              return value.value
            },
          },
          barGap: '10%',
          barCategoryGap: '10%',
        },
        {
          name: 'Closed Tickets',
          type: 'bar',
          data: this.closedTicketsSerie,
          label: {
            show: true,
            fontWeight: 'bolder',
            fontSize: 16,
            rotate: this.closedTicketsSerie.some(number => number > 999) ? 90 : 0,
            formatter: (value) => {
              if (value.value === 0) {
                return ''
              }
              return value.value
            },
          },
          barGap: '10%',
          barCategoryGap: '10%',
        },
      ]
    },
    categories: function () {
      const monthNames = this.ticketsByMonth.map(month => month.month_abbr)
      let categories = []
      for (let month of monthNames) {
        categories.push({
          value: month,
          textStyle: {
            fontWeight: 'bolder',
            fontSize: 16,
          },
        })
      }
      return categories
    },
    chartOptions: function () {
      return {
        grid: {
          left: '0%',
          right: '3%',
          bottom: '22%',
          top: '10%',
          containLabel: true,
        },
        legend: {
          show: true,
          itemHeight: 8,
          right: '4%',
          bottom: '92%',
          textStyle: {
            fontWeight: 'bolder',
          },
        },
        xAxis: {
          type: 'category',
          data: this.categories,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          type: 'value',
          show:  false,
        },
        color: [
          '#FF4560', '#4CAF50',
        ],
        series: this.series,
      }
    }
  }
}
</script>
