<template>
  <v-toolbar tile dense elevation="1">
    <v-btn
      v-if="hasPermission('add_user')"
      text
      :to="{ name: 'UserFormCreate', params: { editing: true } }">
      <v-icon small left>mdi-plus</v-icon>
      Create
    </v-btn>
    <v-menu
      v-if="
        selectedUsers.length
        && (
          hasPermission('change_user')
          || hasPermission('delete_user')
        )"
      close-on-click
      close-on-content-click
      offsetY>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">
          Actions
          <v-icon small right>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="h-menu-list">
        <v-list-item
          v-if="hasPermission('change_user')"
          @click="$emit('activateUsers', selectedUsers)">
          <v-icon small class="mr-4">mdi-archive-arrow-up</v-icon>
          Activate
        </v-list-item>
        <v-list-item
          v-if="hasPermission('change_user')"
          @click="$emit('deactivateUsers', selectedUsers)">
          <v-icon small class="mr-4">mdi-archive-arrow-down</v-icon>
          Deactivate
        </v-list-item>
        <v-list-item
          v-if="hasPermission('delete_user')"
          @click="$emit('deleteUsers', selectedUsers)">
          <v-icon small class="mr-4">mdi-delete</v-icon>
          Delete
        </v-list-item>
      </v-list>
    </v-menu>
    <v-spacer/>
    <h-filtering-bar
      :availableFilters="availableFilters"
      :defaultFilters="[
        { fieldName: 'is_user_system', label: 'System Users', optionText: 'System Users', type: 'boolean', value: true },
      ]"/>
    <v-spacer/>
    <v-btn
      tile
      icon
      link
      :class="{'v-btn--active': viewType === 'kanban'}"
      @click="$emit('setKanbanView')">
      <v-icon>mdi-view-grid</v-icon>
    </v-btn>
    <v-btn
      tile
      icon
      link
      :class="{'v-btn--active': viewType === 'list'}"
      @click="$emit('setListView')">
      <v-icon>mdi-format-list-bulleted</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import HFilteringBar from '@/common/components/HFilteringBar'
export default {
  name: 'UserListingToolbar',
  components: { HFilteringBar },
  props: {
    viewType: { type: String },
    selectedUsers: {
      type: Array,
      default: () => { return [] },
    },
  },
  data () {
    return {
      availableFilters: [
        { fieldName: 'username', label: 'Username', type: 'string' },
        { fieldName: 'name', label: 'Name', type: 'string' },
        { fieldName: 'email', label: 'Email', type: 'string' },
        { fieldName: 'group', label: 'Group', type: 'string' },
        { fieldName: 'is_active', label: 'Activated', optionText: 'Activated', type: 'boolean', value: true },
        { fieldName: 'is_active', label: 'Activated', optionText: 'Inactivated', type: 'boolean', value: false },
        { fieldName: 'is_user_system', label: 'System Users', optionText: 'System Users', type: 'boolean', value: true },
        { fieldName: 'is_user_portal', label: 'Portal Users', optionText: 'Portal Users', type: 'boolean', value: true },
        { fieldName: 'is_user_portal_ref', label: 'Portal Ref. Users', optionText: 'Portal Ref. Users', type: 'boolean', value: true },
      ],
    }
  },
}
</script>

<style scoped>
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child {
    /* Override the default -12px right margin in order to be symmetrical */
    margin-right: 0px;
  }
  .v-toolbar {
    height: unset!important;
  }
  .v-toolbar::v-deep .v-toolbar__content {
    height: fit-content!important;
  }
  .v-list.h-menu-list {
    padding: 0;
  }
</style>
