<template>
  <v-date-picker
    v-model="date"
    color="blue darken-1"
    width="400"
    first-day-of-week="1"
    @input="handleInput"/>
</template>

<script>
export default {
  name: 'HDatePickerDialog',
  data () {
    return {
      date: '',
    }
  },
  methods: {
    handleInput () {
      this.$emit('submit', this.date)
    },
  },
}
</script>
