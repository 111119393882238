<template>
  <h-app-main-menu :menuItems="menuItems"/>
</template>

<script>
import HAppMainMenu from '@/common/components/HAppMainMenu'
export default {
  name: 'InventoryMainMenu',
  components: { HAppMainMenu },
  data () {
    return {
      menuItems: [
        {
          menuLabel: 'Items',
          menuPermissions: [
            'view_inventoryitem',
          ],
          routeName: 'InventoryItemListing',
          matchingPaths: [
            '/inventory/items',
          ],
        },
        {
          menuLabel: 'Categories',
          menuPermissions: [
            'view_inventoryitemcategory',
          ],
          routeName: 'InventoryItemCategoryListing',
          matchingPaths: [
            '/inventory/categories',
          ],
        },
        {
          menuLabel: 'Suppliers',
          menuPermissions: [
            'view_inventoryitemsupplier',
          ],
          routeName: 'InventoryItemSupplierListing',
          matchingPaths: [
            '/inventory/suppliers',
          ],
        },
        {
          menuLabel: 'Contractors',
          menuPermissions: [
            'view_inventoryitemcontractor',
          ],
          routeName: 'InventoryItemContractorListing',
          matchingPaths: [
            '/inventory/contractors',
          ],
        },
      ],
    }
  },
}
</script>
