<template>
  <div>
    <!-- <v-toolbar flat dense>
      For future actions on multiple, and filtering
    </v-toolbar> -->
    <v-divider/>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="recurringTicketTasks"
      :hide-default-footer="recurringTicketTasks.length < 10">
      <template v-slot:loading>
        <div class="my-12">Loading...</div>
      </template>
      <template v-slot:[`item.error_text`]="{ item }">
        <v-icon v-if="!item.error_text" color="green">mdi-check-bold</v-icon>
        <v-tooltip v-else bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="red">mdi-alert</v-icon>
          </template>
          {{ item.error_text }}
        </v-tooltip>
      </template>
      <template v-slot:[`item.enabled`]="{ item }">
        <v-icon v-if="item.enabled" color="green">mdi-check-bold</v-icon>
        <v-icon v-else color="red">mdi-close-thick</v-icon>
      </template>
      <template v-slot:[`item.create_date`]="{ item }">
        {{ momentCreateDate(item.create_date) }}
      </template>
      <template v-slot:[`item.start_time`]="{ item }">
        <template v-if="item.start_time">
          {{ momentStartTime(item.start_time) }}
        </template>
      </template>
      <template v-slot:[`item.expires`]="{ item }">
        <template v-if="item.expires">
          {{ momentExpires(item.expires) }}
        </template>
      </template>
      <template v-slot:[`item.last_run_at`]="{ item }">
        <template v-if="item.last_run_at">
          {{ momentLastRunAt(item.last_run_at) }}
        </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip v-if="hasPermission('change_helpdeskrecurringtickettask')" bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="item.enabled ? disableTask(item) : enableTask(item)" icon>
              <v-icon v-if="item.enabled">mdi-archive-arrow-down</v-icon>
              <v-icon v-else>mdi-archive-arrow-up</v-icon>
            </v-btn>
          </template>
          {{ item.enabled ? 'Disable' : 'Enable' }}
        </v-tooltip>
        <v-tooltip v-if="hasPermission('delete_helpdeskrecurringtickettask')" bottom open-delay="250">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="deleteTask(item)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          Delete
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment'
import DeleteModal from '@/common/components/DeleteModal'
import HelpdeskTicketService from '@/services/helpdesk/helpdesk.ticket.service'
export default {
  name: 'HelpdeskRecurringTicketTaskList',
  data () {
    return {
      loading: false,
      recurringTicketTasks: [],
      headers: [
        { value: 'description', text: 'Description' },
        { value: 'author.full_name', text: 'Creator' },
        { value: 'error_text', text: 'Status' },
        { value: 'enabled', text: 'Enabled' },
        { value: 'kwargs', text: 'Parameters' },
        { value: 'create_date', text: 'Creation Date' },
        { value: 'start_time', text: 'Start Date' },
        { value: 'expires', text: 'Expiration Date' },
        { value: 'total_run_count', text: '# Total Runs' },
        { value: 'last_run_at', text: 'Last Run Date' },
        { value: 'actions', text: 'Actions', sortable: false },
      ]
    }
  },
  async mounted () {
    await this.getRecurringTicketTasks()
  },
  methods: {
    momentStartTime (startDate) {
      return moment(startDate).format('YYYY-MM-DD')
    },
    momentExpires (expirationDate) {
      return moment(expirationDate).format('YYYY-MM-DD')

    },
    momentLastRunAt (lastRunAtDate) {
      return moment(lastRunAtDate).calendar({
        sameElse: 'YYYY-MM-DD, HH:mm:ss'
      })
    },
    momentCreateDate (createDate) {
      return moment(createDate).calendar({
        sameElse: 'YYYY-MM-DD, HH:mm:ss'
      })
    },
    async getRecurringTicketTasks () {
      this.loading = true
      this.recurringTicketTasks = await HelpdeskTicketService.getRecurringTicketTasks()
      this.loading = false
    },
    async enableTask (task) {
      const enabledTask = await HelpdeskTicketService.enableRecurringTicketTask(task.id)
      if (enabledTask) {
        this.recurringTicketTasks.splice(this.recurringTicketTasks.indexOf(task), 1, enabledTask)
        this.$dialog.notify.success(
          `Task '${task.description}' enabled`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000
          }
        )
      }
    },
    async disableTask (task) {
      const disabledTask = await HelpdeskTicketService.disableRecurringTicketTask(task.id)
      if (disabledTask) {
        this.recurringTicketTasks.splice(this.recurringTicketTasks.indexOf(task), 1, disabledTask)
        this.$dialog.notify.success(
          `Task '${task.description}' disabled`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000
          }
        )
      }
    },
    deleteTask (task) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Recurring Ticket Task',
        recordNamePlural: 'Recurring Ticket Tasks',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTicketService.deleteRecurringTicketTasks({ ids: [task.id] })
          if (response) {
            this.recurringTicketTasks.splice(this.recurringTicketTasks.indexOf(task), 1)
            this.$dialog.notify.success(
              `Task '${task.description}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    }
  }
}
</script>
