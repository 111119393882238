<template>
  <div>
    <email-settings-classic-outbound-form
      :editing="editing"
      @cancelEdit="cancelEdit"/>
    <v-divider class="mt-2 mb-4"/>
    <email-settings-classic-inbound-list/>
  </div>
</template>

<script>
import EmailSettingsClassicOutboundForm from '@/views/base/settings/settings/emails/EmailSettingsClassicOutboundForm'
import EmailSettingsClassicInboundList from '@/views/base/settings/settings/emails/EmailSettingsClassicInboundList'
export default {
  name: 'EmailSettingsClassicForm',
  components: {
    EmailSettingsClassicOutboundForm,
    EmailSettingsClassicInboundList,
  },
  props: {
    editing: { type: Boolean, required: true },
  },
  methods: {
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
  },
}
</script>
