<template>
  <v-card tile :height="height">
    <v-sheet
      class="v-sheet--offset ml-5"
      elevation="3"
      color="light-blue darken-4"
      max-width="40%">
      <p class="text-center white--text text-overline">{{ statTitle }}</p>
    </v-sheet>
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  name: 'StatisticWrapper',
  props: {
    height: { type: Number, default: () => { return 160 } },
    statTitle: { type: String, required: true }
  }
}
</script>

<style scoped>
  .v-sheet--offset {
    position: relative;
    top: -8px;
  }
  .v-sheet > p {
    margin: 0;
  }
  .text-overline {
    line-height: 1.5rem;
    text-transform: uppercase;
  }
</style>
