<template>
  <v-card
    :color="cardColor">
    <v-card-title>
      <span class="subtitle-2">{{ message.message_type_display }}</span>
      <span class="caption">&nbsp;by&nbsp;</span>
      <span class="subtitle-2">
        <template v-if="message.author">
          {{ message.author.full_name }}
        </template>
        <template v-else>
          {{ message.author_full_name }}
        </template>
      </span>
      &nbsp;
      <span class="caption font-italic" style="opacity: 0.5">- {{ message.create_date | moment('calendar') }}</span>
      <v-spacer/>
      <v-btn
        v-if="
          hasPermission('delete_helpdeskticketmessage')
          && message.message_type === 'comment'
        "
        icon
        small
        @click="deleteMessage(message)">
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
      <v-tooltip
        v-if="
          message.message_type !== 'comment'
          && message.is_sending_failed || message.sending_date
        "
        bottom
        open-delay="150">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-btn
              icon
              small
              style="pointer-events: none;">
              <v-icon
                small
                :color="iconMailStatusAttributes(message).color"
                style="opacity: 0.7">
                {{ iconMailStatusAttributes(message).icon }}
              </v-icon>
            </v-btn>
          </span>
        </template>
        {{ iconMailStatusAttributes(message).tooltipText }}
      </v-tooltip>
    </v-card-title>
    <v-card-text class="pb-2">
      <h-rich-text-editor
        :value="message.message"/>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment'
import HRichTextEditor from '@/common/components/rich-text-editor/HRichTextEditor'
export default {
  name: 'HelpdeskTicketMessageCard',
  components: {
    HRichTextEditor,
  },
  props: {
    message: {
      type: Object,
      default: () => { return null },
    },
    cardColor: {
      type: String,
      default: '#e6e6e6',
    },
  },
  methods: {
    deleteMessage (message) {
      this.$emit('deleteMessage', message)
    },
    momentSendingDate (sendingDate) {
      return moment(sendingDate).calendar({
        sameElse: 'YYYY-MM-DD, HH:mm:ss'
      })
    },
    iconMailStatusAttributes (message) {
      if (message.is_sending_failed) {
        return {
          icon: 'mdi-email-remove',
          color: 'red',
          tooltipText: 'Could not send associated email, will retry every 2 minutes.'
        }
      } else if (message.sending_date) {
        return {
          icon: 'mdi-email-check',
          color: 'green',
          tooltipText: `Email sent: ${this.momentSendingDate(message.sending_date)}.`
        }
      }
    },
  },
}
</script>

<style scoped>
.v-card__title {
  padding: 5px;
}
</style>
