import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class ContactContactTagService {
  static getTag = async (id) => {
    try {
      const response = await http.get(`contact/tags/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTags = async (urlSearchParams) => {
    try {
      const response = await http.get('contact/tags', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createTag = async (data) => {
    try {
      const response = await http.post('contact/tags/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateTag = async (id, data) => {
    try {
      const response = await http.patch(`contact/tags/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteTags = async (data) => {
    try {
      const response = await http.post('contact/tags/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getTagsInput = async () => {
    try {
      const response = await http.get('contact/tags/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
