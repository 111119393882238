<template>
  <statistic-wrapper
    :height="height"
    statTitle="Time Averages">
    <v-row class="my-0 h-dashboard-team-time-averages">
      <v-col class="d-flex justify-center align-center" cols="6">
        <div>
          <h4 class="mb-3">Mean Time to Assign</h4>
          <v-chip
            :color="averageTimeToFirstAssignStatusColor"
            :dark="shouldTextBeWhite(averageTimeToFirstAssignStatusColor)"
            class="font-weight-bold justify-center"
            large
            :style="{'width': '100%'}">
            <h3>{{ averageTimeToFirstAssign }}</h3>
          </v-chip>
        </div>
      </v-col>
      <v-divider vertical/>
      <v-col class="d-flex justify-center align-center" cols="6">
        <div>
          <h4 class="mb-3">Mean Time to Close</h4>
          <v-chip
            :color="averageTimeToCloseStatusColor"
            :dark="shouldTextBeWhite(averageTimeToFirstAssignStatusColor)"
            class="font-weight-bold justify-center"
            large
            :style="{'width': '100%'}">
            <h3>{{ averageTimeToClose }}</h3>
          </v-chip>
        </div>
      </v-col>
    </v-row>
  </statistic-wrapper>
</template>

<script>
import moment from 'moment'
import StatisticWrapper from '@/common/components/StatisticWrapper'
export default {
  name: 'TeamTimeAverages',
  components: {
    StatisticWrapper
  },
  props: {
    height: { type: Number },
    team: { type: Object }
  },
  computed: {
    averageTimeToFirstAssign: function () {
      return this.team.average_time_to_first_assign > 0
      ? moment.duration(this.team.average_time_to_first_assign).humanize()
      : 0
    },
    averageTimeToFirstAssignNormal: function () {
      return (
        (
          this.team.average_ttfa_warning_threshold === 0 &&
          this.team.average_ttfa_alert_threshold === 0
        ) ||
        this.team.average_time_to_first_assign < this.team.average_ttfa_warning_threshold
      )
    },
    averageTimeToFirstAssignWarning: function () {
      return (
        (
          this.team.average_ttfa_warning_threshold <= this.team.average_time_to_first_assign &&
          this.team.average_ttfa_alert_threshold === 0 &&
          this.team.average_ttfa_warning_threshold > 0
        ) ||
        (
          this.team.average_ttfa_alert_threshold > 0 &&
          this.team.average_ttfa_warning_threshold > 0 &&
          this.team.average_time_to_first_assign < this.team.average_ttfa_alert_threshold &&
          this.team.average_time_to_first_assign >= this.team.average_ttfa_warning_threshold
        )
      )
    },
    averageTimeToFirstAssignAlert: function () {
      return (
        this.team.average_time_to_first_assign >= this.team.average_ttfa_alert_threshold &&
        this.team.average_ttfa_alert_threshold > 0
      )
    },
    averageTimeToFirstAssignStatusColor: function () {
      if (this.averageTimeToFirstAssignAlert) {
        return '#FF4560'
      } else if (this.averageTimeToFirstAssignWarning) {
        return '#FFCA28'
      } else if (this.averageTimeToFirstAssignNormal) {
        return '#4CAF50'
      } else {
        return '#039BE5'
      }
    },
    averageTimeToClose: function () {
      return this.team.average_time_to_close > 0
      ? moment.duration(this.team.average_time_to_close).humanize()
      : 0
    },
    averageTimeToCloseNormal: function () {
      return (
        (
          this.team.average_ttc_warning_threshold === 0 &&
          this.team.average_ttc_alert_threshold === 0
        ) ||
        this.team.average_time_to_close < this.team.average_ttc_warning_threshold
      )
    },
    averageTimeToCloseWarning: function () {
      return (
        (
          this.team.average_ttc_warning_threshold <= this.team.average_time_to_close &&
          this.team.average_ttc_alert_threshold === 0 &&
          this.team.average_ttc_warning_threshold > 0
        ) ||
        (
          this.team.average_ttc_alert_threshold > 0 &&
          this.team.average_ttc_warning_threshold > 0 &&
          this.team.average_time_to_close < this.team.average_ttc_alert_threshold &&
          this.team.average_time_to_close >= this.team.average_ttc_warning_threshold
        )
      )
    },
    averageTimeToCloseAlert: function () {
      return this.team.average_time_to_close >= this.team.average_ttc_alert_threshold &&
             this.team.average_ttc_alert_threshold > 0
    },
    averageTimeToCloseStatusColor: function () {
      if (this.averageTimeToCloseAlert) {
        return '#FF4560'
      } else if (this.averageTimeToCloseWarning) {
        return '#FFCA28'
      } else if (this.averageTimeToCloseNormal) {
        return '#4CAF50'
      } else {
        return '#039BE5'
      }
    },
  }
}
</script>

<style scoped>
.h-dashboard-team-time-averages {
  height: calc(100% - 48px);
}
</style>
