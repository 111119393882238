<template>
  <statistic-wrapper
    :height="height"
    statTitle="Tickets By Stage">
    <div v-if="zeroInEveryStage" :style="{'height': 'calc(100% - 72px)'}">
      <v-row class="my-0" :style="{'height': '100%'}">
        <v-col class="d-flex justify-center align-center font-weight-bold" cols="12">
          <v-chip
            color="#4CAF50"
            class="font-weight-bold"
            :dark="shouldTextBeWhite('#4CAF50')">
            There are currently no tickets in open stages
          </v-chip>
        </v-col>
      </v-row>
    </div>
    <echart v-else :option="chartOptions"/>
  </statistic-wrapper>
</template>

<script>
import StatisticWrapper from '@/common/components/StatisticWrapper'
export default {
  name: 'TeamTicketsByStage',
  components: {
    StatisticWrapper,
  },
  props: {
    height: { type: Number },
    openTicketsCountByStage: { type: Array },
  },
  computed: {
    zeroInEveryStage: function () {
      return this.openTicketsCountByStage.every(stage => stage.tickets_count === 0)
    },
    series: function () {
      let stackedSeries = []
      for (let stage of this.openTicketsCountByStage) {
        if (stage.tickets_count !== 0) {
          stackedSeries.push({
            name: stage.name,
            type: 'bar',
            stack: 'total',
            label: {
              show: true,
              fontWeight: 'bolder',
              fontSize: 24,
            },
            data: [stage.tickets_count],
          })
        }
      }
      return stackedSeries
    },
    chartOptions: function () {
      return {
        grid: {
          top: '0%',
          left: '5%',
          right: '5%',
          bottom: '40%',
        },
        legend: {
          show: true,
          top: '58%',
          textStyle: {
            fontWeight: 'bolder',
          },
        },
        xAxis: {
          type: 'value',
          show: false,
          max: 'dataMax',
        },
        yAxis: {
          type: 'category',
          show: false,
        },
        series: this.series,
      }
    },
  },
}
</script>
