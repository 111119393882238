import BaseMainMenu from '@/views/base/menus/BaseMainMenu'
import UserProfileForm from '@/views/base/settings/settings/UserProfileForm'
import applicationRoutes from './models/application.routes'
import groupRoutes from './models/group.routes'
import settingsRoutes from './models/settings.routes'
import userRoutes from './models/user.routes'

export default [
  {
    path: '/settings',
    component: BaseMainMenu,
    meta: {
      type: 'menu',
      requiresAuth: true,
    },
    children: [
      // Keep the same order as in the menu of the related application.
      ...userRoutes,
      ...groupRoutes,
      ...applicationRoutes,
      ...settingsRoutes,
    ],
  },
  {
    path: '/settings/profile',
    name: 'UserProfileForm',
    component: UserProfileForm,
    meta: {
      type: 'form',
      requiresAuth: true,
    },
  },
]
