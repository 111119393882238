<template>
  <v-main>
    <v-card tile>
      <v-tabs grow v-model="activeTab">
        <v-tab
          v-for="menu in allowedMenus"
          :key="menu.routeName"
          @click="clickTab(menu)">
          {{ menu.menuLabel }}
        </v-tab>
      </v-tabs>
    </v-card>
    <v-container fluid class="view-container">
      <transition name="router-anim-secondary" mode="out-in">
        <router-view/>
      </transition>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'HAppMainmenu',
  props: {
    menuItems: { type: Array, required: true },
  },
  data () {
    return {
      activeTab: null,
    }
  },
  mounted () {
    // Because it can't find correct tab itself on refresh, when multiple subroutes.
    this.selectCorrectTab(this.$route.path)
  },
  watch: {
    $route (to, from) {
      this.selectCorrectTab(to.path)
    },
  },
  computed: {
    allowedMenus () {
      return this.menuItems.filter(menu => menu.menuPermissions?.some(permission => this.hasPermission(permission)))
    },
  },
  methods: {
    selectCorrectTab (referencePath) {
      let menuFound = false
      for (const menu of this.allowedMenus) {
        for (const matchingPath of menu.matchingPaths) {
          if (referencePath.includes(matchingPath)) {
            this.activeTab = this.allowedMenus.indexOf(menu)
            menuFound = true
            break
          }
        }
        if (menuFound) break
      }
    },
    clickTab (menu) {
      this.$router.push({ name: menu.routeName })
    },
  },
}
</script>

<style scoped>
  .container.view-container {
    height: calc(100% - 48px);
    padding: 16px;
  }
  .router-anim-secondary-enter,
  .router-anim-secondary-leave-to {
    opacity: 0;
    transform: translateX(2em);
  }
  .router-anim-secondary-enter-active,
  .router-anim-secondary-leave-active {
    transition: all .25s ease;
  }
</style>
