var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"tile":"","elevation":"3"}},[(
      _vm.contractor
      && !_vm.editing
      && (
        _vm.hasPermission('add_inventoryitemcontractor')
        || _vm.hasPermission('change_inventoryitemcontractor')
        || _vm.hasPermission('delete_inventoryitemcontractor')
      )
    )?_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-items',[(_vm.hasPermission('change_inventoryitemcontractor'))?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.edit()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1):_vm._e(),(_vm.hasPermission('delete_inventoryitemcontractor'))?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.deleteContractor(_vm.contractor)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1):_vm._e(),(_vm.hasPermission('add_inventoryitemcontractor'))?_c('v-btn',{attrs:{"text":"","to":{ name: 'InventoryItemContractorFormCreate' }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Another ")],1):_vm._e()],1)],1):_vm._e(),(_vm.contractor)?_c('v-divider'):_vm._e(),(!_vm.loadingContractor)?[_c('v-form',{staticClass:"pa-4",attrs:{"disabled":!_vm.editing},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{attrs:{"label":"Name","required":"","clearable":"","autofocus":"","type":"text","maxlength":"255","counter":_vm.editing,"rules":[_vm.formRules.required]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-text-field',{attrs:{"label":"Email","clearable":"","type":"email","maxlength":"254","counter":_vm.editing,"rules":[
          _vm.formRules.email,
          _vm.formRules.required,
        ]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('v-text-field',{attrs:{"label":"Phone","clearable":"","type":"tel","maxlength":"100","counter":_vm.editing},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('v-text-field',{attrs:{"label":"Address","clearable":"","type":"text"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),(_vm.editing)?[_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")]),(_vm.hasPermission('change_inventoryitemcontractor') || _vm.hasPermission('add_inventoryitemcontractor'))?_c('v-btn',{attrs:{"text":"","color":"green","disabled":!_vm.isFormValid},on:{"click":function($event){!_vm.contractor ? _vm.createContractor() : _vm.updateContractor(_vm.contractor)}}},[_vm._v(" Save ")]):_vm._e()],1)]:_vm._e()]:[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('h-request-loading')],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }