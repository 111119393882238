<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    tile
    nudge-right="28"
    nudge-bottom="15"
    min-width="auto"
    transition="scroll-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        :label="label"
        @click:clear="handleClear"
        readonly
        clearable
        prepend-inner-icon="mdi-clock-outline"/>
    </template>
    <v-time-picker
      v-model="time"
      @input="handleInput"
      :use-seconds="useSeconds"
      :min="minTime"
      :max="maxTime"
      format="24hr"
      scrollable
      color="blue darken-1"/>
  </v-menu>
</template>

<script>
export default {
  name: 'HTimePicker',
  props: {
    disabled: { type: Boolean },
    label: { type: String },
    useSeconds: { type: Boolean, default: false },
    minTime: { type: String, default: "" }, // Must be format HH:MM(:SS)
    maxTime: { type: String, default: "" }, // Must be format HH:MM(:SS)
  },
  watch: {
    $attrs (to, from) {
      if (from.value !== to.value) {
        this.time = to.value
      }
    }
  },
  data () {
    return {
      menu: false,
      time: null,
    }
  },
  methods: {
    handleInput () {
      this.menu = false
      this.$emit('input', this.time)
    },
    handleClear () {
      this.$emit('input', null)
    }
  }
}
</script>
