import HelpdeskDashboard from '@/views/helpdesk/dashboard/HelpdeskDashboard'

export default [
  {
    path: 'dashboard',
    name: 'HelpdeskDashboard',
    component: HelpdeskDashboard,
    meta: {
      type: 'dashboard',
      requiresAuth: true,
      permission: 'view_helpdeskdashboard',
    },
  }
]
