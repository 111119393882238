<template>
  <helpdesk-team-form
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import HelpdeskTeamForm from './shared/HelpdeskTeamForm'
import DialogUnsavedChanges from '../../../common/mixins/DialogUnsavedChanges'
export default {
  name: 'HelpdeskTeamFormCreate',
  mixins: [ DialogUnsavedChanges ],
  components: { HelpdeskTeamForm },
  data () {
    return {
      formIsEditing: true
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
