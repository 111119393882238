<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="red">
      <v-toolbar-title>
        <v-icon left>mdi-{{ icon }}</v-icon>
        {{ title }}
      </v-toolbar-title>
    </v-toolbar>
    <div class="pa-4 subtitle-1">
      <div>{{ message }}</div>
      <div
        v-if="advice"
        class="mt-3">
        {{ advice }}
      </div>
    </div>
  </DialogCard>
</template>

<script>
export default {
  name: 'ErrorModal',
  props: {
    icon: {
      type: String,
      default: 'alert-octagon',
    },
    title: {
      type: String,
      default: 'Error',
    },
    message: {
      type: String,
      default: 'An error occured.',
    },
    advice: {
      type: String,
      default: '',
    },
    okText: {
      type: String,
      default: 'OK',
    },
    okColor: {
      type: String,
    },
    okHandler: {
      type: Function,
    },
  },
  computed: {
    actions () {
      let actions = {}
      actions.true = {
        text: this.okText,
        color: this.okColor,
      }
      if (this.okHandler) {
        actions.true.handler = this.okHandler
      }
      return actions
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
</style>
