<template>
  <contact-company-form
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import ContactCompanyForm from './shared/ContactCompanyForm.vue'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'ContactCompanyFormCreate',
  mixins: [ DialogUnsavedChanges ],
  components: { ContactCompanyForm },
  data () {
    return {
      formIsEditing: true,
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    },
  },
}
</script>
