<template>
  <div>
    <contact-contact-tag-listing-toolbar
      :viewType="viewType"
      :selectedTags="selectedTags"
      @setListView="setListView"
      @deleteTags="deleteTags"/>
    <transition name="router-anim" mode="out-in">
      <contact-contact-tag-list
        v-if="viewType === 'list'"
        v-model="selectedTags"
        :tags="tags"
        :loading="loading"
        @deleteTag="deleteTag"/>
    </transition>
  </div>
</template>

<script>
import DeleteModal from '@/common/components/DeleteModal'
import ContactContactTagListingToolbar from '@/views/contact/tag/shared/ContactContactTagListingToolbar'
import ContactContactTagList from '@/views/contact/tag/ContactContactTagList'
import ContactContactTagService from '@/services/contact/contact.contact.tag.service'
export default {
  name: 'ContactContactTagListing',
  components: {
    ContactContactTagList,
    ContactContactTagListingToolbar,
  },
  data () {
    return {
      loading: false,
      viewType: 'list',
      tags: [],
      selectedTags: [],
    }
  },
  mounted () {
    this.getTags(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getTags(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    async getTags (urlSearchParams) {
      this.loading = true
      this.tags = await ContactContactTagService.getTags(urlSearchParams)
      this.loading = false
    },
    deleteTag (tag) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Tag',
        recordNamePlural: 'Tags',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await ContactContactTagService.deleteTags({ ids: [tag.id] })
          if (response) {
            this.tags.splice(this.tags.indexOf(tag), 1)
            this.selectedTags = []
            this.$dialog.notify.success(
              `Tag '${tag.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteTags (tagsToDelete) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Tag',
        recordNamePlural: 'Tags',
        summaryHeaders: [
          { value: 'name', text: 'Label' },
          { value: 'contacts_count', text: '# Contacts' },
        ],
        summaryRecords: tagsToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const tagIdsToDelete = tagsToDelete.map(tag => tag.id)
          const response = await ContactContactTagService.deleteTags({ ids: tagIdsToDelete })
          if (response) {
            this.tags = this.tags.filter(undeletedTag => !tagIdsToDelete.includes(undeletedTag.id))
            this.selectedTags = []
            this.$dialog.notify.success(
              `${tagsToDelete.length} ${tagsToDelete.length > 1 ? 'tags' : 'tag'} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
