<template>
  <div class="breadcrumb">
    <a
      v-for="stage in ticketStages"
      :key="stage.id"
      :class="{
        'active': stage.id === ticket.stage.id,
        'white--text': stage.id === ticket.stage.id,
        'disable-events': !hasPermission('change_helpdeskticket'),
        'is-closing': stage.is_closing,
      }"
      @click="$emit('changeTicketStage', stage)">
      {{ stage.name }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'HelpdeskTicketStatusesBar',
  props: {
    ticket: {
      type: Object,
      default: null,
    },
    ticketStages: {
      type: Array,
      default: () => { return [] },
    },
  },
}
</script>

<style scoped>
.disable-events {
  pointer-events: none;
}
.breadcrumb,
.breadcrumb a {
  height: 33px;
  display: inline-block;
}

.breadcrumb {
  background-color: #e5e9e9;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.breadcrumb a {
  line-height: 33px;
  font-size: 14px;
  text-decoration: none;
  padding: 0 10px 0 20px;
  position: relative;
  width: 100%;
  text-align: center;
}
.breadcrumb a:first-child {
  padding-left: 16px;
}
.breadcrumb a:last-child {
  padding-right: 16px;
}
.breadcrumb a:after,
.breadcrumb a:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  /* make left half the height */
  border-left: 10px solid transparent;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 100%;
  z-index: 2;
}
.breadcrumb a:before {
  margin-left: 1px;
  z-index: 1;
}

.breadcrumb a:last-child:after,
.breadcrumb a:last-child:before {
  border: none;
}

/* .breadcrumb {
  border-color: #9C9E9E;
} */

.breadcrumb a {
  color: #454545;
  background-color: #e5e9e9;
  font-weight: 500;
}

.breadcrumb a:after {
  border-left-color: #e5e9e9;
}
.breadcrumb a:before {
  border-left-color: #9C9E9E;
}

.breadcrumb a:hover {
  background: #BBDEFB;
}
.breadcrumb a:hover:after {
  border-left-color: #BBDEFB;
}
.breadcrumb a.is-closing:hover {
  background: #C8E6C9;
}
.breadcrumb a.is-closing:hover:after {
  border-left-color: #C8E6C9;
}

.breadcrumb a.active {
  background-color: #2196F3;
}
.breadcrumb a.active:after {
  border-left-color: #2196F3;
}
.breadcrumb a.active:before {
  border-left-color: #2196F3;
}

.breadcrumb a.active.is-closing {
  background-color: #4CAF50;
}
.breadcrumb a.active.is-closing:after {
  border-left-color: #4CAF50;
}
.breadcrumb a.active.is-closing:before {
  border-left-color: #4CAF50;
}
</style>
