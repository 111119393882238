<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-account-edit</v-icon>
        Change ticket contact
      </v-toolbar-title>
    </v-toolbar>
    <div class="pa-4">
      <v-alert
        dense
        text
        outlined
        type="info">
        <p>You are about to change the contact of the ticket, from '{{ previousContact ? previousContact.full_identification : 'None' }}' to '{{ newContact.full_identification }}'.</p>
        <p>Do you want to add extra context to the mail that will be sent to this new contact ?</p>
      </v-alert>
      <v-form>
        <v-switch
          v-model="form.sendExtraContext"
          dense
          :label="switchLabel"/>
        <h-rich-text-editor
          v-model="form.extraContext"
          editorBorders
          editorPadding
          formatParagraph
          formatBold
          formatItalic
          formatStrike
          formatH1
          formatH2
          formatH3
          formatListB
          formatListN
          historyUndo
          historyRedo
          :required="form.sendExtraContext"
          :editable="form.sendExtraContext"/>
      </v-form>
    </div>
  </DialogCard>
</template>

<script>
import HRichTextEditor from '@/common/components/rich-text-editor/HRichTextEditor'
export default {
  name: 'HelpdeskTicketContactChangedDialog',
  components: {
    HRichTextEditor,
  },
  props: {
    previousContact: { type: Object },
    newContact: { type: Object }
  },
  data () {
    return {
      form: {
        extraContext: '',
        sendExtraContext: false,
      },
    }
  },
  computed: {
    switchLabel () {
      if (this.form.sendExtraContext) {
        return 'Yes, I want to add extra context to the mail.'
      }
      return "No, I don't want to add extra context to the mail."
    },
    actions () {
      return {
        false: {
          text: 'No'
        },
        true: {
          text: 'Ok',
          color: 'primary',
          disabled: this.form.sendExtraContext && !this.form.extraContext,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          }
        }
      }
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
</style>
