<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-magnify</v-icon>
        Select Inventory Items to Link
      </v-toolbar-title>
    </v-toolbar>
    <v-row no-gutters class="pa-4">
      <v-col class="d-flex justify-end">
        <span class="caption my-auto">Showing <span style="color:#1E88E5">{{ currentlyShownItemsCount() }}</span> of {{ pagination?.total_records }}</span>
        <v-divider
          v-if="pagination?.has_next_page"
          class="mx-4"
          vertical/>
        <v-btn
          v-if="pagination?.has_next_page"
          color="blue darken-1"
          outlined
          :loading="loading"
          @click="loadNextItemPage">
          <v-icon left>mdi-download</v-icon>
          Load more
        </v-btn>
      </v-col>
    </v-row>
    <v-divider/>
    <v-text-field
      v-model="inventoryItemSearch"
      prepend-inner-icon="mdi-magnify"
      label="Search Inventory Items..."
      single-line
      hide-details
      class="pb-4 px-4"
      clearable/>
    <v-data-table
      v-model="selectedInventoryItems"
      ref="inventoryItemsTable"
      show-select
      checkbox-color="blue darken-2"
      :search="inventoryItemSearch"
      :loading="loading"
      :headers="headers"
      :items="inventoryItems"
      :items-per-page=10
      :hide-default-footer="inventoryItems.length < 11"
      :footer-props="{
        'disable-items-per-page': true,
        'items-per-page-options': [10],
        'show-first-last-page': true,
      }">
    </v-data-table>
    <v-divider class="mt-1"/>
  </DialogCard>
</template>

<script>
import ContactCompanyService from '@/services/contact/contact.company.service'
import HelpdeskTicketService from '@/services/helpdesk/helpdesk.ticket.service'
export default {
  name: 'HelpdeskTicketInventoryItemsSelectorLinkerDialog',
  props: {
    mode: {
      type: String,
      default: 'selector',
      validator (value) {
        return ['selector', 'linker'].includes(value)
      },
    },
    ticket: {
      type: Object,
      default: () => { return null },
    },
    company: {
      type: Object,
      default: () => { return null },
    },
    initiallySelectedOrLinkedInventoryItems: {
      type: Array,
      default: () => { return [] },
    },
  },
  data () {
    return {
      loading: false,
      headers: [
        { value: 'name', text: 'Name' },
        { value: 'serial_number', text: 'S/N' },
        { value: 'part_number', text: 'P/N' },
        { value: 'contractor.name', text: 'Contractor' },
      ],
      inventoryItems: [],
      pagination: null,
      inventoryItemSearch: '',
      selectedInventoryItems: this.mode === 'selector' ? this.initiallySelectedOrLinkedInventoryItems : [],
    }
  },
  async mounted () {
    this.loading = true
    var responseData = null
    if (this.mode === 'selector') {
      responseData = await ContactCompanyService.getCompanyInventoryItemsInput(this.company.id, null)
    } else if (this.mode === 'linker') {
      responseData = await HelpdeskTicketService.getInventoryItemsTicketHasNot(this.ticket.id, { company_id: this.company?.id })
    }
    if (responseData) {
      this.inventoryItems = responseData.inventory_items
      this.pagination = responseData.pagination
      if (this.mode === 'selector') {
        for (const inventoryItem of this.initiallySelectedOrLinkedInventoryItems) {
          this.optimizeInventoryItemsChoice(inventoryItem)
        }
      }
    }
    this.loading = false
  },
  computed: {
    actions () {
      if (this.mode === 'selector') {
        return {
          true: {
            text: `SELECT (${this.selectedInventoryItems.length})`,
            color: 'primary',
            handler: () => {
              return new Promise(resolve => {
                resolve(this.selectedInventoryItems)
              })
            }
          },
          false: {
            text: 'CANCEL',
          },
        }
      } else if (this.mode === 'linker') {
        return {
          true: {
            text: `LINK ITEMS (${this.selectedInventoryItems.length})`,
            color: 'green',
            disabled: !this.selectedInventoryItems.length,
            handler: () => {
              return new Promise(resolve => {
                resolve(this.selectedInventoryItems)
              })
            }
          },
          false: {
            text: 'CANCEL',
          },
        }
      }
    },
  },
  methods: {
    currentlyShownItemsCount () {
      return this.$refs.inventoryItemsTable?.$children[0].itemsLength
    },
    async loadNextItemPage () {
      if (this.pagination.has_next_page) {
        this.loading = true
        const nextPageNumber = this.pagination.page_number + 1
        var responseData = null
        if (this.mode === 'selector') {
          responseData = await ContactCompanyService.getCompanyInventoryItemsInput(this.company.id, { page: nextPageNumber })
        } else if (this.mode === 'linker') {
          responseData = await HelpdeskTicketService.getInventoryItemsTicketHasNot(this.ticket.id, {
            company_id: this.company?.id,
            page: nextPageNumber,
          })
        }
        if (responseData) {
          this.pagination = responseData.pagination
          for (const newPaginatedItem of responseData.inventory_items) {
            this.optimizeInventoryItemsChoice(newPaginatedItem, 'push')
          }
        }
        this.loading = false
      }
    },
    optimizeInventoryItemsChoice (inventoryItem, optimizationMethod = 'unshift') {
      if (!this.inventoryItems.some(item => item.id === inventoryItem.id)) {
        if (optimizationMethod === 'unshift') {
          this.inventoryItems.unshift(inventoryItem)
        } else if (optimizationMethod === 'push') {
          this.inventoryItems.push(inventoryItem)
        }
      }
    }
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
  .v-data-table::v-deep .v-data-footer {
    justify-content: end;
    padding-top: 8px;
  }
</style>
