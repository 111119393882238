import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class BaseSettingsService {
  static getEmailSettingsConfig = async () => {
    try {
      const response = await http.get('base/settings/emails/config')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getOffice365EmailSettings = async () => {
    try {
      const response = await http.get('base/settings/emails/config/office-365')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static checkOffice365EmailSettings = async (data) => {
    try {
      const response = await http.post('base/settings/emails/config/office-365/check', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createOrUpdateOffice365EmailSettings = async (data) => {
    try {
      const response = await http.post('base/settings/emails/config/office-365/update', data)
      return response .data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getClassicOutboundEmailSettings = async () => {
    try {
      const response = await http.get('base/settings/emails/config/classic-outbound')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createOrUpdateClassicOutboundEmailSettings = async (data) => {
    try {
      const response = await http.post('base/settings/emails/config/classic-outbound/update', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static checkClassicOutboundEmailSettings = async (data) => {
    try {
      const response = await http.post('base/settings/emails/config/classic-outbound/check', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getOutboundFailedMails = async () => {
    try {
      const response = await http.get('base/settings/emails/failed-emails')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteOutboundFailedMailManually = async (id) => {
    try {
      const response = await http.delete(`base/settings/emails/failed-emails/${id}/delete`)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getClassicInboundEmailSettings = async (id) => {
    try {
      const response = await http.get(`base/settings/emails/config/classic-inbound/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getClassicInboundEmailSettingss = async () => {
    try {
      const response = await http.get('base/settings/emails/config/classic-inbound')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createClassicInboundEmailSettings = async (data) => {
    try {
      const response = await http.post('base/settings/emails/config/classic-inbound/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateClassicInboundEmailSettings = async (id, data) => {
    try {
      const response = await http.patch(`base/settings/emails/config/classic-inbound/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static checkClassicInboundEmailSettings = async (data) => {
    try {
      const response = await http.post('base/settings/emails/config/classic-inbound/check', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteClassicInboundEmailSettings = async (data) => {
    try {
      const response = await http.post('base/settings/emails/config/classic-inbound/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getGlobalSchedule = async () => {
    try {
      const response = await http.get('base/settings/global-schedule')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateGlobalSchedule = async (data) => {
    try {
      const response = await http.patch('base/settings/global-schedule/update', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getHolidaysCountries = async () => {
    try {
      const response = await http.get('base/holidays-countries/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getAPIKeys = async () => {
    try {
      const response = await http.get('base/settings/api-keys')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createAPIKey = async (data) => {
    try {
      const response = await http.post('base/settings/api-keys/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static rotateAPIKey = async (id) => {
    try {
      const response = await http.patch(`base/settings/api-keys/${id}/rotate`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static activateAPIKey = async (id) => {
    try {
      const response = await http.patch(`base/settings/api-keys/${id}/activate`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deactivateAPIKey = async (id) => {
    try {
      const response = await http.patch(`base/settings/api-keys/${id}/deactivate`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteAPIKey = async (id) => {
    try {
      const response = await http.delete(`base/settings/api-keys/${id}/delete`)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getWebhooks = async () => {
    try {
      const response = await http.get('base/settings/webhooks')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createWebhook = async (data) => {
    try {
      const response = await http.post('base/settings/webhooks/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static activateWebhook = async (id) => {
    try {
      const response = await http.patch(`base/settings/webhooks/${id}/activate`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deactivateWebhook = async (id) => {
    try {
      const response = await http.patch(`base/settings/webhooks/${id}/deactivate`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteWebhook = async (id) => {
    try {
      const response = await http.delete(`base/settings/webhooks/${id}/delete`)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
