<template>
  <v-main>
    <v-container fluid class="view-container">
      <v-card tile elevation="3">
        <v-card-title>{{ $t('applications.base.menus.myProfile') }}</v-card-title>
        <v-toolbar flat dense>
          <v-btn
            v-if="!formIsEditing"
            text
            @click="changeEditingStatus(true)">
            <v-icon small left>mdi-pencil</v-icon>
            {{ $t('common.buttons.edit') }}
          </v-btn>
          <v-btn
            v-if="!formIsEditing"
            text
            @click="updateUserPassword()">
            <v-icon small left>mdi-key</v-icon>
            {{ $t('common.buttons.changePassword') }}
          </v-btn>
          <v-btn
            v-if="formIsEditing"
            text
            @click="cancel()">
            <v-icon small left>mdi-arrow-u-left-top</v-icon>
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn
            v-if="formIsEditing"
            text
            color="green"
            @click="updateUserProfile()">
            <v-icon small left>mdi-content-save-check</v-icon>
            {{ $t('common.buttons.save') }}
          </v-btn>
        </v-toolbar>
        <v-divider/>
        <template v-if="!loadingUserProfile">
          <div class="pa-4">
            <v-hover v-slot="{ hover }">
              <v-img
                width=250
                height=250
                alt="No user picture"
                lazy-src="@/assets/images/no-user-picture.jpeg"
                :src="newPictureURL || getPictureURL(userProfile?.picture) || require('@/assets/images/no-user-picture.jpeg')">
                <v-expand-transition v-if="formIsEditing">
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out blue darken-1"
                    style="height: 100%; opacity: 0.7;">
                    <div class="mx-auto my-auto">
                      <v-btn fab small @click="choosePicture()">
                        <v-icon>mdi-camera-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-expand-transition>
              </v-img>
            </v-hover>
            <v-file-input
              ref="userPictureInput"
              class="d-none"
              v-model="newPicture"
              accept="image/*"
              @change="chosePicture()"/>
          </div>
          <v-form
            class="pa-4"
            v-model="isFormValid"
            :disabled="!formIsEditing">
            <v-row class="my-0">
              <v-col cols=12 sm=6 md=4>
                <v-text-field
                  v-model="form.first_name"
                  :label="$t('common.labels.firstName')"
                  required
                  clearable
                  type="text"
                  maxlength="150"
                  :counter="formIsEditing"
                  :rules="[formRules.required]"/>
              </v-col>
              <v-col cols=12 sm=6 md=4>
                <v-text-field
                  v-model="form.last_name"
                  :label="$t('common.labels.lastName')"
                  required
                  clearable
                  type="text"
                  maxlength="150"
                  :counter="formIsEditing"
                  :rules="[formRules.required]"/>
              </v-col>
              <v-col cols=12 md=4>
                <v-select
                  v-model="form.language"
                  :label="$t('common.labels.language')"
                  item-value="value"
                  :items="userSupportedLanguages">
                  <template v-slot:item="{ item }">
                    {{ item.flag }}<span class="ml-3">{{ item.text }}</span>
                  </template>
                  <template v-slot:selection="data">
                    {{ data.item.flag }}<span class="ml-3">{{ data.item.text }}</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols=12 sm="6">
                <v-text-field
                  v-model="form.phone_perso"
                  :label="$t('common.labels.phonePerso')"
                  clearable
                  type="tel"
                  maxlength="100"
                  :counter="formIsEditing"/>
              </v-col>
              <v-col cols=12 sm="6">
                <v-text-field
                  v-model="form.phone_perso_mobile"
                  :label="$t('common.labels.phonePersoMobile')"
                  clearable
                  type="tel"
                  maxlength="100"
                  :counter="formIsEditing"/>
              </v-col>
            </v-row>
            <v-text-field
              v-model="form.street_perso"
              :label="$t('common.labels.streetPerso')"
              required
              clearable
              autofocus
              type="text"
              maxlength="255"
              :counter="formIsEditing"/>
            <v-row class="my-0">
              <v-col cols=12 md="4">
                <v-text-field
                  v-model="form.zip_code_perso"
                  :label="$t('common.labels.zipCodePerso')"
                  required
                  clearable
                  type="text"
                  maxlength="10"
                  :counter="formIsEditing"
                  :rules="[formRules.zipCode]"/>
              </v-col>
              <v-col cols=12 md="4">
                <v-text-field
                  v-model="form.city_perso"
                  :label="$t('common.labels.city')"
                  required
                  clearable
                  type="text"
                  maxlength="100"
                  :counter="formIsEditing"/>
              </v-col>
              <v-col cols=12 md="4">
                <v-text-field
                  v-model="form.country_perso"
                  :label="$t('common.labels.country')"
                  required
                  clearable
                  type="text"
                  maxlength="100"
                  :counter="formIsEditing"/>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-else>
          <v-col cols=12 align="center" justify="center">
            <h-request-loading/>
          </v-col>
        </template>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import UserChangePassword from '@/views/base/user/shared/UserChangePassword'
import HRequestLoading from '@/common/components/HRequestLoading'
import BaseUserService from '@/services/base/base.user.service'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'UserProfileForm',
  mixins: [ DialogUnsavedChanges ],
  components: { HRequestLoading },
  async mounted () {
    await this.getUserProfile()
    if (this.userProfile) {
      this.initFormFields()
    }
  },
  computed: {
    userMe () {
      return this.$store.state.login.me
    },
  },
  data () {
    return {
      loadingUserProfile: false,
      userProfile: null,
      newPicture: null,
      newPictureURL: null,
      userSupportedLanguages: [
        { flag: '🇬🇧', text: 'English', value: 'en' },
        { flag: '🇫🇷', text: 'Français', value: 'fr' },
      ],
      formIsEditing: false,
      form: {
        first_name: '',
        last_name: '',
        language: 'en',
        phone_perso: '',
        phone_perso_mobile: '',
        street_perso: '',
        zip_code_perso: '',
        city_perso: '',
        country_perso: '',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || 'Invalid e-mail.'
        },
        zipCode: value => {
          const pattern = /^([0-9]{3,10})$/
          return !value || pattern.test(value) || 'Invalid Postal Code.'
        },
      },
    }
  },
  methods: {
    initFormFields () {
      for (let field in this.form) {
        this.form[field] = this.userProfile[field]
      }
    },
    changeEditingStatus (status) {
      this.formIsEditing = status
    },
    cancel () {
      if (this.userProfile) {
        this.initFormFields()
      }
      this.changeEditingStatus(false)
    },
    async getUserProfile () {
      this.loadingUserProfile = true
      this.userProfile = await BaseUserService.getUserProfile()
      this.loadingUserProfile = false
    },
    async updateUserProfile () {
      const formData = new FormData()
      for (const field in this.$data.form) {
        formData.append(field, this.$data.form[field])
      }
      if (this.newPicture) {
        formData.append('picture', this.newPicture)
      }
      const updatedProfile = await BaseUserService.updateUserProfile(formData)
      if (updatedProfile) {
        this.changeEditingStatus(false)
        this.userProfile = updatedProfile
        URL.revokeObjectURL(this.newPicture)
        this.newPicture = null
        this.newPictureURL = null
        this.updateMeInStore(updatedProfile)
        this.$dialog.notify.success(
          'User Profile updated', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    updateMeInStore (updatedProfile) {
      let updatedMe = this.userMe
      updatedMe.full_name = updatedProfile.full_name
      updatedMe.picture = updatedProfile.picture
      updatedMe.language = updatedProfile.language
      this.$store.commit('SET_ME', updatedMe)
      this.$root.$i18n.locale = this.$store.state.login.me.language
    },
    choosePicture () {
      this.$refs.userPictureInput.$refs.input.click()
    },
    chosePicture () {
      this.newPictureURL = URL.createObjectURL(this.newPicture)
    },
    updateUserPassword () {
      this.$dialog.show(UserChangePassword, {
        user: this.userMe,
        persistent: true,
        waitForResult: true,
      }).then(async result => {
        if (result) {
          const response = await BaseUserService.selfUpdatePassword({ password: result.password })
          if (response) {
            this.$dialog.notify.success(
              'Password updated successfully', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.container.view-container {
  height: calc(100% - 48px);
  padding: 16px;
}
</style>
