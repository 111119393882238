export default {
  all: 'Tous',
  selectionOnly: 'Sélection seulement',
  loading: 'Chargement',
  showing: 'Montre',
  of: 'de',
  for: 'pour',
  applicationNames: {
    base: 'Paramètres',
    contact: 'Contacts',
    helpdesk: 'Helpdesk',
    inventory: 'Inventaire',
    timesheets: 'Timesheets',
  },
  applications: {
    base: {
      loginPage: {
        login: 'Connexion',
        loginBtnText: 'Se connecter',
        switchBtnText: 'Changer d\'utilisateur',
        welcomeBack: 'Content de vous revoir,',
      },
      menus: {
        logout: 'Déconnexion',
        myProfile: 'Mon Profil',
      },
    },
    contact: {
      address: {
        messages: {
          addressCreated: 'Adresse créée',
          addressUpdated: 'Adresse mise à jour',
          addressDeleted: 'Adresse supprimée',
        },
      },
      buttons: {
        archiveContact: 'Archiver le Contact',
        unarchiveContact: 'Désarchiver le Contact',
      },
      company: {
        messages: {
          companyCreated: 'Société \'{name}\' créée',
          companyUpdated: 'Société mise à jour',
          companyDeleted: 'Société \'{name}\' supprimée',
        },
      },
      userFromContactFormDialog: {
        userTypeFormHint: {
          system: 'Ce type d\'utilisateur aura accès à la plateforme par défault.',
          portal: 'Ce type d\'utilisateur aura accès uniquement au portail, si activé.',
          portalRef: 'Ce type d\'utilisateur aura accès uniquement au portail, si activé, mais avec une vision sur la société.',
        }
      },
    },
    helpdesk: {

    },
    inventory: {

    },
    timesheets: {

    },
  },
  common: {
    buttons: {
      addAddress: 'Ajouter une Adresse',
      cancel: 'Annuler',
      changePassword: 'Changer le mot de passe',
      delete: 'Supprimer',
      edit: 'Modifier',
      loadMore: 'Afficher plus',
      save: 'Sauvegarder',
      export: 'Exporter',
      create: 'Créer',
      createUser: 'Créer un Utilisateur',
      open: 'Voir',
      archive: 'Archiver',
      unarchive: 'Désarchiver',
    },
    errors: {
      invalidEmail: 'Adresse Email Invalide',
      invalidPostalCode: 'Code Postal Invalide',
    },
    hints: {
      pressToValidate: 'Appuyez sur <enter>, <tab>, <espace> ou <,> pour valider une nouvelle entrée',
    },
    labels: {
      archived: 'Archivé',
      unarchived: 'Non Archivé',
      slaLevel: "Niveau SLA",
      domain: 'Domaine',
      domains: 'Domaines',
      contacts: 'Contacts',
      company: 'Société',
      companies: 'Sociétés',
      selection: 'Sélection',
      duration: 'Durée',
      actions: 'Actions',
      createdBy: 'Créé(e) par',
      source: 'Source',
      description: 'Description',
      author: 'Auteur',
      assignee: 'Assigné(e) à',
      unassigned: 'Non assigné',
      customer: 'Client',
      from: 'De',
      to: 'À',
      name: 'Nom',
      firstName: 'Prénom',
      lastName: 'Nom',
      email: 'Email',
      language: 'Langue',
      phone: 'Téléphone',
      phonePerso: 'Téléphone Personnel',
      phonePersoMobile: 'Téléphone Personnel (mobile)',
      phonePro: 'Téléphone Professionnel',
      phoneProMobile: 'Téléphone Professionnel (mobile)',
      address: 'Adresse',
      addresses: 'Adresses',
      streetPerso: 'Adresse',
      zipCodePerso: 'Code Postal',
      city: 'Ville',
      country: 'Pays',
      username: 'Nom d\'utilisateur',
      password: 'Mot de passe',
      required: 'Requis(e)',
      userType: 'Type d\'Utilisateur',
      user: 'Utilisateur',
      status: 'État',
    },
  },
  timing: {
    minutes: 'minute | minute | minutes',
    hours: 'heure | heure | heures',
    days: 'jour | jour | jours',
  },
}
