<template>
  <div>
    <contact-contact-listing-toolbar
      :viewType="viewType"
      @setListView="setListView"
      @setKanbanView="setKanbanView"/>
    <transition name="router-anim" mode="out-in">
      <contact-contact-kanban
        v-if="viewType === 'kanban'"
        :contacts="contacts"
        :pagination="pagination"
        :loading="loading"
        :loadingMore="loadingMore"
        @loadNextContactPage="loadNextContactPage"
        @createUserForContact="createUserForContact"
        @archiveContact="archiveContact"
        @unarchiveContact="unarchiveContact"
        @deleteContact="deleteContact"/>
      <contact-contact-list
        v-else
        :contacts="contacts"
        :pagination="pagination"
        :loading="loading"
        :loadingMore="loadingMore"
        @loadNextContactPage="loadNextContactPage"
        @createUserForContact="createUserForContact"
        @archiveContact="archiveContact"
        @unarchiveContact="unarchiveContact"
        @deleteContact="deleteContact"/>
    </transition>
  </div>
</template>

<script>
import DeleteModal from '@/common/components/DeleteModal'
import UserFromContactFormDialog from './shared/UserFromContactFormDialog'
import ContactContactKanban from '@/views/contact/contact/ContactContactKanban'
import ContactContactListingToolbar from '@/views/contact/contact/shared/ContactContactListingToolbar'
import ContactContactList from '@/views/contact/contact/ContactContactList'
import ContactContactService from '@/services/contact/contact.contact.service'
export default {
  name: 'ContactContactListing',
  components: {
    ContactContactKanban,
    ContactContactList,
    ContactContactListingToolbar,
  },
  data () {
    return {
      loading: false,
      loadingMore: false,
      viewType: 'kanban',
      contacts: [],
      pagination: null,
    }
  },
  mounted () {
    this.getContacts(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getContacts(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    setKanbanView () {
      this.viewType = 'kanban'
    },
    async getContacts (urlSearchParams) {
      this.loading = true
      const responseData = await ContactContactService.getContacts(urlSearchParams)
      this.pagination = responseData.pagination
      this.contacts = responseData.contacts
      this.loading = false
    },
    async loadNextContactPage () {
      if (this.pagination.has_next_page) {
        this.loadingMore = true
        const nextPageNumber = this.pagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await ContactContactService.getContacts(urlSearchParams)
        this.pagination = responseData.pagination
        const newContactPage = responseData.contacts
        const currentContacts = this.contacts
        const updatedContactList = currentContacts.concat(newContactPage)
        this.contacts = updatedContactList
        this.loadingMore = false
      }
    },
    createUserForContact (contact) {
      this.$dialog.show(UserFromContactFormDialog, {
        persistent: true,
        waitForResult: true,
        contact: contact,
      }).then(async result => {
        if (result) {
          const returnedContact = await ContactContactService.createUserForContact(contact.id, {
            username: result.username,
            password: result.password,
            user_type: result.user_type,
          })
          if (returnedContact) {
            this.contacts.splice(this.contacts.indexOf(contact), 1, returnedContact)
            this.$dialog.notify.success(
              `User created for contact '${returnedContact.full_name}'`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    async unarchiveContact (contact) {
      const unarchivedContact = await ContactContactService.unarchiveContact(contact.id)
      if (unarchivedContact) {
        this.contacts.splice(this.contacts.indexOf(contact), 1, unarchivedContact)
        this.$dialog.notify.success(
          `Contact '${contact.full_name}' unarchived`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async archiveContact (contact) {
      const archivedContact = await ContactContactService.archiveContact(contact.id)
      if (archivedContact) {
        this.contacts.splice(this.contacts.indexOf(contact), 1, archivedContact)
        this.$dialog.notify.success(
          `Contact '${contact.full_name}' archived`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    deleteContact (contact) {
      this.$dialog.show(DeleteModal, {
        alertMessage: 'By deleting a contact, you may loose links and history records.',
        checkRequired: true,
        checkMessage: 'I prefer deleting this contact',
        recordName: 'Contact',
        recordNamePlural: 'Contacts',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await ContactContactService.deleteContacts({ ids: [contact.id] })
          if (response) {
            this.contacts.splice(this.contacts.indexOf(contact), 1)
            this.pagination.total_records -= 1
            this.$dialog.notify.success(
              `Contact '${contact.full_name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
