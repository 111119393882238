import ContactCompanyFormCreate from '@/views/contact/company/ContactCompanyFormCreate'
import ContactCompanyFormRead from '@/views/contact/company/ContactCompanyFormRead'
import ContactCompanyListing from '@/views/contact/company/ContactCompanyListing'

export default [
  {
    path: 'companies',
    name: 'ContactCompanyListing',
    component: ContactCompanyListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_contactcompany',
    },
  },
  {
    path: 'companies/create',
    name: 'ContactCompanyFormCreate',
    component: ContactCompanyFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_contactcompany',
    },
  },
  {
    path: 'companies/:companyId',
    name: 'ContactCompanyFormRead',
    component: ContactCompanyFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_contactcompany',
    },
  },
]
