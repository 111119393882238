var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"tile":"","elevation":"3"}},[(
      _vm.category
      && !_vm.editing
      && (
        _vm.hasPermission('add_inventoryitemcategory')
        || _vm.hasPermission('change_inventoryitemcategory')
        || _vm.hasPermission('delete_inventoryitemcategory')
      )
    )?_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-items',[(_vm.hasPermission('change_inventoryitemcategory'))?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.edit()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-pencil")]),_vm._v(" Edit ")],1):_vm._e(),(_vm.hasPermission('delete_inventoryitemcategory'))?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.deleteCategory(_vm.category)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1):_vm._e(),(_vm.hasPermission('add_inventoryitemcategory'))?_c('v-btn',{attrs:{"text":"","to":{ name: 'InventoryItemCategoryFormCreate' }}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Another ")],1):_vm._e()],1)],1):_vm._e(),(_vm.category)?_c('v-divider'):_vm._e(),(!_vm.loadingCategory)?[_c('v-form',{staticClass:"pa-4",attrs:{"disabled":!_vm.editing},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{attrs:{"label":"Name","required":"","clearable":"","autofocus":"","type":"text","maxlength":"255","counter":_vm.editing,"rules":[_vm.formRules.required]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),(_vm.editing)?[_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel ")]),(_vm.hasPermission('change_inventoryitemcategory') || _vm.hasPermission('add_inventoryitemcategory'))?_c('v-btn',{attrs:{"text":"","color":"green","disabled":!_vm.isFormValid},on:{"click":function($event){!_vm.category ? _vm.createCategory() : _vm.updateCategory(_vm.category)}}},[_vm._v(" Save ")]):_vm._e()],1)]:_vm._e()]:[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('h-request-loading')],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }