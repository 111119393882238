<template>
  <v-toolbar tile dense elevation="1">
    <v-btn
      v-if="hasPermission('add_contactcontact')"
      text
      :to="{ name: 'ContactContactFormCreate', params: { editing: true } }">
      <v-icon small left>mdi-plus</v-icon>
      {{ $t('common.buttons.create') }}
    </v-btn>
    <v-spacer/>
    <h-filtering-bar
      :availableFilters="availableFilters"
      :defaultFilters="[
        { fieldName: 'is_archived', label: 'Archived', optionText: 'Not Archived', type: 'boolean', value: false }
      ]"/>
    <v-spacer/>
    <v-btn
      tile
      icon
      link
      :class="{'v-btn--active': viewType === 'kanban'}"
      @click="$emit('setKanbanView')">
      <v-icon>mdi-view-grid</v-icon>
    </v-btn>
    <v-btn
      tile
      icon
      link
      :class="{'v-btn--active': viewType === 'list'}"
      @click="$emit('setListView')">
      <v-icon>mdi-format-list-bulleted</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import HFilteringBar from '@/common/components/HFilteringBar'
export default {
  name: 'ContactContactListingToolbar',
  components: { HFilteringBar },
  props: {
    viewType: { type: String },
  },
  data () {
    return {
      availableFilters: [
        { fieldName: 'name', label: this.$t('common.labels.name'), type: 'string' },
        { fieldName: 'email', label: this.$t('common.labels.email'), type: 'string' },
        { fieldName: 'phone', label: this.$t('common.labels.phone'), type: 'string' },
        { fieldName: 'company', label: this.$t('common.labels.company'), type: 'string' },
        { fieldName: 'tag', label: 'Tag', type: 'string' },
        { fieldName: 'is_archived', label: this.$t('common.labels.archived'), optionText: this.$t('common.labels.unarchived'), type: 'boolean', value: false },
        { fieldName: 'is_archived', label: this.$t('common.labels.archived'), optionText: this.$t('common.labels.archived'), type: 'boolean', value: true },
      ],
    }
  },
}
</script>

<style scoped>
  .v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:last-child {
    /* Override the default -12px right margin in order to be symmetrical */
    margin-right: 0px;
  }
  .v-toolbar {
    height: unset!important;
  }
  .v-toolbar::v-deep .v-toolbar__content {
    height: fit-content!important;
  }
</style>
