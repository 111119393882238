import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class AuthService {
  static logout = async () => {
    try {
      const response = await http.post('logout')
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
