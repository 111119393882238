var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogCard',{attrs:{"actions":_vm.actions}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"blue darken-1"}},[(!_vm.inboundMailServer)?_c('v-toolbar-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Inbound Mailbox Configuration ")],1):_c('v-toolbar-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" Update Inbound Mailbox Configuration ")],1)],1),_c('v-alert',{staticClass:"mt-4 mx-4 mb-0",attrs:{"outlined":"","text":"","dense":"","type":_vm.testAlert.type}},[_vm._v(" "+_vm._s(_vm.testAlert.text)+" ")]),_c('v-form',{staticClass:"pa-4",model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-text-field',{attrs:{"label":"Name","required":"","clearable":"","autofocus":"","type":"text","maxlength":"255","counter":"","rules":[_vm.formRules.required]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"label":"Server Type","items":[
            { text: 'IMAP', value: 'imap' },
            { text: 'POP', value: 'pop' },
          ],"item-value":"value","rules":[_vm.formRules.required]},model:{value:(_vm.form.server_type),callback:function ($$v) {_vm.$set(_vm.form, "server_type", $$v)},expression:"form.server_type"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-switch',{attrs:{"label":"Use SSL","inset":"","dense":"","color":"green"},model:{value:(_vm.form.use_ssl),callback:function ($$v) {_vm.$set(_vm.form, "use_ssl", $$v)},expression:"form.use_ssl"}})],1)],1),_c('v-text-field',{attrs:{"label":"Host","required":"","clearable":"","type":"text","maxlength":"255","counter":"","rules":[
        _vm.formRules.required,
        _vm.formRules.domain
      ]},model:{value:(_vm.form.host),callback:function ($$v) {_vm.$set(_vm.form, "host", $$v)},expression:"form.host"}}),_c('v-text-field',{attrs:{"label":"Port","required":"","clearable":"","type":"number","rules":[
        _vm.formRules.aboveMinPort,
        _vm.formRules.belowMaxPort,
        _vm.formRules.required,
      ]},model:{value:(_vm.form.port),callback:function ($$v) {_vm.$set(_vm.form, "port", $$v)},expression:"form.port"}}),_c('v-text-field',{attrs:{"label":"Username","required":"","clearable":"","type":"text","maxlength":"255","counter":"","rules":[_vm.formRules.required]},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('v-text-field',{attrs:{"label":"Password","required":"","clearable":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"maxlength":"255","counter":"","rules":[_vm.formRules.required]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('v-btn',{staticClass:"mx-auto mb-4",staticStyle:{"display":"block","width":"80%"},attrs:{"color":"primary","disabled":!_vm.isFormValid,"loading":_vm.loadingTest},on:{"click":_vm.testSettings}},[_vm._v(" Test Connection ")]),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }