var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',[_c('v-subheader',{staticClass:"mb-2"},[_c('h2',[_vm._v("Outbound (SMTP)")])]),(_vm.editing)?_c('v-toolbar',{staticClass:"mb-6",attrs:{"flat":"","height":"36","color":"grey lighten-4"}},[_c('v-toolbar-items',[_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.loadingTest,"disabled":!_vm.isFormValid || !_vm.formHasChanges},on:{"click":function($event){return _vm.testSettings()}}},[_vm._v(" Test Connection ")]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{attrs:{"text":"","color":"green","disabled":!_vm.formSuccessfullyTested || !_vm.isFormValid},on:{"click":function($event){return _vm.updateSettings()}}},[_vm._v(" Save ")])],1)],1):_vm._e(),(_vm.editing && _vm.formHasChanges)?_c('v-alert',{staticClass:"mx-4 mt-4 mb-2",attrs:{"outlined":"","text":"","dense":"","type":_vm.connectionAlert.type}},[_vm._v(" "+_vm._s(_vm.connectionAlert.text)+" ")]):_vm._e(),_c('v-form',{staticClass:"pa-4",attrs:{"disabled":!_vm.editing},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Host","required":"","clearable":"","autofocus":"","type":"text","maxlength":"255","counter":_vm.editing,"rules":[
            _vm.formRules.required,
            _vm.formRules.domain
          ]},model:{value:(_vm.form.host),callback:function ($$v) {_vm.$set(_vm.form, "host", $$v)},expression:"form.host"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Port","required":"","clearable":"","type":"number","rules":[
            _vm.formRules.aboveMinPort,
            _vm.formRules.belowMaxPort,
            _vm.formRules.required,
          ]},model:{value:(_vm.form.port),callback:function ($$v) {_vm.$set(_vm.form, "port", $$v)},expression:"form.port"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Username","clearable":"","type":"text","maxlength":"255","counter":_vm.editing},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Password","clearable":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"maxlength":"255","counter":_vm.editing,"rules":[_vm.formRules.required]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1)],1),_c('v-switch',{attrs:{"label":"Use TLS","inset":"","dense":"","color":"green","rules":[_vm.formRules.tlsExclusiveSSL]},model:{value:(_vm.form.use_tls),callback:function ($$v) {_vm.$set(_vm.form, "use_tls", $$v)},expression:"form.use_tls"}}),_c('v-switch',{attrs:{"label":"Use SSL","inset":"","dense":"","color":"green","rules":[_vm.formRules.sslExclusiveTLS]},model:{value:(_vm.form.use_ssl),callback:function ($$v) {_vm.$set(_vm.form, "use_ssl", $$v)},expression:"form.use_ssl"}}),_c('v-text-field',{attrs:{"label":"Default Email Address (From)","placeholder":"support@example.be","hint":"Will be used as the default address for sending emails.","persistent-hint":"","maxlength":"254","counter":_vm.editing,"rules":[_vm.formRules.required]},model:{value:(_vm.form.default_from),callback:function ($$v) {_vm.$set(_vm.form, "default_from", $$v)},expression:"form.default_from"}})],1)],1):_c('div',[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('h-request-loading')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }