<template>
  <div>
    <inventory-item-contractor-listing-toolbar
      :viewType="viewType"
      :selectedContractors="selectedContractors"
      @setListView="setListView"
      @setKanbanView="setKanbanView"
      @deleteContractors="deleteContractors"/>
    <transition name="router-anim" mode="out-in">
      <inventory-item-contractor-kanban
        v-if="viewType === 'kanban'"
        :contractors="contractors"
        :loading="loading"
        @deleteContractor="deleteContractor"/>
      <inventory-item-contractor-list
        v-else
        v-model="selectedContractors"
        :contractors="contractors"
        :loading="loading"
        @deleteContractor="deleteContractor"/>
    </transition>
  </div>
</template>

<script>
import DeleteModal from '@/common/components/DeleteModal'
import InventoryItemContractorKanban from '@/views/inventory/contractor/InventoryItemContractorKanban'
import InventoryItemContractorList from '@/views/inventory/contractor/InventoryItemContractorList'
import InventoryItemContractorListingToolbar from '@/views/inventory/contractor/shared/InventoryItemContractorListingToolbar'
import InventoryItemContractorService from '@/services/inventory/inventory.item.contractor.service'
export default {
  name: 'InventoryItemContractorListing',
  components: {
    InventoryItemContractorKanban,
    InventoryItemContractorList,
    InventoryItemContractorListingToolbar,
  },
  data () {
    return {
      loading: false,
      viewType: 'kanban',
      contractors: [],
      selectedContractors: [],
    }
  },
  mounted () {
    this.getContractors(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getContractors(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    setKanbanView () {
      this.viewType = 'kanban'
    },
    async getContractors (urlSearchParams) {
      this.loading = true
      this.contractors = await InventoryItemContractorService.getContractors(urlSearchParams)
      this.loading = false
    },
    deleteContractor (contractor) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Contractor',
        recordNamePlural: 'Contractors',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await InventoryItemContractorService.deleteContractors({ ids: [contractor.id] })
          if (response) {
            this.contractors.splice(this.contractors.indexOf(contractor), 1)
            this.selectedContractors = []
            this.$dialog.notify.success(
              `Contractor '${contractor.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteContractors (contractorsToDelete) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Contractor',
        recordNamePlural: 'Contractors',
        summaryHeaders: [
          { value: 'name', text: 'Name' },
          { value: 'items_count', text: '# Items' },
        ],
        summaryRecords: contractorsToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const contractorIdsToDelete = contractorsToDelete.map(contractor => contractor.id)
          const response = await InventoryItemContractorService.deleteContractors({ ids: contractorIdsToDelete })
          if (response) {
            this.contractors = this.contractors.filter(undeletedContractor => !contractorIdsToDelete.includes(undeletedContractor.id))
            this.selectedContractors = []
            this.$dialog.notify.success(
              `${contractorsToDelete.length} ${contractorsToDelete.length > 1 ? 'contractors' : 'contractor'} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
