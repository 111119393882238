<template>
  <div v-if="!loading">
    <v-subheader class="mb-2">
      <h2>Office 365 Configuration</h2>
    </v-subheader>
    <v-toolbar
      v-if="editing"
      flat
      height=36
      color="grey lighten-4"
      class="mb-6">
      <v-toolbar-items>
        <v-btn
          text
          color="primary"
          :loading="loadingTest"
          :disabled="!formHasChanges || !isFormValid"
          @click="checkOffice365Credentials()">
          Test Credentials
        </v-btn>
        <v-divider vertical/>
        <v-btn
          text
          color="green"
          :disabled="!formSuccessfullyTested || !isFormValid"
          @click="updateOffice365Credentials()">
          Save
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-alert
      v-if="editing && formHasChanges"
      outlined
      text
      dense
      class="mx-4 mt-4 mb-2"
      :type="connectionAlert.type">
      {{ connectionAlert.text }}
    </v-alert>
    <v-form v-model="isFormValid" class="pa-4" :disabled="!editing">
      <v-text-field
        v-model="form.tenant_id"
        label="Tenant ID"
        type="text"
        maxlength="255"
        counter
        :rules="[formRules.required]"/>
      <v-text-field
        v-model="form.client_id"
        type="text"
        label="Client ID"
        maxlength="255"
        :rules="[formRules.required]"/>
      <v-text-field
        v-model="form.secret"
        label="Secret"
        maxlength="255"
        counter
        prepend-inner-icon="mdi-lock"
        :append-icon="showSecret ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showSecret ? 'text' : 'password'"
        :rules="[formRules.required]"
        @click:append="showSecret = !showSecret"/>
      <v-text-field
        v-model="form.default_from"
        label="Default Email Address (From)"
        placeholder="support@example.be"
        hint="Will be used as the default address for sending emails. You must use an existing address/mailbox."
        persistent-hint
        maxlength="254"
        :counter="editing"
        :rules="[formRules.required]"/>
    </v-form>
  </div>
  <div v-else>
    <v-col cols=12 align="center" justify="center">
      <h-request-loading/>
    </v-col>
  </div>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import BaseSettingsService from '@/services/base/base.settings.service'
import WarningModal from '@/common/components/WarningModal'
export default {
  name: 'EmailSettingsOffice365Form',
  components: {
    HRequestLoading,
  },
  props: {
    editing: { type: Boolean, required: true },
  },
  data () {
    return {
      loading: false,
      loadingTest: false,
      showSecret: false,
      office365Settings: null,
      isFormValid: false,
      form: {
        tenant_id: '',
        client_id: '',
        secret: '',
        default_from: '',
      },
      formRules: {
        required: value => !!value || 'Required.',
      },
      formTested: false,
      formSuccessfullyTested: false,
    }
  },
  watch: {
    'form': {
      handler (form) {
        this.formTested = false
        this.formSuccessfullyTested = false
      },
      deep: true,
    },
  },
  computed: {
    formHasChanges () {
      if (this.office365Settings) {
        return Object.keys(this.form).some(field => this.form[field] !== this.office365Settings[field])
      }
      return false
    },
    connectionAlert () {
      if (this.formHasChanges && !this.formTested) {
        return {
          type: 'info',
          text: 'Please test whether the connection is working or not before saving.',
        }
      } else if (this.formHasChanges && this.formTested && !this.formSuccessfullyTested) {
        return {
          type: 'error',
          text: 'The provided connection information did not work.',
        }
      } else {
        return {
          type: 'success',
          text: 'The provided connection information successfully connected to Office 365 server.',
        }
      }
    },
  },
  async mounted () {
    await this.getOffice365EmailSettings()
    if (this.office365Settings) {
      this.initFormFields()
    }
  },
  methods: {
    initFormFields () {
      for (let field in this.form) {
        if (field === 'secret') continue
        this.form[field] = this.office365Settings[field]
      }
    },
    cancelEdit () {
      this.form.secret = ''
      this.$emit('toggleEdit', false)
    },
    async getOffice365EmailSettings () {
      this.loading = true
      this.office365Settings = await BaseSettingsService.getOffice365EmailSettings()
      this.loading = false
    },
    async checkOffice365Credentials () {
      this.loadingTest = true
      const { tenant_id, client_id, secret } = this.$data.form
      const testResult = await BaseSettingsService.checkOffice365EmailSettings({
        tenant_id: tenant_id,
        client_id: client_id,
        secret: secret,
      })
      if (testResult) {
        this.formTested = true
        if (testResult.success === true) {
          this.formSuccessfullyTested = true
        } else {
          this.formSuccessfullyTested = false
        }
      }
      this.loadingTest = false
    },
    updateOffice365Credentials () {
      const { tenant_id, client_id, secret, default_from } = this.$data.form
      this.$dialog.show(WarningModal, {
        icon: 'pencil',
        title: 'Update email settings ?',
        message: 'Are you sure you want to save these email settings ?',
        okText: 'YES',
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const updatedSettings = await BaseSettingsService.createOrUpdateOffice365EmailSettings({
            tenant_id: tenant_id,
            client_id: client_id,
            secret: secret,
            default_from: default_from,
          })
          if (updatedSettings) {
            this.cancelEdit()
            this.office365Settings = updatedSettings
            this.$dialog.notify.success(
              'Office 365 email settings updated', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    }
  },
}
</script>
