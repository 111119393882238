import Vue from 'vue'
import Vuex from 'vuex'
import base from './modules/base.store'
import helpdesk from './modules/helpdesk.store'
import login from './modules/login.store'
import url from './modules/url.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    base: base,
    helpdesk: helpdesk,
    login: login,
    url: url,
  },
})
