import InventoryItemFormCreate from '@/views/inventory/item/InventoryItemFormCreate'
import InventoryItemFormRead from '@/views/inventory/item/InventoryItemFormRead'
import InventoryItemListing from '@/views/inventory/item/InventoryItemListing'

export default [
  {
    path: 'items',
    name: 'InventoryItemListing',
    component: InventoryItemListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_inventoryitem',
    },
  },
  {
    path: 'items/create',
    name: 'InventoryItemFormCreate',
    component: InventoryItemFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_inventoryitem',
    },
  },
  {
    path: 'items/:itemId',
    name: 'InventoryItemFormRead',
    component: InventoryItemFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_inventoryitem',
    },
  },
]
