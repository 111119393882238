<template>
  <div
    class="h-rich-editor-menubar"
    :class="{'disabled': disabled}">
    <v-btn-toggle
      v-model="btnToggleState"
      dense
      borderless
      tile
      dark
      :background-color="disabled ? 'grey' : 'blue darken-2'">
      <h-rich-text-editor-menu-button
        v-if="formatParagraph"
        icon="format-paragraph"
        :disabled="disabled"
        @click.native="editor.chain().focus().setParagraph().run()"/>
      <h-rich-text-editor-menu-button
        v-if="formatBold"
        icon="format-bold"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleBold().run()"/>
      <h-rich-text-editor-menu-button
        v-if="formatItalic"
        icon="format-italic"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleItalic().run()"/>
      <h-rich-text-editor-menu-button
        v-if="formatStrike"
        icon="format-strikethrough"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleStrike().run()"/>
      <h-rich-text-editor-menu-button
        v-if="formatMarker"
        icon="marker"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleHighlight().run()"/>
      <v-divider
        v-if="formatH1 || formatH2 || formatH3"
        vertical/>
      <h-rich-text-editor-menu-button
        v-if="formatH1"
        icon="format-header-1"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleHeading({ level: 1 }).run()"/>
      <h-rich-text-editor-menu-button
        v-if="formatH2"
        icon="format-header-2"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleHeading({ level: 2 }).run()"/>
      <h-rich-text-editor-menu-button
        v-if="formatH3"
        icon="format-header-3"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleHeading({ level: 3 }).run()"/>
      <v-divider
        v-if="formatListB || formatListN"
        vertical/>
      <h-rich-text-editor-menu-button
        v-if="formatListB"
        icon="format-list-bulleted"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleBulletList().run()"/>
      <h-rich-text-editor-menu-button
        v-if="formatListN"
        icon="format-list-numbered"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleOrderedList().run()"/>
      <v-divider
        v-if="formatCode || formatCodeBlock"
        vertical/>
      <h-rich-text-editor-menu-button
        v-if="formatCode"
        icon="code-tags"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleCode().run()"/>
      <h-rich-text-editor-menu-button
        v-if="formatCodeBlock"
        icon="code-array"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleCodeBlock().run()"/>
      <v-divider
        v-if="formatSeparator"
        vertical/>
      <!-- quote is not working -->
      <!-- <h-rich-text-editor-menu-button
        v-if="formatBlockQuote"
        icon="format-quote-close"
        :disabled="disabled"
        @click.native="editor.chain().focus().toggleBlockquote().run()"/> -->
      <h-rich-text-editor-menu-button
        v-if="formatSeparator"
        icon="minus"
        :disabled="disabled"
        @click.native="editor.chain().focus().setHorizontalRule().run()"/>
      <v-divider
        v-if="historyUndo || historyRedo"
        vertical/>
      <h-rich-text-editor-menu-button
        v-if="historyUndo"
        icon="undo"
        :disabled="disabled"
        @click.native="editor.chain().focus().undo().run()"/>
      <h-rich-text-editor-menu-button
        v-if="historyRedo"
        icon="redo"
        :disabled="disabled"
        @click.native="editor.chain().focus().redo().run()"/>
    </v-btn-toggle>
  </div>
</template>

<script>
import { Editor } from '@tiptap/vue-2'
import HRichTextEditorMenuButton from './HRichTextEditorMenuButton'
export default {
  name: 'HRichTextEditorMenuBar',
  components: {
    HRichTextEditorMenuButton
  },
  props: {
    disabled: { type: Boolean, default: true },
    editor: { type: Editor, default: () => { return null } },
    formatParagraph: { type: Boolean, default: false },
    formatBold: { type: Boolean, default: false },
    formatItalic: { type: Boolean, default: false },
    formatStrike: { type: Boolean, default: false },
    formatMarker: { type: Boolean, default: false },
    formatH1: { type: Boolean, default: false },
    formatH2: { type: Boolean, default: false },
    formatH3: { type: Boolean, default: false },
    formatListB: { type: Boolean, default: false },
    formatListN: { type: Boolean, default: false },
    formatCode: { type: Boolean, default: false },
    formatCodeBlock: { type: Boolean, default: false },
    // formatBlockQuote: { type: Boolean, default: false },
    formatSeparator: { type: Boolean, default: false },
    historyUndo: { type: Boolean, default: false },
    historyRedo: { type: Boolean, default: false },

  },
  data() {
    return {
      btnToggleState: []
      // btnToggleState is just here to keep all buttons
      // in deactivated state.
      // TODO: Remove this mechanism whenever a prop is
      //       available to handle this.
    }
  },
  watch: {
    btnToggleState(n, o) {
      if (JSON.stringify(n) == JSON.stringify(o)) return;
      this.btnToggleState = [];
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.h-rich-editor-menubar {
  background-color: #1976D2;  /* blue darken-2 */
}
.h-rich-editor-menubar.disabled {
  background-color: #9E9E9E;  /* grey */
}
.h-rich-editor-menubar .v-btn-toggle {
  flex-wrap: wrap;
}
</style>
