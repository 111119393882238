<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-cog</v-icon>
        Dashboard Configuration
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs
      background-color="grey lighten-4"
      grow
      class="pa-4">
      <v-tab>
        <v-icon>mdi-update</v-icon>
      </v-tab>
      <v-tab>
        <v-icon>mdi-bullseye-arrow</v-icon>
      </v-tab>
      <v-tab>
        <v-icon>mdi-clock-alert-outline</v-icon>
      </v-tab>
      <v-tab-item eager>
        <v-form
          v-model="isFormValid"
          :disabled="mode === 'view'">
          <v-text-field
            v-model.number="form.dashboardRefreshInterval"
            type="number"
            label="Refresh Interval"
            suffix="minutes"
            :hint="refreshIntervalHint"
            persistent-hint
            filled
            class="mb-4"
            :rules="[formRules.positiveNumber]"/>
          <v-text-field
            v-model.number="form.windowSwitchInterval"
            type="number"
            label="Switch Interval"
            suffix="minutes"
            :hint="switchIntervalHint"
            persistent-hint
            filled
            :rules="[formRules.positiveNumber]"/>
        </v-form>
      </v-tab-item>
      <v-tab-item eager>
        <v-form
          v-model="isFormValid"
          :disabled="mode === 'view'">
          <v-row class="my-0">
            <v-col>
              <v-text-field
              v-model.number="form.totalOpenTicketsWarningThreshold"
              type="number"
              label="Open Tickets Warning"
              :hint="openTicketsWarningHint"
              persistent-hint
              filled
              :rules="[
                formRules.positiveNumber,
                formRules.openTicketsWarningLTEAlert
              ]"/>
            </v-col>
            <v-col>
              <v-text-field
              v-model.number="form.totalOpenTicketsAlertThreshold"
              type="number"
              label="Open Tickets Alert"
              :hint="openTicketsAlertHint"
              persistent-hint
              filled
              :rules="[
                formRules.positiveNumber,
                formRules.openTicketsAlertGTEWarning
              ]"/>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row class="my-0">
            <v-col>
              <v-text-field
              v-model.number="form.totalUnassignedTicketsWarningThreshold"
              type="number"
              label="Unassigned Tickets Warning"
              :hint="unassignedTicketsWarningHint"
              persistent-hint
              filled
              :rules="[
                formRules.positiveNumber,
                formRules.unassignedTicketsWarningLTEAlert
              ]"/>
            </v-col>
            <v-col>
              <v-text-field
              v-model.number="form.totalUnassignedTicketsAlertThreshold"
              type="number"
              label="Unassigned Tickets Alert"
              :hint="unassignedTicketsAlertHint"
              persistent-hint
              filled
              :rules="[
                formRules.positiveNumber,
                formRules.unassignedTicketsAlertGTEWarning
              ]"/>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>
      <v-tab-item eager>
        <v-form
          v-model="isFormValid"
          :disabled="mode === 'view'">
          <span class="overline font-weight-black">Time To Assign</span>
          <v-divider class="mb-2"/>
          <span class="caption font-weight-bold">Warning Threshold :</span>
          <v-row dense class="my-0">
            <v-col>
              <v-text-field
                v-model.number="form.averageTtfaWarningThresholdDays"
                type="number"
                suffix="days"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="form.averageTtfaWarningThresholdHours"
                type="number"
                suffix="hours"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="form.averageTtfaWarningThresholdMinutes"
                type="number"
                suffix="minutes"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
          </v-row>
          <span class="caption font-italic text--disabled">{{ averageTtfaWarningHint }}</span><br/>
          <span class="caption font-weight-bold">Alert Threshold :</span>
          <v-row dense class="my-0">
            <v-col>
              <v-text-field
                v-model.number="form.averageTtfaAlertThresholdDays"
                type="number"
                suffix="days"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="form.averageTtfaAlertThresholdHours"
                type="number"
                suffix="hours"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="form.averageTtfaAlertThresholdMinutes"
                type="number"
                suffix="minutes"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
          </v-row>
          <span class="caption font-italic text--disabled">{{ averageTtfaAlertHint }}</span><br/>
          <span class="overline font-weight-black">Time To Close</span>
          <v-divider class="mb-2"/>
          <span class="caption font-weight-bold">Warning Threshold :</span>
          <v-row dense class="my-0">
            <v-col>
              <v-text-field
                v-model.number="form.averageTtcWarningThresholdDays"
                type="number"
                suffix="days"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="form.averageTtcWarningThresholdHours"
                type="number"
                suffix="hours"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="form.averageTtcWarningThresholdMinutes"
                type="number"
                suffix="minutes"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
          </v-row>
          <span class="caption font-italic text--disabled">{{ averageTtcWarningHint }}</span><br/>
          <span class="caption font-weight-bold">Alert Threshold :</span>
          <v-row dense class="my-0">
            <v-col>
              <v-text-field
                v-model.number="form.averageTtcAlertThresholdDays"
                type="number"
                suffix="days"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="form.averageTtcAlertThresholdHours"
                type="number"
                suffix="hours"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
            <v-col>
              <v-text-field
                v-model.number="form.averageTtcAlertThresholdMinutes"
                type="number"
                suffix="minutes"
                dense
                hide-details="auto"
                filled
                :rules="[formRules.positiveNumber]"/>
            </v-col>
          </v-row>
          <span class="caption font-italic text--disabled">{{ averageTtcAlertHint }}</span>
        </v-form>
      </v-tab-item>
    </v-tabs>
  </DialogCard>
</template>

<script>
export default {
  name: 'HelpdeskDashboardFormDialog',
  props: {
    mode: { type: String, required: true },  // Should be 'view' or 'change'
    dashboard: { type: Object }
  },
  computed: {
    actions () {
      if (this.mode === 'change') {
        return {
          false: {
            text: 'Cancel',
          },
          true: {
            text: 'Save',
            color: 'green',
            disabled: !this.isFormValid,
            handler: () => {
              return new Promise(resolve => {
                resolve(this.form)
              })
            },
          },
        }
      } else {
        return {
          false: {
            text: 'Close',
            color: 'primary',
          }
        }
      }
    },
    refreshIntervalHint: function () {
      if (this.form.dashboardRefreshInterval <= 0) {
        return 'The Dashboard will never refresh automatically.'
      } else {
        return `The Dashboard will refresh every ${this.form.dashboardRefreshInterval} minutes`
      }
    },
    switchIntervalHint: function () {
      if (this.form.windowSwitchInterval <= 0) {
        return 'The Windows will never switch automatically.'
      } else {
        return `The Windows will switch every ${this.form.windowSwitchInterval} minutes`
      }
    },
    openTicketsWarningHint: function () {
      if (this.form.totalOpenTicketsWarningThreshold <= 0) {
        return "There won't be any Warning status."
      } else {
        return `The Warning status will be shown when there are more than ${this.form.totalOpenTicketsWarningThreshold} opened Tickets.`
      }
    },
    openTicketsAlertHint: function () {
      if (this.form.totalOpenTicketsAlertThreshold <= 0) {
        return "There won't be any Alert status."
      } else {
        return `The Alert status will be shown when there are more than ${this.form.totalOpenTicketsAlertThreshold} opened Tickets.`
      }
    },
    unassignedTicketsWarningHint: function () {
      if (this.form.totalUnassignedTicketsWarningThreshold <= 0) {
        return "There won't be any Warning status."
      } else {
        return `The Warning status will be shown when there are more than ${this.form.totalUnassignedTicketsWarningThreshold} unassigned Tickets.`
      }
    },
    unassignedTicketsAlertHint: function () {
      if (this.form.totalUnassignedTicketsAlertThreshold <= 0) {
        return "There won't be any Alert status."
      } else {
        return `The Alert status will be shown when there are more than ${this.form.totalUnassignedTicketsAlertThreshold} unassigned Tickets.`
      }
    },
    averageTtfaWarningHint: function () {
      if (
        this.form.averageTtfaWarningThresholdDays > 0 ||
        this.form.averageTtfaWarningThresholdHours > 0 ||
        this.form.averageTtfaWarningThresholdMinutes > 0
      ) {
        return `The Warning status will be shown when the average Time To Assign reaches ${this.form.averageTtfaWarningThresholdDays} days ${this.form.averageTtfaWarningThresholdHours} hours ${this.form.averageTtfaWarningThresholdMinutes} minutes`
      } else {
        return "There won't be any Warning status."
      }
    },
    averageTtfaAlertHint: function () {
      if (
        this.form.averageTtfaAlertThresholdDays > 0 ||
        this.form.averageTtfaAlertThresholdHours > 0 ||
        this.form.averageTtfaAlertThresholdMinutes > 0
      ) {
        return `The Alert status will be shown when the average Time To Assign reaches ${this.form.averageTtfaAlertThresholdDays} days ${this.form.averageTtfaAlertThresholdHours} hours ${this.form.averageTtfaAlertThresholdMinutes} minutes`
      } else {
        return "There won't be any Alert status."
      }
    },
    averageTtcWarningHint: function () {
      if (
        this.form.averageTtcWarningThresholdDays > 0 ||
        this.form.averageTtcWarningThresholdHours > 0 ||
        this.form.averageTtcWarningThresholdMinutes > 0
      ) {
        return `The Warning status will be shown when the average Time To Close reaches ${this.form.averageTtcWarningThresholdDays} days ${this.form.averageTtcWarningThresholdHours} hours ${this.form.averageTtcWarningThresholdMinutes} minutes`
      } else {
        return "There won't be any Warning status."
      }
    },
    averageTtcAlertHint: function () {
      if (
        this.form.averageTtcAlertThresholdDays > 0 ||
        this.form.averageTtcAlertThresholdHours > 0 ||
        this.form.averageTtcAlertThresholdMinutes > 0
      ) {
        return `The Alert status will be shown when the average Time To Close reaches ${this.form.averageTtcAlertThresholdDays} days ${this.form.averageTtcAlertThresholdHours} hours ${this.form.averageTtcAlertThresholdMinutes} minutes`
      } else {
        return "There won't be any Alert status."
      }
    },
  },
  data () {
    return {
      form: {
        dashboardRefreshInterval: this.dashboard.dashboard_refresh_interval / 60 / 1000,  // to show in minutes
        windowSwitchInterval: this.dashboard.window_switch_interval / 60 / 1000,          // to show in minutes
        totalOpenTicketsWarningThreshold: this.dashboard.total_open_tickets_warning_threshold,
        totalOpenTicketsAlertThreshold: this.dashboard.total_open_tickets_alert_threshold,
        totalUnassignedTicketsWarningThreshold: this.dashboard.total_unassigned_tickets_warning_threshold,
        totalUnassignedTicketsAlertThreshold: this.dashboard.total_unassigned_tickets_alert_threshold,
        // Average Time To First Assign Warning
        averageTtfaWarningThresholdDays: this.getDaysFromMilliseconds(this.dashboard.average_ttfa_warning_threshold),
        averageTtfaWarningThresholdHours: this.getHoursFromMilliseconds(this.dashboard.average_ttfa_warning_threshold),
        averageTtfaWarningThresholdMinutes: this.getMinutesFromMilliseconds(this.dashboard.average_ttfa_warning_threshold),
        // Average Time To First Assign Alert
        averageTtfaAlertThresholdDays: this.getDaysFromMilliseconds(this.dashboard.average_ttfa_alert_threshold),
        averageTtfaAlertThresholdHours: this.getHoursFromMilliseconds(this.dashboard.average_ttfa_alert_threshold),
        averageTtfaAlertThresholdMinutes: this.getMinutesFromMilliseconds(this.dashboard.average_ttfa_alert_threshold),
        // Average Time To Close Warning
        averageTtcWarningThresholdDays: this.getDaysFromMilliseconds(this.dashboard.average_ttc_warning_threshold),
        averageTtcWarningThresholdHours: this.getHoursFromMilliseconds(this.dashboard.average_ttc_warning_threshold),
        averageTtcWarningThresholdMinutes: this.getMinutesFromMilliseconds(this.dashboard.average_ttc_warning_threshold),
        // Average Time To Close Alert
        averageTtcAlertThresholdDays: this.getDaysFromMilliseconds(this.dashboard.average_ttc_alert_threshold),
        averageTtcAlertThresholdHours: this.getHoursFromMilliseconds(this.dashboard.average_ttc_alert_threshold),
        averageTtcAlertThresholdMinutes: this.getMinutesFromMilliseconds(this.dashboard.average_ttc_alert_threshold)
      },
      isFormValid: false,
      formRules: {
        positiveNumber: value => value >= 0 || 'Must be >= 0.',
        openTicketsWarningLTEAlert: value => value <= this.form.totalOpenTicketsAlertThreshold || 'Must be <= than the Alert threshold.',
        openTicketsAlertGTEWarning: value => value >= this.form.totalOpenTicketsWarningThreshold || 'Must be >= than the Warning threshold.',
        unassignedTicketsWarningLTEAlert: value => value <= this.form.totalUnassignedTicketsAlertThreshold || 'Must be <= than the Alert threshold.',
        unassignedTicketsAlertGTEWarning: value => value >= this.form.totalUnassignedTicketsWarningThreshold || 'Must be >= than the Warning threshold.'
      },
    }
  },
  methods: {
    getDaysFromMilliseconds (time) {
      return Math.floor(time / 1000 / 60 / 60 / 24)
    },
    getHoursFromMilliseconds (time) {
      return Math.floor((time / 1000 / 60 / 60 / 24 - this.getDaysFromMilliseconds(time)) * 24)
    },
    getMinutesFromMilliseconds (time) {
      return Math.floor(((time / 1000 / 60 / 60 / 24 - this.getDaysFromMilliseconds(time)) * 24 - this.getHoursFromMilliseconds(time)) * 60)
    },
  }
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
  .v-tabs::v-deep .v-tabs-bar {
    margin-bottom: 20px;
  }
</style>
