import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class InventoryItemContractorService {
  static getContractors = async (urlSearchParams) => {
    try {
      const response = await http.get('inventory/contractors', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getContractorsInput = async () => {
    try {
      const response = await http.get('inventory/contractors/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getContractor = async (id) => {
    try {
      const response = await http.get(`inventory/contractors/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createContractor = async (data) => {
    try {
      const response = await http.post('inventory/contractors/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateContractor = async (id, data) => {
    try {
      const response = await http.patch(`inventory/contractors/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteContractors = async (data) => {
    try {
      const response = await http.post('inventory/contractors/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
