<template>
  <div class="ma-12">
    <v-progress-circular
      :size="60"
      :width="6"
      color="light-blue darken-4"
      indeterminate/>
    <div class="mt-4 caption">
      Loading...
    </div>
  </div>
</template>

<script>
export default {
  name: 'HRequestLoading'
}
</script>
