export default {
  state: () => ({
    urlSearchParams: new URLSearchParams()
  }),
  mutations: {
    SET_URL_SEARCH_PARAMS: (state, urlSearchParams) => {
      state.urlSearchParams = urlSearchParams
    }
  }
}
