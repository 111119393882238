import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class HelpdeskTicketSubtaskService {
  static getSubtasks = async (urlSearchParams) => {
    try {
      const response = await http.get('helpdesk/subtasks', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateSubtaskStatus = async (id, data) => {
    try {
      const response = await http.patch(`helpdesk/subtasks/${id}/change-status`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static bulkUpdateSubtasksStatus = async (data) => {
    try {
      const response = await http.patch('helpdesk/subtasks/change-status', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteSubtasks = async (data) => {
    try {
      const response = await http.post('helpdesk/subtasks/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
