<template>
  <v-card tile elevation="3">
    <v-tabs vertical background-color="grey lighten-4">
      <v-tab :to="{ name: 'HelpdeskGeneralSettingsForm' }">
        General
      </v-tab>
      <v-tab :to="{ name: 'HelpdeskSLALevelList' }">
        SLA Levels
      </v-tab>
      <v-tab :to="{ name: 'HelpdeskRecurringTicketTaskList' }">
        Recurring Tickets
      </v-tab>
      <v-tabs-items>
        <router-view/>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  name: 'HelpdeskSettingsMenu'
}
</script>

<style scoped>
.v-card, .v-tabs.v-tabs--vertical {
  height: 100%;
}
</style>
