<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon v-if="address" left>mdi-pencil</v-icon>
        <v-icon v-else left>mdi-plus</v-icon>
        {{ modalTitle }}
      </v-toolbar-title>
    </v-toolbar>
    <v-form v-model="isFormValid" class="pa-4">
      <v-text-field
        v-model="form.name"
        autofocus
        required
        clearable
        type="text"
        maxlength="50"
        counter
        :label="$t('common.labels.name')"
        :rules="[formRules.required]"/>
      <v-text-field
        v-model="form.street"
        required
        clearable
        type="text"
        maxlength="255"
        counter
        :label="$t('common.labels.address')"
        :rules="[formRules.required]"/>
      <v-text-field
        v-model="form.zip_code"
        required
        clearable
        type="text"
        maxlength="10"
        counter
        :label="$t('common.labels.zipCodePerso')"
        :rules="[
          formRules.zipCode,
          formRules.required
        ]"/>
      <v-text-field
        v-model="form.city"
        required
        clearable
        type="text"
        maxlength="100"
        counter
        :label="$t('common.labels.city')"
        :rules="[formRules.required]"/>
      <v-text-field
        v-model="form.country"
        required
        clearable
        type="text"
        maxlength="100"
        counter
        :label="$t('common.labels.country')"
        :rules="[formRules.required]"/>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
export default {
  name: 'ContactCompanyAddressFormDialog',
  props: {
    address: {
      type: Object,
      default: () => { return null },
    },
  },
  computed: {
    modalTitle () {
      if (this.address) return `${this.$t('common.buttons.edit')} ${this.$t('common.labels.address')}`
      return `${this.$t('common.buttons.create')} ${this.$t('common.labels.address')}`
    },
    actions () {
      return {
        false: {
          text: this.$t('common.buttons.cancel'),
        },
        true: {
          text: this.$t('common.buttons.save'),
          color: 'green',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
  data () {
    return {
      form: {
        name: this.address ? this.address.name : '',
        street: this.address ? this.address.street : '',
        zip_code: this.address ? this.address.zip_code : '',
        city: this.address ? this.address.city : '',
        country: this.address ? this.address.country : '',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || `${this.$t('common.labels.required')}.`,
        zipCode: value => {
          const pattern = /^([0-9]{3,10})$/
          return !value || pattern.test(value) || `${this.$t('common.errors.invalidPostalCode')}.`
        },
      },
    }
  },
}
</script>

<style scoped>
  .v-card::v-deep
    .v-card__text {
      padding: 0;
    }
</style>
