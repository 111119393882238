<template>
  <statistic-wrapper
    :height="height"
    statTitle="Performers">
    <div v-if="!topPerformers.length" :style="{'height': 'calc(100% - 72px)'}">
      <v-row class="my-0" :style="{'height': '100%'}">
        <v-col class="d-flex justify-center align-center font-weight-bold" cols="12">
          <v-chip class="font-weight-bold">
            There are no performers to show yet
          </v-chip>
        </v-col>
      </v-row>
    </div>
    <echart v-else :option="chartOptions"/>
  </statistic-wrapper>
</template>

<script>
import StatisticWrapper from '@/common/components/StatisticWrapper'
export default {
  name: 'TeamTopPerformers',
  components: {
    StatisticWrapper,
  },
  props: {
    height: { type: Number },
    topPerformers: { type: Array },
  },
  computed: {
    series: function () {
      return [{
        name: 'Closed Tickets',
        type: 'bar',
        data: this.topPerformers.map(user => user.helpdesk_assigned_tickets_closed_count),
        label: {
          show: true,
          fontWeight: 'bolder',
        },
      }]
    },
    categories: function () {
      const performerNames = this.topPerformers.map(user => user.full_name)
      let categories = []
      for (let name of performerNames) {
        categories.push({
          value: name,
          textStyle: {
            fontWeight: 'bolder',
            fontSize: 12,
          },
        })
      }
      return categories
    },
    chartOptions: function () {
      return {
        grid: {
          left: '5%',
          right: '5%',
          bottom: '10%',
          top: '5%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          show: false,
        },
        yAxis: {
          type: 'category',
          data: this.categories,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontWeight: 'bolder',
          },
        },
        color: [
          '#039BE5',
        ],
        series: this.series,
      }
    },
  },
}
</script>

<style scoped>
  .chart {
    margin-top: -20px;
  }
</style>
