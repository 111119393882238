import ErrorModal from '../components/ErrorModal'
import router from '../../router/index'
import { handleUnloggedStatus } from '../../router/tools'

function handleAxiosError (error) {
  if (error.response) {
    switch (error.response.status) {
      case 400:
        showErrorNotification(error.response.data.detail)
        break
      case 401:
        showErrorModal(error.response.data.detail, {
          icon: 'close-octagon',
          title: 'Unauthorized',
          advice: 'Please log again.',
          okHandler: function () {
            return new Promise(resolve => {
              handleUnloggedStatus()
              router.push({ name: 'LoginForm' })
              resolve()
            })
          }
        })
        break
      case 403:
        showErrorModal(error.response.data.detail, {
          icon: 'close-octagon',
          title: 'Permission Denied',
          advice: 'Contact your administrator to get this access.'
        })
        break
      default:
        showErrorModal(error.response.data.detail, {
          title: `Error - ${error.response.status}`
        })
        break
    }
  } else if (error.request) {
    showErrorModal(`${error.message}.`, {
      icon: 'lan-disconnect',
      advice: 'There might be a problem with the server, contact your administrator.'
    })
  } else {
    showErrorModal(`${error.message}.`, {
      advice: error.stack
    })
  }
}

function showErrorNotification (errorMessage) {
  // TODO: Sometimes errorMessage appears to be an object and not a string...
  router.app.$dialog.notify.error(
    errorMessage,
    {
      position: 'top-right',
      border: 'left',
      dense: true,
      timeout: 0,
    }
  )
}

function showErrorModal (errorMessage, modalOptions) {
  router.app.$dialog.show(ErrorModal, {
    persistent: true,
    message: errorMessage,
    ...modalOptions
  })
}

export { handleAxiosError }
export default {
  methods: {
    handleAxiosError
  }
}
