import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class ContactContactService {
  static getContacts = async (urlSearchParams) => {
    try {
      const response = await http.get('contact/contacts', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getContact = async (id) => {
    try {
      const response = await http.get(`contact/contacts/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createContact = async (data) => {
    try {
      const response = await http.post('contact/contacts/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateContact = async (id, data) => {
    try {
      const response = await http.patch(`contact/contacts/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteContacts = async (data) => {
    try {
      const response = await http.post('contact/contacts/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createUserForContact = async (id, data) => {
    try {
      const response = await http.post(`contact/contacts/${id}/create-user`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getContactsInput = async () => {
    try {
      const response = await http.get(`contact/contacts/input`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getPortalContactsInput = async () => {
    try {
      const response = await http.get('contact/portal/contacts/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static archiveContact = async (id) => {
    try {
      const response = await http.patch(`contact/contacts/${id}/archive`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static unarchiveContact = async (id) => {
    try {
      const response = await http.patch(`contact/contacts/${id}/unarchive`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
