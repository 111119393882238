import HelpdeskTicketFormCreate from '@/views/helpdesk/ticket/HelpdeskTicketFormCreate'
import HelpdeskTicketFormRead from '@/views/helpdesk/ticket/HelpdeskTicketFormRead'
import HelpdeskTicketKanban from '@/views/helpdesk/ticket/HelpdeskTicketKanban'
import HelpdeskTicketList from '@/views/helpdesk/ticket/HelpdeskTicketList'
import HelpdeskTicketListingToolbar from '@/views/helpdesk/ticket/shared/HelpdeskTicketListingToolbar'
import HelpdeskTicketSubtaskListing from '@/views/helpdesk/subtask/HelpdeskTicketSubtaskListing'

export default [
  {
    path: 'tickets',
    name: 'HelpdeskTicketListingToolbar',
    component: HelpdeskTicketListingToolbar,
    meta: {
      type: 'toolbar',
      requiresAuth: true,
      permission: 'view_helpdeskticket',
    },
    children: [
      {
        path: 'list',
        name: 'HelpdeskTicketList',
        component: HelpdeskTicketList,
        meta: {
          type: 'list',
          requiresAuth: true,
          permission: 'view_helpdeskticket',
        },
      },
    ],
  },
  {
    path: 'tasks',
    name: 'HelpdeskTicketSubtaskListing',
    component: HelpdeskTicketSubtaskListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_helpdeskticketsubtask',
    },
  },
  {
    path: 'teams/:helpdeskTeamId/tickets',
    name: 'HelpdeskTicketKanban',
    component: HelpdeskTicketKanban,
    props: true,
    meta: {
      requiresAuth: true,
      permission: 'view_helpdeskticket',
    },
  },
  {
    path: 'tickets/create',
    name: 'HelpdeskTicketFormCreate',
    component: HelpdeskTicketFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_helpdeskticket',
    },
  },
  {
    path: 'tickets/:helpdeskTicketId',
    name: 'HelpdeskTicketFormRead',
    component: HelpdeskTicketFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_helpdeskticket',
    },
  }
]
