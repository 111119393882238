<template>
  <v-card tile elevation="3">
    <v-toolbar
      v-if="
        category
        && !editing
        && (
          hasPermission('add_inventoryitemcategory')
          || hasPermission('change_inventoryitemcategory')
          || hasPermission('delete_inventoryitemcategory')
        )
      "
      flat
      dense>
      <v-toolbar-items>
        <v-btn
          v-if="hasPermission('change_inventoryitemcategory')"
          text
          @click="edit()">
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          v-if="hasPermission('delete_inventoryitemcategory')"
          text
          @click="deleteCategory(category)">
          <v-icon small class="mr-1">mdi-delete</v-icon>
          Delete
        </v-btn>
        <v-btn
          v-if="hasPermission('add_inventoryitemcategory')"
          text
          :to="{ name: 'InventoryItemCategoryFormCreate' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          Create Another
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider v-if="category"/>
    <template v-if="!loadingCategory">
      <v-form
        v-model="isFormValid"
        class="pa-4"
        :disabled="!editing">
        <v-text-field
          v-model="form.name"
          label="Name"
          required
          clearable
          autofocus
          type="text"
          maxlength="255"
          :counter="editing"
          :rules="[formRules.required]"/>
      </v-form>
      <template v-if="editing">
        <v-divider/>
        <v-card-actions>
          <v-btn text @click="cancel()">
            Cancel
          </v-btn>
          <v-btn
            v-if="hasPermission('change_inventoryitemcategory') || hasPermission('add_inventoryitemcategory')"
            text
            color="green"
            :disabled="!isFormValid"
            @click="!category ? createCategory() : updateCategory(category)">
            Save
          </v-btn>
        </v-card-actions>
      </template>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-card>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DeleteModal from '@/common/components/DeleteModal'
import InventoryItemCategoryService from '@/services/inventory/inventory.item.category.service.js'
export default {
  name: 'InventoryItemCategoryForm',
  components: {
    HRequestLoading
  },
  props: {
    editing: { type: Boolean },
    categoryId: {
      type: [String, Number],
      default: () => { return null }
    }
  },
  data () {
    return {
      loadingCategory: false,
      category: null,
      form: {
        name: ''
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || 'Required.'
      }
    }
  },
  async mounted () {
    if (this.categoryId) await this.getCategory(this.categoryId)
    if (this.category) this.initFormFields()
  },
  methods: {
    async getCategory (categoryId) {
      this.loadingCategory = true
      this.category = await InventoryItemCategoryService.getCategory(categoryId)
      this.loadingCategory = false
    },
    initFormFields () {
      for (let field in this.form) {
        this.form[field] = this.category[field]
      }
    },
    edit () {
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.category) {
        this.initFormFields()
      } else {
        history.back()
      }
    },
    async createCategory () {
      const { name } = this.$data.form
      const createdCategory = await InventoryItemCategoryService.createCategory({
        name: name
      })
      if (createdCategory) {
        this.cancelEdit()
        this.$router.push({ name: 'InventoryItemCategoryFormRead', params: { categoryId: createdCategory.id } })
        this.$dialog.notify.success(
          `Category '${createdCategory.name}' created`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000
          }
        )
      }
    },
    async updateCategory (category) {
      const { name } = this.$data.form
      const updatedCategory = await InventoryItemCategoryService.updateCategory(category.id, {
        name: name
      })
      if (updatedCategory) {
        this.category = updatedCategory
        this.$dialog.notify.success(
          'Category updated', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000
          }
        )
        this.cancelEdit()
      }
    },
    async deleteCategory (category) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Category',
        recordNamePlural: 'Categories',
        persistent: true,
        waitForResult: true
      }).then(async yes => {
        if (yes) {
          const response = await InventoryItemCategoryService.deleteCategories({ ids: [category.id] })
          if (response) {
            this.$router.push({ name: 'InventoryItemCategoryListing' })
            this.$dialog.notify.success(
              `Category '${category.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    }
  }
}
</script>
