import ContactContactTagFormCreate from '@/views/contact/tag/ContactContactTagFormCreate'
import ContactContactTagFormRead from '@/views/contact/tag/ContactContactTagFormRead'
import ContactContactTagListing from '@/views/contact/tag/ContactContactTagListing'

export default [
  {
    path: 'tags',
    name: 'ContactContactTagListing',
    component: ContactContactTagListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_contactcontacttag',
    },
  },
  {
    path: 'tags/create',
    name: 'ContactContactTagFormCreate',
    component: ContactContactTagFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_contactcontacttag',
    },
  },
  {
    path: 'tags/:tagId',
    name: 'ContactContactTagFormRead',
    component: ContactContactTagFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_contactcontacttag',
    },
  },
]
