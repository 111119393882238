import HelpdeskTicketService from '@/services/helpdesk/helpdesk.ticket.service'

export default {
  state: () => ({
    selectedHelpdeskTickets: [],
    currentTicketLogs: [],
    currentTicketMessages: [],
    initialTicketFormTimer: 0,
  }),
  mutations: {
    SET_SELECTED_HELPDESK_TICKETS: (state, tickets) => {
      state.selectedHelpdeskTickets = tickets
    },
    SET_CURRENT_TICKET_LOGS: (state, logs) => {
      state.currentTicketLogs = logs
    },
    SET_CURRENT_TICKET_MESSAGES: (state, messages) => {
      state.currentTicketMessages = messages
    },
    ADD_LOG_TO_TICKET_LOGS: (state, log) => {
      state.currentTicketLogs.unshift(log)
    },
    ADD_MESSAGE_TO_TICKET_MESSAGES: (state, message) => {
      state.currentTicketMessages.unshift(message)
    },
    REMOVE_MESSAGE_FROM_TICKET_MESSAGES: (state, message) => {
      state.currentTicketMessages.splice(state.currentTicketMessages.indexOf(message), 1)
    },
    UPDATE_INITIAL_TICKET_FORM_TIMER: (state, time) => {
      state.initialTicketFormTimer = Math.max(0, time)
    },
    INCREMENT_INITIAL_TICKET_FORM_TIMER: (state, time) => {
      state.initialTicketFormTimer = Math.max(0, state.initialTicketFormTimer + time)
    },
    DECREMENT_INITIAL_TICKET_FORM_TIMER: (state, time) => {
      state.initialTicketFormTimer = Math.max(0, state.initialTicketFormTimer - time)
    },
    RESET_INITIAL_TICKET_FORM_TIMER: (state) => {
      state.initialTicketFormTimer = 0
    },
  },
  actions: {
    UPDATE_INITIAL_TICKET_FORM_TIMER ({ commit }, time) {
      commit('UPDATE_INITIAL_TICKET_FORM_TIMER', time)
    },
    INCREMENT_INITIAL_TICKET_FORM_TIMER ({ commit }, time) {
      commit('INCREMENT_INITIAL_TICKET_FORM_TIMER', time)
    },
    DECREMENT_INITIAL_TICKET_FORM_TIMER ({ commit }, time) {
      commit('DECREMENT_INITIAL_TICKET_FORM_TIMER', time)
    },
    RESET_INITIAL_TICKET_FORM_TIMER ({ commit }) {
      commit('RESET_INITIAL_TICKET_FORM_TIMER')
    },
    async GET_HELPDESK_TICKET_MESSAGES ({ commit }, ticketId) {
      const messages = await HelpdeskTicketService.getTicketMessages(ticketId)
      if (messages) {
        commit('SET_CURRENT_TICKET_MESSAGES', messages)
      }
    },
    async GET_HELPDESK_TICKET_LOGS ({ commit }, ticketId) {
      const logs = await HelpdeskTicketService.getTicketLogs(ticketId)
      if (logs) {
        commit('SET_CURRENT_TICKET_LOGS', logs)
      }
    },
  }
}
