import ApplicationListing from '@/views/base/application/ApplicationListing'

export default [
  {
    path: 'applications',
    name: 'ApplicationListing',
    component: ApplicationListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_application',
    },
  },
]
