import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class InventoryItemCategoryService {
  static getCategories = async (urlSearchParams) => {
    try {
      const response = await http.get('inventory/categories', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getCategory = async (id) => {
    try {
      const response = await http.get(`inventory/categories/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createCategory = async (data) => {
    try {
      const response = await http.post('inventory/categories/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateCategory = async (id, data) => {
    try {
      const response = await http.patch(`inventory/categories/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getCategoriesInput = async () => {
    try {
      const response = await http.get('inventory/categories/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteCategories = async (data) => {
    try {
      const response = await http.post('inventory/categories/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
