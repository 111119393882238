<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-eye</v-icon>
        Preview Contractor notification for Inventory Items
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs grow>
      <v-tab>Text Preview</v-tab>
      <v-tab>HTML Preview</v-tab>
      <v-divider vertical/>
      <v-tab>Add Extra Context</v-tab>
      <v-tab-item>
        <v-divider/>
        <h-rich-text-editor
          v-model="notificationText"
          editorPadding
          required/>
      </v-tab-item>
      <v-tab-item>
        <v-divider/>
        <span v-html="notificationHTML"></span>
      </v-tab-item>
      <v-tab-item>
        <v-divider/>
        <v-form class="pa-4">
          <h-rich-text-editor
            v-model="form.notificationExtraContext"
            editorBorders
            editorPadding
            historyUndo
            historyRedo
            required
            editable/>
        </v-form>
      </v-tab-item>
    </v-tabs>
    <v-divider/>
  </DialogCard>
</template>

<script>
import HRichTextEditor from '@/common/components/rich-text-editor/HRichTextEditor'
export default {
  name: 'HelpdeskTicketInventoryItemContractorNotificationPreviewDialog',
  components: {
    HRichTextEditor,
  },
  props: {
    notificationText: { type: String },
    notificationHTML: { type: String },
  },
  data () {
    return {
      form: {
        notificationExtraContext: '',
      },
    }
  },
  computed: {
    actions () {
      return {
        false: {
          text: 'Cancel',
        },
        true: {
          text: 'Notify',
          color: 'green',
          disabled: false,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form.notificationExtraContext || '')
            })
          },
        },
      }
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
</style>
