<template>
  <v-row class="my-0">
    <v-col>
      <v-card tile elevation="3">
        <v-row no-gutters class="pa-4">
          <v-col class="d-flex justify-end">
            <span class="caption my-auto">{{ $t('showing') }} <span style="color:#1E88E5">{{ timesheets.length }}</span> {{ $t('of') }} {{ pagination?.total_records }}</span>
            <v-divider
              v-if="pagination?.has_next_page"
              class="mx-4"
              vertical/>
            <v-btn
              v-if="pagination?.has_next_page"
              color="blue darken-1"
              outlined
              :loading="loadingMore"
              @click="$emit('loadNextTimesheetPage')">
              <v-icon left>mdi-download</v-icon>
              {{ $t('common.buttons.loadMore') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
        <v-data-table
          v-model="selectedTimesheets"
          multi-sort
          checkbox-color="blue darken-2"
          :loading="loading"
          :show-select="hasPermission('delete_timesheetitem')"
          :headers="tableHeaders"
          :items="timesheets"
          :hide-default-footer="timesheets.length < 10">
          <template v-slot:loading>
            <div class="my-12">{{ $t('loading') }}...</div>
          </template>
          <template v-slot:no-data>
            <h-no-records-found/>
          </template>
          <template v-slot:[`item.description`]="{ item }">
            {{ item.description | truncate(40, '...') }}
          </template>
          <template v-slot:[`item.author.full_name`]="{ item }">
            <template v-if="item.author">
              <v-avatar size="24px" class="mr-1">
                <v-img :src="item.author.picture ? getPictureURL(item.author.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
              </v-avatar>
              {{ item.author.full_name }}
            </template>
            <template v-else-if="item.author_full_name">
              <v-avatar size="24px" class="mr-1">
                <v-img :src="require('@/assets/images/no-user-picture.jpeg')"/>
              </v-avatar>
              {{ item.author_full_name }}
            </template>
          </template>
          <template v-slot:[`item.assigned_user.full_name`]="{ item }">
            <template v-if="item.assigned_user">
              <v-avatar size="24px" class="mr-1">
                <v-img :src="item.assigned_user.picture ? getPictureURL(item.assigned_user.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
              </v-avatar>
              {{ item.assigned_user.full_name }}
            </template>
            <template v-else-if="item.assigned_user_full_name">
              <v-avatar size="24px" class="mr-1">
                <v-img :src="require('@/assets/images/no-user-picture.jpeg')"/>
              </v-avatar>
              {{ item.assigned_user_full_name }}
            </template>
          </template>
          <template v-slot:[`item.content_type`]="{ item }">
            <v-btn
              v-if="item.content_type === 'helpdeskticket'"
              text
              color="blue darken-1"
              :ripple="false"
              :to="{ name: 'HelpdeskTicketFormRead', params: { helpdeskTicketId: item.object_id }}">
              Ticket #{{ item.object_id }}
            </v-btn>
          </template>
          <template v-slot:[`item.duration`]="{ item }">
            {{ timesheetDuration(item) }}
          </template>
          <template v-slot:[`item.start_datetime`]="{ item }">
            {{ item.start_datetime | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template v-slot:[`item.end_datetime`]="{ item }">
            {{ item.end_datetime | moment('YYYY-MM-DD HH:mm') }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="hasPermission('delete_timesheetitem')" bottom open-delay="250">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="$emit('deleteTimesheet', item)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              {{ $t('common.buttons.delete') }}
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
export default {
  name: 'TimesheetItemList',
  components: { HNoRecordsFound },
  props: {
    value: {
      type: Array,
      default: () => { return [] },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    timesheets: {
      type: Array,
      default: () => { return [] },
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  computed: {
    selectedTimesheets: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
    tableHeaders () {
      return [
        { value: 'description', text: this.$t('common.labels.description') },
        { value: 'content_type', text: this.$t('common.labels.source') },
        { value: 'author.full_name', text: this.$t('common.labels.createdBy') },
        { value: 'assigned_user.full_name', text: this.$t('common.labels.assignee') },
        { value: 'customer', text: this.$t('common.labels.customer') },
        { value: 'duration', text: this.$t('common.labels.duration') },
        { value: 'start_datetime', text: this.$t('common.labels.from') },
        { value: 'end_datetime', text: this.$t('common.labels.to') },
        { value: 'actions', text: this.$t('common.labels.actions'), sortable: false },
      ]
    },
  },
  beforeDestroy () {
    this.selectedTimesheets = []
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.selectedTimesheets = []
    },
  },
  methods: {
    timesheetDuration (timesheet) {
      if (timesheet.duration === 0) return 0
      const momentDuration = moment.duration(timesheet.duration, 'seconds')
      let strDuration = ''
      if (momentDuration.minutes()) strDuration = `${momentDuration.minutes()} ${this.$tc('timing.minutes', momentDuration.minutes())}`
      if (momentDuration.hours()) strDuration = `${momentDuration.hours()} ${this.$tc('timing.hours', momentDuration.hours())} ${strDuration}`
      if (momentDuration.days()) strDuration = `${momentDuration.days()} ${this.$tc('timing.days', momentDuration.days())} ${strDuration}`
      return strDuration
    },
  },
}
</script>
