import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import http from './http'
import i18n from './i18n/index'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'

import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { BarChart, GaugeChart } from 'echarts/charts'
import { GridComponent, LegendComponent } from 'echarts/components'

import getPictureURL from '@/common/helpers/getPictureURL'
import hasAccess from '@/common/helpers/hasAccess'
import hasPermission from '@/common/helpers/hasPermission'
import handleAxiosError from '@/common/helpers/handleAxiosError'
import shouldTextBeWhite from '@/common/helpers/shouldTextBeWhite'

Vue.config.productionTip = false

export const eventBus = new Vue()

/*
  -----------------------------------
  *          VUE LIBRARIES          *
  -----------------------------------
*/

Vue.use(VueAxios, http)
Vue.use(require('vue-moment'))

use([
  SVGRenderer,
  BarChart,
  GaugeChart,
  GridComponent,
  LegendComponent,
])

Vue.component('echart', ECharts)

/*
  -----------------------------------
  *          GLOBAL MIXINS          *
  -----------------------------------
*/

Vue.mixin(getPictureURL)
Vue.mixin(hasAccess)
Vue.mixin(hasPermission)
Vue.mixin(handleAxiosError)
Vue.mixin(shouldTextBeWhite)

/*
  ------------------------------------
  *          GLOBAL FILTERS          *
  ------------------------------------
*/

Vue.filter('truncate', function (text, length, suffix) {
  if (text.length > length) return text.substring(0, length) + suffix
  return text
})

Vue.filter('humanFileSize', function (size) {
  var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1000))
  return (size / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + ['Bytes', 'kB', 'MB', 'GB', 'TB'][i]
})

/*
  ----------------------------------
  *          VUE INSTANCE          *
  ----------------------------------
*/

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
