import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class InventoryItemService {
  static getItem = async (id) => {
    try {
      const response = await http.get(`inventory/items/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getPortalItems = async (urlSearchParams) => {
    try {
      const response = await http.get('inventory/portal/items', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getItems = async (urlSearchParams) => {
    try {
      const response = await http.get('inventory/items', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createItem = async (data) => {
    try {
      const response = await http.post('inventory/items/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateItem = async (id, data) => {
    try {
      const response = await http.patch(`inventory/items/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteItems = async (data) => {
    try {
      const response = await http.post('inventory/items/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
