import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class HelpdeskDashboardService {
  static getDashboard = async () => {
    try {
      const response = await http.get('helpdesk/dashboard')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateDashboard = async (data) => {
    try {
      const response = await http.patch('helpdesk/dashboard/update', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getDashboardTeams = async () => {
    try {
      const response = await http.get('helpdesk/dashboard/teams')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateTeamConfiguration = async (id, data) => {
    try {
      const response = await http.patch(`helpdesk/dashboard/teams/${id}/update-configuration`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
