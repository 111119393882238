import HelpdeskTeamFormCreate from '@/views/helpdesk/team/HelpdeskTeamFormCreate'
import HelpdeskTeamFormRead from '@/views/helpdesk/team/HelpdeskTeamFormRead'
import HelpdeskTeamListing from '@/views/helpdesk/team/HelpdeskTeamListing'

export default [
  {
    path: 'teams',
    name: 'HelpdeskTeamListing',
    component: HelpdeskTeamListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_helpdeskteam',
    },
  },
  {
    path: 'teams/create',
    name: 'HelpdeskTeamFormCreate',
    component: HelpdeskTeamFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_helpdeskteam',
    },
  },
  {
    path: 'teams/:helpdeskTeamId',
    name: 'HelpdeskTeamFormRead',
    component: HelpdeskTeamFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_helpdeskteam',
    },
  },
]
