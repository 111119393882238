import store from '../../store/index'

function hasPermission (permCodename) {
  if (store.state.login.me) {
    return store.state.login.me.permissions?.includes(permCodename)
  }
  return false
}

export { hasPermission }
export default {
  methods: {
    hasPermission
  }
}
