import InventoryItemContractorFormCreate from '@/views/inventory/contractor/InventoryItemContractorFormCreate'
import InventoryItemContractorFormRead from '@/views/inventory/contractor/InventoryItemContractorFormRead'
import InventoryItemContractorListing from '@/views/inventory/contractor/InventoryItemContractorListing'

export default [
  {
    path: 'contractors',
    name: 'InventoryItemContractorListing',
    component: InventoryItemContractorListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_inventoryitemcontractor',
    },
  },
  {
    path: 'contractor/create',
    name: 'InventoryItemContractorFormCreate',
    component: InventoryItemContractorFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_inventoryitemcontractor',
    },
  },
  {
    path: 'contractor/:contractorId',
    name: 'InventoryItemContractorFormRead',
    component: InventoryItemContractorFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_inventoryitemcontractor',
    },
  },
]
