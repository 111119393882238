import GroupFormCreate from '@/views/base/group/form/GroupFormCreate'
import GroupFormRead from '@/views/base/group/form/GroupFormRead'
import GroupListing from '@/views/base/group/list/GroupListing'

export default [
  {
    path: 'groups',
    name: 'GroupListing',
    component: GroupListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_group',
    },
  },
  {
    path: 'groups/create',
    name: 'GroupFormCreate',
    component: GroupFormCreate,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'add_group',
    },
  },
  {
    path: 'groups/:groupId',
    name: 'GroupFormRead',
    component: GroupFormRead,
    props: true,
    meta: {
      type: 'form',
      requiresAuth: true,
      permission: 'view_group',
    },
  },
]
