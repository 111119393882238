<template>
  <v-dialog
    v-model="showDialog"
    ref="datetimePickerDialog"
    width="400px"
    @click:outside="closeDialog">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        v-show="show"
        :value="datetimeFormatted"
        :label="label"
        :clearable="clearable"
        :rules="rules"
        readonly
        prepend-icon="mdi-calendar-clock"/>
    </template>
    <v-card>
      <v-toolbar height="36" color="primary" dark flat>
        <v-tabs v-model="tab" grow height="36">
          <v-tab href="#date">
            <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab href="#time">
            <v-icon>mdi-clock</v-icon>
          </v-tab>
          <template v-if="clearable">
            <v-divider vertical/>
            <v-tooltip bottom open-delay="50">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text @click="clear">
                  <v-icon>mdi-calendar-remove</v-icon>
                </v-btn>
              </template>
              Clear
            </v-tooltip>
          </template>
        </v-tabs>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="date">
          <v-date-picker
            v-model="date"
            first-day-of-week="1"
            full-width
            class="rounded-0"
            :min="minDate"
            :max="maxDate"
            @input="tab = 'time'"/>
        </v-tab-item>
        <v-tab-item value="time">
          <v-time-picker
            v-model="time"
            :min="minTime"
            :max="maxTime"
            format="24hr"
            class="rounded-0"
            full-width
            @click:minute="apply"/>
        </v-tab-item>
      </v-tabs-items>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="closeDialog">Cancel</v-btn>
        <v-btn text color="success" @click="apply">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment-timezone'
export default {
  name: 'HDatetimePicker',
  props: {
    label: { type: String },
    value: { type: String },
    show: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    rules: { type: Array, default: () => { return [] } },
    minDate: { type: String, default: '' }, // Must be format YYYY-MM-DD
    maxDate: { type: String, default: '' }, // Must be format YYYY-MM-DD
    minTime: { type: String, default: '' }, // Must be format HH:mm
    maxTime: { type: String, default: '' }, // Must be format HH:mm
  },
  watch: {
    showDialog: function (val) {
      if (val && this.value) {
        // Load into separated value as dialog opens
        this.date = moment(this.value).format(this.dateFormat)
        this.time = moment(this.value).format(this.timeFormat)
      }
    },
  },
  data () {
    return {
      dateFormat: 'YYYY-MM-DD',
      timeFormat: 'HH:mm',
      date: '',
      time: '',
      tab: false,
      showDialog: false,
    }
  },
  computed: {
    datetimeFormat () {
      return `${this.dateFormat} ${this.timeFormat}`
    },
    datetimeFormatted () {
      if (this.value) return moment(this.value).format(this.datetimeFormat)
      return ''
    },
  },
  methods: {
    clear () {
      this.date = ''
      this.time = ''
      this.tab = 'date'
    },
    apply () {
      if (this.date && this.time) {
        const clientTZ = moment.tz.guess()
        const datetimeTZ = moment(`${this.date} ${this.time}`).tz(clientTZ).format()
        this.$emit('input', datetimeTZ)
      } else {
        this.$emit('input', null)
      }
      this.closeDialog()
    },
    closeDialog() {
      this.showDialog = false;
    },
  }
}
</script>
