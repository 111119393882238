<template>
  <v-card tile elevation="3">
    <v-toolbar
      v-if="item && !editing"
      flat
      dense>
      <v-toolbar-items>
        <v-btn
          v-if="hasPermission('change_inventoryitem')"
          text
          @click="edit()">
          <v-icon small class="mr-1">mdi-pencil</v-icon>
          Edit
        </v-btn>
        <v-btn
          v-if="hasPermission('delete_inventoryitem')"
          text
          @click="deleteItem(item)">
          <v-icon small class="mr-1">mdi-delete</v-icon>
          Delete
        </v-btn>
        <v-btn
          v-if="hasPermission('add_inventoryitem')"
          text
          :to="{ name: 'InventoryItemFormCreate' }">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          Create Another
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider v-if="item"/>
    <v-tabs vertical background-color="grey lighten-4">
      <v-tab>
        <v-tooltip right open-delay="250">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-form-select</v-icon>
          </template>
          Item
        </v-tooltip>
      </v-tab>
      <v-tab-item>
        <template v-if="!loadingItem">
          <v-form
            v-model="isFormValid"
            class="pa-4"
            :disabled="!editing">
            <v-text-field
              v-model="form.name"
              label="Name"
              required
              clearable
              autofocus
              type="text"
              maxlength="255"
              :counter="editing"
              :rules="[formRules.required]"/>
            <v-row class="my-0">
              <v-col cols=12 sm=4>
                <v-text-field
                  v-model="form.mac_address"
                  label="MAC Address"
                  clearable
                  type="text"
                  maxlength="17"
                  :counter="editing"
                  :rules="[formRules.macAddress]"/>
              </v-col>
              <v-col cols=12 sm=4>
                <v-text-field
                  v-model="form.serial_number"
                  label="Serial Number"
                  clearable
                  type="text"
                  maxlength="255"
                  :counter="editing"/>
              </v-col>
              <v-col cols=12 sm=4>
                <v-text-field
                  v-model="form.part_number"
                  label="Part Number"
                  clearable
                  type="text"
                  maxlength="255"
                  :counter="editing"/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <!-- <v-col cols=12 sm=4>
                <v-text-field
                  v-model="form.contract_reference"
                  label="Contract Reference"
                  clearable
                  type="text"
                  maxlength="255"
                  :counter="editing"/>
              </v-col> -->
            </v-row>
            <v-row class="my-0">
              <v-col cols=12 sm=4>
                <v-autocomplete
                  v-model="form.category"
                  label="Category"
                  clearable
                  :loading="loadingCategoriesInput"
                  :items="categories"
                  item-text="name"
                  return-object
                  @click="getCategoriesInput"/>
              </v-col>
              <v-col cols=12 sm=4>
                <v-autocomplete
                  v-model="form.supplier"
                  label="Supplier"
                  clearable
                  :loading="loadingSuppliersInput"
                  :items="suppliers"
                  item-text="name"
                  return-object
                  @click="getSuppliersInput"/>
              </v-col>
              <v-col cols=12 sm=4>
                <v-autocomplete
                  v-model="form.contractor"
                  label="Contractor"
                  clearable
                  :loading="loadingContractorsInput"
                  :items="contractors"
                  item-text="name"
                  return-object
                  @click="getContractorsInput"/>
              </v-col>
            </v-row>
            <v-row class="my-0">
              <v-col cols=12 sm=6>
                <v-autocomplete
                  label="Company"
                  v-model="form.company"
                  clearable
                  :loading="loadingCompaniesInput"
                  :items="companies"
                  item-text="name"
                  return-object
                  @click="getCompaniesInput"
                  @change="changeCompanySelection()"/>
              </v-col>
              <v-col cols=12 sm=6>
                <v-autocomplete
                  label="Contact"
                  v-if="form.company"
                  v-model="form.contact"
                  clearable
                  :loading="loadingCompanyContactsInput"
                  :items="companyContacts"
                  item-text="full_name"
                  return-object
                  @click="getCompanyContactsInput(form.company)"/>
              </v-col>
            </v-row>
          </v-form>
          <template v-if="editing">
            <v-divider/>
            <v-card-actions>
              <v-btn
                text
                @click="cancel()">
                Cancel
              </v-btn>
              <v-btn
                v-if="hasPermission('add_inventoryitem') || hasPermission('change_inventoryitem')"
                text
                color="green"
                :disabled="!isFormValid"
                @click="!item ? createItem() : updateItem(item)">
                Save
              </v-btn>
            </v-card-actions>
          </template>
        </template>
        <template v-else>
          <v-col cols=12 align="center" justify="center">
            <h-request-loading/>
          </v-col>
        </template>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DeleteModal from '@/common/components/DeleteModal'
import ContactCompanyService from '@/services/contact/contact.company.service'
import InventoryItemService from '@/services/inventory/inventory.item.service'
import InventoryItemCategoryService from '@/services/inventory/inventory.item.category.service'
import InventoryItemContractorService from '@/services/inventory/inventory.item.contractor.service'
import InventoryItemSupplierService from '@/services/inventory/inventory.item.supplier.service'
export default {
  name: 'InventoryItemForm',
  components: {
    HRequestLoading,
  },
  props: {
    editing: { type: Boolean },
    itemId: {
      type: [String, Number],
      default: () => { return null },
    },
  },
  data () {
    return {
      loadingItem: false,
      item: null,
      form: {
        name: '',
        serial_number: '',
        part_number: '',
        mac_address: '',
        contract_reference: '',
        category: null,
        company: null,
        contact: null,
        supplier: null,
        contractor: null,
      },
      formRules: {
        required: value => !!value || 'Required.',
        macAddress: value => {
          const pattern = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/
          return !value || pattern.test(value) || 'Invalid MAC Address.'
        }
      },
      isFormValid: false,
      loadingCategoriesInput: false,
      loadingContractorsInput: false,
      loadingSuppliersInput: false,
      loadingCompaniesInput: false,
      loadingCompanyContactsInput: false,
      categories: [],
      contractors: [],
      suppliers: [],
      companies: [],
      companyContacts: [],
    }
  },
  async mounted () {
    if (this.itemId) await this.getItem(this.itemId)
    if (this.item) this.initFormFields()
  },
  methods: {
    async getItem (itemId) {
      this.loadingItem = true
      this.item = await InventoryItemService.getItem(itemId)
      this.loadingItem = false
    },
    async getCategoriesInput () {
      this.loadingCategoriesInput = true
      this.categories = await InventoryItemCategoryService.getCategoriesInput()
      this.loadingCategoriesInput = false
    },
    async getContractorsInput () {
      this.loadingContractorsInput = true
      this.contractors = await InventoryItemContractorService.getContractorsInput()
      this.loadingContractorsInput = false
    },
    async getSuppliersInput () {
      this.loadingSuppliersInput = true
      this.suppliers = await InventoryItemSupplierService.getSuppliersInput()
      this.loadingSuppliersInput = false
    },
    async getCompaniesInput () {
      this.loadingCompaniesInput = true
      this.companies = await ContactCompanyService.getCompaniesInput()
      this.loadingCompaniesInput = false
    },
    async getCompanyContactsInput (company) {
      this.loadingCompanyContactsInput = true
      this.companyContacts = await ContactCompanyService.getCompanyContactsInput(company.id)
      this.loadingCompanyContactsInput = false
    },
    changeCompanySelection () {
      this.form.contact = null
      this.companyContacts = []
    },
    initFormFields () {
      for (let field in this.form) {
        if (field === 'category') this.categories = [this.item[field]]
        if (field === 'contractor') this.contractors = [this.item[field]]
        if (field === 'supplier') this.suppliers = [this.item[field]]
        if (field === 'company') this.companies = [this.item[field]]
        if (field === 'contact') this.companyContacts = [this.item[field]]
        this.form[field] = this.item[field]
      }
    },
    edit () {
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.item) {
        this.initFormFields()
      } else {
        history.back()
      }
    },
    async createItem () {
      const {
        name,
        serial_number,
        part_number,
        mac_address,
        contract_reference,
        category,
        company,
        contact,
        contractor,
        supplier,
      } = this.$data.form
      const createdItem = await InventoryItemService.createItem({
        name: name,
        serial_number: serial_number,
        part_number: part_number,
        mac_address: mac_address,
        contract_reference: contract_reference,
        category_id: category ? category.id : null,
        company_id: company ? company.id : null,
        contact_id: company && contact ? contact.id : null,
        contractor_id: contractor ? contractor.id : null,
        supplier_id: supplier ? supplier.id : null,
      })
      if (createdItem) {
        this.cancelEdit()
        this.$router.push({
          name: 'InventoryItemFormRead',
          params: {
            itemId: createdItem.id,
          }
        })
        this.$dialog.notify.success(
          'Item created', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async updateItem (item) {
      const {
        name,
        serial_number,
        part_number,
        mac_address,
        contract_reference,
        category,
        company,
        contact,
        contractor,
        supplier,
      } = this.$data.form
      const updatedItem = await InventoryItemService.updateItem(item.id, {
        name: name,
        serial_number: serial_number,
        part_number: part_number,
        mac_address: mac_address,
        contract_reference: contract_reference,
        category_id: category ? category.id : null,
        company_id: company ? company.id : null,
        contact_id: company && contact ? contact.id : null,
        contractor_id: contractor ? contractor.id : null,
        supplier_id: supplier ? supplier.id : null,
      })
      if (updatedItem) {
        this.item = updatedItem
        this.$dialog.notify.success(
          'Item updated', {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
        this.cancelEdit()
      }
    },
    deleteItem (item) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Item',
        recordNamePlural: 'Items',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await InventoryItemService.deleteItems({ ids: [item.id] })
          if (response) {
            this.$dialog.notify.success(
              `Item '${item.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
            this.$router.push({ name: 'InventoryItemListing' })
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>
