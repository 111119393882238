<template>
	<v-main>
		<v-container fluid class="view-container">
      <v-toolbar tile elevation=1 class="mb-4">
        <v-spacer/>
        <h-filtering-bar :availableFilters="availableFilters"/>
        <v-spacer/>
			</v-toolbar>
			<v-card tile elevation="3">
        <v-row no-gutters class="pa-4">
          <v-col class="d-flex justify-end">
            <span class="caption my-auto">Showing <span style="color:#1E88E5">{{ items.length }}</span> of {{ pagination?.total_records }}</span>
            <v-divider
              v-if="pagination?.has_next_page"
              class="mx-4"
              vertical/>
            <v-btn
              v-if="pagination?.has_next_page"
              color="blue darken-1"
              outlined
              :loading="loadingMore"
              @click="loadNextItemPage">
              <v-icon left>mdi-download</v-icon>
              Load more
            </v-btn>
          </v-col>
        </v-row>
        <v-divider/>
				<v-data-table
					multi-sort
          :loading="loading"
					:headers="headers"
					:items="items"
          :items-per-page=10
          :hide-default-footer="items.length < 11"
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
            'show-first-last-page': true,
          }">
          <template v-slot:loading>
            <div class="my-12">Loading...</div>
          </template>
				</v-data-table>
			</v-card>
		</v-container>
	</v-main>
</template>

<script>
import HFilteringBar from '@/common/components/HFilteringBar'
import InventoryItemService from '@/services/inventory/inventory.item.service'
export default {
	name: 'HelpdeskPortalInventoryItemList',
  components: { HFilteringBar },
	data () {
		return {
      loading: false,
      loadingMore: false,
      pagination: null,
			headers: [
				{ value: 'name', text: 'Name' },
        { value: 'category.name', text: 'Category' },
        { value: 'serial_number', text: 'S/N' },
        { value: 'part_number', text: 'P/N' },
				{ value: 'contact.full_name', text: 'Contact' },
			],
			items: [],
      availableFilters: [
        { fieldName: 'name', label: 'Name', type: 'string' },
        { fieldName: 'category', label: 'Category', type: 'string' },
        { fieldName: 'serial_number', label: 'Serial Number', type: 'string' },
        { fieldName: 'part_number', label: 'Part Number', type: 'string' },
        { fieldName: 'contact', label: 'Contact', type: 'string' },
      ],
		}
	},
	async mounted () {
    await this.getPortalItems(this.$store.state.url.urlSearchParams)
	},
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getPortalItems(this.$store.state.url.urlSearchParams)
    },
  },
	methods: {
    async getPortalItems (urlSearchParams) {
			this.loading = true
			const responseData = await InventoryItemService.getPortalItems(urlSearchParams)
			this.items = responseData.items
      this.pagination = responseData.pagination
			this.loading = false
		},
		async loadNextTicketPage () {
      if (this.pagination.has_next_page) {
        this.loadingMore = true
        const nextPageNumber = this.pagination.page_number + 1
        const urlSearchParamsString = this.$store.state.url.urlSearchParams.toString()
        // We do not use the one from the store otherwise the page is stored there..
        const urlSearchParams = new URLSearchParams(urlSearchParamsString)
        urlSearchParams.set('page', nextPageNumber)
        const responseData = await InventoryItemService.getPortalItems(urlSearchParams)
        this.pagination = responseData.pagination
        const newItemPage = responseData.items
        const currentItems = this.items
        const updatedItemList = currentItems.concat(newItemPage)
        this.items = updatedItemList
        this.loadingMore = false
      }
		},
	},
}
</script>

<style scoped>
  .container.view-container {
    height: calc(100% - 48px);
    padding: 16px;
  }
  .v-toolbar {
    height: unset!important;
  }
  .v-toolbar::v-deep .v-toolbar__content {
    height: fit-content!important;
  }
</style>
