<template>
  <v-row class="my-0">
    <v-col cols=12>
      <v-toolbar flat tile color="transparent">
        <v-spacer/>
        <span
          v-if="companies.length"
          class="caption my-auto">
          {{ $t('showing') }} <span style="color:#1E88E5">{{ companies.length }}</span> {{ $t('of') }} {{ pagination?.total_records }}
        </span>
        <v-divider
          v-if="pagination?.has_next_page"
          class="mx-4"
          vertical/>
        <v-btn
          v-if="pagination?.has_next_page"
          color="blue darken-1"
          outlined
          :loading="loadingMore"
          @click="$emit('loadNextCompanyPage')">
          <v-icon left>mdi-download</v-icon>
          {{ $t('common.buttons.loadMore') }}
        </v-btn>
      </v-toolbar>
    </v-col>
    <template v-if="companies.length && !loading">
      <v-col
        cols=12 sm=6 md=3 lg=2
        v-for="company in companies"
        :key="company.id">
        <v-card
          tile
          elevation="3"
          class="company-card"
          :ripple="false"
          @click="onCompanyClick(company)">
          <v-card-title>
            {{ company.name | truncate(20, '...') }}
            <v-spacer/>
            <v-menu
              v-if="hasPermission('delete_contactcompany')"
              close-on-click
              close-on-content-click
              offsetY
              left>
              <!-- TODO: Add close-delay when it work -->
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" tile icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="hasPermission('delete_contactcompany')"
                  @click="$emit('deleteCompany', company)">
                  <v-icon small class="mr-4">mdi-delete</v-icon>
                  {{ $t('common.buttons.delete') }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-subtitle class="pb-0 caption">
            {{ company.domain }}
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon x-small left>mdi-phone</v-icon>
            <template v-if="company.phone">{{ company.phone }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon x-small left>mdi-email</v-icon>
            <template v-if="company.email">{{ company.email }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="pt-1">
            <v-chip
              x-small
              outlined
              class="font-weight-bold"
              :color="company.addresses_count > 0 ? 'primary' : 'grey lighten-2'">
              <v-icon left x-small>
                mdi-office-building-marker
              </v-icon>
              {{ company.addresses_count }}
            </v-chip>
            <v-chip
              x-small
              outlined
              :color="company.contacts_count > 0 ? 'primary' : 'grey lighten-2'"
              class="ml-1 font-weight-bold">
              <v-icon left x-small>
                mdi-account-group
              </v-icon>
              {{ company.contacts_count }}
            </v-chip>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </template>
    <template v-else-if="!companies.length && !loading">
      <v-col cols=12 align="center" justify="center">
        <h-no-records-found createFormViewName="ContactCompanyFormCreate"/>
      </v-col>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import HNoRecordsFound from '@/common/components/HNoRecordsFound'
import HRequestLoading from '@/common/components/HRequestLoading'
export default {
  name: 'ContactCompanyKanban',
  components: {
    HNoRecordsFound,
    HRequestLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    companies: {
      type: Array,
      default: () => { return [] },
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  methods: {
    onCompanyClick (company) {
      this.$router.push({
        name: 'ContactCompanyFormRead',
        params: { companyId: company.id },
      })
    },
  },
}
</script>

<style scoped>
  .company-card {
    transition: all 0.15s;
    cursor: pointer;
  }
  .company-card:hover {
    border: thin solid rgba(0, 0, 0, 0.25);
  }
</style>
