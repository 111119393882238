<template>
  <h-app-main-menu :menuItems="menuItems"/>
</template>

<script>
import HAppMainMenu from '@/common/components/HAppMainMenu'
import { getFirstAuthorizedChildViewNameFromRouteName } from '@/router/tools'
export default {
  name: 'HelpdeskMainMenu',
  components: { HAppMainMenu },
  data () {
    return {
      menuItems: [
        {
          menuLabel: 'Teams',
          menuPermissions: [
            'view_helpdeskteam',
          ],
          routeName: 'HelpdeskTeamListing',
          matchingPaths: [
            '/helpdesk/teams',
          ],
        },
        {
          menuLabel: 'Tickets',
          menuPermissions: [
            'view_helpdeskticket',
          ],
          routeName: 'HelpdeskTicketList',
          matchingPaths: [
            '/helpdesk/tickets',
          ],
        },
        {
          menuLabel: 'Tasks',
          menuPermissions: [
            'view_helpdeskticketsubtask',
          ],
          routeName: 'HelpdeskTicketSubtaskListing',
          matchingPaths: [
            '/helpdesk/tasks',
          ],
        },
        {
          menuLabel: 'Dashboard',
          menuPermissions: [
            'view_helpdeskdashboard',
          ],
          routeName: 'HelpdeskDashboard',
          matchingPaths: [
            '/helpdesk/dashboard',
          ],
        },
        {
          menuLabel: 'Tags',
          menuPermissions: [
            'view_helpdesktickettag',
          ],
          routeName: 'HelpdeskTicketTagListing',
          matchingPaths: [
            '/helpdesk/tags',
          ],
        },
        {
          menuLabel: 'Settings',
          menuPermissions: [
            'view_helpdesksettings',
            'view_helpdeskrecurringtickettask',
          ],
          routeName: getFirstAuthorizedChildViewNameFromRouteName('HelpdeskSettingsMenu'),
          matchingPaths: [
            '/helpdesk/settings',
          ],
        },
      ],
    }
  },
}
</script>
