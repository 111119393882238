<template>
  <v-row class="my-0">
    <template v-if="contractors.length && !loading">
      <v-col
        v-for="contractor in contractors"
        cols=12 sm=6 md=3 lg=2
        :key="contractor.id">
        <v-card
          tile
          elevation="3"
          class="kanban-card"
          :ripple="false"
          @click="onContractorClick(contractor)">
          <v-card-title>
            <span class="subtitle-2">{{ contractor.name | truncate(20, '...')}}</span>
            <v-spacer/>
            <v-menu
              v-if="hasPermission('delete_inventoryitemcontractor')"
              close-on-click
              close-on-content-click
              offsetY
              left>
              <!-- TODO: Add close-delay when it work -->
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" tile icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="hasPermission('delete_inventoryitemcontractor')"
                  @click="$emit('deleteContractor', contractor)">
                  <v-icon small class="mr-4">mdi-delete</v-icon>
                  Delete
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-subtitle class="py-0 caption">
            <v-icon x-small left>mdi-email</v-icon>
            <template v-if="contractor.email">{{ contractor.email }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="py-0 caption">
            <v-icon x-small left>mdi-phone</v-icon>
            <template v-if="contractor.phone">{{ contractor.phone }}</template>
            <template v-else>n/a</template>
          </v-card-subtitle>
          <v-card-subtitle class="pt-1">
            <v-chip
              x-small
              outlined
              class="font-weight-bold"
              :color="contractor.items_count > 0 ? 'green' : 'grey lighten-2'">
              <v-icon left x-small>
                mdi-devices
              </v-icon>
              {{ contractor.items_count }} items
            </v-chip>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </template>
    <template v-else-if="!contractors.length && !loading">
      <v-col cols=12 align="center" justify="center">
        <h-no-records-found createFormViewName="InventoryItemContractorFormCreate"/>
      </v-col>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-row>
</template>

  <script>
  import HNoRecordsFound from '@/common/components/HNoRecordsFound'
  import HRequestLoading from '@/common/components/HRequestLoading'
  export default {
    name: 'InventoryItemContractorKanban',
    components: {
      HNoRecordsFound,
      HRequestLoading,
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      contractors: {
        type: Array,
        default: () => { return [] },
      },
    },
    methods: {
      onContractorClick (contractor) {
        this.$router.push({
          name: 'InventoryItemContractorFormRead',
          params: { contractorId: contractor.id },
        })
      },
    },
  }
  </script>

  <style scoped>
    .kanban-card {
      transition: all 0.15s;
      cursor: pointer;
    }
    .kanban-card:hover {
      border: thin solid rgba(0, 0, 0, 0.25);
    }
  </style>
