<template>
  <inventory-item-category-form
    :categoryId=categoryId
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import InventoryItemCategoryForm from './shared/InventoryItemCategoryForm'
import DialogUnsavedChanges from '../../../common/mixins/DialogUnsavedChanges'
export default {
  name: 'InventoryItemCategoryFormRead',
  mixins: [ DialogUnsavedChanges ],
  components: { InventoryItemCategoryForm },
  props: {
    categoryId: {
      type: [String, Number],
      default: () => { return null }
    }
  },
  data () {
    return {
      formIsEditing: false
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
