<template>
  <helpdesk-team-form
    :helpdeskTeamId=helpdeskTeamId
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import HelpdeskTeamForm from './shared/HelpdeskTeamForm'
import DialogUnsavedChanges from '../../../common/mixins/DialogUnsavedChanges'
export default {
  name: 'HelpdeskTeamFormRead',
  mixins: [ DialogUnsavedChanges ],
  components: { HelpdeskTeamForm },
  props: {
    helpdeskTeamId: {
      type: [Number, String],
      default: () => { return null }
    }
  },
  data () {
    return {
      formIsEditing: false
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
