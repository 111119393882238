<template>
  <v-card tile elevation="3">
    <v-tabs v-model="activeTab" vertical background-color="grey lighten-4">
      <v-tab
        v-for="menu in allowedMenus"
        :key="menu.routeName"
        @click="clickTab(menu)">
        {{ menu.menuLabel }}
      </v-tab>
      <v-tabs-items>
        <router-view/>
      </v-tabs-items>
    </v-tabs>
  </v-card>
</template>

<script>
import { getFirstAuthorizedChildViewNameFromRouteName } from '@/router/tools'
export default {
  name: 'BaseSettingsMenu',
  data () {
    return {
      activeTab: null,
      menuItems: [
        {
          menuLabel: 'Global Schedule',
          menuPermissions: ['view_globalschedule'],
          routeName: 'GlobalScheduleForm',
          matchingPaths: ['/settings/global-schedule'],
        },
        {
          menuLabel: 'Emails',
          menuPermissions: [
            'view_emailsettings',
            'view_outboundfailedmail',
          ],
          routeName: this.getFirstAuthorizedSubmenuRoute('BaseEmailSettingsMenu'),
          matchingPaths: ['/settings/emails'],
        },
        {
          menuLabel: 'Integrations',
          menuPermissions: [
            'view_apikey',
            'view_webhook',
          ],
          routeName: this.getFirstAuthorizedSubmenuRoute('BaseIntegrationsSettingsMenu'),
          matchingPaths: ['/settings/integrations'],
        },
      ],
    }
  },
  mounted () {
    // Because it can't find correct tab itself on refresh, when multiple subroutes.
    this.selectCorrectTab(this.$route.path)
  },
  computed: {
    allowedMenus () {
      return this.menuItems.filter(menu => menu.menuPermissions?.some(permission => this.hasPermission(permission)))
    },
  },
  methods: {
    getFirstAuthorizedSubmenuRoute (sourceRouteName) {
      return getFirstAuthorizedChildViewNameFromRouteName(sourceRouteName)
    },
    selectCorrectTab (referencePath) {
      let menuFound = false
      for (const menu of this.allowedMenus) {
        for (const matchingPath of menu.matchingPaths) {
          if (referencePath.includes(matchingPath)) {
            this.activeTab = this.allowedMenus.indexOf(menu)
            menuFound = true
            break
          }
        }
        if (menuFound) break
      }
    },
    clickTab (menu) {
      this.$router.push({ name: menu.routeName })
    },
  },
}
</script>

<style scoped>
  .v-card, .v-tabs.v-tabs--vertical {
    height: 100%;
  }
</style>
