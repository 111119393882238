<template>
  <div>
    <inventory-item-supplier-listing-toolbar
      :viewType="viewType"
      :selectedSuppliers="selectedSuppliers"
      @setListView="setListView"
      @setKanbanView="setKanbanView"
      @deleteSuppliers="deleteSuppliers"/>
    <transition name="router-anim" mode="out-in">
      <inventory-item-supplier-kanban
        v-if="viewType === 'kanban'"
        :suppliers="suppliers"
        :loading="loading"
        @deleteSupplier="deleteSupplier"/>
      <inventory-item-supplier-list
        v-else
        v-model="selectedSuppliers"
        :suppliers="suppliers"
        :loading="loading"
        @deleteSupplier="deleteSupplier"/>
    </transition>
  </div>
</template>

<script>
import DeleteModal from '@/common/components/DeleteModal'
import InventoryItemSupplierKanban from '@/views/inventory/supplier/InventoryItemSupplierKanban'
import InventoryItemSupplierList from '@/views/inventory/supplier/InventoryItemSupplierList'
import InventoryItemSupplierListingToolbar from '@/views/inventory/supplier/shared/InventoryItemSupplierListingToolbar'
import InventoryItemSupplierService from '@/services/inventory/inventory.item.supplier.service'
export default {
  name: 'InventoryItemSupplierListing',
  components: {
    InventoryItemSupplierKanban,
    InventoryItemSupplierList,
    InventoryItemSupplierListingToolbar,
  },
  data () {
    return {
      loading: false,
      viewType: 'kanban',
      suppliers: [],
      selectedSuppliers: [],
    }
  },
  mounted () {
    this.getSuppliers(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getSuppliers(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setListView () {
      this.viewType = 'list'
    },
    setKanbanView () {
      this.viewType = 'kanban'
    },
    async getSuppliers (urlSearchParams) {
      this.loading = true
      this.suppliers = await InventoryItemSupplierService.getSuppliers(urlSearchParams)
      this.loading = false
    },
    deleteSupplier (supplier) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Supplier',
        recordNamePlural: 'Suppliers',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await InventoryItemSupplierService.deleteSuppliers({ ids: [supplier.id] })
          if (response) {
            this.suppliers.splice(this.suppliers.indexOf(supplier), 1)
            this.selectedSuppliers = []
            this.$dialog.notify.success(
              `Supplier '${supplier.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteSuppliers (suppliersToDelete) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Supplier',
        recordNamePlural: 'Suppliers',
        summaryHeaders: [
          { value: 'name', text: 'Name' },
          { value: 'items_count', text: '# Items' },
        ],
        summaryRecords: suppliersToDelete,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const supplierIdsToDelete = suppliersToDelete.map(supplier => supplier.id)
          const response = await InventoryItemSupplierService.deleteSuppliers({ ids: supplierIdsToDelete })
          if (response) {
            this.suppliers = this.suppliers.filter(undeletedSupplier => !supplierIdsToDelete.includes(undeletedSupplier.id))
            this.selectedSuppliers = []
            this.$dialog.notify.success(
              `${suppliersToDelete.length} ${suppliersToDelete.length > 1 ? 'suppliers' : 'supplier'} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
