<template>
  <div v-if="!loading">
    <v-subheader class="mb-2">
      <h2>Outbound (SMTP)</h2>
    </v-subheader>
    <v-toolbar
      v-if="editing"
      flat
      height=36
      color="grey lighten-4"
      class="mb-6">
      <v-toolbar-items>
        <v-btn
          text
          color="primary"
          :loading="loadingTest"
          :disabled="!isFormValid || !formHasChanges"
          @click="testSettings()">
          Test Connection
        </v-btn>
        <v-divider vertical/>
        <v-btn
          text
          color="green"
          :disabled="!formSuccessfullyTested || !isFormValid"
          @click="updateSettings()">
          Save
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-alert
      v-if="editing && formHasChanges"
      outlined
      text
      dense
      class="mx-4 mt-4 mb-2"
      :type="connectionAlert.type">
      {{ connectionAlert.text }}
    </v-alert>
    <v-form v-model="isFormValid" class="pa-4" :disabled="!editing">
      <v-row>
        <v-col cols=12 lg=6>
          <v-text-field
            v-model="form.host"
            label="Host"
            required
            clearable
            autofocus
            type="text"
            maxlength="255"
            :counter="editing"
            :rules="[
              formRules.required,
              formRules.domain
            ]"/>
        </v-col>
        <v-col cols=12 lg=6>
          <v-text-field
            v-model="form.port"
            label="Port"
            required
            clearable
            type="number"
            :rules="[
              formRules.aboveMinPort,
              formRules.belowMaxPort,
              formRules.required,
            ]"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols=12 lg=6>
          <v-text-field
            v-model="form.username"
            label="Username"
            clearable
            type="text"
            maxlength="255"
            :counter="editing"/>
        </v-col>
        <v-col cols=12 lg=6>
          <v-text-field
            v-model="form.password"
            label="Password"
            clearable
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            maxlength="255"
            :counter="editing"
            :rules="[formRules.required]"/>
        </v-col>
      </v-row>
      <v-switch
        v-model="form.use_tls"
        label="Use TLS"
        inset
        dense
        color="green"
        :rules="[formRules.tlsExclusiveSSL]"/>
      <v-switch
        v-model="form.use_ssl"
        label="Use SSL"
        inset
        dense
        color="green"
        :rules="[formRules.sslExclusiveTLS]"/>
      <v-text-field
        v-model="form.default_from"
        label="Default Email Address (From)"
        placeholder="support@example.be"
        hint="Will be used as the default address for sending emails."
        persistent-hint
        maxlength="254"
        :counter="editing"
        :rules="[formRules.required]"/>
    </v-form>
  </div>
  <div v-else>
    <v-col cols=12 align="center" justify="center">
      <h-request-loading/>
    </v-col>
  </div>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
import BaseSettingsService from '@/services/base/base.settings.service'
import WarningModal from '@/common/components/WarningModal'
export default {
  name: 'EmailSettingsClassicOutboundForm',
  mixins: [ DialogUnsavedChanges ],
  components: {
    HRequestLoading
  },
  props: {
    editing: { type: Boolean, required: true },
  },
  data () {
    return {
      loading: false,
      loadingTest: false,
      outboundMailServer: null,
      form: {
        host: '',
        port: 0,
        username: '',
        password: '',
        use_ssl: false,
        use_tls: false,
        default_from: '',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || 'Required.',
        belowMaxPort: value => value <= 65535 || 'The highest allowed port is 65535.',
        aboveMinPort: value => value >= 0 || 'The lowest allowed port is 0.',
        domain: value => {
          const pattern = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/
          return !value || pattern.test(value) || 'Invalid hostname.'
        },
        tlsExclusiveSSL: value => ((value === !this.form.use_ssl) && !this.form.use_ssl) || !value || 'Use TLS and Use SSL are mutually exclusive',
        sslExclusiveTLS: value => ((value === !this.form.use_tls) && !this.form.use_tls) || !value || 'Use SSL and Use TLS are mutually exclusive'
      },
      showPassword: false,
      formTested: false,
      formSuccessfullyTested: false
    }
  },
  async mounted () {
    await this.getClassicOutboundEmailSettings()
    if (this.outboundMailServer) {
      this.initFormFields()
    }
  },
  watch: {
    'form': {
      handler (form) {
        this.formTested = false
        this.formSuccessfullyTested = false
      },
      deep: true,
    },
  },
  computed: {
    formHasChanges () {
      if (this.outboundMailServer) {
        return Object.keys(this.form).some(field => this.form[field] !== this.outboundMailServer[field])
      }
      return false
    },
    connectionAlert () {
      if (this.formHasChanges && !this.formTested) {
        return {
          type: 'info',
          text: 'Please test whether the connection is working or not before saving.',
        }
      } else if (this.formHasChanges && this.formTested && !this.formSuccessfullyTested) {
        return {
          type: 'error',
          text: 'The provided connection information did not work.',
        }
      } else {
        return {
          type: 'success',
          text: 'The provided connection information successfully connected to SMTP server.',
        }
      }
    },
  },
  methods: {
    initFormFields () {
      for (let field in this.form) {
        if (field === 'password') continue
        this.form[field] = this.outboundMailServer[field]
      }
    },
    cancelEdit () {
      this.form.password = ''
      this.$emit('cancelEdit')
    },
    async getClassicOutboundEmailSettings () {
      this.loading = true
      this.outboundMailServer = await BaseSettingsService.getClassicOutboundEmailSettings()
      this.loading = false
    },
    async updateSettings () {
      const { host, port, username, password, use_tls, use_ssl, default_from } = this.$data.form
      this.$dialog.show(WarningModal, {
        icon: 'pencil',
        title: 'Update email settings ?',
        message: 'Are you sure you want to save these email settings ?',
        okText: 'YES',
        okColor: 'green',
        noButton: true,
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const updatedSettings = await BaseSettingsService.createOrUpdateClassicOutboundEmailSettings({
            host: host,
            port: port,
            username: username,
            password: password,
            use_tls: use_tls,
            use_ssl: use_ssl,
            default_from: default_from,
          })
          if (updatedSettings) {
            this.cancelEdit()
            this.outboundMailServer = updatedSettings
            this.$dialog.notify.success(
              'Outbound Mailbox updated', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    async testSettings () {
      this.loadingTest = true
      const { host, port, username, password, use_tls, use_ssl } = this.$data.form
      const testResult = await BaseSettingsService.checkClassicOutboundEmailSettings({
        host: host,
        port: port,
        username: username,
        password: password,
        use_tls: use_tls,
        use_ssl: use_ssl,
      })
      if (testResult) {
        this.formTested = true
        if (testResult.success === true) {
          this.formSuccessfullyTested = true
        } else {
          this.formSuccessfullyTested = false
        }
      }
      this.loadingTest = false
    },
  },
}
</script>
