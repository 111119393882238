<template>
  <contact-company-form
    :companyId=companyId
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import ContactCompanyForm from './shared/ContactCompanyForm.vue'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'ContactCompanyFormRead',
  mixins: [ DialogUnsavedChanges ],
  components: { ContactCompanyForm },
  props: {
    companyId: {
      type: [Number, String],
      default: () => { return null }
    },
  },
  data () {
    return {
      formIsEditing: false
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    },
  },
}
</script>
