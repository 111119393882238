<template>
  <v-dialog
    v-model="show"
    fullscreen
    transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar flat dark color="blue darken-1">
        <v-btn
          icon
          dark
          @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Update Ticket: {{ ticket?.name }}</v-toolbar-title>
      </v-toolbar>
      <v-row class="ma-4">
        <v-col cols=12>
        <!-- <v-col cols=12 md=6> -->
          <v-card elevation="3">
            <v-form v-model="isFormValid" class="pa-4">
              <v-text-field
                v-model="form.name"
                label="Title"
                required
                clearable
                autofocus
                type="text"
                maxlength="255"
                counter
                :rules="[formRules.required]"/>
              <h-rich-text-editor
                v-model="form.description"
                class="my-4"
                required
                editorBorders
                editorPadding
                opacifyDisabled
                formatParagraph
                formatBold
                formatItalic
                formatStrike
                formatMarker
                formatH1
                formatH2
                formatH3
                formatListB
                formatListN
                formatCode
                formatCodeBlock
                formatSeparator
                historyUndo
                historyRedo
                :editable="true"/>
              <v-autocomplete
                v-model="form.contact"
                label="Contact"
                :disabled="userMe?.user_type === 'portal'"
                :loading="loadingContactsInput"
                :items="contacts"
                item-text="full_name"
                return-object
                @click="getContactsInput">
              </v-autocomplete>
              <v-select
                v-model="form.priority"
                label="Priority"
                :items="priorities"
                item-value="value">
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-icon>
                      <v-icon :color="item.iconColor">mdi-{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ item.text }}
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="data">
                  <v-icon class="mr-2" :color="data.item.iconColor">mdi-{{ data.item.icon }}</v-icon>{{ data.item.text }}
                </template>
              </v-select>
            </v-form>
            <v-divider/>
            <v-card-actions>
              <!-- <v-spacer/> -->
              <v-btn text @click="show = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="green"
                :disabled="!isFormValid || !form.description"
                @click="updateTicket()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- <v-col cols=12 md=6>
          <v-card elevation="3">logger will come here</v-card>
        </v-col> -->
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import HRichTextEditor from '@/common/components/rich-text-editor/HRichTextEditor'
import ContactContactService from '@/services/contact/contact.contact.service'
import HelpdeskTicketPortalService from '@/services/helpdesk/helpdesk.ticket.portal.service'
export default {
  name: 'HelpdeskTicketUpdateFormPortalDialog',
  components: {
    HRichTextEditor,
  },
  props: {
    value: Boolean,
    userMe: {
      type: Object,
      required: true,
    },
    ticketId: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      ticket: null,
      loadingContactsInput: false,
      contacts: [],
      priorities: [
        { text: 'Low', value: 'low', icon: 'chevron-up', iconColor: 'green' },
        { text: 'Normal', value: 'normal', icon: 'chevron-double-up', iconColor: 'orange' },
        { text: 'High', value: 'high', icon: 'chevron-triple-up', iconColor: 'red' },
      ],
      form: {
        name: '',
        description: '',
        contact: null,
        priority: 'normal',
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  async mounted () {
    this.ticket = await HelpdeskTicketPortalService.getTicket(this.ticketId)
    this.initFormFields()
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    async getContactsInput () {
      if (this.userMe?.user_type === 'portal_referent') {
        this.loadingContactsInput = true
        this.contacts = await ContactContactService.getPortalContactsInput()
        this.loadingContactsInput = false
      }
    },
    initFormFields () {
      for (const field in this.form) {
        if (field === 'contact') this.contacts = [this.ticket[field]]
        this.form[field] = this.ticket[field]
      }
    },
    async updateTicket () {
      const {
        name,
        description,
        priority,
        contact,
      } = this.$data.form
      const responseData = await HelpdeskTicketPortalService.updateTicket(this.ticketId, {
        name: name,
        description: description,
        priority: priority,
        contact_id: contact ? contact.id : null,
      })
      if (responseData) {
        this.ticket = responseData
        this.$emit('updateTicket', this.ticket)
        this.show = false
      }
    },
  },
}
</script>
