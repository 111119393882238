<template>
  <inventory-item-contractor-form
    :contractorId=contractorId
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import InventoryItemContractorForm from './shared/InventoryItemContractorForm'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'InventoryItemContractorFormRead',
  mixins: [ DialogUnsavedChanges ],
  components: { InventoryItemContractorForm },
  props: {
    contractorId: {
      type: [String, Number],
      default: () => { return null },
    },
  },
  data () {
    return {
      formIsEditing: false,
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    },
  },
}
</script>
