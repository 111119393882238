<template>
  <group-form
    :groupId=groupId
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import GroupForm from './GroupForm'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'GroupFormRead',
  mixins: [ DialogUnsavedChanges ],
  components: { GroupForm },
  props: {
    groupId: {
      type: [Number, String],
      default: () => { return null }
    }
  },
  data () {
    return {
      formIsEditing: false
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
