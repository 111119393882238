<template>
  <v-app>
    <transition name="fade">
      <h-app-header v-if="isLoggedIn"/>
    </transition>
    <transition name="fade">
      <h-app-system-sidebar v-if="isLoggedIn && userMe && isUserSystem"/>
      <h-app-portal-sidebar v-if="isLoggedIn && userMe && !isUserSystem"/>
    </transition>
    <transition name="router-anim-main" mode="out-in">
      <router-view class="grey-background"/>
    </transition>
  </v-app>
</template>

<script>
import { getIsLoggedIn } from '@/router/tools'
import HAppHeader from '@/common/components/layout/HAppHeader'
import HAppSystemSidebar from '@/common/components/layout/HAppSystemSidebar'
import HAppPortalSidebar from '@/common/components/layout/HAppPortalSidebar'
export default {
  name: 'App',
  components: {
    HAppHeader,
    HAppSystemSidebar,
    HAppPortalSidebar,
  },
  async mounted () {
    const isLoggedIn = getIsLoggedIn()
    if (isLoggedIn && !this.$store.state.login.me) {
      await this.$store.dispatch('FETCH_ME')
      this.$root.$i18n.locale = this.$store.state.login.me.language
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.state.login.isLoggedIn
    },
    userMe () {
      return this.$store.state.login.me
    },
    isUserSystem () {
      return this.userMe?.user_type === 'system'
    },
  },
}
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.9s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .router-anim-main-enter,
  .router-anim-main-leave-to {
    opacity: 0;
    transform: translateY(-2em);
  }
  .router-anim-main-enter-active,
  .router-anim-main-leave-active {
    transition: all .25s ease;
  }
  .grey-background {
    background-color: #F2F2F2;
  }
</style>
