<template>
  <DialogCard :actions="actions">
    <v-toolbar
      flat
      dark
      color="blue darken-1">
      <v-toolbar-title>
        <v-icon left>mdi-account-key</v-icon>
        Change Password of: {{ user.username }}
      </v-toolbar-title>
    </v-toolbar>
    <v-form
      v-model="isFormValid"
      class="pa-4">
      <v-text-field
        v-model="form.password"
        label="Password"
        required
        maxlength="128"
        counter
        autofocus
        :rules="[formRules.required]"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"/>
      <v-text-field
        v-model="form.confirm"
        label="Confirm"
        required
        maxlength="128"
        counter
        :rules="[formRules.required, formRules.confirmIsEqual]"
        :type="showConfirm ? 'text' : 'password'"
        :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showConfirm = !showConfirm"/>
    </v-form>
  </DialogCard>
</template>

<script>
export default {
  name: 'UserChangePassword',
  props: { user: Object },
  data () {
    return {
      form: {
        password: '',
        confirm: ''
      },
      showPassword: false,
      showConfirm: false,
      isFormValid: false,
      formRules: {
        required: value => !!value || 'Required.',
        confirmIsEqual: value => value === this.form.password || 'Passwords do not match.'
      },
    }
  },
  computed: {
    actions () {
      return {
        false: {
          text: 'CANCEL',
        },
        true: {
          text: 'SAVE',
          color: 'green',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep
    .v-card__text {
      padding: 0;
    }
</style>
