var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.hasPermission('view_helpdeskticket')
      || _vm.hasPermission('add_helpdeskticket')
      || _vm.hasPermission('delete_helpdeskticket')
    )?_c('v-toolbar',{attrs:{"tile":"","dense":"","elevation":"1"}},[(_vm.hasPermission('add_helpdeskticket'))?_c('v-btn',{attrs:{"text":"","to":{ name: 'HelpdeskTicketFormCreate', params: { editing: true } }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create ")],1):_vm._e(),(
        _vm.selectedTickets.length
        && _vm.hasPermission('delete_helpdeskticket')
      )?_c('v-menu',{attrs:{"close-on-click":"","close-on-content-click":"","offsetY":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" Actions "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-chevron-down")])],1)]}}],null,false,2120033577)},[_c('v-list',[(_vm.hasPermission('delete_helpdeskticket'))?_c('v-list-item',{on:{"click":function($event){return _vm.deleteTickets()}}},[_c('v-icon',{staticClass:"mr-4",attrs:{"small":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1):_vm._e()],1)],1):_vm._e(),_c('v-spacer'),_c('h-filtering-bar',{attrs:{"availableFilters":_vm.availableFilters}}),_c('v-spacer'),(_vm.hasPermission('view_helpdeskticket'))?_c('v-btn',{attrs:{"tile":"","icon":"","to":{ name: 'HelpdeskTicketList' }}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1):_vm._e()],1):_vm._e(),_c('transition',{attrs:{"name":"router-anim","mode":"out-in"}},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }