import axios from 'axios'

function getBaseURL () {
  const location = window.location
  if (process.env.NODE_ENV === 'production') {
    return `${location.protocol}//${location.hostname}/api/`
  } else {
    return `http://${location.hostname}:8001/api/`
  }
}

const axiosInstance = axios.create({
  baseURL: getBaseURL(),
  // TODO DEPLOY: set 'timeout'
  // TODO DEPLOY: Check other axios options
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default axiosInstance
