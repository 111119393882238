<template>
  <DialogCard :actions="actions">
    <v-toolbar flat dark color="blue darken-1">
      <v-toolbar-title>
        <v-icon v-if="timesheet" left>mdi-pencil</v-icon>
        <v-icon v-else left>mdi-timer-plus</v-icon>
        {{ timesheet ? 'Edit' : 'Create' }} Timesheet
      </v-toolbar-title>
    </v-toolbar>
    <v-form v-model="isFormValid" class="pa-4" ref="form">
      <h-datetime-picker
        v-model="form.from"
        label="From"
        :maxDate="maxFromDate"
        :rules="[defaultFormRules.required, ...formFromRules]"/>
      <h-datetime-picker
        v-model="form.to"
        label="To"
        :minDate="minToDate"
        :rules="[defaultFormRules.required, ...formToRules]"/>
      <v-textarea
        v-model="form.description"
        label="Description"
        clearable
        type="text"
        maxlength="140"
        auto-grow
        counter/>
      <v-autocomplete
        label="Assigned To"
        v-model="form.assigned_user"
        :loading="loadingMembers"
        :items="members"
        item-text="full_name"
        return-object
        :rules="[defaultFormRules.required]"
        @click="getMembersInput()">
        <template v-slot:item="data">
          <v-list-item-avatar>
            <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ data.item.full_name }}</v-list-item-title>
            <v-list-item-subtitle>{{ data.item.username }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-slot:selection="data">
          <v-chip
            small
            pill
            class="font-weight-bold">
            <v-avatar left>
              <v-img :src="data.item.picture ? getPictureURL(data.item.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
            </v-avatar>
            {{ data.item.full_name }}
          </v-chip>
        </template>
      </v-autocomplete>
    </v-form>
    <v-divider/>
  </DialogCard>
</template>

<script>
import moment from 'moment'
import BaseUserService from '@/services/base/base.user.service'
import HDatetimePicker from '@/common/components/forms/HDatetimePicker'
export default {
  name: 'HelpdeskTicketTimesheetFormDialog',
  components: {
    HDatetimePicker,
  },
  props: {
    timesheet: { type: Object, default: () => { return null } },
  },
  data () {
    return {
      dateFormat: "YYYY-MM-DD",
      timeFormat: "HH:mm",
      loadingMembers: false,
      members: [],
      form: {
        from: this.timesheet ? moment(this.timesheet.start_datetime).format(this.datetimeFormat) : '',
        to: this.timesheet ? moment(this.timesheet.end_datetime).format(this.datetimeFormat) : '',
        description: this.timesheet ? this.timesheet.description : '',
        assigned_user: this.timesheet ? this.timesheet.assigned_user : null,
      },
      isFormValid: false,
      defaultFormRules: {
        required: value => !!value || 'Required.',
      },
    }
  },
  mounted () {
    if (this.timesheet?.assigned_user) {
      this.members = [this.timesheet.assigned_user]
    }
  },
  watch: {
    'form.from': async function () {
      await this.$nextTick()
      this.$refs.form.validate()
    },
    'form.to': async function () {
      await this.$nextTick()
      this.$refs.form.validate()
    },
  },
  computed: {
    datetimeFormat () {
      return `${this.dateFormat} ${this.timeFormat}`
    },
    maxFromDate () {
      if (this.form.to) return moment(this.form.to).format(this.dateFormat)
      return ''
    },
    minToDate () {
      if (this.form.from) return moment(this.form.from).format(this.dateFormat)
      return ''
    },
    formFromRules () {
      const rules = []
      if (this.form.from && this.form.to) {
        const rule = () => this.form.from < this.form.to || '"From" datetime must be sooner than "To"'
        rules.push(rule)
      }
      return rules
    },
    formToRules () {
      const rules = []
      if (this.form.from && this.form.to) {
        const rule = () => this.form.from < this.form.to || '"To" datetime must be later than "From"'
        rules.push(rule)
      }
      return rules
    },
    actions () {
      return {
        false: {
          text: 'Cancel',
        },
        true: {
          text: 'Save',
          color: 'green',
          disabled: !this.isFormValid,
          handler: () => {
            return new Promise(resolve => {
              resolve(this.form)
            })
          },
        },
      }
    },
  },
  methods: {
    async getMembersInput () {
      this.loadingMembers = true
      this.members = await BaseUserService.getUsersInput()
      this.loadingMembers = false
    },
  },
}
</script>

<style scoped>
  .v-card::v-deep .v-card__text {
    padding: 0;
  }
</style>
