import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class InventoryItemSupplierService {
  static getSuppliers = async (urlSearchParams) => {
    try {
      const response = await http.get('inventory/suppliers', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getSuppliersInput = async () => {
    try {
      const response = await http.get('inventory/suppliers/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getSupplier = async (id) => {
    try {
      const response = await http.get(`inventory/suppliers/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createSupplier = async (data) => {
    try {
      const response = await http.post('inventory/suppliers/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateSupplier = async (id, data) => {
    try {
      const response = await http.patch(`inventory/suppliers/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteSuppliers = async (data) => {
    try {
      const response = await http.post('inventory/suppliers/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
