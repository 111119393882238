<template>
  <div class="ma-12">
    <v-icon size=100 class="mb-2">mdi-filter-remove-outline</v-icon>
    <div>No records found or matching filters</div>
    <div>Try <a @click="emitRemoveFilters">removing filters</a><template v-if="createFormViewName"> or <a @click="routeToCreateForm">create</a> new records</template> !</div>
  </div>
</template>

<script>
import { eventBus } from '../../main.js'
export default {
  name: 'HNoRecordsFound',
  props: {
    createFormViewName: { type: String }
  },
  methods: {
    routeToCreateForm () {
      this.$router.push({ name: this.createFormViewName })
    },
    emitRemoveFilters () {
      eventBus.$emit('remove-selected-filters')
    }
  }
}
</script>

<style scoped>
  .v-icon {
    opacity: 0.5;
  }
</style>
