
import TimesheetItemListing from '@/views/timesheets/item/TimesheetItemListing'

export default [
  {
    path: 'items',
    name: 'TimesheetItemListing',
    component: TimesheetItemListing,
    meta: {
      type: 'listing',
      requiresAuth: true,
      permission: 'view_timesheetitem',
    },
  },
]
