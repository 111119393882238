import { render, staticRenderFns } from "./HelpdeskTeamForm.vue?vue&type=template&id=2ee5c123&scoped=true"
import script from "./HelpdeskTeamForm.vue?vue&type=script&lang=js"
export * from "./HelpdeskTeamForm.vue?vue&type=script&lang=js"
import style0 from "./HelpdeskTeamForm.vue?vue&type=style&index=0&id=2ee5c123&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ee5c123",
  null
  
)

export default component.exports