<template>
	<v-navigation-drawer
		app
		clipped
		permanent>
		<v-list>
			<v-list-item :to="{ name: 'HelpdeskPortalTicketList' }">
				<v-list-item-icon>
					<v-icon>mdi-lifebuoy</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('applicationNames.helpdesk') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item :to="{ name: 'HelpdeskPortalInventoryItemList' }">
				<v-list-item-icon>
					<v-icon>mdi-devices</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('applicationNames.inventory') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-list-item :to="{ name: 'UserProfileForm' }">
				<v-list-item-icon>
					<v-icon>mdi-account-cog</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ $t('applications.base.menus.myProfile') }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
		<template v-slot:append>
      <v-divider/>
			<div class="d-flex justify-center align-center overline py-2">
				Powered by
				<a href="https://www.hubbiz.be" target="_blank">
					<v-img
						src="@/assets/images/hubbiz_full_mix_black_h.png"
						contain
						max-height="20"
						max-width="100"/>
				</a>
			</div>
		</template>
	</v-navigation-drawer>
</template>

<script>
import { addPortalRoutes } from "@/router/tools"
export default {
	name: 'HAppPortalSidebar',
	mounted () {
		addPortalRoutes()
	},
}
</script>

<style scoped>
div.v-list .v-list-item--active {
  border-right: 4px solid #1E88E5;
}
</style>
