<template>
  <statistic-wrapper
    :height="height"
    statTitle="Open/Closed Today">
    <div
      v-if="!team.tickets_opened_today_count && !team.tickets_closed_today_count"
      :style="{'height': 'calc(100% - 72px)'}">
      <v-row class="my-0" :style="{'height': '100%'}">
        <v-col class="d-flex justify-center align-center font-weight-bold" cols="12">
          <v-chip
            color="#4CAF50"
            class="font-weight-bold"
            :dark="shouldTextBeWhite('#4CAF50')">
            There are no closed or opened tickets today
          </v-chip>
        </v-col>
      </v-row>
    </div>
    <echart v-else :option="chartOptions"/>
  </statistic-wrapper>
</template>

  <script>
  import StatisticWrapper from '@/common/components/StatisticWrapper'
  export default {
    name: 'TeamTicketsOpenClosedToday',
    components: {
      StatisticWrapper
    },
    props: {
      height: { type: Number },
      team: { type: Object }
    },
    computed: {
      series: function () {
        let dataset = [
          { name: 'OPENED', count: this.team.tickets_opened_today_count },
          { name: 'CLOSED', count: this.team.tickets_closed_today_count },
        ]
        let stackedSeries = []
        for (let data of dataset) {
          stackedSeries.push({
            name: data.name,
            type: 'bar',
            stack: 'total',
            label: {
              show: data.count > 0,
              fontWeight: 'bolder',
              fontSize: 24,
            },
            data: [data.count],
          })
        }
        return stackedSeries
      },
      chartOptions: function () {
        return {
          grid: {
            top: '0%',
            left: '5%',
            right: '5%',
            bottom: '40%',
          },
          legend: {
            show: true,
            top: '58%',
            textStyle: {
              fontWeight: 'bolder',
            },
          },
          xAxis: {
            type: 'value',
            show: false,
            max: 'dataMax',
          },
          yAxis: {
            type: 'category',
            show: false,
          },
          series: this.series,
          color: ['#FF4560', '#4CAF50'],
        }
      },
    },
  }
  </script>
