<template>
  <inventory-item-form
    :itemId=itemId
    :editing="formIsEditing"
    @toggleEdit="updateEditingStatus"/>
</template>

<script>
import InventoryItemForm from '@/views/inventory/item/shared/InventoryItemForm'
import DialogUnsavedChanges from '@/common/mixins/DialogUnsavedChanges'
export default {
  name: 'InventoryItemFormRead',
  mixins: [ DialogUnsavedChanges ],
  components: { InventoryItemForm },
  props: {
    itemId: {
      type: [String, Number],
      default: () => { return null }
    }
  },
  data () {
    return {
      formIsEditing: false
    }
  },
  methods: {
    updateEditingStatus (editingStatus) {
      this.formIsEditing = editingStatus
    }
  }
}
</script>
