<template>
  <div>
    <application-listing-toolbar
      :viewType="viewType"
      @setKanbanView="setKanbanView"/>
    <transition name="router-anim" mode="out-in">
      <application-kanban
        :loading="loading"
        :applications="applications"
        @activateApplication="activateApplication"
        @deactivateApplication="deactivateApplication"/>
    </transition>
  </div>
</template>

<script>
import ApplicationKanban from '@/views/base/application/ApplicationKanban'
import ApplicationListingToolbar from '@/views/base/application/shared/ApplicationListingToolbar'
import BaseApplicationService from '@/services/base/base.application.service'
export default {
  name: 'ApplicationListing',
  components: {
    ApplicationKanban,
    ApplicationListingToolbar,
  },
  data () {
    return {
      viewType: 'kanban',
      loading: false,
      applications: [],
    }
  },
  mounted () {
    this.getApplications(this.$store.state.url.urlSearchParams)
  },
  watch: {
    '$store.state.url.urlSearchParams' (newParams, oldParams) {
      this.getApplications(this.$store.state.url.urlSearchParams)
    },
  },
  methods: {
    setKanbanView () {
      this.viewType = 'kanban'
    },
    async getApplications (urlSearchParams) {
      this.loading = true
      this.applications = await BaseApplicationService.getApplications(urlSearchParams)
      this.loading = false
    },
    async activateApplication (application) {
      const activatedApplication = await BaseApplicationService.activateApplication(application.id)
      if (activatedApplication) {
        this.applications.splice(this.applications.indexOf(application), 1, activatedApplication)
        this.$store.commit('ADD_ACTIVATED_APPLICATION', activatedApplication)
        await this.$store.dispatch('FETCH_ME')
        this.$dialog.notify.success(
          `Application '${application.verbose_name}' activated`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async deactivateApplication (application) {
      const deactivatedApplication = await BaseApplicationService.deactivateApplication(application.id)
      if (deactivatedApplication) {
        this.applications.splice(this.applications.indexOf(application), 1, deactivatedApplication)
        this.$store.commit('REMOVE_DEACTIVATED_APPLICATION', deactivatedApplication)
        await this.$store.dispatch('FETCH_ME')
        this.$dialog.notify.success(
          `Application '${application.verbose_name}' deactivated`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
  },
}
</script>

<style scoped>
  .router-anim-enter,
  .router-anim-leave-to {
    opacity: 0;
    transform: translateY(2em);
  }
  .router-anim-enter-active,
  .router-anim-leave-active {
    transition: all .20s ease;
  }
</style>
