import http from '@/http'
import { handleAxiosError } from '@/common/helpers/handleAxiosError'

export default class ContactCompanyService {
  static getCompanies = async (urlSearchParams) => {
    try {
      const response = await http.get('contact/companies', { params: urlSearchParams })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getCompany = async (id) => {
    try {
      const response = await http.get(`contact/companies/${id}`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createCompany = async (data) => {
    try {
      const response = await http.post('contact/companies/create', data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateCompany = async (id, data) => {
    try {
      const response = await http.patch(`contact/companies/${id}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteCompanies = async (data) => {
    try {
      const response = await http.post('contact/companies/delete', data)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static createCompanyAddress = async (companyId, data) => {
    try {
      const response = await http.post(`contact/companies/${companyId}/addresses/create`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static updateCompanyAddress = async (companyId, addressId, data) => {
    try {
      const response = await http.patch(`contact/companies/${companyId}/addresses/${addressId}/update`, data)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static deleteCompanyAddress = async (companyId, addressId) => {
    try {
      const response = await http.delete(`contact/companies/${companyId}/addresses/${addressId}/delete`)
      return response
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getCompanyAddresses = async (companyId) => {
    try {
      const response = await http.get(`contact/companies/${companyId}/addresses`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getCompaniesInput = async () => {
    try {
      const response = await http.get('contact/companies/input')
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getCompanyAddressesInput = async (companyId) => {
    try {
      const response = await http.get(`contact/companies/${companyId}/addresses/input`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getCompanyContactsInput = async (companyId) => {
    try {
      const response = await http.get(`contact/companies/${companyId}/contacts/input`)
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }

  static getCompanyInventoryItemsInput = async (companyId, params) => {
    try {
      const response = await http.get(`contact/companies/${companyId}/inventory/items`, { params: params })
      return response.data
    } catch (error) {
      handleAxiosError(error)
    }
  }
}
