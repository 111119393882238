<template>
  <v-tabs grow>
    <v-tab
      v-if="hasPermission('view_apikey')"
      :to="{ name: 'APIKeyList' }">
      API Keys
    </v-tab>
    <v-tab
      v-if="hasPermission('view_webhook')"
      :to="{ name: 'WebhookList' }">
      Webhooks
    </v-tab>
    <v-tabs-items>
      <v-divider/>
      <router-view/>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
export default {
  name: 'BaseIntegrationsSettingsMenu',
}
</script>
