<template>
  <v-card tile elevation="3" class="h-ticket-logger-card">
    <v-tabs grow>
      <v-tab>
        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-forum</v-icon>
          </template>
          History
        </v-tooltip>
      </v-tab>
      <v-tab>
        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-paperclip</v-icon>
          </template>
          Attachments
        </v-tooltip>
      </v-tab>
      <v-tab v-if="isInventoryAppActivated">
        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-devices</v-icon>
          </template>
          Inventory Items
        </v-tooltip>
      </v-tab>
      <v-tab>
        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-format-list-checks</v-icon>
          </template>
          Subtasks
        </v-tooltip>
      </v-tab>
      <v-tab v-if="isTimesheetsAppActivated">
        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-clipboard-text-clock</v-icon>
          </template>
          Timesheets
        </v-tooltip>
      </v-tab>
      <v-tab-item>
        <v-divider/>
        <v-toolbar
          dense
          flat
          class="logger-chat-toolbar"
          v-if="
            hasPermission('add_helpdeskticketcomment')
            || hasPermission('add_helpdeskticketmessage')
            || hasPermission('add_helpdeskticketanswer')
          ">
          <v-btn-toggle v-model="selectedMessageType" group tile class="mx-auto">
            <v-btn v-if="hasPermission('add_helpdeskticketcomment')" text small @click="toggleCommentZone()">
              <v-icon small left>mdi-lead-pencil</v-icon>
              Comment
            </v-btn>
            <v-btn v-if="hasPermission('add_helpdeskticketmessage') && ticket.contact" text small @click="toggleMessageZone()">
              <v-icon small left>mdi-send</v-icon>
              Message
            </v-btn>
            <v-btn v-if="hasPermission('add_helpdeskticketanswer')" text small @click="toggleAnswerZone()">
              <v-icon small left>mdi-comment-check</v-icon>
              Answer
            </v-btn>
          </v-btn-toggle>
          <v-progress-linear
            v-if="loading"
            absolute
            bottom
            height="4"
            color="light-blue darken-4"
            indeterminate/>
        </v-toolbar>
        <transition name="form-fade">
          <v-form
            v-if="writing"
            class="logger-form">
            <h-rich-text-editor
              v-model="message"
              required
              editable
              editorBorders
              editorPadding
              formatParagraph
              formatBold
              formatItalic
              formatStrike
              formatH1
              formatH2
              formatH3
              formatListB
              formatListN
              historyUndo
              historyRedo/>
            <v-btn
              absolute
              right
              small
              tile
              depressed
              :color="sendButtonStyle.color"
              class="logger-form-btn mt-3"
              :disabled="!message"
              v-if="
                hasPermission('add_helpdeskticketcomment')
                || hasPermission('add_helpdeskticketmessage')
                || hasPermission('add_helpdeskticketanswer')
              "
              @click="sendMessage()">
              <v-icon left>mdi-{{ sendButtonStyle.icon }}</v-icon>
              {{ sendButtonStyle.text }}
            </v-btn>
          </v-form>
        </transition>
        <v-divider/>
        <v-toolbar dense flat>
          <v-btn-toggle v-model="showLogsOptions" mandatory multiple group dense class="mx-auto" color="blue darken-2">
            <v-btn small>
              <v-simple-checkbox :ripple="false" :value="showLogsOptions.includes(0)" @click="handleShowMessagesClick"/>
              Show Messages
            </v-btn>
            <v-btn small>
              <v-simple-checkbox :ripple="false" :value="showLogsOptions.includes(1)" @click="handleShowLogsClick"/>
              Show Logs
            </v-btn>
          </v-btn-toggle>
        </v-toolbar>
        <v-divider/>
        <v-sheet class="h-ticket-logger-timeline-sheet pr-5">
          <v-timeline v-if="combinedLogsAndMessages.length" dense align-top>
            <transition-group name="timeline-item-fade">
              <v-timeline-item
                v-for="(log, index) in combinedLogsAndMessages"
                :key="index"
                color="transparent">
                <template v-slot:icon>
                  <v-avatar>
                    <img
                      :src="
                        log.author?.picture
                        ? getPictureURL(log.author.picture)
                        : require('@/assets/images/no-user-picture.jpeg')
                      "/>
                  </v-avatar>
                </template>
                <template v-if="log.message_type">
                  <helpdesk-ticket-message-card
                    :message="log"
                    :cardColor="cardColor(log.message_type)"
                    @deleteMessage="deleteMessage"/>
                </template>
                <template v-else>
                  <helpdesk-ticket-log-card
                    :log="log"
                    :cardColor="cardColor('comment')"/>
                </template>
              </v-timeline-item>
            </transition-group>
          </v-timeline>
        </v-sheet>
      </v-tab-item>
      <v-tab-item>
        <v-divider/>
        <template v-if="hasPermission('add_helpdeskticketattachment')">
          <div class="mt-4 mx-4">
            <v-file-input
              v-model="newAttachments"
              outlined
              small-chips
              multiple
              counter
              show-size
              clearable
              placeholder="Select file(s) to attach..."
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"/>
            <v-btn
              block
              class="mb-5 white--text"
              color="blue darken-1"
              :loading="uploadingAttachments"
              :disabled="!newAttachments.length"
              @click="uploadAttachments(newAttachments)">
              <v-icon left>mdi-upload</v-icon>
              Upload attachment(s)
            </v-btn>
          </div>
          <v-divider/>
        </template>
        <v-list two-line class="attachment-list">
          <template v-for="(attachment, index) in ticketAttachments">
            <v-list-item :key="attachment.id">
              <v-list-item-avatar>
                <v-icon large color="blue darken-1">mdi-{{ getFileIcon(attachment) }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ attachment.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ attachment.create_date | moment("YYYY-MM-DD") }}&nbsp;&nbsp;|&nbsp;&nbsp;{{ attachment.size | humanFileSize }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="hasPermission('download_helpdeskticketattachment')">
                <v-btn icon @click="downloadAttachment(attachment)">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action v-if="hasPermission('delete_helpdeskticketattachment')">
                <v-btn icon @click="deleteAttachment(attachment)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < ticketAttachments.length - 1" :key="index"/>
          </template>
        </v-list>
      </v-tab-item>
      <v-tab-item v-if="isInventoryAppActivated">
        <v-divider/>
        <template v-if="hasPermission('change_helpdeskticket')">
          <v-alert
            v-if="!ticket.company"
            type="info"
            text
            dense>
            First set a Company on the Ticket to enable Inventory Item selection.
          </v-alert>
          <v-row class="ma-1">
            <v-col>
              <v-btn
                block
                class="white--text"
                color="blue darken-1"
                :disabled="!ticket.company"
                @click="selectInventoryItemsToLink">
                <v-icon left>mdi-link-variant-plus</v-icon>
                Link Inventory Item(s)
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                class="white--text"
                color="blue darken-1"
                :disabled="!selectedInventoryItems.length"
                @click="getContractorNotificationPreviews(selectedInventoryItems)">
                <v-icon left>mdi-email-send</v-icon>
                Notify to Contractor
              </v-btn>
            </v-col>
          </v-row>
          <v-divider/>
        </template>
        <v-list
          flat
          three-line>
          <v-list-item-group v-model="selectedInventoryItems" multiple>
            <template v-for="(item, index) in ticketInventoryItems">
              <v-hover v-slot="{ hover }">
                <v-list-item
                  :ripple="false"
                  :key="item.id"
                  :value="item"
                  :disabled="
                    item.contractor
                    && selectedInventoryItems.some(selectedInventoryItem => selectedInventoryItem.contractor?.id !== item.contractor?.id)
                  ">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        v-if="
                          !item.inventory_item_contractor_notified
                          && item.contractor
                        "
                        :input-value="active"
                        :disabled="selectedInventoryItems.some(selectedInventoryItem => selectedInventoryItem.contractor?.id !== item.contractor?.id)"/>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                        <span
                          v-if="hover"
                          style="opacity: 0.5"
                          class="caption font-italic">
                          - added {{ item.link_date | moment('calendar') }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip x-small class="font-weight-bold">
                          <v-icon x-small left>mdi-barcode</v-icon>
                          S/N: {{ item.serial_number || '-' }}
                        </v-chip>
                        <v-chip x-small class="ml-1 font-weight-bold">
                          <v-icon x-small left>mdi-fingerprint</v-icon>
                          P/N: {{ item.part_number || '-' }}
                        </v-chip>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon small left>mdi-face-agent</v-icon> {{ item.contractor?.name || '-' }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip
                        v-if="
                          item.contractor
                          && !item.inventory_item_contractor_notified
                        "
                        right
                        open-delay="250">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click.stop v-on="on">
                            <v-icon
                              @click="getContractorNotificationPreviews([item])">
                              mdi-email-send
                            </v-icon>
                          </v-btn>
                        </template>
                        Notify {{ item.contractor.name }}
                      </v-tooltip>
                      <v-tooltip
                        v-if="
                          hasPermission('change_helpdeskticket')
                          && !item.inventory_item_contractor_notified
                        "
                        right
                        open-delay="250">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click.stop v-on="on">
                            <v-icon
                              @click="unlinkInventoryItem(item)">
                              mdi-link-variant-remove
                            </v-icon>
                          </v-btn>
                        </template>
                        Unlink Inventory Item
                      </v-tooltip>
                      <v-tooltip
                        v-if="item.inventory_item_contractor_notified"
                        right
                        open-delay="250">
                        <template v-slot:activator="{ on }">
                          <v-btn icon @click.stop v-on="on">
                            <v-icon color="green">
                              mdi-email-check
                            </v-icon>
                          </v-btn>
                        </template>
                        Inventory Item notified ({{ item.inventory_item_contractor_notification_date | moment('calendar') }})
                      </v-tooltip>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-hover>
              <v-divider
                v-if="index < ticketInventoryItems.length - 1"
                :key="index"/>
            </template>
          </v-list-item-group>
        </v-list>
      </v-tab-item>
      <v-tab-item>
        <v-divider/>
        <template v-if="hasPermission('add_helpdeskticketsubtask')">
          <v-row class="ma-1">
            <v-col>
              <v-btn
                block
                class="white--text"
                color="blue darken-1"
                @click="addTicketSubtask">
                <v-icon left>mdi-sticker-plus</v-icon>
                Add a task
              </v-btn>
            </v-col>
          </v-row>
          <v-divider/>
        </template>
        <template v-if="ticketSubtasks.length">
          <v-toolbar flat color="grey lighten-4">
            <v-icon class="ml-5">mdi-sticker-check</v-icon>
            <v-spacer/>
            <v-progress-linear
              background-color="red"
              background-opacity="0.3"
              color="green"
              height="10"
              :value="doneTicketSubtasksPercent"
              :style="{'width': '80%'}"/>
            <v-spacer/>
            <span class="caption mr-5">{{ doneTicketSubtasksCount }} of {{ ticketSubtasks.length }}</span>
          </v-toolbar>
          <v-divider class="mb-4"/>
        </template>
        <v-card
          v-for="subtask in ticketSubtasks"
          :key="subtask.id"
          flat
          outlined
          class="mx-4 mb-2">
          <v-card-title>
            <v-row>
              <v-col cols=10>
                <span class="subtitle-1">
                  {{ subtask.description }}
                </span>
              </v-col>
              <v-col cols=2 class="d-flex justify-center">
                <v-btn
                  v-if="hasPermission('change_helpdeskticketsubtask')"
                  icon
                  @click="updateTicketSubtask(subtask)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="hasPermission('delete_helpdeskticketsubtask')"
                  icon
                  class="ml-2"
                  @click="deleteTicketSubtask(subtask)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions class="px-4">
            <v-menu
              offsetY
              close-on-click
              close-on-content-click>
              <template v-slot:activator="{ on: menu }">
                <v-btn
                  v-on="menu"
                  small
                  outlined
                  :color="ticketSubtaskStatusAttributes(subtask.status).color"
                  :class="{ 'disable-events': !hasPermission('change_helpdeskticketsubtask') }">
                  <v-icon left small>{{ ticketSubtaskStatusAttributes(subtask.status).icon }}</v-icon>
                  {{ subtask.status_display }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="status in ticketSubtasksStatuses"
                    :key="status.value"
                    @click="changeTicketSubtaskStatus(subtask, status)">
                    <v-list-item-icon>
                      <v-icon :color="ticketSubtaskStatusAttributes(status.value).color">
                        {{ ticketSubtaskStatusAttributes(status.value).icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ ticketSubtaskStatusAttributes(status.value).text }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-menu
              offsetY
              close-on-click
              close-on-content-click>
              <template v-slot:activator="{ on: menu }">
                <v-btn
                  v-on="menu"
                  small
                  outlined
                  class="ml-2"
                  :class="{ 'disable-events': !hasPermission('change_helpdeskticketsubtask') }"
                  @click="getActiveUsersInput()">
                  <v-icon v-if="!subtask.assigned_user && !subtask.assigned_user_full_name" small left>mdi-account-plus-outline</v-icon>
                  <v-avatar v-else size="22px" class="mr-2">
                    <v-img :src="subtask.assigned_user?.picture ? getPictureURL(subtask.assigned_user.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
                  </v-avatar>
                  <span v-if="subtask.assigned_user">{{ subtask.assigned_user.full_name }}</span>
                  <span v-else-if="subtask.assigned_user_full_name">{{ subtask.assigned_user_full_name }}</span>
                  <span v-else>Not Assigned</span>
                </v-btn>
              </template>
              <v-list
                class="user-list"
                :style="{
                  'overflow-y': activeUsers.length > 4 ? 'scroll' : 'unset'
                }">
                <v-list-item-group>
                  <v-list-item
                    v-if="subtask.assigned_user"
                    @click="unassignSubtaskUser(subtask)"
                    class="not-assigned-user">
                    <v-list-item-avatar>
                      <v-icon>mdi-account-remove</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      Unassign
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="!subtaskIsAssignedToMe(subtask)"
                    @click="assignSubtaskToMe(subtask)"
                    class="not-assigned-user">
                    <v-list-item-avatar>
                      <v-icon>mdi-account-arrow-left</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      Assign to Me
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                    v-if="activeUsers.length"
                    class="my-2"/>
                  <v-list-item
                    v-for="user in activeUsers"
                    :key="user.id"
                    @click="assignUserToSubtask(subtask, user)"
                    :class="{
                      'current-assigned-user': subtask.assigned_user && subtask.assigned_user.id === user.id,
                      'not-assigned-user': !subtask.assigned_user || subtask.assigned_user.id !== user.id
                    }">
                    <v-list-item-avatar>
                      <v-img :src="user.picture ? getPictureURL(user.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="user.full_name"/>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-btn
              small
              outlined
              class="ml-2"
              :ref="`subtaskDueDatePickerBtn-${subtask.id}`"
              :class="{ 'disable-events': !hasPermission('change_helpdeskticketsubtask') }"
              @click="openTicketSubtaskDueDateDialog(subtask)">
              <v-icon left small>mdi-calendar-clock</v-icon>
              <template v-if="subtask.due_date">{{ momentDueDate(subtask.due_date) }}</template>
              <template v-else>-</template>
              <h-datetime-picker :value="subtask.due_date" :show="false" :clearable=true @input="updateTicketSubtaskDueDate(subtask, $event)"/>
            </v-btn>
            <v-btn
              v-if="subtask.status === 'done'"
              small
              outlined
              class="ml-2"
              :ref="`subtaskDoneDatePickerBtn-${subtask.id}`"
              :class="{ 'disable-events': !hasPermission('change_helpdeskticketsubtask') }"
              @click="openTicketSubtaskDoneDateDialog(subtask)">
              <v-icon left small>mdi-calendar-check</v-icon>
              <template v-if="subtask.done_date">{{ momentDoneDate(subtask.done_date) }}</template>
              <template v-else>-</template>
              <h-datetime-picker :value="subtask.done_date" :show="false" :clearable=true @input="updateTicketSubtaskDoneDate(subtask, $event)"/>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-divider/>
        <template>
          <v-row class="ma-1">
            <v-col>
              <v-btn
                block
                class="white--text"
                color="blue darken-1"
                @click="addTicketTimesheet">
                <v-icon left>mdi-timer-plus</v-icon>
                Add a timesheet
              </v-btn>
            </v-col>
          </v-row>
          <v-divider/>
        </template>
        <template v-if="ticketTimesheets.length">
          <v-toolbar flat dense color="grey lighten-4">
            <span class="mx-auto overline">
              Time spent on ticket : {{ totalTicketTimesheetsDuration }}
            </span>
          </v-toolbar>
          <v-divider class="mb-4"/>
        </template>
        <v-card
          v-for="timesheet in ticketTimesheets"
          :key="timesheet.id"
          flat
          outlined
          class="mx-4 mb-2">
          <v-card-title>
            <v-row>
              <v-col cols=10 class="body-2">
                <span class="overline" style="color: #1E88E5">
                  {{ timesheetDuration(timesheet) }}
                </span>
                <br>
                <span class="caption font-weight-bold">
                  {{ timesheet.start_datetime | moment('YYYY-MM-DD HH:mm') }}
                </span>
                &rarr;
                <span class="caption font-weight-bold">
                  {{ timesheet.end_datetime | moment('YYYY-MM-DD HH:mm') }}
                </span>
              </v-col>
              <v-col cols=2 class="d-flex justify-center">
                <v-btn
                  v-if="hasPermission('change_timesheetitem')"
                  icon
                  @click="updateTicketTimesheet(timesheet)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  v-if="hasPermission('delete_timesheetitem')"
                  icon
                  class="ml-2"
                  @click="deleteTicketTimesheet(timesheet)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text v-if="timesheet.description">
            {{ timesheet.description }}
          </v-card-text>
          <v-card-actions class="px-4">
            <v-menu
              offsetY
              close-on-click
              close-on-content-click>
              <template v-slot:activator="{ on: menu }">
                <v-btn
                  v-on="menu"
                  small
                  outlined
                  :class="{ 'disable-events': !hasPermission('change_timesheetitem') }"
                  @click="getActiveUsersInput()">
                  <v-icon v-if="!timesheet.assigned_user && !timesheet.assigned_user_full_name" small left>mdi-account-plus-outline</v-icon>
                  <v-avatar v-else size="22px" class="mr-2">
                    <v-img :src="timesheet.assigned_user?.picture ? getPictureURL(timesheet.assigned_user.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
                  </v-avatar>
                  <span v-if="timesheet.assigned_user">{{ timesheet.assigned_user.full_name }}</span>
                  <span v-else-if="timesheet.assigned_user_full_name">{{ timesheet.assigned_user_full_name }}</span>
                  <span v-else>Not Assigned</span>
                </v-btn>
              </template>
              <v-list
                class="user-list"
                :style="{
                  'overflow-y': activeUsers.length > 4 ? 'scroll' : 'unset'
                }">
                <v-list-item-group>
                  <v-list-item
                    v-if="!timesheetIsAssignedToMe(timesheet)"
                    class="not-assigned-user"
                    @click="assignTimesheetToMe(timesheet)">
                    <v-list-item-avatar>
                      <v-icon>mdi-account-arrow-left</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      Assign to Me
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="activeUsers.length" class="my-2"/>
                  <v-list-item
                    v-for="user in activeUsers"
                    :key="user.id"
                    @click="assignUserToTimesheet(timesheet, user)"
                    :class="{
                      'current-assigned-user': timesheet.assigned_user && timesheet.assigned_user.id === user.id,
                      'not-assigned-user': !timesheet.assigned_user || timesheet.assigned_user.id !== user.id
                    }">
                    <v-list-item-avatar>
                      <v-img :src="user.picture ? getPictureURL(user.picture) : require('@/assets/images/no-user-picture.jpeg')"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="user.full_name"/>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import moment from 'moment-timezone'
import DeleteModal from '@/common/components/DeleteModal'
import HRichTextEditor from '@/common/components/rich-text-editor/HRichTextEditor'
import HDatetimePicker from '@/common/components/forms/HDatetimePicker'
import BaseUserService from '@/services/base/base.user.service'
import HelpdeskTicketInventoryItemsSelectorLinkerDialog from '@/common/components/forms/HelpdeskTicketInventoryItemsSelectorLinkerDialog'
import HelpdeskTicketInventoryItemContractorNotificationPreviewDialog from '@/common/components/forms/HelpdeskTicketInventoryItemContractorNotificationPreviewDialog'
import HelpdeskTicketSubtaskFormDialog from '@/views/helpdesk/ticket/shared/HelpdeskTicketSubtaskFormDialog'
import HelpdeskTicketTimesheetFormDialog from '@/views/helpdesk/ticket/shared/HelpdeskTicketTimesheetFormDialog'
import HelpdeskTicketLogCard from '@/views/helpdesk/ticket/shared/HelpdeskTicketLogCard'
import HelpdeskTicketMessageCard from '@/views/helpdesk/ticket/shared/HelpdeskTicketMessageCard'
import HelpdeskTicketService from '@/services/helpdesk/helpdesk.ticket.service'
import FileService from '@/services/file.service.js'
export default {
  name: 'HelpdeskTicketFormLogger',
  components: {
    HDatetimePicker,
    HRichTextEditor,
    HelpdeskTicketLogCard,
    HelpdeskTicketMessageCard,
  },
  props: {
    ticket: {
      type: Object,
      default: () => { return null },
    },
  },
  data () {
    return {
      loading: false,
      showLogsOptions:  [0, 1],
      uploadingAttachments: false,
      newAttachments: [],
      ticketAttachments: [],
      activeUsers: [],
      ticketTimesheets: [],
      ticketSubtasks: [],
      ticketSubtasksStatuses: [
        { text: 'To Do', value: 'to_do', },
        { text: 'In Progress', value: 'in_progress' },
        { text: 'Done', value: 'done' },
      ],
      ticketInventoryItems: [],
      selectedInventoryItems: [],
      writing: false,
      selectedMessageType: null,
      messageType: null,
      message: null,
      me: this.$store.state.login.me,
    }
  },
  async mounted () {
    if (this.ticket?.id) {
      this.loading = true
      await this.getTicketMessages(this.ticket.id)
      await this.getTicketLogs(this.ticket.id)
      await this.getTicketAttachments(this.ticket.id)
      if (this.isInventoryAppActivated) await this.getTicketInventoryItems(this.ticket.id)
      if (this.isTimesheetsAppActivated) await this.getTicketTimesheets(this.ticket.id)
      await this.getTicketSubtasks(this.ticket.id)
      this.loading = false
    }
  },
  beforeDestroy () {
    this.$store.commit('SET_CURRENT_TICKET_LOGS', [])
    this.$store.commit('SET_CURRENT_TICKET_MESSAGES', [])
  },
  computed: {
    isInventoryAppActivated () {
      return this.$store.state.base.activatedApplications.find(app => app.name === 'inventory') !== undefined
    },
    isTimesheetsAppActivated () {
      return this.$store.state.base.activatedApplications.find(app => app.name === "timesheets") !== undefined
    },
    isUseTimerEnabled () {
      return this.$store.state.base.allSettingsByApplication.helpdesk?.use_timer_on_ticket_form
    },
    ticketLogs () {
      return this.$store.state.helpdesk.currentTicketLogs
    },
    ticketMessages () {
      return this.$store.state.helpdesk.currentTicketMessages
    },
    combinedLogsAndMessages () {
      const logs = this.ticketLogs
      const messages = this.ticketMessages
      if (this.showLogsOptions.includes(0) && this.showLogsOptions.includes(1)) {
        return logs.concat(messages).sort((a, b) => -(Date.parse(a.create_date) - Date.parse(b.create_date)))
      } else if (this.showLogsOptions.includes(0)) {
        return messages
      } else if (this.showLogsOptions.includes(1)) {
        return logs
      } else {
        return []
      }
    },
    sendButtonStyle: function () {
      switch (this.messageType) {
        case 'comment':
          return {
            icon: 'lead-pencil',
            text: 'Add Comment',
            color: '#e6e6e6',
          }
        case 'message':
          return {
            icon: 'send',
            text: 'Send Message',
            color: '#abd5eb',
          }
        case 'answer':
          return {
            icon: 'comment-check',
            text: 'Post Answer',
            color: '#d2ebab',
          }
        default:
          return {
            icon: 'send',
            text: 'Send',
            color: '',
          }
      }
    },
    doneTicketSubtasksCount: function () {
      const doneTicketSubtasks = this.ticketSubtasks.filter(subtask => subtask.status === 'done')
      return doneTicketSubtasks.length
    },
    doneTicketSubtasksPercent: function () {
      if (this.ticketSubtasks.length > 0) {
        return (this.doneTicketSubtasksCount / this.ticketSubtasks.length) * 100
      }
      return 0
    },
    totalTicketTimesheetsDuration () {
      let sum = 0
      this.ticketTimesheets.forEach((timesheet) => sum += timesheet.duration)
      if (sum === 0) return sum
      const momentDuration = moment.duration(sum, 'seconds')
      let strDuration = ''
      if (momentDuration.minutes()) strDuration = ` ${momentDuration.minutes()} minute(s)`
      if (momentDuration.hours()) strDuration = ` ${momentDuration.hours()} hour(s)${strDuration}`
      if (momentDuration.days()) strDuration = ` ${momentDuration.days()} day(s)${strDuration}`
      return strDuration
    },
  },
  methods: {
    async getActiveUsersInput () {
      this.activeUsers = await BaseUserService.getUsersInput()
    },
    async getTicketMessages (ticketId) {
      await this.$store.dispatch('GET_HELPDESK_TICKET_MESSAGES', ticketId)
    },
    async getTicketLogs (ticketId) {
      await this.$store.dispatch('GET_HELPDESK_TICKET_LOGS', ticketId)
    },
    async getTicketAttachments (ticketId) {
      this.ticketAttachments = await HelpdeskTicketService.getTicketAttachments(ticketId)
    },
    async getTicketInventoryItems (ticketId) {
      this.ticketInventoryItems = await HelpdeskTicketService.getTicketInventoryItems(ticketId)
    },
    async getTicketSubtasks (ticketId) {
      this.ticketSubtasks = await HelpdeskTicketService.getTicketSubtasks(ticketId)
    },
    async getTicketTimesheets (ticketId) {
      this.ticketTimesheets = await HelpdeskTicketService.getTicketTimesheets(ticketId)
    },
    handleShowMessagesClick () {
      if (this.showLogsOptions.includes(0)) {
        if (this.showLogsOptions.length > 1) {
          this.showLogsOptions.splice(this.showLogsOptions.indexOf(0), 1)
        }
      } else {
        this.showLogsOptions.push(0)
      }
    },
    handleShowLogsClick () {
      if (this.showLogsOptions.includes(1)) {
        if (this.showLogsOptions.length > 1) {
          this.showLogsOptions.splice(this.showLogsOptions.indexOf(1), 1)
        }
      } else {
        this.showLogsOptions.push(1)
      }
    },
    timesheetDuration (timesheet) {
      const momentDuration = moment.duration(timesheet.duration, 'seconds')
      let strDuration = ''
      if (momentDuration.seconds()) strDuration = ` ${momentDuration.seconds()} second(s)`
      if (momentDuration.minutes()) strDuration = ` ${momentDuration.minutes()} minute(s)${strDuration}`
      if (momentDuration.hours()) strDuration = ` ${momentDuration.hours()} hour(s)${strDuration}`
      if (momentDuration.days()) strDuration = ` ${momentDuration.days()} day(s)${strDuration}`
      return strDuration
    },
    ticketSubtaskStatusAttributes (status) {
      if (status === 'to_do') return { icon: 'mdi-circle-outline', color: 'red', text: "To Do" }
      if (status === 'in_progress') return { icon: 'mdi-progress-wrench', color: 'orange', text: "In Progress" }
      if (status === 'done') return { icon: 'mdi-check-circle', color: 'green', text: "Done" }
    },
    addTicketSubtask () {
      this.$dialog.show(HelpdeskTicketSubtaskFormDialog, {
        persistent: true,
        waitForResult: true,
      }).then(async subtask => {
        if (subtask) {
          const createdSubtask = await HelpdeskTicketService.createTicketSubtask(this.ticket.id, {
            description: subtask.description,
            assigned_user_id: subtask.assigned_user ? subtask.assigned_user.id : null,
            status: subtask.status,
            due_date: subtask.due_date,
            done_date: subtask.done_date,
          })
          if (createdSubtask) {
            this.ticketSubtasks.push(createdSubtask)
            this.$dialog.notify.success(
              'Subtask created', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    updateTicketSubtask (subtask) {
      this.$dialog.show(HelpdeskTicketSubtaskFormDialog, {
        subtask: subtask,
        persistent: true,
        waitForResult: true,
      }).then(async form => {
        if (form) {
          const updatedSubtask = await HelpdeskTicketService.updateTicketSubtask(this.ticket.id, subtask.id, {
            description: form.description,
            assigned_user_id: form.assigned_user ? form.assigned_user.id : null,
            status: form.status,
            due_date: form.due_date,
            done_date: form.done_date,
          })
          if (updatedSubtask) {
            this.ticketSubtasks.splice(this.ticketSubtasks.indexOf(subtask), 1, updatedSubtask)
            this.$dialog.notify.success(
              'Subtask successfully updated', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    async changeTicketSubtaskStatus (subtask, status) {
      if (subtask.status !== status.value) {
        const updatedSubtask = await HelpdeskTicketService.changeTicketSubtaskStatus(this.ticket.id, subtask.id, {
          status: status.value
        })
        if (updatedSubtask) {
          this.ticketSubtasks.splice(this.ticketSubtasks.indexOf(subtask), 1, updatedSubtask)
          this.$dialog.notify.success(
            `Subtask status changed to '${status.text}'`, {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    subtaskIsAssignedToMe (subtask) {
      const meId = this.$store.state.login.me.id
      return subtask.assigned_user?.id === meId
    },
    async unassignSubtaskUser (subtask) {
      if (subtask.assigned_user) {
        const updatedSubtask = await HelpdeskTicketService.changeTicketSubtaskAssignee(this.ticket.id, subtask.id, {
          assigned_user_id: null,
        })
        if (updatedSubtask) {
          this.ticketSubtasks.splice(this.ticketSubtasks.indexOf(subtask), 1, updatedSubtask)
          this.$dialog.notify.success(
            'Subtask unassigned', {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    async assignSubtaskToMe (subtask) {
      const meId = this.$store.state.login.me.id
      if (!subtask.assigned_user || subtask.assigned_user.id !== meId) {
        const updatedSubtask = await HelpdeskTicketService.changeTicketSubtaskAssignee(this.ticket.id, subtask.id, {
          assigned_user_id: meId,
        })
        if (updatedSubtask) {
          this.ticketSubtasks.splice(this.ticketSubtasks.indexOf(subtask), 1, updatedSubtask)
          this.$dialog.notify.success(
            'Successfully assigned you to the subtask', {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    async assignUserToSubtask (subtask, user) {
      if (!subtask.assigned_user || subtask.assigned_user.id !== user.id) {
        const updatedSubtask = await HelpdeskTicketService.changeTicketSubtaskAssignee(this.ticket.id, subtask.id, {
          assigned_user_id: user.id,
        })
        if (updatedSubtask) {
          this.ticketSubtasks.splice(this.ticketSubtasks.indexOf(subtask), 1, updatedSubtask)
          this.$dialog.notify.success(
            `Subtask assigned to '${user.full_name}'`, {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    momentDueDate (dueDate) {
      return moment(dueDate).calendar({
        sameElse: 'YYYY-MM-DD HH:mm:ss'
      })
    },
    openTicketSubtaskDueDateDialog (subtask) {
      const subtaskDueDatePickerBtn = this.$refs[`subtaskDueDatePickerBtn-${subtask.id}`][0]
      const dueDateDialog = subtaskDueDatePickerBtn.$children.find(child => child.$refs.hasOwnProperty('datetimePickerDialog'))
      if (dueDateDialog) dueDateDialog.showDialog = true
    },
    isSubtaskDueDateSame (subtaskDueDate, newDueDate) {
      /*
        Have to do such check because backend format and DatetimePicker format
        are not the same.
      */
      if (subtaskDueDate) {
        const clientTZ = moment.tz.guess()
        const subtaskDueDateClientTZ = moment(subtaskDueDate).tz(clientTZ).format()
        return subtaskDueDateClientTZ === newDueDate
      } else {
        return subtaskDueDate === newDueDate
      }
    },
    async updateTicketSubtaskDueDate (subtask, newDate) {
      const areDatesSame = this.isSubtaskDueDateSame(subtask.due_date, newDate)
      if (!areDatesSame) {
        const updatedSubtask = await HelpdeskTicketService.changeTicketSubtaskDueDate(this.ticket.id, subtask.id, {
          due_date: newDate
        })
        if (updatedSubtask) {
          this.ticketSubtasks.splice(this.ticketSubtasks.indexOf(subtask), 1, updatedSubtask)
          this.$dialog.notify.success(
            'Successfully changed due date of the subtask', {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    momentDoneDate (doneDate) {
      return moment(doneDate).calendar({
        sameElse: 'YYYY-MM-DD HH:mm:ss'
      })
    },
    openTicketSubtaskDoneDateDialog (subtask) {
      const subtaskDoneDatePickerBtn = this.$refs[`subtaskDoneDatePickerBtn-${subtask.id}`][0]
      const doneDateDialog = subtaskDoneDatePickerBtn.$children.find(child => child.$refs.hasOwnProperty('datetimePickerDialog'))
      if (doneDateDialog) doneDateDialog.showDialog = true
    },
    isSubtaskDoneDateSame (subtaskDoneDate, newDoneDate) {
      /*
        Have to do such check because backend format and DatetimePicker format
        are not the same.
      */
      if (subtaskDoneDate) {
        const clientTZ = moment.tz.guess()
        const subtaskDoneDateClientTZ = moment(subtaskDoneDate).tz(clientTZ).format()
        return subtaskDoneDateClientTZ === newDoneDate
      } else {
        return subtaskDoneDate === newDoneDate
      }
    },
    async updateTicketSubtaskDoneDate (subtask, newDate) {
      const areDatesSame = this.isSubtaskDoneDateSame(subtask.done_date, newDate)
      if (!areDatesSame) {
        const updatedSubtask = await HelpdeskTicketService.changeTicketSubtaskDoneDate(this.ticket.id, subtask.id, {
          done_date: newDate
        })
        if (updatedSubtask) {
          this.ticketSubtasks.splice(this.ticketSubtasks.indexOf(subtask), 1, updatedSubtask)
          this.$dialog.notify.success(
            'Successfully changed done date of the subtask', {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    async deleteTicketSubtask (subtask) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Ticket Subtask',
        recordNamePlural: 'Ticket Subtasks',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTicketService.deleteTicketSubtask(this.ticket.id, subtask.id)
          if (response) {
            this.ticketSubtasks.splice(this.ticketSubtasks.indexOf(subtask), 1)
            this.$dialog.notify.success(
              'Subtask successfully deleted', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    timesheetIsAssignedToMe (timesheet) {
      const meId = this.$store.state.login.me.id
      return timesheet.assigned_user?.id === meId
    },
    updateTicketTimesheet (timesheet) {
      this.$dialog.show(HelpdeskTicketTimesheetFormDialog, {
        timesheet: timesheet,
        persistent: true,
        waitForResult: true,
      }).then(async form => {
        if (form) {
          const updatedTimesheet = await HelpdeskTicketService.updateTicketTimesheet(this.ticket.id, timesheet.id, {
            start_datetime: form.from,
            end_datetime: form.to,
            description: form.description || '',
            assigned_user_id: form.assigned_user.id,
          })
          if (updatedTimesheet) {
            this.ticketTimesheets.splice(this.ticketTimesheets.indexOf(timesheet), 1, updatedTimesheet)
            if (this.isUseTimerEnabled) {
              const meId = this.$store.state.login.me.id
              const isTimesheetAssignedToMe = updatedTimesheet.assigned_user?.id === meId
              const wasTimesheetAssignedToMe = timesheet.assigned_user?.id === meId
              const timesheetAssigneeHasChanged = timesheet.assigned_user?.id != updatedTimesheet.assigned_user?.id
              const timesheetDurationHasChanged = timesheet.duration !== updatedTimesheet.duration
              if (timesheetDurationHasChanged && timesheetAssigneeHasChanged) {
                if (isTimesheetAssignedToMe) {
                  this.$store.dispatch('INCREMENT_INITIAL_TICKET_FORM_TIMER', updatedTimesheet.duration)
                } else if (wasTimesheetAssignedToMe) {
                  this.$store.dispatch('DECREMENT_INITIAL_TICKET_FORM_TIMER', timesheet.duration)
                }
              } else if (timesheetAssigneeHasChanged) {
                if (isTimesheetAssignedToMe) {
                  this.$store.dispatch('INCREMENT_INITIAL_TICKET_FORM_TIMER', updatedTimesheet.duration)
                } else if (wasTimesheetAssignedToMe) {
                  this.$store.dispatch('DECREMENT_INITIAL_TICKET_FORM_TIMER', timesheet.duration)
                }
              } else if (timesheetDurationHasChanged) {
                if (isTimesheetAssignedToMe) {
                  this.$store.dispatch('INCREMENT_INITIAL_TICKET_FORM_TIMER', updatedTimesheet.duration - timesheet.duration)
                }
              }
            }
            this.$dialog.notify.success(
              'Timesheet successfully updated', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    async assignTimesheetToMe (timesheet) {
      const meId = this.$store.state.login.me.id
      if (!timesheet.assigned_user || timesheet.assigned_user.id !== meId) {
        const updatedTimesheet = await HelpdeskTicketService.changeTicketTimesheetAssignee(this.ticket.id, timesheet.id, {
          assigned_user_id: meId,
        })
        if (updatedTimesheet) {
          this.ticketTimesheets.splice(this.ticketTimesheets.indexOf(timesheet), 1, updatedTimesheet)
          if (this.isUseTimerEnabled && updatedTimesheet.assigned_user?.id === this.$store.state.login.me.id) {
            this.$store.dispatch('INCREMENT_INITIAL_TICKET_FORM_TIMER', updatedTimesheet.duration)
          }
          this.$dialog.notify.success(
            'Successfully assigned you to the timesheet', {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    async assignUserToTimesheet (timesheet, user) {
      if (!timesheet.assigned_user || timesheet.assigned_user.id !== user.id) {
        const updatedTimesheet = await HelpdeskTicketService.changeTicketTimesheetAssignee(this.ticket.id, timesheet.id, {
          assigned_user_id: user.id,
        })
        if (updatedTimesheet) {
          this.ticketTimesheets.splice(this.ticketTimesheets.indexOf(timesheet), 1, updatedTimesheet)
          if (this.isUseTimerEnabled) {
            const meId = this.$store.state.login.me.id
            if (updatedTimesheet.assigned_user?.id === meId) {
              this.$store.dispatch('INCREMENT_INITIAL_TICKET_FORM_TIMER', timesheet.duration)
            } else if (updatedTimesheet.assigned_user?.id !== meId && timesheet.assigned_user?.id === meId) {
              this.$store.dispatch('DECREMENT_INITIAL_TICKET_FORM_TIMER', timesheet.duration)
            }
          }
          this.$dialog.notify.success(
            `Timesheet assigned to '${user.full_name}'`, {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    addTicketTimesheet () {
      this.$dialog.show(HelpdeskTicketTimesheetFormDialog, {
        persistent: true,
        waitForResult: true,
      }).then(async timesheet => {
        if (timesheet) {
          const createdTimesheet = await HelpdeskTicketService.createTicketTimesheet(this.ticket.id, {
            start_datetime: timesheet.from,
            end_datetime: timesheet.to,
            description: timesheet.description || '',
            assigned_user_id: timesheet.assigned_user ? timesheet.assigned_user.id : null,
          })
          if (createdTimesheet) {
            this.ticketTimesheets.unshift(createdTimesheet)
            if (this.isUseTimerEnabled && createdTimesheet.assigned_user?.id === this.$store.state.login.me.id) {
              this.$store.dispatch('INCREMENT_INITIAL_TICKET_FORM_TIMER', createdTimesheet.duration)
            }
            this.$dialog.notify.success(
              'Timesheet created', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      })
    },
    async deleteTicketTimesheet (timesheet) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Ticket Timesheet',
        recordNamePlural: 'Ticket Timesheet',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTicketService.deleteTicketTimesheet(this.ticket.id, timesheet.id)
          if (response) {
            this.ticketTimesheets.splice(this.ticketTimesheets.indexOf(timesheet), 1)
            if (this.isUseTimerEnabled && timesheet.assigned_user?.id === this.$store.state.login.me.id) {
              this.$store.dispatch('DECREMENT_INITIAL_TICKET_FORM_TIMER', timesheet.duration)
            }
            this.$dialog.notify.success(
              'Timesheet successfully deleted', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    selectInventoryItemsToLink () {
      this.$dialog.show(HelpdeskTicketInventoryItemsSelectorLinkerDialog, {
        mode: 'linker',
        ticket: this.ticket,
        company: this.ticket.company,
        initiallySelectedOrLinkedInventoryItems: this.ticketInventoryItems,
        persistent: true,
        waitForResult: true,
      }).then(async selectedInventoryItems => {
        if (selectedInventoryItems) {
          const responseData = await HelpdeskTicketService.linkInventoryItemsToTicket(this.ticket.id, {
            inventory_item_ids: selectedInventoryItems.map(item => item.id)
          })
          if (responseData) {
            this.$store.commit('ADD_LOG_TO_TICKET_LOGS', responseData.log)
            for (const inventoryItem of responseData.inventory_items) {
              this.ticketInventoryItems.push(inventoryItem)
            }
            this.$emit('increaseTicketInventoryItemsCount', responseData.inventory_items.length)
            this.$dialog.notify.success(
              `Inventory Item${responseData.inventory_items.length > 1 ? 's' : ''} linked`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    unlinkInventoryItem (inventoryItem) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Inventory Item',
        recordNamePlural: 'Inventory Items',
        titleIcon: 'mdi-link-variant-remove',
        titleText: `Unlink Inventory Item '${inventoryItem.name}' ?`,
        warningText: 'Are you sure you want to unlink this Inventory Item ?',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const responseData = await HelpdeskTicketService.unlinkInventoryItemFromTicket(this.ticket.id, inventoryItem.id)
          if (responseData) {
            this.$store.commit('ADD_LOG_TO_TICKET_LOGS', responseData.log)
            this.ticketInventoryItems.splice(this.ticketInventoryItems.indexOf(inventoryItem), 1)
            this.$emit('decreaseTicketInventoryItemsCount', 1)
            this.$dialog.notify.success(
              `Inventory Item '${inventoryItem.name}' unlinked`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    async getContractorNotificationPreviews (inventoryItems) {
      const inventoryItemIds = inventoryItems.map(item => item.id)
      const responseData = await HelpdeskTicketService.getInventoryItemsContractorNotificationPreviews(this.ticket.id, {
        inventory_item_ids: inventoryItemIds
      })
      if (responseData) {
        this.$dialog.show(HelpdeskTicketInventoryItemContractorNotificationPreviewDialog, {
          notificationText: responseData.text_notification,
          notificationHTML: responseData.html_notification,
          persistent: true,
          waitForResult: true,
        }).then(async notificationExtraContext => {
          if (notificationExtraContext) {
            notificationExtraContext = notificationExtraContext === true ? '' : notificationExtraContext
            await this.notifyContractor(notificationExtraContext, inventoryItemIds)
          }
        }).catch(error => {
          // TODO: Do something smarter than just printing the error to console, which shouldn't be done
          console.log(error)
        })
      }
    },
    async notifyContractor (notificationExtraContext, inventoryItemIds) {
      const responseData = await HelpdeskTicketService.notifyInventoryItemsContractorForTicket(this.ticket.id, {
        inventory_item_ids: inventoryItemIds,
        notification_extra_context: notificationExtraContext,
      })
      if (responseData) {
        for (const inventoryItem of responseData.inventory_items) {
          const inventoryItemToReplace = this.ticketInventoryItems.find(itemToReplace => itemToReplace.id === inventoryItem.id)
          this.ticketInventoryItems.splice(this.ticketInventoryItems.indexOf(inventoryItemToReplace), 1, inventoryItem)
        }
        this.$store.commit('ADD_MESSAGE_TO_TICKET_MESSAGES', responseData.ticket_message)
        this.selectedInventoryItems = []
        this.$dialog.notify.success(
          `Inventory Item${inventoryItemIds.length > 1 ? 's' : ''} notified to Contractor`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async downloadAttachment (attachment) {
      const response = await HelpdeskTicketService.downloadTicketAttachment(this.ticket.id, attachment.id)
      FileService.downloadFile(response)
    },
    async uploadAttachments (newAttachments) {
      this.uploadingAttachments = true
      const formData = new FormData()
      for (const attachment of newAttachments) {
        formData.append('attachments', attachment)
      }
      const responseData = await HelpdeskTicketService.uploadTicketAttachments(this.ticket.id, formData)
      if (responseData) {
        this.ticketAttachments = responseData.attachments
        if (responseData.log) {
          this.$store.commit('ADD_LOG_TO_TICKET_LOGS', responseData.log)
        }
        this.$dialog.notify.success(
          `Attachment${newAttachments.length > 1 ? 's' : ''} uploaded`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
      this.newAttachments = []
      this.uploadingAttachments = false
    },
    deleteAttachment (attachment) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Attachment',
        recordNamePlural: 'Attachments',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const ticketLog = await HelpdeskTicketService.deleteTicketAttachment(this.ticket.id, attachment.id)
          if (ticketLog) {
            this.$store.commit('ADD_LOG_TO_TICKET_LOGS', ticketLog)
            this.ticketAttachments.splice(this.ticketAttachments.indexOf(attachment), 1)
            this.$dialog.notify.success(
              `Attachment '${attachment.name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    cardColor (messageType) {
      switch (messageType) {
        case 'comment':
          return '#e6e6e6'
        case 'message':
          return '#abd5eb'
        case 'answer':
          return '#d2ebab'
      }
    },
    getFileIcon (attachment) {
      switch (attachment.type) {
        case 'txt':
          return 'file-document'
        case 'odp':
          return 'file-powerpoint'
        case 'odt':
          return 'file-word'
        case 'ods':
          return 'file-excel'
        case 'pdf':
          return 'file-pdf-box'
        case 'ppt':
        case 'pptx':
          return 'microsoft-powerpoint'
        case 'doc':
        case 'docx':
          return 'microsoft-word'
        case 'xls':
        case 'xlsx':
          return 'microsoft-excel'
        case 'csv':
          return 'file-delimited'
        case 'xml':
          return 'xml'
        case 'json':
          return 'code-json'
        case 'zip':
          return 'folder-zip'
        case 'jpe':
        case 'jpg':
        case 'jpeg':
        case 'png':
          return 'file-image'
        default:
          return 'file'
      }
    },
    toggleMessageZone () {
      if (!this.writing) {
        this.writing = true
      } else if (this.writing && this.messageType === 'message') {
        this.writing = false
        this.message = null
      }
      this.messageType = 'message'
    },
    toggleCommentZone () {
      if (!this.writing) {
        this.writing = true
      } else if (this.writing && this.messageType === 'comment') {
        this.writing = false
        this.message = null
      }
      this.messageType = 'comment'
    },
    toggleAnswerZone () {
      if (!this.writing) {
        this.writing = true
      } else if (this.writing && this.messageType === 'answer') {
        this.writing = false
        this.message = null
      }
      this.messageType = 'answer'
    },
    async sendMessage () {
      this.writing = false
      let createdMessage = null
      if (this.messageType === 'comment') {
        createdMessage = await HelpdeskTicketService.createTicketComment(this.ticket.id, {
          message: this.message
        })
      } else if (this.messageType === 'message') {
        createdMessage = await HelpdeskTicketService.createTicketMessage(this.ticket.id, {
          message: this.message
        })
      } else if (this.messageType === 'answer') {
        createdMessage = await HelpdeskTicketService.createTicketAnswer(this.ticket.id, {
          message: this.message
        })
      }
      if (createdMessage) {
        this.$store.commit('ADD_MESSAGE_TO_TICKET_MESSAGES', createdMessage)
        this.message = null
        this.messageType = null
        this.selectedMessageType = null
        if (createdMessage.is_sending_failed === true) {
          this.$dialog.notify.warning(
            `${createdMessage.message_type_display} created but couldn't be sent`, {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        } else {
          this.$dialog.notify.success(
            `${createdMessage.message_type_display} sent`, {
              position: 'top-right',
              border: 'left',
              dense: true,
              timeout: 5000,
            }
          )
        }
      }
    },
    deleteMessage (message) {
      this.$dialog.show(DeleteModal, {
        recordName: 'Message',
        recordNamePlural: 'Messages',
        persistent: true,
        waitForResult: true,
      }).then(async yes => {
        if (yes) {
          const response = await HelpdeskTicketService.deleteTicketMessage(this.ticket.id, message.id)
          if (response) {
            this.$store.commit('REMOVE_MESSAGE_FROM_TICKET_MESSAGES', message)
            this.$dialog.notify.success(
              `${message.message_type_display} deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000,
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
  .disable-events {
    pointer-events: none;
  }
  .v-card.h-ticket-logger-card {
    height: 100%;
  }
  /* .v-window-item {
    max-height: calc(100vh - 192px);
  }
  div.h-ticket-logger-timeline-sheet {
    max-height: calc(100vh - 252px);
    overflow-y: auto;
  } */
  .v-toolbar.logger-chat-toolbar {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .v-form.logger-form {
    padding: 12px;
    margin-bottom: 40px;
  }
  .v-btn.logger-form-btn {
    right: 12px;
  }
  .form-fade-enter-active,
  .form-fade-leave-active {
    max-height: 1000px;
    transition: opacity .5s, max-height .5s;
  }
  .form-fade-enter,
  .form-fade-leave-to {
    opacity: 0;
    max-height: 0px;
    padding: 0 !important;
    margin: 0 !important;
    transition: opacity .5s, max-height .5s, padding .1s .4s, margin .1s .4s;
  }
  .timeline-item-fade-enter-active {
    transition: all 1.5s;
  }
  .timeline-item-fade-enter {
    opacity: 0;
  }
  .timeline-item-fade-move {
    transition: transform 1.5s;
  }
  .v-timeline::v-deep .v-timeline-item__dot {
    height: 0px;
  }
  div.v-list.attachment-list {
    overflow-y: auto;
  }
  .v-list.user-list {
    padding: 0;
    max-height: 360px;
  }
  .v-list-item.not-assigned-user:hover {
    background-color: #F8F8F8;
  }
  .v-list-item.current-assigned-user {
    background-color: #e0f0ff;
  }
</style>
